import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CreateIcon from "@material-ui/icons/Create";
import { OrderFormWrapper, Label, Input } from "../../styles/Order";
import { ButtonSmall } from "../../styles/Forms";
import { usePermissionChecker } from "../../utils/user";
import _ from "moment";

const DeliveryItems = ({ order, edit, resetView }) => {
  const [editMode, setEditMode] = useState(false);
  const [quantities, setQuantites] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [totalObj, setTotalObj] = useState({});
  const [total, setTotal] = useState(0);
  const success = useSelector(state => state.stateManager.success);
  const error = useSelector(state => state.stateManager.error);
  const message = useSelector(state => state.stateManager.message);

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      dispatch({ type: "RESET_VIEW" });
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    }
  });

  useEffect(() => {
    if (success) {
      dispatch({ type: "RESET_VIEW" });
    }
    var totalObj = {};

    order.items.map((item, index) => {
      totalObj[index] = item.cost;
    });
    setTotalObj(totalObj);
    setTotal(order.cost);
  }, []);

  const handleQuantityChange = (index, event, price) => {
    if (event.target.value === "") {
      setQuantites({ ...quantities, [index]: event.target.value });

      setIsSubmitDisabled(true);
    } else {
      let quantity = parseInt(event.target.value);
      setQuantites({ ...quantities, [index]: event.target.value });
      setTotalObj({ ...totalObj, [index]: quantity * price });

      let totalArr = Object.keys(totalObj).map(key => totalObj[key]);
      totalArr[index] = quantity * price;
      let total = totalArr.reduce((a, b) => a + b, 0);
      setTotal(total);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {success && <p className="alert alert-success">{message}</p>}
          {error && <p className="alert alert-danger">{message}</p>}
        </div>
      </div>
      <div className="row pb-3">
        <div className="col-sm-6"></div>
        <div className="col-sm-6">
          {usePermissionChecker("can_edit_order_items") && (
            <div
              onClick={() => setEditMode(true)}
              className="float-right pointer sfa-btn-bordered"
            >
              <CreateIcon className="primary pointer fs-12 " />{" "}
              <span className="primary font-weight-bold">Edit Order</span>
            </div>
          )}
        </div>
      </div>

      <OrderFormWrapper>
        <div className="row row-bg">
          <div className="col-sm-4 ">Items</div>
          <div className="col-sm-4 text-center">qty</div>
          <div className="col-sm-4 ">amount</div>
        </div>

        {order &&
          order.items &&
          order.items.length > 0 &&
          order.items.map((item, index) => (
            <div className="row row-items" key={item.orderItemId}>
              <div className="col-sm-4 ">
                <span className="pl-2">{item.productName}</span>
              </div>
              {!editMode && (
                <div className="col-sm-4 text-center">{item.quantity}</div>
              )}

              {editMode && (
                <div className="col-sm-4 text-center">
                  <Input
                    type="number"
                    key={item.identity}
                    name={`quantity-${item.identity}`}
                    placeholder={item.quantity}
                    min="1"
                    value={quantities[index]}
                    onChange={e => handleQuantityChange(index, e, item.price)}
                    disabled={quantities[index] === 0}
                  />
                </div>
              )}

              <div className="col-sm-4">
                {quantities[index] ? item.price * quantities[index] : item.cost}
              </div>
            </div>
          ))}
      </OrderFormWrapper>
    </>
  );
};

export default DeliveryItems;
