import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

import {
  LOADING,
  ON_CLICK_SUCCESS,
} from "../../../store/constants/ActionTypes";
import {
  getTreeMapEventAction,
  updateSelectedVetID,
  clearSateAction,
  getTopProductsSalesByVericalAction,
} from "../../../store/actions/ReportsAction";
import { getVerticalOptionsAction } from "../../../store/actions/ProductAction";
import { updateReconStatus } from "../../../services/SalesService";

const TreeMapChart = ({ title, yaxisData, startDate, endDate }) => {
  const [from, setFrom] = useState(null);
  const [dataSelectedIndex, setDataSelectedIndex] = useState(null);
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [to, setTo] = useState(null);
  const [options, setOptions] = useState(null);
  const [fetch, setFetch] = useState({});
  const [vetId, setVetId] = useState(null);
  const verticalOptions = useSelector(
    (state) => state.productManager.verticalOptions
  );
  const topProductsSales = useSelector(
    (state) => state.reportManager.topProductsSales
  );
  const savedStartDate = useSelector((state) => state.reportManager.startDate);
  const savedEndDate = useSelector((state) => state.reportManager.endDate);
  const canShowTopProducts = useSelector(
    (state) => state.reportManager.canShowTopProducts
  );

  const savedId = useSelector((state) => state.reportManager.vetID);

  const topProductsMargin = useSelector(
    (state) => state.reportManager.topProductsMargin
  );
  const topVertical = useSelector((state) => state.reportManager.topVertical);
  const dispatch = useDispatch();

  useEffect(() => {
    //setOptions(graphOptions);
    setFrom(startDate);
    setTo(endDate);
  }, [savedStartDate, startDate, topProductsSales]);

  useEffect(() => {
    console.log(
      "Tree Data changed",
      topProductsSales.treedata,
      selectedVerticals,
      dataSelectedIndex
    );
    dispatch(
      getTreeMapEventAction(
        selectedVerticals,
        dataSelectedIndex,
        topProductsSales.treedata
      )
    );
  }, [topProductsSales.treedata]);

  console.log("Changes to  startDate + endDate ", startDate, endDate);

  // useEffect(() => {
  //   console.log("find Prods call",savedId)
  //   let fetchtProducts = {
  //     vertical: savedId,
  //     startDate:savedStartDate,
  //     endDate: savedEndDate,
  //   };
  //   console.log("NOW SUBMIT ---==>>.",fetchtProducts)
  //   dispatch(getTopProductsSalesByVericalAction(fetchtProducts));

  // }, [savedStartDate,savedEndDate])
  // const findProducts = () => {
  //   console.log("find Prods call",savedId)
  //   let fetchtProducts = {
  //     vertical: savedId,
  //     startDate: savedStartDate,
  //     endDate: savedEndDate,
  //   };
  //   console.log("NOW SUBMIT ---==>>.",fetchtProducts)
  //   dispatch(getTopProductsSalesByVericalAction(fetchtProducts));
  // }

  useEffect(() => {
    console.log(
      "Plot Vertical Options  startDate + endDate  ",
      savedStartDate,
      savedEndDate
    );
    const graphOptions = {
      legend: {
        show: true,
      },

      chart: {
        height: 350,
        type: "treemap",
        events: {
          click: function (event, chartContext, config) {
            console.log(
              "Vertical clicked startDate + endDate + SavedvetId ",
              savedStartDate,
              savedEndDate,
              savedId
            );
            var eventArr = config.globals.categoryLabels;
            var selectedIndex = config.dataPointIndex;
            setDataSelectedIndex(selectedIndex);
            setSelectedVerticals(eventArr);

            //  dispatch(getTreeMapEventAction(eventArr,selectedIndex));
            dispatch(getVerticalOptionsAction());
            if (eventArr && eventArr.length !== 0) {
              const selectedVertical = eventArr.filter((item, index) => {
                return index === selectedIndex;
              });

              var verticalId = verticalOptions
                .filter((item) => {
                  return item.label === selectedVertical[0];
                })
                .map((item) => item.value);

              // let fetchtProducts = {
              //   vertical: verticalId[0],
              //   startDate: savedStartDate,
              //   endDate: savedEndDate,
              // };
              // console.log(" verticalId[0] --->", verticalId[0])
              // setVetId(verticalId[0])
              // dispatch(
              //   updateSelectedVetID(
              //     verticalId[0]
              //   ));
              // console.log("on Submit date not null-.",fetchtProducts)

              if (
                (startDate === null || startDate === undefined) &&
                (endDate !== null || endDate !== undefined)
              ) {
                let fetchtProducts = {
                  vertical: verticalId[0],
                  startDate: null,
                  endDate: null,
                };
                dispatch(getTopProductsSalesByVericalAction(fetchtProducts));
                dispatch(
                  getTreeMapEventAction(
                    eventArr,
                    selectedIndex,
                    topProductsSales.treedata
                  )
                );
              } else if (
                (startDate !== null || startDate !== undefined) &&
                (endDate !== null || endDate !== undefined)
              ) {
                let fetchtProducts = {
                  vertical: verticalId[0],
                  startDate: savedStartDate,
                  endDate: savedEndDate,
                };
                dispatch(getTopProductsSalesByVericalAction(fetchtProducts));
              }
            }

            setTimeout(() => dispatch(clearSateAction()), 5000);
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      title: {
        text: title,
        align: "center",
      },
      colors: [
        "#3B93A5",
        "#F7B844",
        "#ADD8C7",
        "#EC3C65",
        "#CDD7B6",
        "#C1F666",
        "#D43F97",
        "#1E5D8C",
        "#421243",
        "#7F94B0",
        "#EF6537",
        "#C0ADDB",
      ],

      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
    };

    setOptions(graphOptions);
  }, [startDate]);

  const series = [
    {
      data: yaxisData,
    },
  ];

  return (
    <>
      {options && (
        <ReactApexChart
          options={options}
          series={series}
          type="treemap"
          height={300}
        />
      )}
    </>
  );
};

export default TreeMapChart;
