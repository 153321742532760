import axios from "axios";
import {
  APP_URL,
  contentTypeApplicationJson,
  contentTypeUrlEncoded,
} from "../constants";

export const createTerritory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/add/area`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateTerritory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/edit/area`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getTerritories = async (data) => {
  const result = await axios
    .post(`${APP_URL}outletmanagement/outletService/fetch/areas`)
    .then((data) => data);
  return result;
};
