import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
export function checkPermission(permissionToCheck, permissions) {
  if (permissions && permissionToCheck) {
    let filteredPermission = _.find(permissions, { name: permissionToCheck });
    if (filteredPermission) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function usePermissionChecker(permissionToCheck) {
  var permissions = useSelector((state) => state.authManager.permissions);

  if (permissions) {
    let filteredPermission = _.find(permissions, {
      name: permissionToCheck,
    });
    if (filteredPermission) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
