import {
  getDailySalesReport,
  geOutletsCountByType,
  geLeadsCountByType,
  getOrdersBreakDown,
  getAverageOutletOrderValue,
  getUsersByRole,
  getUsersByStatus,
  geOutletsByUsers,
  geOutletsCreationTrend,
  geOutletsOrderingTrend,
  getOrderStatusSummary,
  getDailyOrderTrend,
  getFrequentlyOrderProduct,
  geOrderProcessByUser,
  getPercentOfOrderProcess,
  getTopVertical,
  getTopVerticalByMargin,
  getProductMarginTrend,
  getTopCustomers,
  getTopCustomersByOutletType,
  getSalesReport,
  getTotalRevenue,
  fetchUsers,
  geOutletsPurchaseHistory,
  getDailyOrderSummary,
  geOutletsCreationDaily,
  getHourlyOrderProcessing,
  getSalesDistributionMap,
  getTopMarginProducts,
  getDailyOrderPerVertical,
  geOutletsStatus,
  geUsersStatus,
  fetchAllTargets,
  getOutletSalesDistribution,
} from "../../services/ReportsService";
// import {fetchUsers } from "../../services/UserService";
import { getOrders } from "../../services/OrderService";
import { handleErrorAction } from "./UtilsAction";
import {
  GET_DAILY_SALES_REPORT_SUCCESS,
  GET_OUTLETS_COUNT_BY_TYPE_SUCCESS,
  GET_LEADS_COUNT_BY_TYPE_SUCCESS,
  GET_ORDERS_BREAKDOWN_SUCCESS,
  GET_OUTLET_AVERAGE_ORDER_VALUE_SUCCESS,
  GET_USERS_BY_ROLE_SUCCESS,
  GET_USERS_BY_STATUS_SUCCESS,
  GET_OUTLETS_BY_USERS_SUCCESS,
  GET_OUTLETS_CREATION_TREND_SUCCESS,
  GET_OUTLETS_ORDERING_TREND_SUCCESS,
  GET_ORDER_STATUS_SUCCESS,
  GET_DAILY_ORDER_TREND_SUCCESS,
  GET_FREQUENTLY_ORDERED_PRODUCT_SUCCESS,
  GET_ORDER_PROPCESS_BY_USER_SUCCESS,
  GET_PERCENT_ORDER_PROCESS_SUCCESS,
  GET_TOP_VERTICAL_SUCCESS,
  GET_TOP_VERTICAL_BY_MARGIN_SUCCESS,
  GET_PRODUCT_MARGIN_TREND_SUCCESS,
  GET_TOP_CUSTOMERS_SUCCESS,
  GET_TOP_CUSTOMERS_BY_OUTLET_TYPE_SUCCESS,
  GET_SALES_REPORT_SUCCESS,
  GET_TOTAL_REVENUE_SUCCESS,
  GET_TOTAL_ORDER_COUNT_SUCCESS,
  GET_USERS_REPORT_SUCCESS,
  GET_OUTLETS_PURCHASE_HISTORY_SUCCESS,
  ON_CLICK_SUCCESS,
  GET_TREEMAP_EVENT_SUCCESS,
  GET_DAILY_ORDER_SUMMARY_REPORT_SUCCESS,
  GET_DAILY_OUTLET_CREATION_SUCCESS,
  GET_HOURLY_ORDER_PROCESSIGNG_SUCCESS,
  GET_SALES_DISTRIBUTION_SUCCESS,
  GET_TOP_PRODUCTS_SALES_BY_VERTICAL_SUCCESS,
  CLEAR_STATE_SUCCESS,
  GET_TOP_PRODUCTS_MARGIN_BY_VERTICAL_SUCCESS,
  GET_TREEMAP_MARGIN_EVENT_SUCCESS,
  GET_DAILY_ORDER_PER_VERTICAL_SUCCESS,
  GET_OUTLETS_STATUS_SUCCESS,
  GET_USERS_STATUS_SUCCESS,
  GET_ALL_TARGETS_SUCCESS,
  GET_OUTLET_SALES_DISTRIBUTION_SUCCESS,
  UPDATE_START_DATE,
  UPDATE_END_DATE,
  UPDATE_VET_ID,
  TOGGLE_TOP_PRODUCTS_VISIBILITY,
} from "../constants/ActionTypes";
import moment from "moment";
import _ from "lodash";
import { RepeatOneSharp } from "@material-ui/icons";
const google = window.google;

//sales
export const getDailySalesReportAction = (data) => {
  return (dispatch) => {
    return getDailySalesReport(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var dailySaleDate = [];
            var totalAmount = [];
            for (const element of response.data) {
              dailySaleDate.push(element.date);
              totalAmount.push(
                parseInt(element.total === null ? 0 : element.total)
              );
            }
          }
          dispatch({
            type: GET_DAILY_SALES_REPORT_SUCCESS,
            payload: {
              dailySaleDate,
              totalAmount,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTopVerticalAction = (data) => {
  return (dispatch) => {
    return getTopVertical(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var treedata = response.data.map((item) => {
              return {
                date: item.date,
                x: item.vertical,
                y: parseFloat(item.amount),
              };
            });
          }
          console.log("treedata----->", treedata);
          if (treedata.length > 0) {
            dispatch({
              type: GET_TOP_VERTICAL_SUCCESS,
              payload: {
                treedata,
              },
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTopProductsSalesByVericalAction = (data) => {
  return (dispatch) => {
    return getTopMarginProducts(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            console.log("****|||response.data|||**** =>=>", response.data);
            var treedata = response.data.map((item) => {
              return {
                date: item.date,
                x: item.product,
                y: parseFloat(item.amount),
              };
            });
          }
          if (treedata.length > 0) {
            dispatch({
              type: GET_TOP_PRODUCTS_SALES_BY_VERTICAL_SUCCESS,
              payload: {
                treedata,
              },
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTopProductsMarginByVericalAction = (data) => {
  return (dispatch) => {
    return getTopMarginProducts(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var treedata = response.data.map((item) => {
              return {
                date: item.date,
                x: item.product,
                y: parseInt(item.margin),
              };
            });
          }
          if (treedata.length > 0) {
            dispatch({
              type: GET_TOP_PRODUCTS_MARGIN_BY_VERTICAL_SUCCESS,
              payload: {
                treedata,
              },
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTopVerticalByMarginAction = (data) => {
  return (dispatch) => {
    return getTopVerticalByMargin(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var treedata = response.data.map((item) => {
              return {
                date: item.date,
                x: item.vertical,
                y: parseInt(item.margin),
              };
            });
          }
          if (treedata.length > 0) {
            dispatch({
              type: GET_TOP_VERTICAL_BY_MARGIN_SUCCESS,
              payload: {
                treedata,
              },
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getProductMarginTrendAction = (data) => {
  return (dispatch) => {
    return getProductMarginTrend(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var marginTrendDate = [];
            var marginTrendTotal = [];
            for (const element of response.data) {
              marginTrendDate.push(element.date);
              marginTrendTotal.push(parseFloat(element.total).toFixed(2));
            }
          }
          dispatch({
            type: GET_PRODUCT_MARGIN_TREND_SUCCESS,
            payload: {
              marginTrendDate,
              marginTrendTotal,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTopCustomersAction = (data) => {
  return (dispatch) => {
    return getTopCustomers(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          dispatch({
            type: GET_TOP_CUSTOMERS_SUCCESS,
            payload: Array.isArray(response.data)
              ? response.data
              : [response.data],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTopCustomersByOutletTypeAction = (data) => {
  return (dispatch) => {
    return getTopCustomersByOutletType(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          dispatch({
            type: GET_TOP_CUSTOMERS_BY_OUTLET_TYPE_SUCCESS,
            payload: Array.isArray(response.data)
              ? response.data
              : [response.data],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getSalesReportAction = (data) => {
  return (dispatch) => {
    return getSalesReport(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          dispatch({
            type: GET_SALES_REPORT_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : response.data.responseObject === null
              ? []
              : [response.data.responseObject],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOutletSalesDistributionAction = (data) => {
  return (dispatch) => {
    return getOutletSalesDistribution(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          console.log(
            "getOutletSalesDistribution response response.data = ",
            response.data
          );
          dispatch({
            type: GET_OUTLET_SALES_DISTRIBUTION_SUCCESS,
            payload: response.data ?? [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTotalRevenueAction = (data) => {
  return (dispatch) => {
    return getTotalRevenue(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var revenue = [];
            var cost = [];
            var margin = [];
            var startDate = [];
            var endDate = [];
            for (const element of [response.data]) {
              revenue.push(parseFloat(element.value).toFixed(2));
              cost.push(parseFloat(element.cost).toFixed(2));
              margin.push(parseFloat(element.margin).toFixed(2));
              startDate.push(element.endDate);
              endDate.push(element.endDate);
            }
          }
          dispatch({
            type: GET_TOTAL_REVENUE_SUCCESS,
            payload: {
              revenue,
              cost,
              margin,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOrdersTransactAction = (data) => {
  return (dispatch) => {
    return getOrders(data).then(
      (response) => {
        if (response.data.code === 200) {
          let orders = response.data.responseObject
            ? response.data.responseObject
            : [];
          var order = orders.filter((item) => item.orderStatus === "PROCESSED");

          return dispatch({
            type: GET_TOTAL_ORDER_COUNT_SUCCESS,
            payload: { processed: order.length },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

//orders
export const getOrdersBreakDownAction = (data) => {
  return (dispatch) => {
    return getOrdersBreakDown(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var orderPlaced = [];
            var orderProcessed = [];
            var orderDate = [];
            for (const element of response.data) {
              orderDate.push(element.date);
              orderPlaced.push(element.placed);
              orderProcessed.push(parseInt(element.processed));
            }
          }
          dispatch({
            type: GET_ORDERS_BREAKDOWN_SUCCESS,
            payload: {
              orderDate,
              orderPlaced,
              orderProcessed,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getSalesDistributionMapAction = (data) => {
  return (dispatch) => {
    return getSalesDistributionMap(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          console.log("reposne from map", response.data);
          const heatmap = response.data.map((item, index) => {
            return {
              id: index,
              location: new google.maps.LatLng(
                parseFloat(item.latitude),
                parseFloat(item.longitude)
              ),
              weight: parseInt(item.amount),
              area: item.area,
              latitude: parseFloat(item.latitude),
              longitude: parseFloat(item.longitude),
              total: item.total,
            };
          });
          dispatch({
            type: GET_SALES_DISTRIBUTION_SUCCESS,
            payload: heatmap,
            //  payload:response.data
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getDailyOrderSummaryAction = (data) => {
  return (dispatch) => {
    return getDailyOrderSummary(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var orderPlaced = [];
            var orderProcessed = [];
            var orderDate = [];
            for (const element of response.data) {
              orderDate.push(element.date);
              orderPlaced.push(element.placed);
              orderProcessed.push(parseInt(element.processed));
            }
          }
          dispatch({
            type: GET_DAILY_ORDER_SUMMARY_REPORT_SUCCESS,
            payload: {
              orderDate,
              orderPlaced,
              orderProcessed,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getHourlyOrderProcessingAction = (data) => {
  return (dispatch) => {
    return getHourlyOrderProcessing(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var rate = [];
            var date = [];
            for (const element of response.data) {
              date.push(element.date);
              rate.push(parseFloat(element.rate).toFixed(2));
            }
          }
          dispatch({
            type: GET_HOURLY_ORDER_PROCESSIGNG_SUCCESS,
            payload: {
              date,
              rate,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};
export const getAverageOutletOrderValueAction = (data) => {
  return (dispatch) => {
    return getAverageOutletOrderValue(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          let orders = response.data;
          dispatch({
            type: GET_OUTLET_AVERAGE_ORDER_VALUE_SUCCESS,
            payload: orders,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOrderStatusSummaryAction = (data) => {
  return (dispatch) => {
    return getOrderStatusSummary(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var orderCount = [];
            var orderStatus = [];
            for (const element of response.data) {
              orderCount.push(parseInt(element.count));
              orderStatus.push(element.status);
            }
          }
          dispatch({
            type: GET_ORDER_STATUS_SUCCESS,
            payload: {
              orderStatus,
              orderCount,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getDailyOrderTrendAction = (data) => {
  return (dispatch) => {
    return getDailyOrderTrend(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data && response.data != undefined) {
            var orderTrendDate = [];
            var orderTrendTotal = [];
            for (const element of response.data) {
              orderTrendTotal.push(parseInt(element.total));
              orderTrendDate.push(element.date);
            }
          }
          dispatch({
            type: GET_DAILY_ORDER_TREND_SUCCESS,
            payload: {
              orderTrendTotal,
              orderTrendDate,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};
//////////////////////////// PENDING
const result = [
  {
    date: "16 Apr 2021",
    data: [
      {
        vertical: "Drinks",
        value: 2,
      },
      {
        vertical: "Glocery",
        value: 3,
      },
    ],
  },
  {
    date: "17 Apr 2021",
    data: [
      {
        vertical: "Drinks",
        value: 21,
      },
      {
        vertical: "Glocery",
        value: 9,
      },
    ],
  },
];

var date = [];
var data = [];
for (const element of result) {
  date.push(element.date);
  data.push(
    result.map((item) => {
      return {
        verticalName: item.vertical,
        verlues: item.value,
      };
    })
  );
}

export const getDailyOrderPerVerticalAction = (data) => {
  return (dispatch) => {
    return getDailyOrderPerVertical(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          let orders = response.data;
          dispatch({
            type: GET_DAILY_ORDER_PER_VERTICAL_SUCCESS,
            payload: orders,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getFrequentlyOrderProductAction = (data) => {
  return (dispatch) => {
    return getFrequentlyOrderProduct(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var frequentAmount = [];
            var frequentValue = [];
            var frequentProduct = [];
            for (const element of response.data) {
              frequentAmount.push(parseInt(element.amount));
              frequentValue.push(parseInt(element.value));
              frequentProduct.push(element.product);
            }
          }
          dispatch({
            type: GET_FREQUENTLY_ORDERED_PRODUCT_SUCCESS,
            payload: {
              frequentAmount,
              frequentValue,
              frequentProduct,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const geOrderProcessByUserAction = (data) => {
  return (dispatch) => {
    return geOrderProcessByUser(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var repsName = [];
            var orderProcessed = [];
            var orderPlaced = [];
            let orders = response.data.filter((item) => item.processed != 0);
            for (const element of orders) {
              repsName.push(element.name);
              orderProcessed.push(parseInt(element.processed));
              orderPlaced.push(parseInt(element.placed));
            }
          }
          dispatch({
            type: GET_ORDER_PROPCESS_BY_USER_SUCCESS,
            payload: {
              repsName,
              orderProcessed,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getPercentOfOrderProcessAction = (data) => {
  return (dispatch) => {
    return getPercentOfOrderProcess(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          dispatch({
            type: GET_PERCENT_ORDER_PROCESS_SUCCESS,
            payload: Array.isArray(response.data)
              ? response.data
              : [response.data],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

//outlets
export const geOutletsCountByTypeAction = (data) => {
  return (dispatch) => {
    return geOutletsCountByType(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var totalOutlets = response.data.reduce(
              (a, v) => (a = a + v.count),
              0
            );
            var outletType = [];
            var outletTypeCount = [];
            for (const element of response.data) {
              outletType.push(element.type === "" ? "Not set" : element.type);
              outletTypeCount.push(parseInt(element.count));
            }
          }

          dispatch({
            type: GET_OUTLETS_COUNT_BY_TYPE_SUCCESS,
            payload: {
              outletType,
              outletTypeCount,
              totalOutlets,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const geOutletsStatusAction = (data) => {
  return (dispatch) => {
    return geOutletsStatus(data).then(
      (response) => {
        console.log("response.data-=-=-", response);
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var active = [];
            var inactive = [];
            for (const element of [response.data]) {
              active.push(parseInt(element.active));
              inactive.push(parseInt(element.inactive));
            }
          }

          dispatch({
            type: GET_OUTLETS_STATUS_SUCCESS,
            payload: {
              active,
              inactive,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const geUsersStatusAction = (data) => {
  return (dispatch) => {
    return geUsersStatus(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var active = [];
            var inactive = [];
            for (const element of [response.data]) {
              active.push(parseInt(element.active));
              inactive.push(parseInt(element.inactive));
            }
          }

          dispatch({
            type: GET_USERS_STATUS_SUCCESS,
            payload: {
              active,
              inactive,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const geLeadsCountByTypeAction = (data) => {
  return (dispatch) => {
    return geLeadsCountByType(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var totalLeads = response.data.reduce(
              (a, v) => (a = a + v.count),
              0
            );
            var leadType = [];
            var leadCount = [];
            for (const element of response.data) {
              leadType.push(element.type === "" ? "Not set" : element.type);
              leadCount.push(parseInt(element.count));
            }
          }

          dispatch({
            type: GET_LEADS_COUNT_BY_TYPE_SUCCESS,
            payload: { leadType, leadCount, totalLeads },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const geOutletsByUsersAction = (data) => {
  return (dispatch) => {
    return geOutletsByUsers(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var repsWholesale = [];
            var repsRetail = [];
            var repsShop = [];
            var repsIndividual = [];
            var repsName = [];
            let outlets = response.data.filter(
              (item) =>
                item.Wholesale !== 0 ||
                item.Retail !== 0 ||
                item.Shop !== 0 ||
                item.Individual !== 0
            );
            for (const element of outlets) {
              repsName.push(element.name);
              repsWholesale.push(parseInt(element.Wholesale));
              repsRetail.push(parseInt(element.Retail));
              repsShop.push(parseInt(element.Shop));
              repsIndividual.push(parseInt(element.Individual));
            }
          }
          dispatch({
            type: GET_OUTLETS_BY_USERS_SUCCESS,
            payload: {
              repsName,
              repsWholesale,
              repsRetail,
              repsShop,
              repsIndividual,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const geOutletsCreationTrendAction = (data) => {
  return (dispatch) => {
    return geOutletsCreationTrend(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var outletsCreatedDate = [];
            var createdWholesale = [];
            var createdRetail = [];
            var createdShop = [];
            var createdIndividual = [];

            for (const element of response.data) {
              outletsCreatedDate.push(element.date);
              createdWholesale.push(parseInt(element.Wholesale));
              createdRetail.push(parseInt(element.Retail));
              createdShop.push(parseInt(element.Shop));
              createdIndividual.push(parseInt(element.Individual));
            }
          }
          dispatch({
            type: GET_OUTLETS_CREATION_TREND_SUCCESS,
            payload: {
              outletsCreatedDate,
              createdWholesale,
              createdRetail,
              createdShop,
              createdIndividual,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const geOutletsCreationDailyAction = (data) => {
  return (dispatch) => {
    return geOutletsCreationDaily(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          let outlets = response.data;
          dispatch({
            type: GET_DAILY_OUTLET_CREATION_SUCCESS,
            payload: outlets,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const geOutletsOrderingTrendAction = (data) => {
  return (dispatch) => {
    return geOutletsOrderingTrend(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var outletOrderingDate = [];
            var outletOrderingderingValue = [];
            for (const element of response.data) {
              outletOrderingDate.push(element.date);
              outletOrderingderingValue.push(parseFloat(element.value).toFixed(2));
            }
          }
          dispatch({
            type: GET_OUTLETS_ORDERING_TREND_SUCCESS,
            payload: {
              outletOrderingDate,
              outletOrderingderingValue,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const geOutletsPurchaseHistoryAction = (data) => {
  return (dispatch) => {
    return geOutletsPurchaseHistory(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          let purchaseHistory = response.data;
          dispatch({
            type: GET_OUTLETS_PURCHASE_HISTORY_SUCCESS,
            payload: purchaseHistory,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

//users
export const geUsersByRoleAction = (data) => {
  return (dispatch) => {
    return getUsersByRole(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var userRole = [];
            var userRoleCount = [];
            for (const element of response.data) {
              userRole.push(element.type);
              userRoleCount.push(parseInt(element.count));
            }
          }
          dispatch({
            type: GET_USERS_BY_ROLE_SUCCESS,
            payload: {
              userRole,
              userRoleCount,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const geUsersByStatusAction = (data) => {
  return (dispatch) => {
    return getUsersByStatus(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          if (response.data != undefined) {
            var statusRole = [];
            var activeStatus = [];
            var inactiveStatus = [];
            for (const element of response.data) {
              statusRole.push(element.role);
              activeStatus.push(parseInt(element.active));
              inactiveStatus.push(parseInt(element.inactive));
            }
          }
          dispatch({
            type: GET_USERS_BY_STATUS_SUCCESS,
            payload: {
              statusRole,
              activeStatus,
              inactiveStatus,
            },
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const fetchAllTargetsAction = (data) => {
  return (dispatch) => {
    return fetchAllTargets(data).then(
      (response) => {
        console.log("FETCH TARGET RESPONSE", response);
        if (response.data && response.data.code === 200) {
          if (response.data.responseObject != undefined) {
            var usernames = [];
            var fullname = [];
            var target = [];
            var achieved = [];
            for (const element of response.data.responseObject) {
              usernames.push(element.username);
              fullname.push(element.fullname);
              target.push(parseInt(element.active));
              achieved.push(parseInt(element.inactive));
            }
          }
          dispatch({
            type: GET_ALL_TARGETS_SUCCESS,
            payload: response.data.responseObject ?? [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUsersReportAction = (data) => {
  console.log("get user called");
  return (dispatch) => {
    return fetchUsers(data).then(
      (response) => {
        console.log("GET USERSSSSSSS", response);
        // dispatch({
        //   type: GET_USERS_REPORT_SUCCESS,
        //   payload: Array.isArray(response.data.responseObject)
        //     ? response.data.responseObject
        //     : [response.data.responseObject],
        //   total: response.data.total,
        //   totalActive: response.data.totalActive,
        //   totalInactive: response.data.totalInactive,
        // })
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTreeMapEventAction = (eventArr, selectedIndex, treedata) => {
  return (dispatch) => {
    dispatch({
      type: GET_TREEMAP_EVENT_SUCCESS,
      payload: {
        eventArr,
        selectedIndex,
        treedata,
      },
    });
  };
};

export const getTreeMapMarginEventAction = (
  eventArr,
  selectedIndex,
  treedata
) => {
  return (dispatch) => {
    dispatch({
      type: GET_TREEMAP_MARGIN_EVENT_SUCCESS,
      payload: {
        eventArr,
        selectedIndex,
        treedata,
      },
    });
  };
};

export const clearSateAction = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_STATE_SUCCESS, payload: [] });
  };
};

export const updateStartDateAction = (date) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_START_DATE, payload: date });
  };
};

export const updateEndDateAction = (date) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_END_DATE, payload: date });
  };
};

export const updateTopProductVisibilityAction = (date) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_TOP_PRODUCTS_VISIBILITY, payload: date });
  };
};

export const updateSelectedVetID = (vetID) => {
  console.log("vetIDvetIDvetIDvetID ::::)))", vetID);
  return (dispatch) => {
    dispatch({ type: UPDATE_VET_ID, payload: vetID });
  };
};
