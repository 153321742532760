import axios from "axios";
import {
  APP_URL,
  contentTypeApplicationJson,
  contentTypeUrlEncoded,
} from "../constants";
import qs from "qs";
export const getOutlets = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/fetch/outlets`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((res) => res);
  return result;
};

export const getOutletVerticals = async (outletNumber) => {
  const result = await axios
    .get(
      `${APP_URL}productmanagement/productService/get/outlet/verticals/${outletNumber}`
    )
    .then((res) => res);
  return result;
};

export const getOutletsByTerritory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/filter/outlets`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((res) => res);
  return result;
};

export const getOutletSummary = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/outlets/summary/status`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((res) => res);
  return result;
};

export const getOutletDetails = async (outletNumber) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/fetch/outlet/${outletNumber}`
    )
    .then((res) => res);
  return result;
};

export const getOutletCategories = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/fetch/all/outlet/category`,
      {},
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const assignOutlets = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/process/assignment`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const assignVerticalToOutlet = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/add/outlet/vertical`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const createOutletCategory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/add/outlet/category`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateOutletCategory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/update/outlet/category`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getOutletSubcategories = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/fetch/all/outlet/subcategory`,
      {},
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const createOutletSubcategory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/add/outlet/subcategory`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateOutletSubategory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/update/outlet/subcategory`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getOutletOrders = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/outlet/orders`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((res) => res);
  return result;
};

export const createOutlet = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/create/outlet`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateOutlet = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/update/outlet`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const changeOutletStatus = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/change/outlet/status`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getOutletProductPrices = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/fetchOutletProductPrices`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((res) => res);
  return result;
};

export const changeOutletCreditStatus = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletService/change/credit/status`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((res) => res);
  return result;
};

export const setCreditLimit = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/setCreditLimit`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getOutletsCreditLimit = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetchOutletTypeCreditLimits`,
      qs.stringify({}),
      contentTypeUrlEncoded
    )
    .then((res) => res);
  return result;
};

export const getOutletCreditLimit = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetchOutletTypeCreditLimit`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((res) => res);
  return result;
};
