import React from "react";
import { appVersion } from "../../../constants";
import "./Footer.css";
export const Footer = () => {
  return (
    <footer>
      A product by AIMGROUP © 2021 All Rights Reserved (App Version {appVersion}{" "}
      )
    </footer>
  );
};
