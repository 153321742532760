import axios from "axios";
import qs from "qs";
import { APP_URL, TOKEN_AUTH_URL, contentTypeUrlEncoded } from "../constants";

export const authenticate = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/authService/authenticate`,
      qs.stringify(data)
    )
    .then((data) => data);
  return result;
};

export const sendPasswordRecoveryToken = async (data) => {
  let headers = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
    },
  };
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/processPasswordRecoveryToken`,
      qs.stringify(data),
      headers
    )
    .then((data) => data);
  return result;
};

export const verifyPasswordRecoveryToken = async (data) => {
  let headers = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
    },
  };
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/verifyPasswordRecoveryToken`,
      qs.stringify(data),
      headers
    )
    .then((data) => data);
  return result;
};

export const processPasswordRecovery = async (data) => {
  let headers = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
    },
  };
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/processPasswordRecovery`,
      qs.stringify(data),
      headers
    )
    .then((data) => data);
  return result;
};

export const getAccessToken = (data) => {
  return axios.post(
    `${TOKEN_AUTH_URL}`,
    qs.stringify(data),
    contentTypeUrlEncoded
  );
};
