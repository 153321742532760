import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginLeftColumn from "./LoginLeftColumn";
import LoginForm from "./LoginForm";
import { isUserLoggedIn } from '../../utils/auth'

const LoginPage = () => {
  const isAuthenticated = useSelector(state => state.authManager.authenticated);
  return isAuthenticated ? (
    <Redirect to="/dashboard" />
  ) : (
    <div className="container-fluid">
      <div className="row">
        <LoginLeftColumn />
        <div className="col-sm-6">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
