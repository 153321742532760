import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserSalesTable from "./UserSalesTable";
import UserUnsoldTable from "./UserUnsoldTable";
import LoadingIndicator from "../../components/dom/LoadingIndicator";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const UserSales = () => {
  const isLoading = useSelector((state) => state.salesManager.isLoading);
  const userObj = useSelector((state) => state.userManager.user);
  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Tabs>
        <TabList>
          <Tab>Sold Products</Tab>
          <Tab>Unsold Products</Tab>
        </TabList>
        <TabPanel>
          <UserSalesTable />
        </TabPanel>
        <TabPanel>
          <UserUnsoldTable
            payload={{
              key: "returnedBy",
              value: userObj.username,
            }}
          />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default UserSales;
