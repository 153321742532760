import React, { useEffect, useRef } from "react";
import axios from "axios";
import ReactApexChart from 'react-apexcharts';
import { defaultOrderByFn } from "react-table";
import moment from "moment";


const AreaChart = ({title,yaxisData,xaxisData,xDataType,dateFormant,timestamp}) => {
  
   const series = yaxisData     
            
   const options =  {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: true
        },
        title: {
            text: title,
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type:xDataType,
          categories:xaxisData,
          labels: {
            rotate: -15,
            rotateAlways: true,
            formatter: function(val, timestamp) {
             return moment(new Date(timestamp)).format(dateFormant)
         //    return moment(new Date(timestamp)).format("DD MMM YYYY hh:mm")
            }
        
        },
        // tooltip: {
        //   x: {
        //     format: 'dd/MM/yy',
        //   },
        },
      };
    
    // const options = {
    //         chart: {
    //             height: 350,
    //             type: 'area',
    //         },
    //         dataLabels: {
    //             enabled: false
    //         },
    //         series: [],
    //         title: {
    //             text: 'Sales Chart',
    //         },
    //         noData: {
    //         text: 'Loading...'
    //         }
    //  }
  
    //  var url = 'http://my-json-server.typicode.com/apexcharts/apexcharts.js/yearly';
    //  console.log('url',url)
  
    //  axios({
    //         method: 'GET',
    //         url: url,
    //     }).then(function(response) {
    //         ReactApexChart.updateSeries([{
    //         name: 'Sales',
    //         data: response.data
    //         }])
    //     })
    //   console.log("apiData",apiData)

     

  return (
    <>
        <ReactApexChart 
            options={options} 
            series={series} 
            type="area"
            height={300} />

    
    </>
  );
};

export default AreaChart;
