import { EXPIRATION_WINDOW_IN_SECONDS,SESSION_EXPIRE_TIME} from "../constants";
import { getAccessToken } from "../services/AuthService";

const AuthHelper = (function() {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    localStorage.setItem("access_token", tokenObj.access_token);
    localStorage.setItem("refresh_token", tokenObj.refresh_token);
  }

  function _getAccessToken() {
    return localStorage.getItem("accessToken");
  }
  function _getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
  function _clearToken() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken
  };
})();
export default AuthHelper;


export function setSessionExpirationTime() {
  var now = new Date();
  now.setSeconds(now.getSeconds() + EXPIRATION_WINDOW_IN_SECONDS);
  sessionStorage.setItem(
    SESSION_EXPIRE_TIME,
    now.getTime()
  ); 
}

export function isUserLoggedIn() {
  let sessionNotExpired = timeIsValid(
    sessionStorage.getItem(SESSION_EXPIRE_TIME)
  );
  if (!sessionNotExpired) {
    return false;
  } else {
  
   setSessionExpirationTime();
  }
  return true;
}

function timeIsValid(theCheckTime) {
  var expireTime = new Date(parseFloat(theCheckTime));
  if (expireTime) {
    return expireTime > new Date();
  }
  
  return false;
}