import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../store/actions/AuthAction";
import { IsJsonString } from "../../utils/basic";
import moment from "moment";
import "./NotificationBubble.css";

const NotificationBubble = ({ user }) => {
  const notifications = useSelector((state) => state.userManager.notifications);
  const newNotificationCount = useSelector(
    (state) => state.userManager.newNotificationCount
  );
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutAction());
  };
  return (
    <div class="speech-bubble notification-bubble NotificationBubble">
      <div className="row">
        <div className="col-sm-12">
          <h5>You have {newNotificationCount} new message </h5>

          <hr />
          {notifications &&
            notifications.length > 0 &&
            notifications.map((notification) => {
              if (IsJsonString(notification)) {
                let jsonNotification = JSON.parse(notification);
                return (
                  <>
                    <p className="date">
                      {moment(jsonNotification.date).format("LLL")}
                    </p>
                    <p>
                      {jsonNotification.title} - {jsonNotification.body}
                    </p>

                    <div className="line"></div>
                  </>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default NotificationBubble;
