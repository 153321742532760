import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getProductsAction } from "../../store/actions/ProductAction";
import Table from "../../components/dom/Table/Table";
import { PageButton } from "../../styles/App";
import CreateIcon from "@material-ui/icons/Create";
import LoadingIndicator from "../../components/dom/LoadingIndicator";

const SuppliersTablePage = ({ addSupplier, edit }) => {
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const suppliers = useSelector((state) => state.supplierManager.suppliers);
  const isLoading = useSelector((state) => state.productManager.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(getProductsAction())
  }, []);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      key: ["name"],
    },
    {
      Header: "Supplier ID",
      accessor: "number",
      key: ["number"],
    },
    {
      Header: "TIN Number",
      accessor: "taxId",
      key: ["taxId"],
    },

    {
      Header: "Verticals",
      accessor: (supplier) =>
        supplier &&
        supplier.verticals &&
        supplier.verticals.map((cat) => <div>{cat.name}</div>),
      key: ["verticals"],
    },

    {
      Header: " ",

      Cell: (row) => (
        <div onClick={() => edit(row.row.original)}>
          <CreateIcon className="primary pointer" />
        </div>
      ),
    },
  ];

  return (
    <>
      {success && <p className="alert alert-success">{message}</p>}
      {/* {isLoading && <LoadingIndicator />} */}

      <div className="row">
        <div className="col-sm-2">
          <h4 className="mt-3">All Suppliers</h4>
        </div>
        <div className="col-sm-10">
          <PageButton className="float-right" onClick={addSupplier}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Supplier
          </PageButton>
        </div>
      </div>

      {suppliers && suppliers.length === 0 && (
        <p className="alert alert-info mt-3">No Suppliers at the moment</p>
      )}
      {suppliers && suppliers.length > 0 && (
        <Table data={suppliers} columns={columns} />
      )}
    </>
  );
};

export default SuppliersTablePage;
