import _ from "lodash";
export const removeKeys = (obj, arr) => {
  let myArr = [];
  Object.keys(obj).forEach((key) => {
    if (arr.indexOf(key) === -1) {
      delete obj[key];
    }
    myArr.push(obj);
  });
};

export const filterCategories = (arr, filter) => {
  var predicate = function() {
    return function(obj) {
      var equalToUserAge = _.partial(_.isEqual, obj.categoryId);
      return filter.some(equalToUserAge);
    };
  };

  var result = _.filter(arr, predicate(filter));
  return result;
};

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
