import React, { useCallback, useEffect, useRef, useState } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import {
  getTerritoryPolygonsAction,
  setTerritoryCoordinatesAction,
  updateTerritoryCoordinatesAction,
} from "../../store/actions/TerritoryAction";
import AddTerritoryForm from "../../pages/territory/AddTerritoryForm";
import { renderToStaticMarkup } from "react-dom/server";
import { updateMapPositionAction } from "../../store/actions/LocationAction";

const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const TerritoryCreator = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBwuLdw8RyUO2mNVZQ3f9ux34F_2duP_lU&v=3.exp&libraries=geometry,drawing,places ",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div
        style={{
          height: `600px`,
          display: "flex",
          flexDirection: "column-reverse",
        }}
      />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ editMode, territory, polygons }) => {
  const [path, setPath] = useState();
  const [mapRef, setMapRef] = useState(null);
  const [canShowOutlets, setCanShowOutlets] = useState(true);

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [infoOpen, setInfoOpen] = useState(false);
  const [zoom, setZoom] = useState(13);
  const mapPosition = useSelector((state) => state.locationManager.mapPosition);
  const [center, setCenter] = useState(mapPosition);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const locatedOutlets = useSelector(
    (state) => state.outletManager.locatedOutlets
  );

  const dispatch = useDispatch();

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onPolygonCompleted = (polygon) => {
    var coordinates = polygon.getPath().getArray();

    let LngLatArr = coordinates.map((coordinate) => [
      coordinate.lng(),
      coordinate.lat(),
    ]);

    LngLatArr.push(LngLatArr[0]);
    console.log("onPolygonCompleted LngLatArr = ", LngLatArr);
    dispatch(setTerritoryCoordinatesAction(LngLatArr));
  };

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  useEffect(() => {
    let updatedCoordinatesaArr =
      path &&
      path.length > 0 &&
      path.map((coordinate) => [coordinate.lng, coordinate.lat]);

    dispatch(updateTerritoryCoordinatesAction(updatedCoordinatesaArr));
  }, [path]);

  useEffect(() => {
    if (editMode) {
      // Store Polygon path in state
      let savedCenter = {
        lat: territory.coordinates[0][1],
        lng: territory.coordinates[0][0],
      };
      setCenter(savedCenter);
      console.log("Edit Mode Center = ", savedCenter);

      let latLongArr = territory.coordinates.map((coordinate) => ({
        lat: coordinate[1],
        lng: coordinate[0],
      }));

      dispatch(updateMapPositionAction(savedCenter));
      setPath(latLongArr);
    } else {
    }
  }, []);

  // useEffect(() => {
  //   //console.log("Map Position changed ", mapPosition);
  //   if (!editMode) {
  //     setCenter(mapPosition);
  //   }
  // }, [mapPosition]);

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    if (zoom < 13) {
      setZoom(13);
    }

    // if you want to center the selected Marker
    //setCenter(place.pos)
  };

  const loadHandler = (map) => {
    // Store a reference to the google map instance in state
    setMapRef(map);
    // Fit map bounds to contain all markers
    //fitBounds(map);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  return (
    <>
      <div className="row">
        {/* {JSON.stringify(center)} */}
        {!editMode && (
          <div className="col-sm-12">
            <p>
              Hint: Click at any point on the map to start drawing your
              territory
            </p>
          </div>
        )}
        {editMode && (
          <div className="col-sm-12">
            <AddTerritoryForm editMode={editMode} territory={territory} />
          </div>
        )}
      </div>

      <GoogleMap
        // Do stuff on map initial laod
        onLoad={loadHandler}
        // Save the current center position in state
        // onCenterChanged={() => setCenter(mapRef.getCenter().toJSON())}
        defaultZoom={zoom}
        defaultCenter={new window.google.maps.LatLng(center)}
        center={{ lat: center.lat, lng: center.lng }}
        version="weekly"
        on
      >
        {editMode ? (
          <Polygon
            // Make the Polygon editable / draggable
            editable
            draggable
            path={path}
            ref={polygonRef}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        ) : (
          <DrawingManager
            //defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
            defaultOptions={{
              drawingControl: true,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
              },
            }}
            onPolygonComplete={onPolygonCompleted}
          />
        )}

        {canShowOutlets &&
          locatedOutlets.length > 0 &&
          locatedOutlets.map((outlet) => {
            return (
              <Marker
                key={outlet.id}
                position={{ lat: outlet.latitude, lng: outlet.longitude }}
                onLoad={(marker) => markerLoadHandler(marker, outlet)}
                onClick={(event) => markerClickHandler(event, outlet)}
              />
            );
          })}

        {infoOpen && selectedPlace && (
          <InfoWindow
            anchor={markerMap[selectedPlace.id]}
            onCloseClick={() => setInfoOpen(false)}
            position={{
              lat: selectedPlace.latitude,
              lng: selectedPlace.longitude,
            }}
          >
            <div>
              <h3>{selectedPlace.name}</h3>
              {/* <p>{selectedPlace.address}</p> */}
              <p>{`${selectedPlace.latitude},${selectedPlace.longitude}`}</p>
            </div>
          </InfoWindow>
        )}

        {polygons &&
          polygons.length > 0 &&
          polygons.map((polygon) => (
            <Polygon
              // Make the Polygon editable / draggable

              path={polygon.path}
              // Event used when manipulating and adding points
              onMouseUp={onEdit}
              // Event used when dragging the whole Polygon
              onDragEnd={onEdit}
              onLoad={onLoad}
              onUnmount={onUnmount}
            ></Polygon>
          ))}
        {polygons &&
          polygons.length > 0 &&
          polygons.map((polygon) => (
            <InfoWindow
              position={{ lat: polygon.path[0].lat, lng: polygon.path[0].lng }}
            >
              <div>{polygon.name} </div>
            </InfoWindow>
          ))}
      </GoogleMap>
    </>
  );
});

export default TerritoryCreator;
