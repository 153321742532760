import React from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../store/actions/AuthAction";

const LogoutBubble = ({ user }) => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutAction());
  };
  return (
    <div class="speech-bubble">
      <div className="row">
        <div className="col-sm-3">
          <img
            src={user.picturePath}
            alt={user.firstName}
            class="rounded-circle img-thumbnail"
          />
        </div>

        <div className="col-sm-6 pl-0">
          <h5 className="logout-title">
            {user.firstName.charAt(0)}.{user.lastName}
          </h5>
          <p className="logout-role">{user.role}</p>
        </div>
        <div className="col-sm-3 pointer" onClick={logout}>
          <p className="logout-action">Logout</p>
        </div>
      </div>
    </div>
  );
};

export default LogoutBubble;
