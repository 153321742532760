import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { BrandTopLine } from "../../styles/Layout";
import Sidebar from "../layout/SideBar/SideBar";
import TopBar from "../layout/TopBar/TopBar";
import { isUserLoggedIn } from "../../utils/auth";
import { Footer } from "../layout/Footer/Footer";

const PrivateRoute = ({ children, ...rest }) => {
  const isAuthenticated = useSelector(
    (state) => state.authManager.authenticated
  );
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-2 pl-0">
                  {/* <Sidebar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                  /> */}
                </div>
                <div className="col-md-10 " id="appContainer">
                  {/* <TopBar /> */}
                  <Sidebar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                  />
                  <div className="bg-white p-4 ml-2 mb-4">{children}</div>
                </div>
                <Footer />
              </div>
            </div>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
