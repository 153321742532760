import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddTruckForm from "./AddTruckForm";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";

const AddTruckPage = ({ editMode, truck, resetView }) => {
  return (
    <div className="row">
      <div className="col-md-1 back-arrow">
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="2x"
          className="mr-2"
          onClick={() => resetView()}
        />
      </div>
      <div className="col-md-11">
        {!editMode && <PageTopHeader title="Truck Creation" />}
        {editMode && <PageTopHeader title="Edit Truck" />}
        <AddTruckForm resetView={resetView} editMode={editMode} truck={truck} />
      </div>
    </div>
  );
};

export default AddTruckPage;
