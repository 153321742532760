import {
  GET_ORDERS_SUCCESS,
  GET_ORDER_SUCCESS,
  GET_ORDER_DELIVERIES_SUCCESS,
  GET_PAYMENTS_SUCCESS,
  GET_FAIL_TO_ORDER_CUSTOMERS_SUCCESS,
  GET_FAIL_TO_DELIVER_ORDERS_SUCCESS,
  GET_DRIVER_ORDERS_SUCCESS,
  GET_ORDER_SUMMARY_SUCCESS,
  GET_PM_ORDERS_SUCCESS,
  GET_ORDERS_IN_ROUTE_SUCCESS,
  GET_RETURNED_PRODUCTS_SUCCESS,
  GET_ROUTE_ORDERS_SUMMARY_SUCCESS,
  GET_EXTRA_ORDERS_SUCCESS,
  GET_RECON_SUMMARY_SUCCESS,
  GET_ORDER_ITEM_DSICOUNT_SUCCESS,
  GET_ORDER_ITEMS_DSICOUNT_SUCCESS,
  CLEAR_DISCOUNT,
  LOAD_CALL_CONNECTION,
  ACTION_SUCCESS,
  RESET_CALL_CONNECTION,
  GET_ORDERED_PRODUCT_DETAILS_SUCCESS,
  GET_DAILY_ORDERS_SUCCESS,
  GET_ALL_ORDER_SUMMARY_SUCCESS,
} from "../constants/ActionTypes";
const initialState = {
  orders: [],
  order: null,
  deliveries: [],
  payments: [],
  couldntOrderCustomers: [],
  loading: true,
  driverOrders: [],
  orderSummary: {},
  routeOrders: [],
  returnedProducts: [],
  routeOrdersSummary: [],
  extraOrders: [],
  failToDeliverOrders: [],
  reconSummary: {},
  discountItems: [],
  orderedProducts: [],
  dailyOrders: [],
};

function OrderReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
        discountItems: {},
      };
    case GET_ORDER_SUMMARY_SUCCESS:
      return {
        ...state,
        orderSummary: {
          ...state.orderSummary,
          processed: action.payload.processed,
          placed: action.payload.placed,
          total: action.payload.total ?? state.orderSummary.total,
        },
        loading: false,
      };
    case GET_ALL_ORDER_SUMMARY_SUCCESS:
      return { ...state, orderSummary: action.payload, loading: false };
    case GET_DAILY_ORDERS_SUCCESS:
      return { ...state, dailyOrders: action.payload, loading: false };
    case GET_ORDERED_PRODUCT_DETAILS_SUCCESS: {
      return { ...state, orderedProducts: action.payload };
    }
    case GET_PM_ORDERS_SUCCESS:
      return { ...state, pmOrders: action.payload, loading: false };
    case GET_DRIVER_ORDERS_SUCCESS:
      return { ...state, driverOrders: action.payload, loading: false };
    case GET_ORDER_DELIVERIES_SUCCESS:
      return { ...state, deliveries: action.payload };
    case GET_ORDER_SUCCESS:
      return { ...state, order: action.payload };
    case GET_PAYMENTS_SUCCESS:
      return { ...state, payments: action.payload };
    case GET_FAIL_TO_ORDER_CUSTOMERS_SUCCESS:
      return { ...state, couldntOrderCustomers: action.payload };
    case GET_FAIL_TO_DELIVER_ORDERS_SUCCESS:
      return { ...state, failToDeliverOrders: action.payload };
    case GET_ORDERS_IN_ROUTE_SUCCESS:
      return { ...state, routeOrders: action.payload, loading: false };
    case GET_RETURNED_PRODUCTS_SUCCESS:
      return { ...state, returnedProducts: action.payload, loading: false };
    case GET_ROUTE_ORDERS_SUMMARY_SUCCESS:
      return { ...state, routeOrdersSummary: action.payload, loading: false };
    case GET_EXTRA_ORDERS_SUCCESS:
      return { ...state, extraOrders: action.payload };
    case GET_RECON_SUMMARY_SUCCESS:
      return { ...state, reconSummary: action.payload, loading: false };
    case LOAD_CALL_CONNECTION:
      return { ...state, isLoading: true };
    case RESET_CALL_CONNECTION:
      return { ...state, isLoading: false };
    case ACTION_SUCCESS:
      return { ...state, isLoading: false };
    case GET_ORDER_ITEM_DSICOUNT_SUCCESS:
      return {
        ...state,
        discountItems: {
          ...state.discountItems,
          [action.payload.key]: action.payload.data,
        },
        loading: false,
      };
    case GET_ORDER_ITEMS_DSICOUNT_SUCCESS:
      console.log("ITEMS DISCOUNT PAYLOAD = ", action.payload);
      return { ...state, discountItems: action.payload, loading: false };
    case CLEAR_DISCOUNT:
      return { ...state, discountItems: {}, loading: false };
    default:
      return state;
  }
}

export default OrderReducer;
