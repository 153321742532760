import { getProductionSchedule } from "../../services/ProductionService";
import { handleErrorAction } from "./UtilsAction";
import { GET_PRODUCTION_SCHEDULE_SUCCESS } from "../constants/ActionTypes";

export const getProductionScheduleAction = data => dispatch => {
  return getProductionSchedule(data).then(
    response => {
      if (response.data.code === "200") {
        let productionSchedule = response.data.responseObject;
        return dispatch({
          type: GET_PRODUCTION_SCHEDULE_SUCCESS,
          payload: productionSchedule
        });
      }
    },
    error => dispatch(handleErrorAction(error))
  );
};
