
import React, { useEffect, useState } from "react";
import {getUserIframeURL} from '../metabase/metabase.embed.js';
import {useSelector} from 'react-redux';

const SalesSummary = (params) => {
    const [reportId, setReportId] = useState(null);
    const user = useSelector(state => state.authManager.user);
    useEffect(()=>{
        console.log("user",user)
        renderReport()
    },[])
    const renderReport = () =>{
        if(user.role === "admin"){
            return setReportId(135)
        }else if(user.role === "call-centre-manager"){
            return setReportId(135)
        }else {
            return setReportId(135)
        }
    }
    
    return (
        <div className="metabase-frame"> 
             <iframe
                 src={getUserIframeURL(reportId,user)}
                 frameBorder={0}
                 width={"100%"}
                 height={"100%"}
                 title={"d1"}
                 allowtransparency="true"
                 />  
          
         </div>
    
 )
}
    

export default SalesSummary