import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import AddTerritoryForm from "../../../pages/territory/AddTerritoryForm";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function TerritoryInfoDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div className="p-3">
        <AddTerritoryForm />
      </div>
    </Dialog>
  );
}

export default TerritoryInfoDialog;
