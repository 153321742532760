import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { login, resetPasswordAction } from "../../store/actions/AuthAction";
import { Formik } from "formik";
import * as Yup from "yup";

const LoginForm = () => {
  const [username, setUsername] = useState("");

  const [isEyeOpen, setIsEyeOpen] = useState(true);
  const [inputType, setInputType] = useState("password");
  const [canShowPasswordResetForm, setCanShowPasswordResetForm] = useState(
    false
  );
  const [canShowLoginForm, setCanShowLoginForm] = useState(true);
  const error = useSelector((state) => state.stateManager.error);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);

  const dispatch = useDispatch();
  const loginAction = (credentials) => {
    const actionRes = login(credentials);
    dispatch(actionRes);
  };

  const showResetPasswordForm = () => {
    setCanShowLoginForm(false);
    setCanShowPasswordResetForm(true);
  };

  const handlePasswordReset = (event) => {
    event.preventDefault();
    let data = {
      username: username,
    };
    dispatch(resetPasswordAction(data));
  };

  const toggleEye = () => {
    if (isEyeOpen) {
      setInputType("text");
      setIsEyeOpen(false);
    } else {
      setInputType("password");
      setIsEyeOpen(true);
    }
  };
  return (
    <div>
      {canShowLoginForm && (
        <Formik
          initialValues={{
            password: "",
            username: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              //console.log(values);
              loginAction(values);

              setSubmitting(false);
            }, 500);
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().required("username is required "),
            password: Yup.string().required("Password is required "),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <div className="login-wrapper">
                <h2 className="mb-5">Log in</h2>
                <form onSubmit={handleSubmit} method="POST">
                  {error && (
                    <p className="alert alert-danger mt-3">
                      Wrong username or password
                    </p>
                  )}
                  {/* <code>{JSON.stringify(values)}</code> */}

                  {success && (
                    <p className="alert alert-success mt-3">{message}</p>
                  )}
                  <div className="form-group">
                    <label>Username</label>
                    <div className="input-group">
                      <input
                        name="username"
                        type="text"
                        placeholder="Enter Username"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autocomplete="off"
                        className={
                          errors.username && touched.username
                            ? "auth-input is-invalid"
                            : "auth-input"
                        }
                      />
                    </div>

                    <div className="line"></div>
                    {errors.username && touched.username && (
                      <p className="error mt-2 ml-2"> {errors.username}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label mb-2>
                      Password
                      <span
                        className="float-right primary pointer text-right"
                        onClick={showResetPasswordForm}
                      >
                        Forgot Password ?
                      </span>
                    </label>
                    <div className="input-group">
                      <input
                        type={inputType}
                        name="password"
                        placeholder="Enter Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autocomplete="off"
                        className={
                          errors.password && touched.password
                            ? "auth-input  mb-2 is-invalid"
                            : "auth-input mb-2"
                        }
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text clickable show-hide-password"
                          id="basic-addon2"
                        >
                          <FontAwesomeIcon
                            icon={isEyeOpen ? faEye : faEyeSlash}
                            className="material-icons float-left status-gray"
                            onClick={toggleEye}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="line"></div>
                    {errors.password && touched.password && (
                      <p className="error mt-2 ml-2"> {errors.password}</p>
                    )}
                  </div>

                  <div className="padding-top-33" align="left">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block btn-lg btn-auth "
                    >
                      <span className="my-auto show-element"></span>
                      <span className="my-auto">Login</span>
                    </button>
                  </div>
                </form>
              </div>
            );
          }}
        </Formik>
      )}
      {canShowPasswordResetForm && (
        <div className="login-wrapper">
          <h2 className="mb-5">Reset Password</h2>
          {error && <p className="alert alert-danger mt-3">{message}</p>}
          {success && <p className="alert alert-success mt-3">{message}</p>}
          <form onSubmit={handlePasswordReset} method="POST">
            <div className="form-group">
              <label>Username</label>
              <div className="input-group mb-3">
                <input
                  name="username"
                  type="text"
                  className="auth-input"
                  placeholder="Username"
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </div>
              <div className="line"></div>
              <div className="padding-top-33" align="center">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-lg btn-auth"
                >
                  <span className="my-auto show-element"></span>
                  <span className="my-auto">RESET</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
export default LoginForm;
