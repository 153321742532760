import React, { useEffect, useRef ,useState} from "react";
import axios from "axios";
import ReactApexChart from 'react-apexcharts';


const DonutChart = ({title,dataValues,dataLabels}) => {
  const [employeeSalary, setEmployeeSalary] = useState([]);
  const [employeeAge, setEmployeeAge] = useState([]);

  let empSal = [];
  let empAge = [];

  // axios.get("http://dummy.restapiexample.com/api/v1/employees")
  //       .then(res =>{
  //         let dataObj = res.data.data
  //         console.log("dataObj",dataObj)
  //         for(const element of dataObj){
  //           empSal.push(parseInt(element.employee_salary))
  //           empAge.push(parseInt(element.employee_age))
  //         }
          
  //       })
  //       .catch(err => {
  //         console.log("err",err)
  //       })

   
   //const series = [44, 55, 41, 17, 15];
   const series = dataValues;
            
            
   const options =  {
                chart: {
                    type: 'donut',
                },
                title: {
                    text: title,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        total: {
                          showAlways: true,
                          show: true
                        }
                      }
                    }
                  }
                },

                // labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
                labels: dataLabels,

                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                        width: 200
                        },
                        legend: {
                        position: 'bottom'
                        }
                    }
                     }],

                noData: { //checks if there us no data to display
                    text: "No results!",
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: undefined,
                      fontSize: '14px',
                      fontFamily: undefined
                    }
                  }
                };
                

  return (
    <>
        <ReactApexChart 
            options={options} 
            series={series} 
            type="donut"
            height={250} />

    
    </>
  );
};

export default DonutChart;
