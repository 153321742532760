import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateTime from "react-datetime";
import {
  getUserOutletsAction,
  setCalendarEventsAction,
} from "../../../store/actions/routePlanActions";
import { Draggable } from "@fullcalendar/interaction";
import Select from "react-select";
import LoadingIndicator from "../../dom/LoadingIndicator";
import moment from "moment";
import _ from "lodash";
import "./DraggableUserOutlets.css";

const planRepeatOptions = [{ value: "WEEKLY", label: "Every Week" }];

const DraggableUserOutlets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRepeatType, setSelectedRepeatType] = useState("");
  const selectedUser = useSelector((state) => state.userManager.user);
  const [
    canShowenitytRepeatUntilDate,
    setCanShowEntityRepeatUntilDate,
  ] = useState(false);
  const userOutlets = useSelector(
    (state) => state.routePlanManager.userOutlets
  );
  const savedUserOutletAndAreas = useSelector(
    (state) => state.routePlanManager.userOutletAndAreas
  );

  const [userAreasAndOutles, setUserAreasAndOutles] = useState([]);
  const [savedAreasAndOutles, setSavedAreasAndOutles] = useState({});
  const [repeatUntil, setRepeatUntil] = useState(
    moment()
      .add("31", "days")
      .format("YYYY-MM-DD")
  );

  const calendarEvents = useSelector(
    (state) => state.routePlanManager.calendarEvents
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserOutletsAction(selectedUser.username));

    //adding dragable properties to external events through javascript

    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".external-event",
      eventData: function(eventEl) {
        let title = eventEl.innerText;
        let id = eventEl.getAttribute("data-id");
        let entityType = eventEl.getAttribute("data-entityType");
        let repeatType = eventEl.getAttribute("data-repeatType");
        let entityId = eventEl.getAttribute("data-entityId");
        let activities = eventEl.getAttribute("data-activities");
        let backgroundColor = eventEl.getAttribute("data-backgroundColor");
        let borderColor = eventEl.getAttribute("data-borderColor");
        let data = {
          title,
          id,
          entityType,
          repeatType,
          activities,
          entityId,
          backgroundColor,
          borderColor,
        };

        return data;
      },
    });
  }, []);

  useEffect(() => {
    let clone = _.cloneDeep(userOutlets);

    //dispatch(getUserOutletsAction(selectedUser.username));
    setTimeout(() => setUserAreasAndOutles(clone), 200);
  }, [userOutlets]);

  const searchAreasAndOutlets = (e) => {
    e.preventDefault();
    const searchValue = e.target.value;
    var filteredAreasAndOutlets = {};

    let clonedUserOOutlets = _.clone(userOutlets);
    if (searchValue !== "") {
      setUserAreasAndOutles({});
      _.map(userOutlets, (outletObj, territory) => {
        let clonedOutletObj = _.clone(outletObj);
        let searchedOutlets = _.filter(outletObj.outlets, (outlet) => {
          if (outlet.name) {
            return (
              outlet.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            );
          }
        });
        if (
          territory.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
          !_.isEmpty(searchedOutlets)
        ) {
          filteredAreasAndOutlets[territory] = {
            ...outletObj,
            outlets: searchedOutlets,
          };
        }
      });

      setUserAreasAndOutles(filteredAreasAndOutlets);
    } else {
      setUserAreasAndOutles(userOutlets);
    }
  };

  const handleRepeatChange = (option) => {
    setSelectedRepeatType(option);
    setCanShowEntityRepeatUntilDate(true);
    if (option && option.value === "WEEKLY") {
      let planRecurringEvents = calendarEvents.map((event) => {
        event.daysOfWeek = [moment(event.start).day()];
        event.repeatType = "WEEKLY";
        event.startRecur = event.start;
        event.endRecur = moment(repeatUntil)
          .add(1, "days")
          .format("YYYY-MM-DD");
        return event;
      });

      dispatch(setCalendarEventsAction(planRecurringEvents));
    }
  };

  return (
    <div className="ibox float-e-margins">
      <div className="ibox-content">
        <div id="external-events">
          <h4>Drag an outlet/area to the date you want to assign </h4>
          <form>
            <input
              placeholder="Search.."
              onChange={searchAreasAndOutlets}
              className="form-control search"
            />
          </form>
          {
            <div className="Scrollable-div">
              {isLoading && <LoadingIndicator />}

              {userAreasAndOutles &&
                Object.keys(userAreasAndOutles).map((area, index) => (
                  <div>
                    <div
                      key={index + 1000}
                      data-id={userAreasAndOutles[area].id}
                      data-entityId={userAreasAndOutles[area].areaId}
                      data-title={area}
                      data-entityType="area"
                      data-repeatType="NONE"
                      data-backgroundColor="#1ab394"
                      data-borderColor="#1ab394"
                      data-activities=""
                      data-comment=""
                      className="external-event navy-bg"
                    >
                      {area}
                    </div>
                    {userAreasAndOutles[area].outlets &&
                      userAreasAndOutles[area].outlets.length > 0 &&
                      userAreasAndOutles[area].outlets.map((outlet) => (
                        <div
                          key={outlet.id}
                          data-id={
                            outlet.assignmentId
                              ? outlet.assignmentId
                              : outlet.id
                          }
                          data-entityId={outlet.id}
                          data-title={outlet.name}
                          data-entityType="outlet"
                          data-repeatType="NONE"
                          data-backgroundColor="#00c400"
                          data-borderColor="#00c400"
                          data-activities=""
                          data-comment=""
                          className="external-event navy-bg draggable-outlet"
                        >
                          {outlet.name}
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default DraggableUserOutlets;
