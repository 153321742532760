import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import TerritoryTablePage from "./TerritoryTablePage";
import AddTerritoryPage from "./AddTerritoryPage";
import { getSuppliersAction } from "../../store/actions/SupplierAction";
import {
  getTerritoriesAction,
  toggleTerritoryFormAction,
} from "../../store/actions/TerritoryAction";
import { TabsWrapper } from "../../styles/Tabs";
import TerritoryDistributionPage from "./TerritoryDistributionPage";

const TerritoryManager = () => {
  const [canShowCreateTerritoryForm, setCanShowCreateTerritoryForm] = useState(
    false
  );
  const [canShowTabs, setCanShowTabs] = useState(true);

  const [editMode, setEditMode] = useState(false);
  const [territory, setTerritory] = useState(null);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTerritoriesAction({}));
  }, []);

  useEffect(() => {
    if (success) {
      setCanShowTabs(true);
      setCanShowCreateTerritoryForm(false);
      setTimeout(() => {
        dispatch(getTerritoriesAction({}));
        dispatch(toggleTerritoryFormAction(false));
      }, 4000);
    }
  }, [success]);

  const editTerritory = (row) => {
    setEditMode(true);
    setTerritory(row);
    setCanShowTabs(false);
    setCanShowCreateTerritoryForm(true);
  };

  const addTerritory = () => {
    setCanShowTabs(false);
    setCanShowCreateTerritoryForm(true);
    setEditMode(false);
  };

  const resetView = () => {
    setCanShowTabs(true);
    setCanShowCreateTerritoryForm(false);
    setEditMode(false);
  };

  return (
    <>
      {canShowTabs && (
        <>
          <PageTopHeader title="Territory Manager" />
          <TabsWrapper>
            <Tabs>
              <TabList>
                <Tab>Territory List</Tab>
                <Tab>Territory Distribution Map</Tab>
              </TabList>
              <TabPanel>
                <TerritoryTablePage add={addTerritory} edit={editTerritory} />
              </TabPanel>
              <TabPanel>
                <TerritoryDistributionPage />
              </TabPanel>
            </Tabs>
          </TabsWrapper>
        </>
      )}

      {canShowCreateTerritoryForm && (
        <AddTerritoryPage
          territory={territory}
          editMode={editMode}
          resetView={resetView}
        />
      )}
    </>
  );
};

export default TerritoryManager;
