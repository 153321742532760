import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddSupplierForm from "./AddSupplierForm";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";

const AddSupplierPage = ({ editMode, supplier, resetView }) => {
  return (
    <div className="row">
      <div className="col-md-1 back-arrow">
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="2x"
          className="mr-2"
          onClick={() => resetView()}
        />
      </div>
      <div className="col-md-11">
        {!editMode && <PageTopHeader title="Create  supplier" />}
        {editMode && <PageTopHeader title="Edit  Supplier" />}
        <AddSupplierForm
          resetView={resetView}
          editMode={editMode}
          supplier={supplier}
        />
      </div>
    </div>
  );
};

export default AddSupplierPage;
