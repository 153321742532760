import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CreateIcon from "@material-ui/icons/Create";
import { OrderFormWrapper, Label, Input, OrderLabel } from "../../styles/Order";
import { ButtonSmall } from "../../styles/Forms";
import {
  updateOrderAction,
  getOrdersAction,
} from "../../store/actions/OrderAction";
import { usePermissionChecker } from "../../utils/user";
import _ from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const OrderItems = ({ order, edit, resetView }) => {
  const [editMode, setEditMode] = useState(false);
  const [quantities, setQuantites] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [deliveryType, setDeliveryType] = useState("delivery");
  const [totalObj, setTotalObj] = useState({});
  const [total, setTotal] = useState(0);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);

  const dispatch = useDispatch();

  const handleDeliveryChange = (event) => {
    setDeliveryType(event.target.value);
  };

  const updateOrderItems = () => {
    let items = order.items.map((product, index) => {
      let quantity = quantities[index]
        ? quantities[index]
        : order.items[index].quantity;

      return {
        orderItemId: product.orderItemId,
        quantity: quantity,
      };
    });
    items = items.filter((item) => item.quantity !== 0);

    let data = {
      orderNumber: order.orderNumber,
      deliveryType,
      items,
    };

    //console.log(data);

    dispatch(updateOrderAction(data));
  };

  useEffect(() => {
    console.log("Call me");
    if (success) {
      dispatch({ type: "RESET_VIEW" });
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    }
  });

  useEffect(() => {
    console.log(order);
    if (success) {
      dispatch({ type: "RESET_VIEW" });
    }
    var totalObj = {};

    order &&
      order.items &&
      order.items.map((item, index) => {
        totalObj[index] = item.cost;
      });
    setTotalObj(totalObj);
    setTotal(order.cost);
    setDeliveryType(order.deliveryType);

    return () => setTimeout(() => dispatch(getOrdersAction({})), 10000);
  }, [dispatch, order, success]);

  const handleQuantityChange = (index, event, price) => {
    if (event.target.value === "") {
      setQuantites({ ...quantities, [index]: event.target.value });

      setIsSubmitDisabled(true);
    } else {
      let quantity = parseInt(event.target.value);
      setQuantites({ ...quantities, [index]: event.target.value });
      setTotalObj({ ...totalObj, [index]: quantity * price });
      console.log(quantities, totalObj, quantity, price, index);

      let totalArr = Object.keys(totalObj).map((key) => totalObj[key]);
      totalArr[index] = quantity * price;
      let total = totalArr.reduce((a, b) => a + b, 0);
      setTotal(total);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {success && <p className="alert alert-success">{message}</p>}
          {error && <p className="alert alert-danger">{message}</p>}
        </div>
      </div>
      <div className="row pb-3">
        <div className="col-sm-3">
          <h4 className="fs-14  mt-3">
            Order Date : {moment(order.deliveryDate).format("LLL")}
          </h4>
        </div>
        <div className="col-sm-3">
          <h4 className="fs-14  mt-3">Customer : {order.outletName}</h4>
        </div>
        <div className="col-sm-3">
          <h4 className="fs-14 mt-3">
            Last Edited : {moment(order.updatedAt).format("LLL")}
          </h4>
        </div>

        <div className="col-sm-3">
          {usePermissionChecker("can_edit_order_items") && (
            <div
              onClick={() => setEditMode(true)}
              className="float-right pointer sfa-btn-bordered pt-3 pr-2"
            >
              <CreateIcon className="primary pointer fs-12 " />{" "}
              <span className="primary font-weight-bold">Edit Order</span>
            </div>
          )}
        </div>
      </div>

      <OrderFormWrapper>
        <div className="row row-bg">
          <div className="col-sm-3 ">Items</div>
          <div className="col-sm-3 text-center">qty</div>
          <div className="col-sm-3 text-center">Sold</div>
          <div className="col-sm-3 ">amount</div>
        </div>

        {order &&
          order.orderItems &&
          order.orderItems.length > 0 &&
          order.orderItems.map((item, index) => (
            <div className="row row-items" key={item.id}>
              <div className="col-sm-3 ">
                <span className="pl-2">{item.productName}</span>
              </div>
              {!editMode && (
                <div className="col-sm-3 text-center">{item.quantity}</div>
              )}

              {editMode && (
                <div className="col-sm-3 text-center">
                  <Input
                    type="number"
                    key={item.identity}
                    name={`quantity-${item.quantity}`}
                    placeholder={item.quantity}
                    min="1"
                    value={quantities[index]}
                    onChange={(e) => handleQuantityChange(index, e, item.price)}
                    disabled={quantities[index] === 0}
                  />
                </div>
              )}
              <div className="col-sm-3 text-center">{item.price}</div>
              <div className="col-sm-3">{item.finalCost}</div>
            </div>
          ))}

        <div className="row pt-3">
          <div className="col-sm-4 total-small text-uppercase font-weight-bold">
            <span className="pl-3">total</span>
          </div>

          <div className="col-sm-4 offset-sm-4  total-small ">
            <span className="pl-1"> {total} MZN</span>
          </div>
        </div>

        {editMode && (
          <div className="row">
            <div className="col-sm-6">
              <OrderLabel>Delivery Type</OrderLabel>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="delivery"
                  name="delivery"
                  value={deliveryType}
                  onChange={handleDeliveryChange}
                  row
                >
                  <FormControlLabel
                    value="delivery"
                    control={<Radio />}
                    label="Delivery "
                  />
                  <FormControlLabel
                    value="pick_up"
                    control={<Radio />}
                    label="Pickup"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        )}

        {editMode && (
          <div className="row">
            <div className="col-sm-12">
              <ButtonSmall
                type="submit"
                className="pointer"
                onClick={updateOrderItems}
              >
                Update Items
              </ButtonSmall>
            </div>
          </div>
        )}
      </OrderFormWrapper>
    </>
  );
};

export default OrderItems;
