import styled from "styled-components";

export const Input = styled.input`
  height: 40px;
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
  background: transparent;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  padding-left: 30px;
  border-right: 0;
  color: rgba(0, 0, 0, 0.99);
  font-family: "Lato", sans-serif;
  font-size: 12px;
  letter-spacing: 0.86px;
  line-height: 15px;
`;

export const SearcButton = styled.button`
  box-sizing: border-box;
  box-sizing: border-box;
  height: 40px;
  width: 56px;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 0 25px 25px 0;
  background-color: #00c400;
  position: relative;
  cursor: pointer;
  left: 0;

  svg {
    font-size: 16px !important;
  }

  @media (min-width: 767px) {
    height: 50px !important;
    top: 0;
  }
`;

export const Circle = styled.div`
  height: 20px;
  width: 20px;
  background-color: #00c400;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  color: white;
  font-weight: 12px;
  margin-left: 5px;
  margin-right: 0px;
  line-height: 16px;

  span {
    font-size: 11px;
  }
`;

export const Basket = styled.div`
  cursor: pointer;

  img {
    opacity: 0.5;
    max-width: 20px;
  }
`;

export const SearchBox = styled.div`
  width: 100%;
  margin-top: 12px;

  form {
    width: 100%;
  }

  @media (min-width: 576px) {
    width: 100%;
    margin-top: 0px;
  }
  .MuiInputLabel-formControl {
    top: -10px;
    left: 5px;
    position: absolute;
    color: rgba(0, 0, 0, 0.38);
  }
  .Mui-focused {
    color: rgba(0, 0, 0, 0.8) !important;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot {
    padding-right: 10px;
  }
  .MuiInput-underline:before {
    border: none;
    bottom: 10px;
  }
  .MuiInput-formControl {
    margin-top: 3px;
  }
  .MuiInputBase-input {
    padding: 8px 15px !important;
    font-size: 14px;
  }
  .search-input-group {
    display: flex;
  }
  .input-group-append {
    margin-left: 0px;
  }
  .MuiSelect-root {
    min-width: 400px;
  }
  .MuiAutocomplete-root {
    width: 100% !important;
  }
  .MuiFormControl-root {
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    border-radius: 25px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 40px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .Mui-focused {
    border: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
    .MuiInputBase-input {
      font-size: 16px;
    }
  }
  
  @media (min-width: 767px) {
  .MuiFormControl-root {
    height: 50px;
  }
    .MuiInputBase-input {
    padding: 12px 18px !important;
  }
  }
`;

export const ActionButtonRound = styled.div`
  border-radius: 20px;
  background-color: #00c400;
  color: #ffffff;
  font-size: 11px;
  text-align: center;
  padding: 12px 10px;
  margin: 5px 10px;
  cursor: pointer;
  width: 90%;

  @media (min-width: 768px) {
    border-radius: 30px;
    padding: 10px 25px;
    font-size: 18px;
    white-space: normal;
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const ActionButtonDisabledRound = styled.div`
  border-radius: 20px;
  background-color: #cccccc;
  color: #ffffff;
  font-size: 11px;
  text-align: center;
  padding: 12px 10px;
  margin: 5px 10px;
  cursor: pointer;

  @media (min-width: 768px) {
    border-radius: 30px;
    padding: 12px 15px;
    font-size: 15px;
  }
`;

export const ActionButtonDangerRound = styled.div`
  border-radius: 20px;
  background-color: red;
  color: #ffffff;
  font-size: 11px;
  text-align: center;
  padding: 12px 10px;
  margin: 5px 10px;
  cursor: pointer;
  width: 90%;

  @media (min-width: 768px) {
    border-radius: 30px;
    padding: 12px 15px;
    font-size: 15px;
  }
`;

export const ActionButtonOutlineDangerRound = styled.div`
  border: 1px red solid;
  border-radius: 20px;
  background-color: white;
  color: red;
  font-size: 11px;
  text-align: center;
  padding: 10px 10px;
  margin: 5px 10px;
  cursor: pointer;

  @media (min-width: 768px) {
    border-radius: 30px;
    padding: 12px 15px;
    font-size: 15px;
  }
`;

export const ButtonInline = styled.div`
  padding: 5px 10px;
  padding: 5px;
  font-size: 13px;
  border: solid 1px #757575;
  margin-right: 2px;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;

  .active {
    border: solid 1px red;
  }
`;

export const FormInputWrapper = styled.div`
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  select {
    box-sizing: border-box;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    width: 100%;
    padding: 10px;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.54);
    font-family: "DM Sans";
    font-size: 16px;
    letter-spacing: 1px;
  }
  .alert-wait {
    display: flex;
    align-items: center;
  }

  h2 {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 21px;
    display: block;
  }
  label {
    color: #3c3f42;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 18px;
    padding-left: 5px;
  }

  .form-control {
    background-color: #f4f4f4;
    border: 0;
    border-color: #ccc;
  }

  input:focus {
    outline: none;
  }

  .btn-submit {
    border-radius: 30px;
    background-color: #00c400;
    width: 100%;
    padding: 10px 80px;
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 21px;
    text-align: center;
    border: none;
  }

  .btn-submit:hover {
    background-color: #01b701;
  }

  h5 {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    color: #00c400;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  }
  h5:before,
  h5:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: "\a0";
    background-color: black;
    opacity: 0.2;
  }
  h5:before {
    margin-left: -51%;
    text-align: right;
  }
  h5:after {
    margin-right: -52%;
  }
  .color {
    background-color: #ccc;
  }
  .current-location-picker {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(157, 117, 68, 0.12);
    padding: 10px;
    margin: 10px 0px;
  }
  .current-location-picker label {
    font-family: "DM Sans", sans-serif;
    color: #000000;
    font-size: 16px;
    letter-spacing: 1px;
  }

  .error {
    color: red;
    font-size: 12px;
  }
  .success-outline {
    border: 0.5px solid rgba(0, 196, 0, 0.6) !important;
  }
  .fail-outline {
    border: 0.6px solid rgba(114, 28, 36, 0.8) !important;
  }
  .TnC {
    margin: 10px;
    font-style: italic;
    font-size: 12px;
  }
  .btn-retry {
    border: none;
    text-decoration: underline;
  }
`;

export const FormSubmitButtonRound = styled.div`
  border-radius: 20px;
  background-color: #00c400;
  color: #ffffff;
  font-size: 17px;
  text-align: center;
  padding: 5px 10px;
  margin: 5px 10px;
  cursor: pointer;
  width: 100%;
`;

export const CheckoutWrapper = styled.div`
margin-bottom{
  130px;
}
    .bg-gray {
        background-color: #F5F5F5;
    }
    h4 {
       font-size: 12px;
       font-weight: 500;
       color: #00C400;
    }
    .line {
        display: block;
        overflow: hidden;
        text-align: center;
        color: #00C400;
    }
    .line::before,
     .line::after {
    background-color: #cacaca;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 47%;
    }
  
     .MuiFormControl-root{
        width:100% !important;
      }
    
      .payment-method img{
        max-width:50px;
        margin-top: 15px;
      }
      .dateTimeSelector{
        display:flex;
        justify-content: space-evenly;
      }
      .dateTimeSelector_option {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
        color: white;
    }
    .dateTimeSelector_option h4{
      color: #3C3F42;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 18px;
    }
    .checkout-submit-btn {
      width:100%;
    }

    @media (min-width: 768px) {   
      .inalipa-btn-primary{
        width: 50%;
        margin: 0 auto;
        display: block;
        margin-top: 30px;
        
    } 
  }
  
}
`;
