import styled from "styled-components";

export const PageTitle = styled.h1`
  color: #373d42;
  font-size: 28px;
  font-weight: bold;
  padding-top: 17px;
`;

export const PageButton = styled.button`
  border: 2px solid #ecece9;
  color: #00c400;
  font-size: 14px;
  padding: 17px 25px;
  color: #00c400;
  font-weight: 500;
  border-radius: 1px;
`;
export const UserButton = styled.button`
  border: 2px solid #ecece9;
  color: #00c400;
  font-size: 14px;
  padding: 7px 12px;
  color: #00c400;
  font-weight: 500;
  border-radius: 1px;
  background-color: white;
  margin-right: 20px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const StatsWrapper = styled.div`
  div {
    flex-basis: 100%;

    margin-right: 50px;
    color: #373d42;
    font-size: 32px;
    font-weight: bold;
    line-height: 36px;
    padding: 20px;
  }

  div span {
    color: #757575;
    font-size: 12px;
    line-height: 14px;
    display: block;
  }
`;

export const reactSelect = {
  control: (base) => ({
    ...base,
    border: 0,
    borderBottom: "1px solid #ccc",
    paddingBottom: "10px",
    // This line disable the blue border
    boxShadow: "none",
    "&:hover": {
      borderBottom: "1px solid #ccc",
    },
  }),
};
