import React from "react";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from "@material-ui/core";
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Map as MapIcon,
} from "react-feather";
import StorefrontIcon from "@material-ui/icons/Storefront";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import NavItem from "./NavItem";
import logo from "../../../assets/img/logo.png";
import inalipa_icon from "../../../assets/img/icon_inalipa.png"
import { checkPermission } from "../../../utils/user";
//import "./SideBar.css";
import { useSelector } from "react-redux";
import TopBar from "../TopBar/TopBar";

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  color: {
    backgroundColor: 'white'
    },
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
     
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer({ onMobileClose, openMobile }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  var permissions = useSelector((state) => state.authManager.permissions);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);


  const items = [
    {
      href: "/dashboard",
      icon: BarChartIcon,
      title: "Dashboard",
      permission: "can_view_dashboard",
    },
    {
      href: "/users",
      icon: UsersIcon,
      title: "users",
      permission: "can_view_users",
    },
    {
      href: "/orders",
      icon: ShoppingCartIcon,
      title: "Orders",
      permission: "can_view_orders",
    },
    {
      href: "/outlets",
      icon: StorefrontIcon,
      title: "Outlets",
      permission: "can_view_orders",
    },
    // {
    //   href: "/surveys",
    //   icon: AssignmentIcon,
    //   title: "Surveys",
    //   permission: "can_view_orders",
    // },
    {
      href: "/products",
      icon: ShoppingBagIcon,
      title: "Products",
      permission: "can_manage_products",
    },
    {
      href: "/territory-manager",
      icon: MapIcon,
      title: "Territories",
      permission: "can_manage_territories",
    },
  
    {
      href: "/suppliers",
      icon: RecentActorsIcon,
      title: "Suppliers",
      permission: "can_manage_suppliers",
    },
    {
      href: "/reports",
      icon: AssessmentIcon,
      title: "Reports",
      permission: "can_view_reports",
    },
  
    {
      href: "/settings",
      icon: SettingsIcon,
      title: "Settings",
      permission: "can_manage_settings",
    },
  ];
  
  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* <div style={{ padding: "10px" }}>
        {open === true ? <img src={logo} alt="logo" style={{ width: "120px" }} />  : ""}
      </div> */}
      
      <Divider />
      <Box sx={{ p: 4 }}>
        <List>
          {items.map(
            (item) =>
              checkPermission(item.permission, permissions) && (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={open === true ? item.title : ""}
                  icon={item.icon}
                />
              )
          )}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          backgroundColor: "background.default",
          m: 2,
          p: 2,
        }}
      ></Box>
    </Box>
  );


  return (
    <div className={classes.root}>
      
      <CssBaseline />
      <AppBar
        position="fixed"
        color="white"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}

      > 
        <Toolbar>
           {open === true ? "" : <img src={inalipa_icon} alt="logo" style={{ width: "35px" }} />  }
            <div className="col-sm-4">
                <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >  
              <MenuIcon />
              </IconButton>
            </div>
            <div className="col-sm-8">
              <TopBar />
            </div>
         
         
          
        </Toolbar>
      
        
       
         
          {/* <TopBar /> */}
        {/* <TopBar>
          <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
             
            </IconButton>
             <MenuIcon />
        </TopBar> 
         */}
       

      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div style={{ padding: "10px" }}>
            {open === true ? <img src={logo} alt="logo" style={{ width: "120px" }} />  : ""}
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
           {content} 
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        
      </main>
    </div>
  );
}
