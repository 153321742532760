import React, { useCallback, useEffect, useRef, useState } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { Link } from "react-router-dom";
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";

import { useDispatch, useSelector } from "react-redux";
import { setTerritoryCoordinatesAction } from "../../store/actions/TerritoryAction";
import { getOrdersAction } from "../../store/actions/OrderAction";
import OutletDetails from "./OutletDetails";
import { markerClustererCalculator } from "../../components/map/MarkerClusterCalculator";
import MarkerCarrierIcon from "./MarkerCarrierIcon";
import CurrencyFormat from "react-currency-format";
import { json } from "d3";

const google = window.google;

const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const SalesMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBwuLdw8RyUO2mNVZQ3f9ux34F_2duP_lU&v=3.exp&libraries=geometry,drawing,places,visualization ",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div
        style={{
          height: `600px`,
          display: "flex",
          flexDirection: "column-reverse",
        }}
      />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    editMode,
    polygons,
    center,
    coordinates,
    outlets,
    sales,
    allSales,
    outletArr,
    canShowAreas,
    canShowOutlets,
  }) => {
    // console.log("allOutlets-->",outletArr)
    // console.log("sales-->",sales)

    const [heatmapData, setHeatmapData] = useState([]);
    const [path, setPath] = useState();
    const [mapRef, setMapRef] = useState(null);
    // const [canShowAreas, setCanShowAreas] = useState(true);

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [salesCluster, setSalesCluster] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState(null);
    const [selectedOutletArr, setSelectedOutletArr] = useState(null);
    const [markerMap, setMarkerMap] = useState({});
    const [infoOpen, setInfoOpen] = useState(false);
    const [infoOpenOutlet, setInfoOpenOutlet] = useState(false);
    const [infoOpenOutletArr, setInfoOpenOutletArr] = useState(false);
    const [zoom, setZoom] = useState(13);
    const [outletSales, setOutletSales] = useState([]);
    const [allOutlet, setAllOutlets] = useState([]);
    const mapPosition = useSelector(
      (state) => state.locationManager.mapPosition
    );
    const salesDistribution = useSelector(
      (state) => state.reportManager.salesDistribution
    );

    const outletSalesDistribution = useSelector(
      (state) => state.reportManager.outletSalesDistribution
    );

    const orders = useSelector((state) => state.OrderManager.orders);
    const success = useSelector((state) => state.stateManager.success);

    const locatedOutlets = useSelector(
      (state) => state.outletManager.locatedOutlets
    );
    const dispatch = useDispatch();

    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    const onPolygonCompleted = (polygon) => {
      var coordinates = polygon.getPath().getArray();
      let LngLatArr = coordinates.map((coordinate) => [
        coordinate.lng(),
        coordinate.lat(),
      ]);

      LngLatArr.push(LngLatArr[0]);
      dispatch(setTerritoryCoordinatesAction(LngLatArr));
    };

    useEffect(() => {
      const outletHeat = outlets.map((item, index) => {
        return {
          id: index,
          location: new google.maps.LatLng(
            parseFloat(item.latitude),
            parseFloat(item.longitude)
          ),
          weight: parseInt(item.amount),
          outletName: item.name,
          outletNumber: parseInt(item.outletNumber),
          latitude: parseFloat(item.latitude),
          longitude: parseFloat(item.longitude),
        };
      });
      setOutletSales(outletHeat);

      let salesDistArr = outletSalesDistribution.map((sale) => ({
        id: parseInt(sale.outletNumber),
        sales: sale.sales,
        lat: parseFloat(sale.latitude),
        lng: parseFloat(sale.longitude),
        name: sale.name,
        level:
          sale.sales > 2 && sale.sales < 5
            ? "alerta"
              ? sale.sales > 5
                ? "anormal"
                : "normal"
              : "normal"
            : "normal",
        amount: sale.amount,
      }));
      console.log("salesDistribution = ", salesDistArr);
      setSalesCluster(salesDistArr);
      // console.log("salesDistribution=-====>", salesDistribution);
    }, []);

    useEffect(() => {
      let salesDistArr = outletSalesDistribution.map((sale) => ({
        id: parseInt(sale.outletNumber),
        sales: sale.sales,
        lat: parseFloat(sale.latitude),
        lng: parseFloat(sale.longitude),
        name: sale.name,
        level:
          sale.sales > 2 && sale.sales < 5
            ? "alerta"
              ? sale.sales > 5
                ? "anormal"
                : "normal"
              : "normal"
            : "normal",
        amount: sale.amount,
      }));
      console.log("salesDistribution = ", salesDistArr);
      setSalesCluster(salesDistArr);
      // console.log("salesDistribution=-====>", salesDistribution);
    }, [outletSalesDistribution]);
    // console.log("outletSales=-====>",outletSales)

    const dailySales = allSales.map((item) => {
      return {
        location: new google.maps.LatLng(
          parseFloat(item.latitude),
          parseFloat(item.longitude)
        ),
        // weight : item.cost
      };
    });

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
          });

        setPath(nextPath);
      }
    }, [setPath]);

    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
      (polygon) => {
        polygonRef.current = polygon;
        const path = polygon.getPath();
        listenersRef.current.push(
          path.addListener("set_at", onEdit),
          path.addListener("insert_at", onEdit),
          path.addListener("remove_at", onEdit)
        );
      },
      [onEdit]
    );

    // Clean up refs
    const onUnmount = useCallback(() => {
      listenersRef.current.forEach((lis) => lis.remove());
      polygonRef.current = null;
    }, []);

    const markerClickHandler = (event, place) => {
      // Remember which place was clicked
      setSelectedPlace(place);

      // Required so clicking a 2nd marker works as expected
      if (infoOpen) {
        setInfoOpen(false);
      }

      setInfoOpen(true);

      // If you want to zoom in a little on marker click
      if (zoom < 13) {
        setZoom(13);
      }

      // if you want to center the selected Marker
      //setCenter(place.pos)
    };

    const markerClickHandler2 = (event, place) => {
      console.log("markerClickHandler2 place = ", place);
      // Remember which place was clicked
      setSelectedOutlet(place);
      // Required so clicking a 2nd marker works as expected
      if (infoOpenOutlet) {
        setInfoOpenOutlet(false);
      }
      setInfoOpenOutlet(true);
      // If you want to zoom in a little on marker click
      if (zoom < 13) {
        setZoom(13);
      }
      // if you want to center the selected Marker
    };

    const markerClickHandlerOutlets = (event, place) => {
      console.log("markerClickHandlerOutlets place = ", place);
      // Remember which place was clicked
      setSelectedOutletArr(place);
      // Required so clicking a 2nd marker works as expected
      if (infoOpenOutletArr) {
        setInfoOpenOutletArr(false);
      }
      setInfoOpenOutletArr(true);
      // If you want to zoom in a little on marker click
      if (zoom < 13) {
        setZoom(13);
      }
      // if you want to center the selected Marker
    };

    const handleClick = (event, place) => {
      console.log("handleClick = ", place);
      // Remember which place was clicked
      setSelectedOutlet(place);
      // Required so clicking a 2nd marker works as expected
      if (infoOpenOutlet) {
        setInfoOpenOutlet(false);
      }
      setInfoOpenOutlet(true);
      // If you want to zoom in a little on marker click
      if (zoom < 13) {
        setZoom(13);
      }
      // if you want to center the selected Marker
    };

    const loadHandler = (map) => {
      // Store a reference to the google map instance in state
      setMapRef(map);
      // Fit map bounds to contain all markers
      //fitBounds(map);
    };

    // We have to create a mapping of our places to actual Marker objects
    const markerLoadHandler = (marker, place) => {
      return setMarkerMap((prevState) => {
        return { ...prevState, [place.id]: marker };
      });
    };

    useEffect(() => {
      getSales(orders);
    }, []);

    const getSales = (orders) => {
      //console.log("orders,,,,,",orders)

      // let filtered = orders.filter((i) => {
      //   return i.latitude != null;
      // });

      const heatmap = orders.map((item) => {
        //  let latLng=`${parseFloat(item.latitude)},${parseFloat(item.longitude)}`
        return {
          location: new google.maps.LatLng(
            parseFloat(item.latitude),
            parseFloat(item.longitude)
          ),
          weight: item.finalCost,
        };
      });
      //  console.log("heatmapData ==>",heatmap)

      setHeatmapData(heatmap);
    };

    /* Data points defined as an array of LatLng objects */
    var heaData = [
      {
        location: new google.maps.LatLng(-6.764191203212667, 39.28159094481175),
        weight: 5550,
      },
      {
        location: new google.maps.LatLng(-6.776853815580169, 39.20898199081421),
        weight: 5,
      },
      {
        location: new google.maps.LatLng(-6.764191203212667, 39.28159094481175),
        weight: 30770,
      },
      {
        location: new google.maps.LatLng(-6.764191203212667, 39.28159094481175),
        weight: 9990,
      },
      {
        location: new google.maps.LatLng(-6.764191203212667, 39.28159094481175),
        weight: 322200,
      },
      {
        location: new google.maps.LatLng(-6.776853815580169, 39.20898199081421),
        weight: 308880,
      },
    ];

    useEffect(() => {
      //  dispatch(getSalesDistributionMapAction([]));
      if (success) {
        setTimeout(() => dispatch(getOrdersAction()), 7000);
      }
    }, [success]);

    const onZoomChanged = (e) => {
      //setCanShowAreas(false)
    };
    const handleBoundsChanged = () => {
      // console.log("handleBoundsChanged Changed---->")
    };

    const [showDetails, setShowDetails] = useState(false);
    const [outletName, setOutletName] = useState(null);
    const [outletNumber, setOutletNumber] = useState(null);

    const outletDetails = (outlet) => {
      console.log("show Datails", outlet);
      setShowDetails(true);
      setOutletName(outlet.outletName);
      setOutletNumber(outlet.outletNumber);
    };

    if (showDetails && outletNumber) {
      return (
        <OutletDetails
          name={outletName}
          resetView={resetView}
          outletNumber={outletNumber}
          // edit={editOutlet}
        />
      );
    } else {
    }
    const resetView = () => {
      // setTimeout(() => {
      //   dispatch(getOutletsAction({}));
      // }, 7000);
      setShowDetails(false);
      // setShowForm(false);
      // setEditMode(false);
      // scrollToTop();
    };

    return (
      <>
        {/* {JSON.stringify(salesCluster)} */}
        <GoogleMap
          defaultZoom={12}
          defaultCenter={center}
          center={center}
          onZoomChanged={onZoomChanged}
          onBoundsChanged={handleBoundsChanged}
        >
          {coordinates && <Marker position={coordinates} />}
          {polygons &&
            polygons.length > 0 &&
            polygons.map((polygon) => (
              <Polygon
                // Make the Polygon editable / draggable

                path={polygon.path}
                ref={polygonRef}
                // Event used when manipulating and adding points
                onMouseUp={onEdit}
                // Event used when dragging the whole Polygon
                onDragEnd={onEdit}
                onLoad={onLoad}
                onUnmount={onUnmount}
              ></Polygon>
            ))}

          <MarkerClusterer averageCenter enableRetinaIcons gridSize={20}>
            {salesCluster.length > 0 &&
              salesCluster.map((outlet, index) => (
                <Marker
                  key={outlet.id}
                  position={{ lat: outlet.lat, lng: outlet.lng }}
                  icon={{
                    url: require("./icons/outlet.png"),
                    size: new google.maps.Size(36, 36),
                    condition: outlet.level,
                  }}
                  onLoad={(marker) => markerLoadHandler(marker, outlet)}
                  onClick={(event) => markerClickHandler2(event, outlet)}
                ></Marker>
                //<MarkerCarrierIcon key={index} lac={lac} />
              ))}
          </MarkerClusterer>

          {/* {canShowOutlets &&
            outletSales.length > 0 &&
            outletSales.map((sale) => {
              return (
                <Marker
                  key={sale.id}
                  position={{ lat: sale.latitude, lng: sale.longitude }}
                  onLoad={(marker) => markerLoadHandler(marker, sale)}
                  onClick={(event) => markerClickHandlerOutlets(event, sale)}
                  icon={{
                    url: require("./icons/outlet.png"),
                    anchor: new google.maps.Point(5, 58),
                    scaledSize: new google.maps.Size(15, 25),
                  }}
                />
              );
            })} */}

            {canShowOutlets &&
            outletSales.length > 0 &&
            outletSales.map((sale) => {
              return (
                <Marker
                  key={sale.id}
                  position={{ lat: sale.latitude, lng: sale.longitude }}
                  onLoad={(marker) => markerLoadHandler(marker, sale)}
                  onClick={(event) => markerClickHandlerOutlets(event, sale)}
                  icon={{
                    url: require("./icons/outlet.png"),
                    anchor: new google.maps.Point(5, 58),
                    scaledSize: new google.maps.Size(15, 25),
                  }}
                />
              );
            })} 

        {infoOpenOutletArr && selectedOutletArr && (
            <InfoWindow
              anchor={markerMap[selectedOutletArr.id]}
              onCloseClick={() => setInfoOpen(false)}
              position={{
                lat: selectedOutletArr.latitude,
                lng: selectedOutletArr.longitude,
              }}
            >
              <div>

              <Link
                  to={{
                    pathname: "/outlets",
                    state: {
                      outletId: selectedOutletArr.outletNumber,
                      nameOfOutlet: selectedOutletArr.outletName,
                    },
                  }}
                >
                  {selectedOutletArr.outletName}
                </Link>
                
              </div>
            </InfoWindow>
          )}  

          {/* {infoOpen && selectedPlace && (
            <InfoWindow
              anchor={markerMap[selectedPlace.id]}
              onCloseClick={() => setInfoOpen(false)}
              position={{
                lat: selectedPlace.latitude,
                lng: selectedPlace.longitude,
              }}
            >
              <div>
                <p>
                  <h6>
                    {" "}
                    <strong>{selectedPlace.area}</strong>
                  </h6>
                  <strong>
                    Amount :{" "}
                    {selectedPlace.weight
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </strong>
                </p>
                <p>
                  <strong>
                    Sales :{" "}
                    {selectedPlace.total
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </strong>
                </p>
              </div>
            </InfoWindow>
          )} */}

          {infoOpenOutlet && selectedOutlet && (
            <InfoWindow
              anchor={markerMap[selectedOutlet.id]}
              onCloseClick={() => setInfoOpenOutlet(false)}
              position={{
                lat: selectedOutlet.lat,
                lng: selectedOutlet.lng,
              }}
            >
              <div>
                <Link
                  to={{
                    pathname: "/outlets",
                    state: {
                      outletId: selectedOutlet.id,
                      nameOfOutlet: selectedOutlet.name,
                    },
                  }}
                >
                  {selectedOutlet.name}
                </Link>
                <p>
                  <strong>Total Sales:</strong> {selectedOutlet.sales} <br />
                  <strong>Total Amount:</strong>
                  <CurrencyFormat
                    value={selectedOutlet.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  <br />
                </p>
                <p>
                  {/* <span className="primary" onClick={() => outletDetails(selectedOutlet)} >go to outlet </span> */}
                  {/* <Link className=""               
              to="/outletDetails"
              params={{outletNumber:selectedOutlet.outletNumber}} 
              
              >
               show outlet details</Link>   */}
                </p>
              </div>
            </InfoWindow>
          )}

          <HeatmapLayer
            data={dailySales} //should pass all sales
          ></HeatmapLayer>

          <HeatmapLayer
            //  data = {territorySales}
            gradient={10}
            opacity={1}
          ></HeatmapLayer>
        </GoogleMap>
      </>
    );
  }
);

export default SalesMap;
