import React,{useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    getCreditsAction,
    getOutletCreditsAction,
    getUserCreditsAction,
    updateCreditRequestAction,
  } from "../../store/actions/SalesAction";
import { ButtonWithIcon } from "../../styles/Forms";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import Table from "../../components/dom/Table/Table";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const CreditProvision = ({})=>{
    const userCredits = useSelector((state) => state.salesManager.userCredits);
    const userRoute = useSelector((state) => state.routeManager.route);
    const userObj = useSelector((state) => state.authManager.user);
    const dispatch = useDispatch();
    const agentCreditsColumns = [
        {
          Header: "Created Date",
          accessor: (d) => moment(d.createdAt).format("ddd D MMM YYYY"),
          key:["createdAt"]
        },
        
        {
          Header: "Customer",
          accessor: "clientName",
          key:["clientName"]
        },
        {
          Header: "Submitted by",
          accessor: "orderBy",
          key:["orderBy"]
        },
        {
          Header: "CHannel",
          accessor:d=>d.orderType==="DRIVER"?"Sale":"Deliver"
        },
        
    
        {
          Header: "Route ID",
          accessor: "routeId",
          key:["routeId"]
        },
    
     
    
        {
          Header: "Shift",
          accessor: "shift",
          key:["shift"]
        },
        {
          Header: "Product (Quantity) ",
          accessor: p=>p.items&&p.items.length>0&&p.items.map(product=>`${product.productName} (${product.quantity}) , `),
          key:["items"]
        },
        {
          Header: " ",
    
          Cell: (row) => (
            <>
              <ButtonWithIcon
                className="primary text-uppercase font-weight-normal"
                onClick={() => ChangeCreditStatus(row.row.original, "ACCEPTED")}
              >
                <CheckCircleIcon />
                Approve
              </ButtonWithIcon>
              <ButtonWithIcon
                className="primary text-uppercase font-weight-normal"
                onClick={() => ChangeCreditStatus(row.row.original, "DECLINED")}
              >
                <CancelIcon />
                Reject
              </ButtonWithIcon>
            </>
          ),
        },
      ];


      useEffect(() => {
        let creditPayload = {
          searches: [
            {
              key: "routeCode",
              value: userRoute&&userRoute.route&&userRoute.route.routeCode ? userRoute.route.routeCode : "",
            },
            {
              key: "status",
              value: "ACTIVE"
            },
          ],
        };
        dispatch(getUserCreditsAction(creditPayload));


     
        
        
      }, []);

    


  const ChangeCreditStatus = (data, status) => {
    let payload = {
      requestId: data.orderNumber,
      username: userObj.username,
      status: status,
      comments: status,
    };
    dispatch(updateCreditRequestAction(payload));
  };


    return (
<>
{userCredits && userCredits.length > 0 && (
                <>
                  <Table data={userCredits} columns={agentCreditsColumns} />
                </>
              )}
              {userCredits && userCredits.length === 0 && (
                <p className="alert alert-info">
                  No credits requests to show at the moment
                </p>
              )}
</>
    )
}
export default CreditProvision