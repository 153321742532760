import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/dom/Table/Table";
import { allSalesColumns } from "./columns";
import { getUserSalesAction } from "../../store/actions/SalesAction";
import "react-tabs/style/react-tabs.css";

const UserSalesTable = () => {
  const userSales = useSelector((state) => state.salesManager.userSales);
  const userObj = useSelector((state) => state.userManager.user);
  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      searches: [
        {
          key: "deliveredBy",
          value: userObj.username,
        },
      ],
    };

    dispatch(getUserSalesAction(data));
  }, []);

  return (
    <>
      <Table data={userSales} columns={allSalesColumns} />
    </>
  );
};

export default UserSalesTable;
