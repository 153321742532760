import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import { TabsWrapper } from "../../styles/Tabs";
import UserProfile from "./UserProfile";
import UserSales from "../sales/UserSales";
import "react-tabs/style/react-tabs.css";
import SchedulePage from "../schedule/SchedulePage";
import DeliveriesPage from "../deliveries/DeliveriesPage";
import UserPerformancePage from "../performance/UserPerformancePage";
import UserSummaryPage from "./UserSummaryPage";
import TrucksSurveyTable from "../trucks/TrucksSurveyTable";
import {
  getUserRouteAction,
  getDriverRouteHistoryAction,
  getAgentRouteAction,
  getAgentRouteHistoryAction,
} from "../../store/actions/RouteAction";
import { usePermissionChecker } from "../../utils/user";
import LoadingIndicator from "../../components/dom/LoadingIndicator";
import RealtimeMap from "../../components/general/RealtimeMap";
import OutletAssignmentPage from "./OutletAssignmentPage";
import OutletAssignment from "../../components/molecules/OutletAssignment/OutletAssignment";
import { resetSelectedOutletsAction } from "../../store/actions/OutletAction";
import RoutePlanner from "../RoutePlanner/RoutePlanner";
import { resetUserOutletsAction } from "../../store/actions/routePlanActions";

const UserProfilePage = ({ resetView, edit }) => {
  const userRoute = useSelector((state) => state.routeManager.route);
  const selectedUser = useSelector((state) => state.userManager.user);

  const isLoading = useSelector((state) => state.userManager.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSelectedOutletsAction());
    dispatch(resetUserOutletsAction());
  }, []);

  return (
    <div className="row">
      <div className="col-md-1 back-arrow">
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="2x"
          className="mr-2"
          onClick={() => resetView()}
        />
      </div>
      <div className="col-md-11">
        <PageTopHeader title="User Management" />
      </div>
      <div className="col-sm-12">
        <TabsWrapper>
          <Tabs>
            <TabList>
              <Tab>Info</Tab>
              {usePermissionChecker("can_view_user_sales") && <Tab>Sales</Tab>}
              {usePermissionChecker("can_assign_outlets") &&
                selectedUser.role === "sales-representative" && (
                  <Tab>Assign Outlets</Tab>
                )}
              {usePermissionChecker("can_plan_route") &&
                selectedUser.role === "sales-representative" && (
                  <Tab>Route Planning</Tab>
                )}

              {usePermissionChecker("can_manage_sales_target") &&
                selectedUser.role === "sales-representative" && (
                  <Tab>Sales Target</Tab>
                )}

              {usePermissionChecker("can_view_user_realtime_location") &&
                selectedUser.role === "sales-representative" && (
                  <Tab>Realtime</Tab>
                )}

              {usePermissionChecker("can_view_user_performance_page") &&
                selectedUser.role === "call-centre-agent" && (
                  <Tab>Performance</Tab>
                )}
              {usePermissionChecker("can_view_truck_survey") && (
                <Tab>Truck Surveys</Tab>
              )}
            </TabList>

            <TabPanel>
              {!isLoading ? <UserProfile edit={edit} /> : <LoadingIndicator />}
            </TabPanel>

            {usePermissionChecker("can_assign_outlets") &&
              selectedUser.role === "sales-representative" && (
                <TabPanel>
                  <OutletAssignment />
                </TabPanel>
              )}

            {usePermissionChecker("can_view_user_sales") && (
              <TabPanel>
                <UserSales username={selectedUser.username} />
              </TabPanel>
            )}

            {usePermissionChecker("can_view_user_deliveries") && (
              <TabPanel>
                <DeliveriesPage username={selectedUser.username} />
              </TabPanel>
            )}

            {usePermissionChecker("can_plan_route") &&
              selectedUser.role === "sales-representative" && (
                <TabPanel>
                  <RoutePlanner />
                </TabPanel>
              )}

            {usePermissionChecker("can_manage_sales_target") &&
              selectedUser.role === "sales-representative" && (
                <TabPanel>
                  <UserPerformancePage username={selectedUser.username} />
                </TabPanel>
              )}

            {usePermissionChecker("can_view_user_realtime_location") &&
              selectedUser.role === "sales-representative" && (
                <TabPanel>
                  <RealtimeMap user={selectedUser} />
                </TabPanel>
              )}

            {usePermissionChecker("can_view_user_summary") &&
              selectedUser.role === "driver" && (
                <TabPanel>
                  <UserSummaryPage user={selectedUser} />
                </TabPanel>
              )}

            {usePermissionChecker("can_view_truck_survey") && (
              <TabPanel>
                <TrucksSurveyTable
                  payload={{ key: "driver", value: selectedUser.username }}
                />
              </TabPanel>
            )}
          </Tabs>
        </TabsWrapper>
      </div>
    </div>
  );
};

export default UserProfilePage;
