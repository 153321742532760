import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/dom/Table/Table";
import { getOrderDeliveriesAction } from "../../store/actions/OrderAction";
import { ButtonWithIcon } from "../../styles/Forms";
import moment from "moment";
import DeliveryItems from "./DeliveryItems";
import PageModal from "../../components/dom/modal";
import DriverCouldNotDeliver from "../../pages/orders/DriverCouldNotDeliver";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const DeliveriesPage = ({ username }) => {
  const [showHome, setShowHome] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [order, setOrder] = useState({});
  const deliveries = useSelector((state) => state.OrderManager.deliveries);
  const dispatch = useDispatch();

  const viewItems = (data) => {
    setOrder(data);
    setmodalIsOpen(true);
  };

  const deliveriesColumns = [
    {
      Header: "DATE",
      accessor: (d) => moment(d.date).format("LLL"),
      key:["date"]
    },
    {
      Header: "Customer Name",
      accessor: "outlet",
      key:["outlet"]
    },
    {
      Header: "Customer Type",
      accessor: "outletType",
      key:["outletType"]
    },
    {
      Header: "Route",
      accessor: "route",
      key:["route"]
    },

    {
      Header: "Driver",
      accessor: "driver",
      key:["driver"]
    },
    {
      Header: "Amount",
      accessor: "amount",
      key:["amount"]
    },
    {
      Header: " ",

      Cell: (row) => (
        <ButtonWithIcon
          className="primary text-uppercase font-weight-normal"
          onClick={() => viewItems(row.row.original)}
        >
          View Items
        </ButtonWithIcon>
      ),
    },
  ];

  useEffect(() => {
    let data = { driver: username };
    dispatch(getOrderDeliveriesAction(data));
  }, []);

  const resetView = () => {
    setShowHome(true);
    setShowForm(false);
  };

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>Delivered</Tab>
          <Tab>Not Delivered</Tab>
        </TabList>

        <TabPanel>
          <>
            {deliveries && deliveries.length > 0 && (
              <Table data={deliveries} columns={deliveriesColumns} />
            )}

            {deliveries && deliveries.length === 0 && (
              <p className="alert alert-info">No Deliveries at the moment</p>
            )}

            <PageModal
              isOpen={modalIsOpen}
              component={<DeliveryItems order={order} resetView={resetView} />}
              setIsOpen={setmodalIsOpen}
            />
          </>
        </TabPanel>
        <TabPanel>
          <DriverCouldNotDeliver username={username} />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default DeliveriesPage;
