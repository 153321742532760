import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetailsAction,
  deactivateUserAction,
  activateUserAction,
} from "../../store/actions/UserAction";
import {
  getUserRouteAction,
  getAgentRouteAction,
  getDriverRouteHistoryAction,
  getAgentRouteHistoryAction,
} from "../../store/actions/RouteAction";
import { sendPasswordRecoveryTokenAction } from "../../store/actions/UserAction";
import moment from "moment";
import { Label } from "../../styles/Forms";
import CreateIcon from "@material-ui/icons/Create";
import { UserButton } from "../../styles/App";
import { ProfilePicture } from "../../styles/User";
import { OrderFormWrapper } from "../../styles/Order";
import PageModal from "../../components/dom/modal";
import UserRouteHistory from "./UserRouteHistory";
import { scrollToTop } from "../../utils/window";
import { usePermissionChecker } from "../../utils/user";

const UserProfile = ({ edit }) => {
  const [assignRouteModal, setAssignRouteModal] = useState(false);
  const user = useSelector((state) => state.userManager.user);
  const error = useSelector((state) => state.stateManager.error);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const userRoute = useSelector((state) => state.routeManager.route);
  const selectedUser = useSelector((state) => state.userManager.user);
  const userVerticals = useSelector((state) => state.userManager.userVerticals);

  const dispatch = useDispatch();

  const resetUserPassword = () => {
    let data = { username: user.username };
    dispatch(sendPasswordRecoveryTokenAction(data));
  };

  const deactivateUser = () => {
    dispatch(deactivateUserAction(user.username));
  };

  const activateUser = () => {
    dispatch(activateUserAction(user.username));
  };

  const resetView = () => {
    setAssignRouteModal(false);
    if (user.role === "driver") {
      setTimeout(
        () =>
          dispatch(
            getUserRouteAction({
              driver: selectedUser.username,
              shift: user.shift,
            })
          ),
        6000
      );
      setTimeout(
        () =>
          dispatch(
            getDriverRouteHistoryAction({ driver: selectedUser.username })
          ),
        6000
      );
    }

    if (user.role === "call-centre-agent") {
      setTimeout(
        () => dispatch(getAgentRouteAction({ agent: selectedUser.username })),
        6000
      );
      setTimeout(
        () =>
          dispatch(
            getAgentRouteHistoryAction({
              agent: selectedUser.username,
              shift: user.shift,
            })
          ),
        6000
      );
    }

    scrollToTop();
  };
  const openRouteAssignmentModal = () => {
    setAssignRouteModal(true);
  };

  useEffect(() => {
    if (success) {
      setTimeout(
        () =>
          dispatch(getUserDetailsAction({ username: selectedUser.username })),
        5000
      );
    }
  });

  return (
    <>
      {user && user.username === selectedUser.username && (
        <>
          <div className="col-sm-12">
            {error && <p className="alert alert-danger mt-3">{message}</p>}
            {success && <p className="alert alert-success mt-3">{message}</p>}
          </div>
          <div className="row mt-9">
            <div className="col-lg-4">
              <h3 className="p-3">Profile Details</h3>
            </div>
            <div className="col-lg-7 ">
              <div className="float-right">
                <UserButton onClick={() => resetUserPassword()}>
                  Reset Password
                </UserButton>
                {/* <UserButton>Access Token</UserButton> */}
                {user.status === "ACTIVE" && (
                  <UserButton onClick={deactivateUser}>Deactivate</UserButton>
                )}
                {user.status === "INACTIVE" && (
                  <UserButton onClick={activateUser}>Activate</UserButton>
                )}

                <CreateIcon
                  className="pointer i-p"
                  onClick={() => edit(user)}
                />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="form-group col-md-4 mb-3">
              <ProfilePicture
                src={user.picturePath}
                alt={`${user.firstName} ${user.lastName}`}
                width="300"
                height="350"
              />
            </div>

            <div className="form-group col-md-8 mb-3">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <Label htmlFor="username">Firstname </Label>
                  <div className="dp-output">{user.firstName}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label htmlFor="username">lastName </Label>
                  <div className="dp-output">{user.lastName}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label htmlFor="username">Username </Label>
                  <div className="dp-output">{user.username}</div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <Label>Access Token</Label>
                  <div className="dp-output">
                    {user.accessToken ? user.accessToken : "-"}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Role</Label>
                  <div className="dp-output">{user.role.replace("-", " ")}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Language</Label>
                  <div>
                    <div className="dp-output">
                      {user.language === "sw" && "Kiswahili"}
                      {user.language === "en" && "english"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4 ">
                  <Label>Adress</Label>
                  <div>{user.address}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Email Address</Label>
                  <div>{user.email}</div>
                </div>

                <div className="form-group col-md-4 ">
                  <Label>Last Login</Label>
                  <div>
                    {user.lastLogin
                      ? moment(user.lastLogin).format("ddd D MMM Y  HH:mm A")
                      : " - "}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <Label>ID Type</Label>
                  <div className="text-capitalize">
                    {user.idType.replace("-", " ")}
                  </div>
                </div>

                <div className="form-group col-md-4">
                  <Label>ID Number</Label>
                  <div>{user.idNumber}</div>
                </div>

                <div className="form-group col-md-4 ">
                  <Label>Mobile</Label>
                  <div>{user.mobile}</div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 mb-3">
                  <Label>Assigned Verticals</Label>
                  <div>
                    {userVerticals && userVerticals.length > 0
                      ? userVerticals.map(
                          (vertical, index) =>
                            `${vertical.name} ${
                              index < userVerticals.length - 1 ? "," : ""
                            } `
                        )
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(user.role === "driver" || user.role === "call-centre-agent") && (
            <UserRouteHistory viewModal={openRouteAssignmentModal} />
          )}
        </>
      )}
    </>
  );
};

export default UserProfile;
