import {
  authenticate,
  sendPasswordRecoveryToken,
  verifyPasswordRecoveryToken,
  processPasswordRecovery,
  getAccessToken,
} from "../../services/AuthService";
import {
  LOGIN,
  ACTION_SUCCESS,
  TOKEN_VERIFY_SUCCESS,
  RESET_PASSWORD_ACTION_SUCCESS,
  ACTION_FAIL,
} from "../constants/ActionTypes";
import { handleErrorAction } from "./UtilsAction";
import { getRoleRoute } from "./RouteAction";
import { setSessionExpirationTime } from "../../utils/auth";

export const logoutAction = () => (dispatch) => {
  
  localStorage.clear();
  sessionStorage.clear();
  dispatch({ type: "LOGOUT" });
};

export function login(credentials) {
  return function(dispatch) {
    return authenticate(credentials).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          let userData = response.data.responseObject;
          setSessionExpirationTime();
          getRoleRoute(userData.user);
          dispatch({
            type: LOGIN,
            payload: {
              user: userData,
            },
          });
        }
      },
      (error) =>
        dispatch({
          type: ACTION_FAIL,
          payload: "Wrong username or Password",
        })
    );
  };
}

export const resetPasswordAction = (data) => {
  return (dispatch) => {
    return sendPasswordRecoveryToken(data).then(
      (response) => {
        console.log(response);
        return dispatch({
          type: ACTION_SUCCESS,
          payload: response.data.message,
        });
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const verifyTokenAction = (data) => {
  return (dispatch) => {
    return verifyPasswordRecoveryToken(data).then(
      (response) => {
        if (response.status === 200) {
          if (response.data && response.data.responseObject) {
            let token = response.data.responseObject;
            let user = response.data.responseObject.user;

            dispatch({
              type: TOKEN_VERIFY_SUCCESS,
              user,
              token,
            });

            dispatch({
              type: ACTION_SUCCESS,
              payload: "Verification succesful . Set the new password .",
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const changePasswordAction = (data) => {
  return (dispatch) => {
    return processPasswordRecovery(data).then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: RESET_PASSWORD_ACTION_SUCCESS,
            payload: "Password reseted succesfully . Login to continue...",
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};
