import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import {
  getPaymentsAction,
  getReconSummaryAction,
} from "../../store/actions/OrderAction";
import Table from "../../components/dom/Table/Table";
import ReconDetails from "./ReconDetails";
import Tile from "../../components/dom/Tile";
import LoadingIndicator from "../../components/dom/LoadingIndicator";

const ReconciliationPage = () => {
  const [paymentReturn, setPaymentReturn] = useState({});
  const [showReconInfo, setShowReconInfo] = useState(false);
  const [showHome, setShowHome] = useState(true);
  const payments = useSelector((state) => state.OrderManager.payments);
  const reconSummary = useSelector((state) => state.OrderManager.reconSummary);
  const isLoading = useSelector((state) => state.OrderManager.loading);
  const dispatch = useDispatch();

  const view = (recon) => {
    setPaymentReturn(recon);
    setShowHome(false);
    setShowReconInfo(true);
  };

  const resetView = () => {
    let date = moment().format("YYYYMMDD");
    let params = `date = ${date}`;
    setPaymentReturn({});
    setShowHome(true);
    setShowReconInfo(false);
    console.log("getReconSummaryAction", params);
    dispatch(getReconSummaryAction(params));
  };

  const columns = [
    {
      Header: "Created Date",
      accessor: (d) => moment(d.createdAt).format("LLL"),
    },
    {
      Header: "Paid By",
      accessor: "username",
    },
    {
      Header: "Received By",
      accessor: "received_by",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",

      Cell: (row) => (
        <div className="row">
          <div
            onClick={() => view(row.row.original)}
            className="col-12 pointer"
          >
            &nbsp; &nbsp;
            <span className="primary">View More </span>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    let date = moment().format("YYYYMMDD");
    let params = `date=${date}`;
    dispatch(getPaymentsAction());
    dispatch(getReconSummaryAction(params));
  }, []);
  return (
    <>
      {showHome && (
        <>
          <PageTopHeader title="Reconciliation" />
          {isLoading && <LoadingIndicator />}
          {/* {!isLoading && (
            <div className="row">
              <Tile
                title="Total Returned"
                inlineStyle="col-sm-3"
                value={
                  reconSummary &&
                  reconSummary.totalReturns &&
                  reconSummary.totalReturns.toLocaleString()
                }
              />
              <Tile
                title="Total Order Sales"
                inlineStyle="col-sm-3"
                value={
                  reconSummary &&
                  reconSummary.totalOrderSales &&
                  reconSummary.totalOrderSales.toLocaleString()
                }
              />
              <Tile
                title="Total Extra Sales"
                inlineStyle="col-sm-3"
                value={
                  reconSummary &&
                  reconSummary.totalExtaSales &&
                  reconSummary.totalExtaSales.toLocaleString()
                }
              />
              <Tile
                title="Total Credits Collected"
                inlineStyle="col-sm-3"
                value={
                  reconSummary &&
                  reconSummary.totalCreditCollected &&
                  reconSummary.totalCreditCollected.toLocaleString()
                }
              />
            </div>
          )} */}
          <Table data={payments} columns={columns} />
        </>
      )}
      {showReconInfo && (
        <ReconDetails payment={paymentReturn} resetView={resetView} />
      )}
    </>
  );
};

export default ReconciliationPage;
