import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getDailyOrdersAction,
  getOrderSummaryAction,
} from "../../store/actions/OrderAction";
import Table from "../../components/dom/Table/Table";
import { dailyOrdersColumns } from "./OrderTableColumns";
import { ButtonWithIcon } from "../../styles/Forms";
import { DateSearchtWrapper } from "../../styles/schedule";
import DatePicker from "react-datepicker";
import { scrollToTop } from "../../utils/window";
import { getAllOrdersSummary } from "../../services/OrderService";

const DailyOrdersPage = ({ shift }) => {
  const orders = useSelector((state) => state.OrderManager.dailyOrders);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    let start = "01/01/2021";
    let today = moment().format("DD/MM/YYYY");
    let data = {
      startDate: start,
      endDate: today,
    };
    //dispatch(getOrderSummaryAction(data));
    getAllOrdersSummary(data);
    dispatch(getDailyOrdersAction(data));
  }, []);

  const handleFiltersSubmit = (e) => {
    e.preventDefault();
    let data = {
      startDate: moment(startDate).format("DD/MM/YYYY"),
      endDate: moment(endDate).format("DD/MM/YYYY"),
    };

    scrollToTop();
    dispatch(getOrderSummaryAction(data));
    dispatch(getDailyOrdersAction(data));
  };

  const columns = [
    {
      Header: "Date",
      accessor: "date",
      key: ["date"],
    },
    {
      Header: "Product Name",
      accessor: "productName",
      key: ["productName"],
    },
    {
      Header: "Unit Price",
      accessor: "unitPrice",
      key: ["unitPrice"],
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      key: ["quantity"],
    },
    {
      Header: "Processed",
      accessor: "processed",
      key: ["processed"],
    },
    {
      Header: "Cancelled",
      accessor: "cancelled",
      key: ["cancelled"],
    },
    {
      Header: "Rejected",
      accessor: "rejected",
      key: ["rejected"],
    },
    {
      Header: "Total",
      accessor: "totalCost",
      key: ["totalCost"],
    },
  ];

  return (
    <>
      <DateSearchtWrapper>
        <form onSubmit={handleFiltersSubmit} role="form">
          <div className="row">
            <div className="form-group col-sm-5">
              <div className="datePicker">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="from"
                  className="date-picker-input"
                />
              </div>
            </div>
            <div className="form-group col-sm-5">
              <div className="datePicker">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="to"
                  className="date-picker-input"
                />
              </div>
            </div>
            <div className="col-sm-2">
              <ButtonWithIcon type="submit">filter</ButtonWithIcon>
            </div>
          </div>
        </form>
      </DateSearchtWrapper>
      <Table data={orders} columns={columns} />
    </>
  );
};

export default DailyOrdersPage;
