import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faLock } from "@fortawesome/free-solid-svg-icons";
import { getPermissionsAction } from "../../store/actions/UserAction";
import Table from "../../components/dom/Table/Table";
import { PageButton } from "../../styles/App";
import AddPermissionForm from "./AddPermissionForm";
import CreateIcon from "@material-ui/icons/Create";

const PermissionsPage = ({ addPermission, edit }) => {
  const [showForm, setShowForm] = useState(false);
  const [editing, setEditing] = useState(false);
  const [canManagePermissions, setCanManagePermissions] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [role, setRole] = useState({});

  const permissions = useSelector((state) => state.userManager.permissions);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  //const products = useSelector(state => state.productManager.products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPermissionsAction());
  }, []);

  const resetView = () => {
    setShowForm(false);
    setShowTable(true);
    // dispatch(getRolesAction());
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Action",

      Cell: (row) => (
        <div onClick={() => edit(row.row.original)} className="pointer">
          <CreateIcon className="primary pointer" />{" "}
          <span className="primary">Edit Permission</span>
        </div>
      ),
    },
  ];

  console.log(permissions);

  return (
    <>
      {success && <p className="alert alert-success">{message}</p>}
      <div className="row">
        <div className="col-sm-2">
          <h4 className="mt-3">All Permissions</h4>
        </div>
        <div className="col-sm-10">
          <PageButton className="float-right" onClick={addPermission}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Permission
          </PageButton>
        </div>
      </div>

      {showTable && permissions && permissions.length > 0 && (
        <Table data={permissions} columns={columns} rowInfo={() => {}} />
      )}

      {showTable && permissions && permissions.length === 0 && (
        <p className="alert alert-info mt-2">No permissions added yet</p>
      )}

      {showForm && (
        <AddPermissionForm
          editMode={editing}
          role={role}
          resetView={resetView}
        />
      )}
    </>
  );
};

export default PermissionsPage;
