import {
  IMAGE_UPLOAD_SUCCESS,
  RESET_UTILS,
  START_FETCHING,
  UPDATE_IMAGE_URL,
} from "../constants/ActionTypes";
const initialState = {
  isFetching: false,
  imageUploadSuccess: false,
  imgUrl:
    "https://sfaimagesbucket.s3.amazonaws.com/Profiles/no-photo-opaque.png",
};

function UtilsReducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING:
      return { ...state, isFetching: true };
    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        imgUrl: action.payload,
        imageUploadSuccess: true,
      };

    case UPDATE_IMAGE_URL:
      return {
        ...state,
        isFetching: false,
        imgUrl: action.payload,
        imageUploadSuccess: true,
      };
    case RESET_UTILS:
      return {
        ...state,
        imageUploadSuccess: false,
        imgUrl:
          "https://sfaimagesbucket.s3.amazonaws.com/Profiles/no-photo-opaque.png",
      };

    default:
      return state;
  }
}

export default UtilsReducer;
