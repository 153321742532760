import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Table from "../../components/dom/Table/Table";
import {
  getCategoriesAction,
  getSubcategoriesAction,
  getTaxCodeAction,
} from "../../store/actions/ProductAction";
import moment from "moment";
import { PageButton } from "../../styles/App";
import CreateIcon from "@material-ui/icons/Create";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";

const TaxCodePage = ({ add, edit, showCutOffTimeForm }) => {
  const columns = [
    {
      Header: "ID",
      accessor: "id",
      key: ["id"],
    },
    {
      Header: "Name",
      accessor: "name",
      key: ["name"],
    },
    {
      Header: "Code",
      accessor: "code",
      key: ["code"],
    },
    {
      Header: "Value",
      accessor: "value",
      key: ["value"],
    },

    {
      Header: "Action",

      Cell: (row) => (
        <div className="row">
          <div onClick={() => edit(row.row.original)} className="col-5 pointer">
            <CreateIcon className="primary " />{" "}
            <span className="primary">Edit </span>
          </div>
        </div>
      ),
    },
  ];

  const taxCodes = useSelector((state) => state.productManager.taxCodes);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaxCodeAction());
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {success && <p className="alert alert-success">{message}</p>}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <h4 className="mt-3">All Tax Codes</h4>
        </div>
        <div className="col-sm-10">
          <PageButton className="float-right" onClick={add}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Create Tax Code
          </PageButton>
        </div>
      </div>
      {taxCodes && taxCodes.length === 0 && (
        <p className="alert alert-info mt-3">No tax codes at the moment</p>
      )}
      {taxCodes && taxCodes.length > 0 && (
        <Table data={taxCodes} columns={columns} rowInfo={() => {}} />
      )}
    </>
  );
};

export default TaxCodePage;
