import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCloudUploadAlt,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import {
  changeProductVisibilityAction,
  deleteProductsAction,
  getAllProductsAction,
  getProductsAction,
} from "../../store/actions/ProductAction";
import Table from "../../components/dom/Table/Table";
import { PageButton } from "../../styles/App";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import { ButtonWithIcon } from "../../styles/Forms";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CSVReader from "react-csv-reader";
import ReactFileReader from "react-file-reader";
import "./products.css";
import LoadingIndicator from "../../components/dom/LoadingIndicator";
import Select from "react-select";
import { deleteProducts } from "../../services/ProductService";
import { TabsWrapper } from "../../styles/Tabs";

const options = [
  { value: "export", label: "Export" },
  { value: "delete", label: "Delete" },
  { value: "hide", label: "Hide" },
];

const ProductsTablePage = ({
  addProduct,
  edit,
  view,
  upload,
  deleteProduct,
  hideProduct,
  buldEdit,
  bulkEditHandleUpload,
}) => {
  const [productIds, setProductIds] = useState([]);
  const userObj = useSelector((state) => state.authManager.user);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [checked, setChecked] = useState([]);
  const [canShowBulkUploadText, setCanShowBulkUploadText] = useState(true);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const products = useSelector((state) => state.productManager.products);
  const isLoading = useSelector((state) => state.productManager.isLoading);
  const isProductsUploadedToS3 = useSelector(
    (state) => state.productManager.isProductsUploadedToS3
  );
  const productMessage = useSelector((state) => state.productManager.message);

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      setProductIds([]);
      setChecked([]);
    }
  }, [success]);

  const handleProductSelect = (productId, index) => {
    var checkedCopy = checked;
    checkedCopy[index] = !checked[index];
    setChecked(checkedCopy);
    if (checkedCopy[index] === false) {
      setIsAllSelected(false);
    }
    if (productIds.includes(productId)) {
      setProductIds(productIds.filter((id) => id !== productId));
    } else {
      setProductIds([...productIds, productId]);
    }
  };

  useEffect(() => {
    let filter = {
      orderBy: "id",
      orderDir: "desc",
    };
    dispatch(getAllProductsAction(filter));
  }, []);

  const selectAll = () => {
    var allSelectStatus = !isAllSelected;
    setIsAllSelected(allSelectStatus);
    var checkedCopy = [];
    let productIds = [];
    products.forEach((product, index) => {
      checkedCopy.push(allSelectStatus);
      productIds.push(product.id);
    });
    setChecked(checkedCopy);
    if (allSelectStatus) {
      setProductIds(productIds);
    } else {
      setProductIds([]);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value === "export") {
      buldEdit(productIds);
    }
    if (selectedOption.value === "delete") {
      dispatch(deleteProductsAction(productIds));
    }
    if (selectedOption.value === "hide") {
      let hidePayload = {
        ids: productIds,
        status: "INACTIVE",
        username: userObj.username,
      };

      dispatch(changeProductVisibilityAction(hidePayload));
    }
  };

  const productColumn = [
    {
      Header: (
        <input type="checkbox" onChange={selectAll} checked={isAllSelected} />
      ),
      sortable: false,
      accessor: "id",
      Cell: (row) => (
        <input
          type="checkbox"
          onChange={() =>
            handleProductSelect(row.row.original.id, row.row.index)
          }
          checked={checked[row.row.index]}
        />
      ),
    },
    {
      Header: "Items",
      accessor: "name",
      Cell: (row) => (
        <>
          <img
            src={
              row.row.original.image
                ? row.row.original.images[0]
                  ? row.row.original.images[0].path
                  : row.row.original.image
                : "https://s3.amazonaws.com/aimrough/potraits/default-avatar.png"
            }
            alt={row.row.original.name}
            className="img-fluid pr-2"
            width="40px"
            height="40px"
          />{" "}
          {row.row.original.name}
        </>
      ),
      key: ["name"],
    },

    {
      Header: "Category",
      accessor: "categoryName",
      key: ["categoryName"],
    },
    {
      Header: "Subcategory",
      accessor: "subcategoryName",
      key: ["subcategoryName"],
    },
    {
      Header: "Supplier",
      accessor: "supplierName",
      key: ["supplierName"],
    },
    {
      Header: "Status",
      accessor: "status",
      key: ["status"],
    },

    {
      Header: "Created By",
      accessor: "createdBy",
      key: ["createdBy"],
    },

    {
      Header: " ",
      Cell: (row) => {
        return (
          <>
            <ButtonWithIcon onClick={() => view(row.row.original)}>
              <VisibilityIcon className="primary pointer" /> View
            </ButtonWithIcon>
            <ButtonWithIcon onClick={() => edit(row.row.original)}>
              <CreateIcon className="primary pointer" /> Edit
            </ButtonWithIcon>
            <ButtonWithIcon onClick={() => hideProduct(row.row.original)}>
              <DeleteIcon className="primary pointer" />{" "}
              {row.row.original.status === "ACTIVE" ? "Hide" : "Show"}
            </ButtonWithIcon>
            <ButtonWithIcon onClick={() => deleteProduct(row.row.original)}>
              <DeleteIcon className="primary pointer" /> Delete
            </ButtonWithIcon>
          </>
        );
      },
    },
  ];

  const priceListColumn = [
    {
      Header: "Product Name",
      accessor: "name",
      key: ["name"],
    },

    {
      Header: "Category",
      accessor: "categoryName",
      key: ["categoryName"],
    },
    {
      Header: "Subcategory",
      accessor: "subcategoryName",
      key: ["subcategoryName"],
    },

    {
      Header: "Supplier",
      accessor: "supplierName",
      key: ["supplierName"],
    },
    {
      Header: "Unit Cost",
      accessor: "costVatInc",
      key: ["costVatInc"],
    },
    {
      Header: "Carton Size",
      accessor: "cartonSize",
      key: ["cartonSize"],
    },

    {
      Header: "Cost Price",
      accessor: "cartonCost",
      key: ["cartonCost"],
    },
  ];

  return (
    <TabsWrapper>
      <Tabs>
        <TabList>
          <Tab>Products</Tab>
          <Tab>Price List</Tab>
        </TabList>
        <TabPanel>
          <>
            {success && <p className="alert alert-success">{message}</p>}
            {error && <p className="alert alert-danger">{message}</p>}
            {isProductsUploadedToS3 && !error && (
              <p className="alert alert-success alert-product-uploaded">
                <CircularProgress />
                {productMessage}
              </p>
            )}

            {/* <code>{JSON.stringify(productIds)}</code> */}

            {isLoading && <LoadingIndicator />}

            {!isLoading && (
              <div className="row">
                <div className="col-sm-4">
                  {productIds.length > 0 && (
                    <Select
                      options={options}
                      placeholder="choose action..."
                      onChange={handleSelectChange}
                    />
                  )}
                </div>
                <div className="col-sm-8">
                  <PageButton className="float-right ml-3" onClick={addProduct}>
                    <FontAwesomeIcon icon={faPlus} className="mr-2 " />
                    Create a product
                  </PageButton>
                  <PageButton
                    className="float-right p-2"
                    onClick={() => setCanShowBulkUploadText(false)}
                  >
                    <ReactFileReader
                      handleFiles={upload}
                      fileTypes={[".zip"]}
                      base64={true}
                    >
                      <button className="btn primary">
                        {" "}
                        <FontAwesomeIcon
                          icon={faCloudUploadAlt}
                          className="mr-2 ml-1"
                        />{" "}
                        Bulk Upload
                      </button>
                    </ReactFileReader>
                  </PageButton>

                  {productIds.length === 0 && (
                    <PageButton
                      className="float-right p-2 mr-1"
                      onClick={() => setCanShowBulkUploadText(false)}
                    >
                      <ReactFileReader
                        handleFiles={bulkEditHandleUpload}
                        fileTypes={[".zip"]}
                        base64={true}
                      >
                        <button className="btn primary">
                          {" "}
                          <FontAwesomeIcon
                            icon={faCloudUploadAlt}
                            className="mr-2 ml-1 "
                          />{" "}
                          Upload Edit Template
                        </button>
                      </ReactFileReader>
                    </PageButton>
                  )}

                  {productIds.length > 0 && (
                    <PageButton
                      className="float-right ml-3 mr-1"
                      onClick={() => buldEdit(productIds)}
                    >
                      <FontAwesomeIcon icon={faFileExport} className="mr-2 " />
                      Export Products
                    </PageButton>
                  )}
                </div>
              </div>
            )}

            {!isLoading && products && products.length === 0 && (
              <p className="alert alert-info mt-3">No Products at the moment</p>
            )}
            {!isLoading && products && products.length > 0 && (
              <Table data={products} columns={productColumn} />
            )}
          </>
        </TabPanel>
        <TabPanel>
          {!isLoading && products && products.length > 0 && (
            <Table data={products} columns={priceListColumn} />
          )}
        </TabPanel>
      </Tabs>
    </TabsWrapper>
  );
};

export default ProductsTablePage;
