import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPermissionsAction,
  addPermissionToRoleAction,
} from "../../store/actions/UserAction";
import Table from "../../components/dom/Table/Table";
import { ButtonSmall } from "../../styles/Forms";
import _ from "lodash";

const AddPermissionToRole = ({ role, resetView }) => {
  const permissions = useSelector((state) => state.userManager.permissions);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const dispatch = useDispatch();
  const [savedPermissions, setSavedPermissions] = useState([]);

  const addPermissions = () => {
    let data = {
      id: role.id,
      permissions: savedPermissions,
      checked: true,
    };
    console.log("addPermissions", data);
    dispatch(addPermissionToRoleAction(data));
  };
  useEffect(() => {
    dispatch(getPermissionsAction());
    console.log("Did Mount", role);
    let rolePermissions = role.permissions ? role.permissions : [];
    rolePermissions = Array.isArray(rolePermissions)
      ? rolePermissions
      : [rolePermissions];
    if (rolePermissions.length > 0) {
      console.log("Permissions exist", rolePermissions);
      rolePermissions = rolePermissions.map((permission) => permission.name);
      setSavedPermissions(rolePermissions);
    } else {
      setSavedPermissions([]);
    }
  }, [role]);

  const toggleCheckboxChange = (permission) => {
    console.log(permission.name, savedPermissions);
    if (_.includes(savedPermissions, permission.name)) {
      console.log(permission.name, "Present");
      var index = savedPermissions.indexOf(permission.name);
      if (index > -1) {
        savedPermissions.splice(index, 1);
      }
      console.log(index, savedPermissions);
      setSavedPermissions(savedPermissions);
    } else {
      setSavedPermissions([...savedPermissions, permission.name]);
    }
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },

    {
      Header: "Assign",

      Cell: (row) => (
        <div>
          <input
            type="checkbox"
            onChange={() => toggleCheckboxChange(row.row.original)}
            defaultChecked={_.includes(savedPermissions, row.row.original.name)}
            value={row.row.original.name}
          />
        </div>
      ),
    },
  ];
  return (
    <div className="container page">
      <div className="row">
        <div className="col-sm-12">
          {success && <p className="alert alert-success">{message}</p>}
          {permissions && permissions.length > 0 && (
            <>
              <Table data={permissions} columns={columns} rowInfo={() => {}} />
              <div className="form-group col-md-12 mb-3">
                <ButtonSmall onClick={addPermissions}>Submit</ButtonSmall>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddPermissionToRole;
