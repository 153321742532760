import axios from "axios";
import {
  APP_URL,
  contentTypeUrlEncoded,
  contentTypeApplicationJson,
} from "../constants";
import qs from "qs";

export const getTrucks = async (data) => {
  const result = await axios
    .post(`${APP_URL}distribution/fetch/truck`, {}, contentTypeApplicationJson)
    .then((data) => data);
  return result;
};

export const createTruck = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/create/truck`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const editTruck = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/update/truck`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getUserTruck = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/driver/truck`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const assignTruckToDriver = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/assign/driver/truck`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getTruckSurveys = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}fetch/truck/survey`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
