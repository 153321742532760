import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllSalesAction,
  updateReconStatusAction,
  getCreditsCollectedAction,
} from "../../store/actions/SalesAction";
import {
  getPaymentsAction,
  getOrdersAction,
  getReconSummaryAction,
} from "../../store/actions/OrderAction";
import { getUserReturnedCratesAction } from "../../store/actions/CratesAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import Table from "../../components/dom/Table/Table";
import moment from "moment";
import { allSalesColumns, cratesColumns } from "../sales/columns";
import { ButtonSmall, ButtonBordered } from "../../styles/Forms";
import UserUnsoldTable from "../sales/UserUnsoldTable";
import { scrollToTop } from "../../utils/window";
import { ButtonWithIcon } from "../../styles/Forms";
import PageModal from "../../components/dom/modal";
import OrderItems from "../orders/OrderItems";
import Tile from "../../components/dom/Tile";
import LoadingIndicator from "../../components/dom/LoadingIndicator";

const ReconDetails = ({ payment, resetView }) => {
  const allSales = useSelector((state) => state.salesManager.allSales);
  const creditsCollected = useSelector(
    (state) => state.salesManager.creditsCollected
  );
  const orders = useSelector((state) => state.OrderManager.orders);
  const crates = useSelector((state) => state.cratesManager.returnedCrates);
  const userObj = useSelector((state) => state.authManager.user);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const reconSummary = useSelector((state) => state.OrderManager.reconSummary);
  const isLoading = useSelector((state) => state.OrderManager.loading);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [order, setOrder] = useState({});
  const dispatch = useDispatch();

  const changeReconStatus = (status) => {
    let data = {
      username: userObj.username,
      id: payment.id,
      status,
    };
    console.log(data);
    dispatch(updateReconStatusAction(data));
  };

  const unsoldPayload = {
    searches: [
      {
        key: "username",
        value: payment.username,
      },
    ],
  };

  useEffect(() => {
    let data = {
      searches: [
        {
          key: "deliveredBy",
          value: payment.username,
        },
        {
          key: "deliveryDateInt",
          value: moment(payment.createdAt).format("YYYYMMDD"),
        },
        {
          key: "type",
          value: "DIRECT_SALE",
        },
      ],
    };

    let reconPayload = {
      searches: [
        {
          key: "driver",
          value: payment.username,
        },
        {
          key: "deliveryDateInt",
          value: moment(payment.createdAt).format("YYYYMMDD"),
        },
      ],
    };

    let date = moment(payment.createdAt).format("YYYYMMDD");

    let params = `date=${date}&username=${payment.username}`;

    let deliveryPayload = {
      searches: [
        {
          key: "deliveredBy",
          value: payment.username,
        },
        {
          key: "status",
          value: "DELIVERED",
        },
        {
          key: "deliveryDateInt",
          value: moment(payment.createdAt).format("YYYYMMDD"),
        },
      ],
    };

    let creditCollectedPayload = {
      searches: [
        {
          key: "paymentType",
          value: "CREDIT",
        },
        {
          key: "paymentStatus",
          value: "PAID",
        },

        {
          key: "paymentReceivedBy",
          value: payment.username,
        },
      ],
    };

    let cratesPayload = {
      searches: [
        {
          key: "driver",
          value: payment.username,
        },
        { key: "returned", comparisonType: "GREATER_THAN", value: "0" },
      ],
    };

    dispatch(getAllSalesAction(data));
    dispatch(getUserReturnedCratesAction(cratesPayload));
    dispatch(getOrdersAction(deliveryPayload));
    dispatch(getCreditsCollectedAction(creditCollectedPayload));
    dispatch(getReconSummaryAction(params));
  }, []);

  useEffect(() => {
    setTimeout(() => dispatch(getPaymentsAction()), 8000);
    scrollToTop();
  }, [success]);

  const viewItems = (order) => {
    setOrder(order);
    setmodalIsOpen(true);
  };

  const columns = [
    {
      Header: "DATE",
      accessor: (d) =>
        `${
          d.createdAt ? moment(d.createdAt).format("ddd D MMM Y  HH:mm A") : "-"
        }`,
    },

    {
      Header: "Order Number",
      accessor: "orderNumber",
    },
    {
      Header: "Route ID",
      accessor: "routeId",
    },

    {
      Header: "Customer Id",
      accessor: "clientId",
    },
    {
      Header: "Customer Name",
      accessor: "clientName",
    },

    {
      Header: "Collected By",
      accessor: "orderBy",
    },

    {
      Header: "Status",
      accessor: "paymentStatus",
    },

    {
      Header: " ",

      Cell: (row) => (
        <ButtonWithIcon
          className="primary text-uppercase font-weight-normal"
          onClick={() => viewItems(row.row.original)}
        >
          View Items
        </ButtonWithIcon>
      ),
    },
  ];

  const creditsCollectionColumns = [
    {
      Header: "Payment Date",
      accessor: (d) =>
        `${
          d.paymentDate
            ? moment(d.paymentDate).format("ddd D MMM Y  HH:mm A")
            : "-"
        }`,
    },

    {
      Header: "Order Number",
      accessor: "orderNumber",
    },
    {
      Header: "Route ID",
      accessor: "routeId",
    },

    {
      Header: "Customer Id",
      accessor: "clientId",
    },
    {
      Header: "Customer Name",
      accessor: "clientName",
    },

    {
      Header: "Status",
      accessor: "paymentStatus",
    },
  ];

  return (
    <div className="row">
      <div className="col-md-1 back-arrow">
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="2x"
          className="mr-2"
          onClick={() => resetView()}
        />
      </div>
      <div className="col-md-11">
        {success && <p className="alert alert-success">{message}</p>}
        <PageTopHeader title="Reconciliation" />
        {isLoading && <LoadingIndicator />}

        {!isLoading && (
          <div className="row">
            <Tile
              title="Total Returned"
              inlineStyle="col-sm-3"
              value={
                reconSummary &&
                reconSummary.totalReturns &&
                reconSummary.totalReturns.toLocaleString()
              }
            />
            <Tile
              title="Total Order Sales"
              inlineStyle="col-sm-3"
              value={
                reconSummary &&
                reconSummary.totalOrderSales &&
                reconSummary.totalOrderSales.toLocaleString()
              }
            />
            <Tile
              title="Total Extra Sales"
              inlineStyle="col-sm-3"
              value={
                reconSummary &&
                reconSummary.totalExtaSales &&
                reconSummary.totalExtaSales.toLocaleString()
              }
            />
            <Tile
              title="Total Credits Collected"
              inlineStyle="col-sm-3"
              value={
                reconSummary &&
                reconSummary.totalCreditCollected &&
                reconSummary.totalCreditCollected.toLocaleString()
              }
            />
          </div>
        )}

        <h3>Sales</h3>
        {allSales && allSales.length > 0 && (
          <Table columns={allSalesColumns} data={allSales} />
        )}
        {allSales && allSales.length === 0 && (
          <p className="alert alert-info mt-3">No Sales has been made yet</p>
        )}

        <h3>Orders Delivered</h3>
        {orders && orders.length > 0 && (
          <Table data={orders} columns={columns} />
        )}
        {orders && orders.length === 0 && (
          <p className="alert alert-info mt-3">No Sales has been made yet</p>
        )}

        <h3>Returned Unsold Products</h3>
        <UserUnsoldTable payload={unsoldPayload} />

        <h3>Returned Crates </h3>
        {crates && crates.length > 0 && (
          <Table columns={cratesColumns} data={crates} />
        )}
        {allSales && allSales.length === 0 && (
          <p className="alert alert-info mt-3">No crates returned made yet</p>
        )}

        <h3>Credits Collected </h3>
        {creditsCollected && creditsCollected.length > 0 && (
          <Table columns={creditsCollectionColumns} data={creditsCollected} />
        )}
        {creditsCollected && creditsCollected.length === 0 && (
          <p className="alert alert-info mt-3">No credits collected yet</p>
        )}

        <div className="bg-grey">
          <div className="row mt-3 pt-3">
            <div className="col-sm-6">
              <div className="border-right-div">
                <h3 className="h3-caps-light">Reference Number</h3>
                <p className="p-caps-light">{payment.reference}</p>
              </div>
            </div>
            <div className="col-sm-6 text-right">
              <h3 className="h3-caps-light">reconciled Amount</h3>
              <p className="p-caps-light">{payment.amount} MZN</p>
            </div>
            <div className="col-sm-6"></div>
          </div>
          <hr className="mt-0" />
          <div className="row mt-3 pt-3">
            <div className="col-sm-12">
              <h3 className="h3-caps-light text-center mb-4">Bank Receipt</h3>
              <img
                className="img-fluid mx-auto d-block"
                src={payment.proof}
                alt="Proof of payment"
              />
            </div>

            <div className="col-sm-6"></div>
          </div>
        </div>

        {payment.status === "SUBMITTED" && (
          <div className="row mt-3 pt-3">
            <div className="col-sm-6">
              <ButtonSmall onClick={() => changeReconStatus("ACCEPTED")}>
                ACCEPT
              </ButtonSmall>
            </div>

            <div className="col-sm-6">
              <ButtonBordered onClick={() => changeReconStatus("DECLINED")}>
                DECLINE
              </ButtonBordered>
            </div>
          </div>
        )}
      </div>

      <PageModal
        isOpen={modalIsOpen}
        component={<OrderItems order={order} resetView={resetView} />}
        setIsOpen={setmodalIsOpen}
      />
    </div>
  );
};

export default ReconDetails;
