import React, { useEffect } from "react";
import { useLeaflet } from "react-leaflet";
import L from "leaflet";
const legend = L.control({ position: "bottomright" });


const SalesLegend = ({salesLegendItems}) => {
     console.log("salesLegendItems",salesLegendItems)
    return (
         <div 
              style = {{
              display: "flex",
              alignItems: "scretch"
         }}
         >    
                 
         <div className = "col-sm-1">
              <strong> Sales :</strong>
         </div>
         
         { salesLegendItems.map((item) => (
              <div
                className = "col-sm-10"
                key={item.title}
                style={{
                     backgroundColor: item.color,
                     flex: 1,
                     display: "flex",
                     alignItems: "center", //vertical
                     justifyContent: "center", //horizonatal
                     color: item.textColor,
                     height: "5vh",
                     fontWeight: "bolder",
                     //fontSize: "1.5em",

                }}
              >
                   <span>{item.title}</span>
              </div>
         ))

         }
             
        </div>
     );
}
 
export default SalesLegend;