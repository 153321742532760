import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import {
  uploadProfPic,
  uploadImageAction,
} from "../../store/actions/UtilsAction";
import { startFetching } from "../../store/actions/UtilsAction";
import { ImageUploaderWrapper } from "../../styles/ImageUploader";
import CircularProgress from "@material-ui/core/CircularProgress";
import LandscapeIcon from "@material-ui/icons/Landscape";
import Img from "react-image";

const ImageUploader = ({ success, imgUrl, showPlaceholder }) => {
  const isFetching = useSelector((state) => state.utilsManager.isFetching);
  const dispatch = useDispatch();

  const handleUploadPicture = (event) => {
    var file = event.target.files[0];
    var type = file.type.split("/")[1];

    var reader = new FileReader();
    reader.onload = (readerEvt) => {
      var binaryString = readerEvt.target.result;
      let base64Str = "base64," + btoa(binaryString);
      let data = {
        content: base64Str,
        folder: "EdoImages",
        thumbs: "200x200",
        format: `${type}`,
      };
      dispatch(startFetching());
      dispatch(uploadImageAction(data));
    };
    reader.readAsBinaryString(file);
  };
  return (
    <ImageUploaderWrapper>
      <div id="file-upload" className="form-group">
        <div
          className="fileinput fileinput-new input-group"
          data-provides="fileinput"
        >
          <span className="input-group-addon btn btn-default btn-file">
            <span className="fileinput-new">
              <AddAPhotoIcon className="float-right" />
            </span>

            <input type="file" onChange={handleUploadPicture} />
          </span>
        </div>
      </div>
      <div className="row">
        {/* {isFetching && <CircularProgress size="140px" />}
        {!isFetching && imgUrl && (
          <div className="col-sm-12 mx-auto">
            <img src={imgUrl} alt="Profile" width="326px" height="auto" />
          </div>
        )} */}

        {isFetching ? (
          <div className="text-center w-100">
            <CircularProgress size="140px" />
          </div>
        ) : (
          <Img
            src={[imgUrl]}
            loader={
              <p className="text-center w-100">
                Image Uploaded . Wait while we are processing the image or view
                it later...
              </p>
            }
            width="326px"
            height="auto"
            border="0"
            className="m-auto"
          />
        )}
      </div>
    </ImageUploaderWrapper>
  );
};

export default ImageUploader;
