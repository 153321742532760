import React, { useEffect, useState } from "react";
import { getUserIframeURL } from "../metabase/metabase.embed.js";
import { useDispatch, useSelector, useStore } from "react-redux";
import { getAgentRouteAction } from "../../store/actions/RouteAction";
import ColumnChart from "./charts/ColumnChart";
import DonutChart from "./charts/DonutChart";
import AreaChart from "./charts/areaChart";
import BarChart from "./charts/BarChart";
import TreeMapChart from "./charts/TreeMap";
//import TreeMapChart from './charts/TreeMapCopy'
import TreeMapChart2 from "./charts/TreeMap2";
import TreeMapChartMargin from "./charts/TreeMapMargin";
import LineChart from "./charts/LineChart";
import PieChart from "./charts/PieChart";
import Table from "../../components/dom/Table/Table";
import CountsCard from "../../components/dom/CountsCard";
import MultiCountsCard from "../../components/dom/MultiCountsCard";
import MultipeYGraph from "./charts/DoubleYaxisGraph";
import MultipleYNoDate from "./charts/DoubleYaxisNoDate";
import { getOrdersAction } from "../../store/actions/OrderAction";
import {
  getDailySalesReportAction,
  geOutletsCountByTypeAction,
  geLeadsCountByTypeAction,
  getOrdersBreakDownAction,
  getAverageOutletOrderValueAction,
  getTopVerticalAction,
  getTopVerticalByMarginAction,
  getProductMarginTrendAction,
  getTopCustomersAction,
  getTopCustomersByOutletTypeAction,
  getSalesReportAction,
  getTotalRevenueAction,
  getDailyOrderSummaryAction,
  getTopProductsSalesByVericalAction,
  getTopProductsMarginByVericalAction,
  geOrderProcessByUserAction,
  getOrdersTransactAction,
  updateStartDateAction,
  updateEndDateAction,
  updateTopProductVisibilityAction,
} from "../../store/actions/ReportsAction";
import { getUsersAction } from "../../store/actions/UserAction";
import PageModal from "../../components/dom/modal";
import { getVerticalOptionsAction } from "../../store/actions/ProductAction";
import moment from "moment";
import _, { indexOf } from "lodash";
import DatePicker from "react-datepicker";
import { BoxWrapper, ButtonBox } from "../../styles/schedule";
import Select from "react-select";
import { date } from "yup";
import { index } from "d3-array";

const SalesSummary = (params) => {
  const [outlet, setOutlet] = useState({});

  const sales = useSelector((state) => state.reportManager.sales);
  const orders = useSelector((state) => state.OrderManager.orders);
  const dailySalesReport = useSelector(
    (state) => state.reportManager.dailySalesReport
  );
  const topVertical = useSelector((state) => state.reportManager.topVertical);
  const topVerticalByMargin = useSelector(
    (state) => state.reportManager.topVerticalByMargin
  );
  const productMarginTrend = useSelector(
    (state) => state.reportManager.productMarginTrend
  );
  const topCustomers = useSelector((state) => state.reportManager.topCustomers);
  const topCustomersByOutlet = useSelector(
    (state) => state.reportManager.topCustomersByOutlet
  );
  const totalRevenue = useSelector((state) => state.reportManager.totalRevenue);
  const ordersCount = useSelector((state) => state.reportManager.ordersCount);
  const verticalArr = useSelector((state) => state.reportManager.verticalArr);
  const verticalMarginArr = useSelector(
    (state) => state.reportManager.verticalMarginArr
  );
  const topProductsSales = useSelector(
    (state) => state.reportManager.topProductsSales
  );
  const topProductsMargin = useSelector(
    (state) => state.reportManager.topProductsMargin
  );
  const orderProcessByUser = useSelector(
    (state) => state.reportManager.orderProcessByUser
  );
  const users = useSelector((state) => state.userManager.users);
  const verticalOptions = useSelector(
    (state) => state.productManager.verticalOptions
  );

  const canShowTopProducts = useSelector(
    (state) => state.reportManager.canShowTopProducts
  );
  const dispatch = useDispatch();

  const [data, setData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const [start_Date, setStart_Date] = useState(
    moment().subtract(30, "days").format("DD/MM/YYYY")
  );
  const [end_Date, setEnd_Date] = useState(moment().format("DD/MM/YYYY"));

  const [dailySaleDate, setDailySaleDate] = useState([]);
  const [totalAmount, setSalesAmount] = useState([]);
  const [topVerticalData, setTopVerticalData] = useState([]);
  const [topVerticalDataMargin, setTopVerticalDataMargin] = useState([]);
  const [marginTrendDate, setMarginTrendDate] = useState([]);
  const [marginTrendTotal, setMarginTrendTotal] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [vertical, setVertical] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showProductsMargin, setShowProductsMargin] = useState(false);
  const [verticalSelected, setVerticalSelected] = useState([]);
  const [itemSelected, setItemSelected] = useState([]);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [salesTrasactions, setSalesTrasactions] = useState(null);

  useEffect(() => {
    if (verticalMarginArr && verticalMarginArr.treedata) {
      setShowProductsMargin(true);
    }
  }, [verticalMarginArr]);

  useEffect(() => {
    if (verticalArr && verticalArr.treedata) {
      setShowProducts(true);
    }
  }, [verticalArr]);

  //////////////////////////////////////////////////////////////////
  //   useEffect(()=>{
  //     getVertical()
  //   },[verticalArr.eventArr !== undefined && verticalArr.eventArr.length >0])

  //  // console.log("verticalArr===++",verticalArr.eventArr)

  //   const getVertical = () => {
  //     console.log("GET VERTICAL CALLED")
  //     console.log("Apex verticalArr Results =>",verticalArr)
  //     if(verticalArr && verticalArr.length !== 0 ){
  //       const selectedVertical = verticalArr.eventArr.filter((item,index) => {
  //         return index === verticalArr.selectedIndex
  //        })
  //      console.log("Selkected vertixal result=>",selectedVertical)
  //      setItemSelected(selectedVertical)

  //     }
  //   }

  // console.log("selectedVertical,,,",itemSelected)
  // useEffect(() => {
  //   console.log("Apex verticalArr Results =>",verticalArr)
  //   if(verticalArr && verticalArr.length !== 0 ){
  //      const selectedVertical = verticalArr.eventArr.filter((item,index) => {
  //        return index === verticalArr.selectedIndex
  //      })
  //      console.log("SELECTED Vertical",selectedVertical)
  //      console.log("verticalOptions::",verticalOptions)
  //     setVerticalSelected(selectedVertical);

  //     var verticalId = verticalOptions.filter(item => {
  //                                      return item.label === selectedVertical[0]
  //                                    }).map((item) => item.value)
  //     console.log("selected Veritcal ID",verticalId[0])

  //     let fetchtProducts = {
  //        "vertical":verticalId[0]
  //      }
  //      dispatch(getTopProductsSalesByVericalAction(fetchtProducts));
  //      setShowProducts(true)
  //      setmodalIsOpen(true);

  //   }

  // }, [verticalArr.eventArr && verticalArr.eventArr.length])

  // //[verticalArr.eventArr && verticalArr.eventArr.length && verticalArr.eventArr.length !== undefined]
  // console.log("TOP PRODUCTS",topProductsSales)

  ///////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   console.log("Apex verticalMarginArr=>",verticalMarginArr)
  //   if(verticalMarginArr && verticalMarginArr.length !== 0 ){

  //     const selectedVertical = verticalMarginArr.eventArr.filter((item,index) => {
  //       return index === verticalMarginArr.selectedIndex
  //      })
  //      console.log("SELECTED Vertical",selectedVertical)
  //     setVerticalSelected(selectedVertical);

  //     var verticalId = verticalOptions.filter(item => {
  //                                      return item.label === selectedVertical[0]
  //                                    }).map((item) => item.value)
  //     console.log("selected Veritcal ID",verticalId[0])
  //     let fetchtProducts = {
  //        "vertical":verticalId[0]
  //      }
  //      dispatch(getTopProductsMarginByVericalAction(fetchtProducts));
  //      setShowProductsMargin(true)

  //   }
  // }, [verticalMarginArr.eventArr && verticalMarginArr.eventArr.length])
  // console.log("TOP PRODUCTS MARGIN",topProductsSales)

  const onClosePoductSales = () => {
    setShowProducts(false);
    // dispatch(getTopVerticalAction(f));
  };
  const onClosePoductMargin = () => {
    setShowProductsMargin(false);
    // dispatch(getTopVerticalByMarginAction(f));
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let begin = moment(startDate).format("DD/MM/YYYY");
    let end = moment(endDate).format("DD/MM/YYYY");
    setStart_Date(begin);
    setEnd_Date(end);
    dispatch(updateStartDateAction(begin));
    dispatch(updateEndDateAction(end));
    dispatch(updateTopProductVisibilityAction(true));

    const reps = users
      .filter((item) => {
        return item.role === "sales-representative";
      })
      .map((item) => item.username)
      .join();

    let filter = {
      startDate: moment(startDate).format("DD/MM/YYYY"),
      endDate: moment(endDate).format("DD/MM/YYYY"),
      vertical: vertical,
      username: reps,
      //  area:"",
    };
    let fetch = {
      limit: "100000",
      searches: [
        { key: "startDate", value: moment(startDate).format("DD/MM/YYYY") },
        { key: "endDate", value: moment(endDate).format("DD/MM/YYYY") },
        { key: "vertical", value: vertical },
      ],
    };
    let fetchSales = {
      searches: [
        { key: "startDate", value: moment(startDate).format("DD/MM/YYYY") },
        { key: "endDate", value: moment(endDate).format("DD/MM/YYYY") },
      ],
    };
    let ordersByuser  = {
      username: reps,
      searches: [
        { key: "lastLoginStartDate", value: moment(startDate).format("YYYYMMDD"), },
        { key: "lastLoginEndDate", value: moment(endDate).format("YYYYMMDD"),}
        ]
      }

    dispatch(getSalesReportAction(fetchSales));
    dispatch(getTopVerticalAction(filter));
    dispatch(getTopVerticalByMarginAction(filter));
    dispatch(getProductMarginTrendAction(filter));

    dispatch(getTopCustomersAction(filter));
    dispatch(getTopCustomersByOutletTypeAction(filter));
    dispatch(getTotalRevenueAction(filter));
    dispatch(geOrderProcessByUserAction(ordersByuser));
    dispatch(getOrdersAction(fetch));
    dispatch(getOrdersTransactAction(fetch));
    dispatch(getDailySalesReportAction(filter));

    setTimeout(() => setLoading(false), 5000);
    setShowProducts(false);
    setShowProductsMargin(false);
  };

  const handleStartDateChange = (selectedStartDate) => {
    setStartDate(moment(selectedStartDate).format("DD/MM/YYYY"));
  };

  const handleEndDateChange = (selectedendDate) => {
    setEndDate(moment(selectedendDate).format("DD/MM/YYYY"));
  };

  useEffect(() => {
    let fetchTopCustomers = {
      startDate: moment().subtract(30, "days").format("DD/MM/YYYY"),
      endDate: moment().format("DD/MM/YYYY"),
      limit: 10,
    };
    const reps = users
      .filter((item) => {
        return item.role === "sales-representative";
      })
      .map((item) => item.username)
      .join();
    let ordersByuser = {
      username: reps,
      startDate : moment(startDate).format("DD/MM/YYYY"), //
      endDate : moment(endDate).format("DD/MM/YYYY"),
    };
    let filter = {
      startDate: start_Date,
      endDate: end_Date,
    };

    dispatch(getSalesReportAction({}));
    let f = {
      startDate: moment().subtract(180, "days").format("DD/MM/YYYY"),
      endDate: moment().format("DD/MM/YYYY"),
    };

    dispatch(getTopVerticalAction(filter));
    dispatch(getTopVerticalByMarginAction(filter));
    dispatch(getProductMarginTrendAction({}));
    dispatch(getDailySalesReportAction({}));

    dispatch(getTopCustomersAction(fetchTopCustomers));
    dispatch(getTopCustomersByOutletTypeAction({}));
    dispatch(getTotalRevenueAction({}));

    dispatch(geOrderProcessByUserAction(ordersByuser));
    setShowProducts(false);
    setShowProductsMargin(false);
    dispatch(getOrdersAction({}));
    dispatch(getOrdersTransactAction({}));
    dispatch(
      updateStartDateAction(moment().subtract(30, "days").format("DD/MM/YYYY"))
    );
    dispatch(updateEndDateAction(moment().format("DD/MM/YYYY")));

    getReports();
    refresh();
  }, []);

  var order = orders.filter((item) => item.orderStatus === "PROCESSED");

  // console.log("orders -->",orders);
  // console.log("orders count -->",orders.length);
  // console.log("processed -->",order);
  // console.log("processed count -->",order.length);
  // useEffect(() => {
  //   var order = orders.filter((item) => item.orderStatus === "PROCESSED")
  //   setSalesTrasactions(order.length)
  // }, [orders]);

  const [value, setValue] = useState();

  const refresh = () => {
    // re-renders the component
    setValue({});
  };

  useEffect(() => {
    dispatch(getVerticalOptionsAction());
    dispatch(updateTopProductVisibilityAction(false));
  }, []);

  useEffect(() => {
    dispatch(getUsersAction({}));
  }, [dispatch]);

  const handleVerticalChange = (selectedOption) => {
    setVertical(selectedOption.value);
    //  setShowProducts(true)

    //  dispatch(apexClictAction())
  };

  const getReports = () => {
    //top vertical by margin

    const daa = [
      { date: "09 Apr 2021", vertical: "Drinks", margin: 2600 },
      { date: "09 Apr 2021", vertical: "Majio", margin: 3300 },
    ];
    const dataByMargin = daa.map((item) => {
      return {
        x: item.vertical,
        y: parseInt(item.margin),
      };
    });
    setTopVerticalDataMargin(dataByMargin);
  };

  const columns = [
    {
      Header: "DATE",
      accessor: (d) =>
        `${
          d.saleDate ? moment(d.saleDate).format("ddd D MMM Y  HH:mm A") : "-"
        }`,
      key: ["saleDate"],
    },

    {
      Header: "Order By",
      accessor: "fullName",
      key: ["fullName"],
    },
    {
      Header: "Sale Number",
      accessor: "saleNumber",
      key: ["saleNumber"],
    },

    {
      Header: "Customer Name",
      accessor: "outletName",
      key: ["outletName"],
    },
    {
      Header: "Location",
      accessor: "areaName",
      key: ["areaName"],
    },
    {
      Header: "Latitude",
      accessor: "latitude",
      key: ["latitude"],
    },
    {
      Header: "Longitude",
      accessor: "longitude",
      key: ["longitude"],
    },

    {
      Header: "Product",
      accessor: "productName",
      key: ["productName"],
    },
    {
      Header: "Vertical",
      accessor: "verticalName",
      key: ["verticalName"],
    },

    {
      Header: "Amount",
      accessor: "cost",
      key: ["cost"],
    },
    {
      Header: "Qunatity",
      accessor: "quantity",
      key: ["quantity"],
    },
  ];

  const topCustomersColumn = [
    // {
    //   Header: "Date",
    //   //accessor: (d) => moment(d.createdAt).format("ddd D MMM YYYY"),
    //   accessor: "date",
    //   key: ["date"],
    // },
    {
      Header: "Customer",
      accessor: "outletName",
      key: ["outletName"],
    },
    {
      Header: "OutletNumber",
      accessor: "outletNumber",
      key: ["outletNumber"],
    },
    {
      Header: "Orders Count",
      accessor: "orders",
      key: ["orders"],
    },
    {
      Header: "Amount",
      accessor: "amount",
      key: ["amount"],
    },
  ];

  return (
    <>
      <h3>Sales summary</h3>

      {loading && (
        <div className="alert alert-primary" role="alert">
          Loading...
        </div>
      )}
      <div className="col-sm-12 shadow-sm p-3 mb-5">
        <BoxWrapper>
          <form onSubmit={onSubmit} role="form">
            <div className="row">
              <div className="col-sm-2">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="from"
                  className="date-picker-input"
                />
              </div>

              <div className="col-sm-2">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="to"
                  className="date-picker-input"
                />
              </div>

              <div className="col-sm-2">
                <Select
                  options={verticalOptions}
                  placeholder="vertical"
                  onChange={(selectedOption) =>
                    handleVerticalChange(selectedOption)
                  }
                />
              </div>

              <div className="col-sm-2">
                <ButtonBox type="submit">filter</ButtonBox>
              </div>
            </div>
          </form>
        </BoxWrapper>
      </div>

      <div className="row">
        <div className="col-sm-3 shadow p-3 mb-5 bg-white  rounded">
          <h6 className="text-muted font-weight-normal mt-0">
            Sales Transaction
          </h6>
          {ordersCount && (
            <CountsCard
              //  title = "Sales Transaction"
              totalValue={ordersCount.processed}
            />
          )}
        </div>
        <div className="col-sm-3 shadow p-3 mb-5 bg-white rounded">
          <h6 className="text-muted font-weight-normal mt-0">Revenue (Tsh)</h6>

          {totalRevenue && totalRevenue.revenue && (
            <CountsCard
              // title = "Revenue (Tsh)"
              totalValue={
                totalRevenue.revenue === undefined
                  ? " "
                  : totalRevenue.revenue
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              subValue
              // description = "Today Total Amount"
            />
          )}
        </div>
        <div className="col-sm-3 shadow p-3 mb-5 bg-white  rounded">
          <h6 className="text-muted font-weight-normal mt-0">Cost (Tsh)</h6>
          {totalRevenue && totalRevenue.cost && (
            <CountsCard
              // title = "Cost (Tsh)"
              totalValue={
                totalRevenue.cost === undefined
                  ? " "
                  : totalRevenue.cost
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          )}
        </div>
        <div className="col-sm-3 shadow p-3 mb-5 bg-white  rounded">
          <h6 className="text-muted font-weight-normal mt-0">Profit (Tsh)</h6>
          {totalRevenue && totalRevenue.margin && (
            <CountsCard
              // title = "Profit (Tsh)"
              totalValue={
                totalRevenue.margin === undefined
                  ? " "
                  : totalRevenue.margin
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 shadow p-3 mb-5 bg-white rounded">
          <h6 className="text-muted font-weight-normal mt-0">
            Daily Sales trend
          </h6>
          {dailySalesReport && dailySalesReport.totalAmount && (
            <AreaChart
              // title = "Daily sales trend"
              yaxisData={[
                {
                  name: "Total Amount",
                  data: dailySalesReport.totalAmount,
                },
              ]}
              xaxisData={dailySalesReport.dailySaleDate}
              xDataType="datetime"
              dateFormant="DD MMM YYYY"
            />
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 shadow p-3 mb-5 bg-white rounded">
          <h6 className="text-muted font-weight-normal mt-0">
            Top Vertical by Sales
          </h6>

          {topVertical.treedata && (
            <TreeMapChart
              //  title = "Top Vertical By Sales"
              yaxisData={topVertical.treedata}
              startDate={start_Date}
              endDate={end_Date}
            />
          )}
        </div>
        <div className="col-sm-6 shadow p-3 mb-5 bg-white rounded">
          <h6 className="text-muted font-weight-normal mt-0">
            Sales per Sales Reps
          </h6>
          {orderProcessByUser && orderProcessByUser.repsName && (
            <BarChart
              xaxisData={orderProcessByUser.repsName}
              yaxisData={[
                {
                  name: "Sales",
                  data: orderProcessByUser.orderProcessed,
                },
              ]}
              stacked={true}
              horizontal={true}
            />
          )}
        </div>
      </div>
      {showProducts && (
        <div className="row">
          <div className="col-sm-12 shadow p-3 mb-5 bg-white rounded">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onClosePoductSales}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h6 className="text-muted font-weight-normal mt-0">
              {" "}
              Top Products sales{" "}
            </h6>
             {/* <code>{JSON.stringify(verticalArr) }</code>   */}
            { //canShowTopProducts &&
              showProducts &&
              verticalArr &&
              verticalArr.treedata && ( //topProductsSales && topProductsSales.treedata &&
                <TreeMapChart2
                  // title = "Products"
                  yaxisData={verticalArr.treedata} //{topProductsSales.treedata}
                />
              )}
          </div>
        </div>
      )}

      {/* <PageModal
                        isOpen={modalIsOpen}
                        component={
                          <>
                               <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                                <h6 className="text-muted font-weight-normal mt-0" >Products</h6> 
                                    
                                  { topProductsSales && topProductsSales.treedata &&
                                    <TreeMapChart
                                        // title = "Top Products By Sales Sales.."
                                          yaxisData = {topProductsSales.treedata}
                                      
                                      />}
                                  </div>
                          </>
                        }
                        setIsOpen={setmodalIsOpen}
                      />
                     */}

      <div className="row">
        <div className="col-sm-6 shadow p-3 mb-5 bg-white rounded">
          <h6 className="text-muted font-weight-normal mt-0">
            Top Vertical by Margin{" "}
          </h6>
          {start_Date &&
            end_Date &&
            topVerticalByMargin &&
            topVerticalByMargin.treedata && (
              <TreeMapChartMargin
                yaxisData={topVerticalByMargin.treedata}
                startDate={start_Date}
                endDate={end_Date}
                // yaxisData = {treedata}
              />
            )}
        </div>

        <div className="col-sm-6 shadow p-3 mb-5 bg-white rounded">
          <h6 className="text-muted font-weight-normal mt-0">
            Overal Product Margin
          </h6>
          {productMarginTrend && productMarginTrend.marginTrendDate && (
            <AreaChart
              // title = "Overal product margin"
              xaxisData={productMarginTrend.marginTrendDate}
              yaxisData={[
                {
                  name: "Total",
                  data: productMarginTrend.marginTrendTotal,
                },
              ]}
              // stacked = {true}
              xDataType="datetime"
              dateFormant="DD MMM YYYY"
            />
          )}
        </div>
      </div>

      {showProductsMargin && (
        <div className="row">
          <div className="col-sm-12 shadow p-3 mb-5 bg-white rounded">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onClosePoductMargin}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h6 className="text-muted font-weight-normal mt-0">
              {" "}
              Top Products margin {verticalSelected}
            </h6>

            {showProductsMargin &&
              verticalMarginArr &&
              verticalMarginArr.treedata && ( // topProductsMargin && topProductsMargin.treedata &&
                <TreeMapChart2
                  // title = "Products"
                  yaxisData={verticalMarginArr.treedata} //{topProductsMargin.treedata}
                />
              )}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-sm-12 shadow p-3 mb-5 bg-white rounded">
          <strong>Top Performing customers</strong>
          <Table title="" columns={topCustomersColumn} data={topCustomers} />
        </div>
      </div>
      {/* <div className = "row">
                         
                        <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                            <strong>Top Performing customers per outlet type</strong>
                            <Table title =" " columns={topCustomersColumn} data={topCustomers}/>
                        </div>               
                        
                    </div>     */}

      <div className="row">
        <div className="col-sm-12 shadow p-3 mb-5 bg-white rounded">
          <strong>Sales Table</strong>
          <Table title="" columns={columns} data={sales} />
        </div>
      </div>
    </>
  );
};

export default SalesSummary;
