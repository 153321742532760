import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormWrapper, Input, Label, ButtonSmall } from "../../styles/Forms";
import DatePicker from "react-datepicker";
import { OrderLabel } from "../../styles/Order";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import moment from "moment";
import {
  setCutOffTimeAction,
  getCategoriesAction
} from "../../store/actions/ProductAction";
import "react-datepicker/dist/react-datepicker.css";

const nameRegExp = /^([^0-9]*)$/;

const SetCutOffTimeForm = ({ category, resetView }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [shift, setShift] = useState("AM");
  const success = useSelector(state => state.stateManager.success);
  const error = useSelector(state => state.stateManager.error);
  const message = useSelector(state => state.stateManager.message);

  const data = useSelector(state => state.stateManager.data);
  const userObj = useSelector(state => state.authManager.user);

  useEffect(() => {
    if (success) {
      resetView();
      setTimeout(() => dispatch(getCategoriesAction()), 10000);

      //dispatch({ type: "RESET_VIEW" });
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    const time = moment(startDate).format("HH:mm");

    let data = {
      time,
      shift,
      categoryNumber: category.categoryNumber,
      username: userObj.username
    };
    dispatch(setCutOffTimeAction(data));
  };
  const dispatch = useDispatch();

  return (
    <FormWrapper>
      {success && <p className="alert alert-success">{message}</p>}
      <h3>Set cut-off time for {category.name} </h3>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="form-group">
          <label>Shift</label>
          <div>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="shift"
                name="shift"
                value={shift}
                onChange={e => setShift(e.target.value)}
                row
              >
                <FormControlLabel
                  value="AM"
                  control={<Radio />}
                  label="AM Shift"
                />
                <FormControlLabel
                  value="PM"
                  control={<Radio />}
                  label="PM SHift"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div class="form-group">
          <label>Time</label>
          <DatePicker
            className="form-control"
            selected={startDate}
            onChange={date => setStartDate(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div>

        <div className="form-group col-md-12 mb-3">
          <ButtonSmall type="submit">
            <span>Set Time</span>
          </ButtonSmall>
        </div>
        {/*JSON.stringify(props, null, 2)*/}
      </form>
    </FormWrapper>
  );
};

export default SetCutOffTimeForm;
