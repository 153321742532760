import React, { useEffect, useState } from "react";
import {getUserIframeURL} from '../metabase/metabase.embed.js';
import { useDispatch, useSelector } from "react-redux"
import { getAgentRouteAction } from "../../store/actions/RouteAction";
import ColumnChart from './charts/ColumnChart';
import BarChart from './charts/BarChart';
import DonutChart from './charts/DonutChart';
import AreaChart from './charts/areaChart';
import AreaChartHrs from './charts/AreaChartHrs';
import LineChart from './charts/LineChart';
import PieChart from './charts/PieChart'; 
import Table from "../../components/dom/Table/Table";
import CountsCard from '../../components/dom/CountsCard';
import MultiCountsCard from '../../components/dom/MultiCountsCard'; 
import MultipeYGraph from "./charts/DoubleYaxisGraph";
import MultipleYNoDate from "./charts/DoubleYaxisNoDate";
import {
    getOutletsAction,
  } from "../../store/actions/OutletAction";
import {
    getDailySalesReportAction,
    geOutletsCountByTypeAction,
    geLeadsCountByTypeAction,
    getOrdersBreakDownAction,
    getAverageOutletOrderValueAction,
    getOrderStatusSummaryAction,
    getDailyOrderTrendAction,
    getFrequentlyOrderProductAction,
    geOrderProcessByUserAction,
    getPercentOfOrderProcessAction,
    getHourlyOrderProcessingAction,
    getDailyOrderPerVerticalAction
  
  } from "../../store/actions/ReportsAction";
import {
    getVerticalOptionsAction,
  
  } from "../../store/actions/ProductAction";
import { getOrdersAction } from "../../store/actions/OrderAction";
import { getUsersAction } from "../../store/actions/UserAction";
  import moment from "moment";
  import _, { set } from "lodash";
  import DatePicker from "react-datepicker";
  import { BoxWrapper,ButtonBox } from "../../styles/schedule";
  import Select from "react-select";
import { getReturnedProducts } from "../../services/OrderService.js";
// import OrderDetails from "./OrderDetails";
import OrderDetails from "../orders/Order/OrderDetails";
import { ButtonWithIcon } from "../../styles/Forms";
import { Link } from "react-router-dom";
  

const OrdersSummary = (params) => {
    const [outlet, setOutlet] = useState({});
    const success = useSelector((state) => state.stateManager.success);
    const error = useSelector((state) => state.stateManager.error);
    const message = useSelector((state) => state.stateManager.message);
    const orders = useSelector((state) => state.OrderManager.orders);
    const outlets = useSelector((state) => state.outletManager.outlets);
    const orderStatusSummary = useSelector((state) => state.reportManager.orderStatusSummary);
    const dailyOrderTrend = useSelector((state) => state.reportManager.dailyOrderTrend);
    const frequentlyOrderProducts = useSelector((state) => state.reportManager.frequentlyOrderProducts);
    const orderProcessByUser = useSelector((state) => state.reportManager.orderProcessByUser);
    const percenOrderProcess  = useSelector((state) => state.reportManager.percenOrderProcess);
    const hourlyOrtderProcessing = useSelector((state) => state.reportManager.hourlyOrtderProcessing);
    const dailyOrderPerVertical = useSelector((state) => state.reportManager.dailyOrderPerVertical);
    const users = useSelector((state) => state.userManager.users);
    const verticalOptions = useSelector(
      (state) => state.productManager.verticalOptions
    );
    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(null);
  
    const [vertical, setVertical] = useState(null);
    const [loading, setLoading] = useState(false);

    const [canViewOrder, setCanViewOrder] = useState(false);
    const [canShowHome, setCanShowHome] = useState(true);
    const [order, setOrder] = useState({});
  
console.log("dailyOrderPerVertical!!!",dailyOrderPerVertical)



///////start
const result = [{
                    date: "16 Apr 2021",
                    data:[
                                {
                                  vertical: "Drinks",
                                  value: 2
                                },
                                {
                                  vertical: "Glocery",
                                  value: 3
                                }
                              ]
                  },


                  {
                    date: "17 Apr 2021",
                    data:[
                                {
                                  vertical: "Drinks",
                                  value: 21
                                },
                                {
                                    vertical: "Glocery",
                                    value: 9
                                },
                                {
                                  vertical: "Mamebe",
                                  value: 3
                                }
                              ]
                  }]

                  var date = [];
                  var data1 = [];
                  var data2 = [];

                  for(const element of result){
                  date.push(element.date);
                  data1.push(element.data);
                  data2.push(
                        
                        result.map(item => {
                          return {
                            verticalName :item.vertical,
                            verlues :item.value,


                          }
                        })
                    )  
                    
                    
                  }

                  console.log("NEW DATE::",date)
                  console.log("NWQ DATA::",data1)

  
    const onChange = dates => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };
////////
    const sortData = (data) => {
      let verticalDataPerDate = []
      data.map(dd => { 
         //loop through the date date
         dd.data.map(item => {
           const ind = verticalDataPerDate.findIndex(el => el.vertical === item.vertical)
           if(ind === -1) {
            verticalDataPerDate.push({
                vertical: item.vertical,
                values: [item.value]   
            })
           } else {
             verticalDataPerDate[ind]['values'].push(item.value)
           }
         })
      })
      return verticalDataPerDate
    }
    useEffect(() => {
      let output = sortData(result)
      console.log(`sortDate:`)
      console.log(output)
    }, [])
////////end test
    const changeData = () => {
     // setData(generateData());
    };
  
    const onSubmit = (e) => {
      e.preventDefault();  
      setLoading(true) ;  
      let filter = {
         startDate : moment(startDate).format("DD/MM/YYYY"),
         endDate : moment(endDate).format("DD/MM/YYYY"),
         vertical: vertical,
         username:"",
         area:"",
      }
      const reps = users
      .filter((item) => {
        return item.role === "sales-representative";
      })
      .map((item) => item.username)
      .join();

      let ordersByuser  = {
        username: reps,
        searches: [
              {
                 key: "lastLoginStartDate",
                 value: moment(startDate).format("YYYYMMDD"),
              },
              {
                 key: "lastLoginEndDate",
                 value: moment(endDate).format("YYYYMMDD"),
              }
              ]
        }
      let fetchOrders = {
                        searches: [
                          {
                            "key": "startDate",
                            "value": moment(startDate).format("DD/MM/YYYY"),
                          },
                          {
                            "key": "endDate",
                            "value": moment(endDate).format("DD/MM/YYYY"),
                          }
                        ]
                      }
      dispatch(getOrdersAction(fetchOrders));
      dispatch(getOutletsAction(filter));

      dispatch(getOrderStatusSummaryAction(filter));
      dispatch(getDailyOrderTrendAction(filter));
      dispatch(getFrequentlyOrderProductAction(filter));

      dispatch(geOrderProcessByUserAction(ordersByuser))
      dispatch(getPercentOfOrderProcessAction(filter));
      dispatch(getDailyOrderPerVerticalAction(filter));

      dispatch(getHourlyOrderProcessingAction(filter));
      setTimeout(
        () =>
        setLoading(false) ,
        5000
      );

    };
  
    let grouped = _.groupBy(outlets, function(cat) {
        return cat.categoryName;
    })
    let activeOutlets = _.filter(outlets, function(o) {
        return o.status = 'active' ; });

    let inactiveOutlets  = _.filter(outlets, function(o) {
         return o.status = 'inactive' ; });

    useEffect(() => {
        const reps = users.filter(item => {
                          return item.role === "sales-representative"
                        }).map((item) => item.username)
                          .join()
        let usernames = {
                          username :reps
                        }
        let frequentProducts = {
                            "orderBy": "total",
                            "orderDir": "desc",
                            "limit": 6
                          }
        dispatch(getOrdersAction());
        dispatch(getOutletsAction({}));

        dispatch(getOrderStatusSummaryAction({}));
        dispatch(getDailyOrderTrendAction({}));
        dispatch(getFrequentlyOrderProductAction(frequentProducts));

        dispatch(geOrderProcessByUserAction(usernames))
        dispatch(getPercentOfOrderProcessAction({}));
        dispatch(getHourlyOrderProcessingAction({}));
        dispatch(getDailyOrderPerVerticalAction({}))

      }, []);

      useEffect(() => {
        dispatch(getVerticalOptionsAction());
      }, []);

      useEffect(() => {
        dispatch(getUsersAction({}));
      }, [dispatch]);
    
    const columns = [
      {
        Header: "DATE",
        accessor: (d) =>
          `${
            d.createdAt ? moment(d.createdAt).format("ddd D MMM Y  HH:mm A") : "-"
          }`,
        key: ["createdAt"],
      },
  
      {
        Header: "Order Number",
        accessor: "orderNumber",
        key: ["orderNumber"],
      },
  
      {
        Header: "Customer Name",
        accessor: "outletName",
        key: ["outletName"],
      },
      {
        Header: "Customer Location",
        accessor: "areaName",
        key: ["areaName"],
      },
      {
        Header: "Latitude",
        accessor: "latitude",
        key: ["latitude"],
      },
      {
        Header: "Longitude",
        accessor: "longitude",
        key: ["longitude"],
      },
      {
        Header: "Total",
        accessor: "finalCost",
        key: ["finalCost"],
      },
      {
        Header: "Collected By",
        accessor: "createdByName",
        key: ["createdByName"],
      },
  
      {
        Header: "Status",
        accessor: "orderStatus",
        key: ["orderStatus"],
      },
      
      {
        Header: " ",
  
        Cell: (row) => (
          <div className="d-flex">
            {/* <ButtonWithIcon
              className="primary text-uppercase font-weight-normal"
               onClick={() => showOrderDetails(row.row.original)}
            >
              View
            </ButtonWithIcon>{" "} */}
              <Link
                  to={{
                    pathname: "/orders",
                    state: {
                      orderReport: row.row.original,
                      
                    },
                  }}
                >
                <ButtonWithIcon
                  className="primary text-uppercase font-weight-normal" >
                    View
                </ButtonWithIcon>        
                </Link>
          </div>

          
        ),
      },
   
    ];

    const onCloseOrderDetails  = () => {
      setCanShowHome(true);
      setCanViewOrder(false);
      dispatch(getOrdersAction());
     
    }

    const showOrderDetails = (order) => {
      console.log("order details",order)
       setOrder(order);
       setCanViewOrder(true);
       setCanShowHome(false);
    }
    const handleVerticalChange = (selectedOption) => {
    console.log("selectedOption vertical",selectedOption)
    setVertical(selectedOption.value);

    };
    
    return (
       <>       
                      
                    <div className="col-sm-12">
                      {success && <p className="alert alert-success">{message}</p>}
                      {error && <p className="alert alert-danger">{message}</p>}   
                    </div>  
                    {/* d-flex flex-row-reverse */}
                    {canShowHome && 
                    <div>
                      <h3>Order summary</h3>
                      
                          { loading &&
                            <div className="alert alert-primary" role="alert">
                                Loading...
                              </div>
                            }
                          <div className = "col-sm-12 shadow-sm p-3 mb-5  ">
                          <BoxWrapper>
                                <form onSubmit={onSubmit} role="form">
                                  <div className="row">
                                      {/* <div className="form-group col-sm-3 ">
                                      <input type="text" className="form-control" value ="ffff"/>
                                      </div> */}
                                    
                                      
                                        <div className="col-sm-2">
                                          <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            placeholderText="from"
                                            className="date-picker-input"
                                          />
                                        </div>
                                    
                                        <div className="col-sm-2">
                                          <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            placeholderText="to"
                                            className="date-picker-input"
                                          />
                                        </div>

                                        <div className = "col-sm-2">
                                        <Select
                                            options={verticalOptions}
                                            placeholder="vertical"
                                            onChange={(selectedOption) => handleVerticalChange(selectedOption)}
                                    
                                          />
                                      </div>
                                      
                                      <div className="col-sm-2">
                                        <ButtonBox type="submit">
                                          filter
                                        </ButtonBox>
                                      </div>
                                  
                                  </div>
                                </form>
                              </BoxWrapper>
                          </div>

                          <div className = "row">
                            <div className = "col-sm-4 shadow p-3 mb-5 bg-white rounded">
                            <h6 className="text-muted font-weight-normal mt-0" >Orders</h6>
                                {orderStatusSummary && orderStatusSummary.orderStatus &&
                                  <DonutChart 
                                    // title = "Orders"
                                      dataLabels = {orderStatusSummary.orderStatus}
                                      dataValues = {orderStatusSummary.orderCount}
                                  />}
                            </div>
                              <div className = 'col-sm-8 shadow p-3 mb-5 bg-white rounded'>
                              <h6 className="text-muted font-weight-normal mt-0" >Daily Orders trend</h6>
                                {dailyOrderTrend && dailyOrderTrend.orderTrendTotal &&
                                  <AreaChart
                                    //  title = "Daily Orders trend"
                                      yaxisData = {[{
                                                      name: 'Total',
                                                      data: dailyOrderTrend.orderTrendTotal
                                                  }
                                                  
                                                  ]}
                                      xaxisData = {dailyOrderTrend.orderTrendDate}
                                      xDataType = "datetime"
                                      dateFormant = "DD MMM YYYY"
                                  />}
                              </div>
                          </div>

                          <div className = "row">

                            {/* <div className = "col-sm-4 shadow p-3 mb-5 bg-white rounded">
                            <h6 className="text-muted font-weight-normal mt-0" >Frequent Ordered Products</h6>
                                { frequentlyOrderProducts && frequentlyOrderProducts.frequentProduct &&
                                <DonutChart 
                                    // title = "Frequent Ordered Products"
                                      dataLabels = {frequentlyOrderProducts.frequentProduct}
                                      dataValues = {frequentlyOrderProducts.frequentValue}
                                  />}
                            </div>
                              */}
                            <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                            <h6 className="text-muted font-weight-normal mt-0" >Order processed by Sales Rep</h6>
                                { orderProcessByUser && orderProcessByUser.repsName &&
                                <BarChart
                                    //  title = "Order processed by user"
                                      xaxisData = {orderProcessByUser.repsName}
                                      yaxisData = { [{
                                                      name: 'Processed',
                                                      data: orderProcessByUser.orderProcessed
                                                  }, 
                                                  {
                                                      name: 'Placed',
                                                      data: orderProcessByUser.orderPlaced
                                                  }, 
                                                ]
                                            }
                                      stacked = {true}
                                      horizontal = {false}
                                  />}
                              </div>

                          </div>

                          <div className="row">
                            <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                            <h6 className="text-muted font-weight-normal mt-0" >Frequent Ordered Products</h6>
                          {   frequentlyOrderProducts && frequentlyOrderProducts.frequentProduct &&
                                <BarChart
                                      // title = "Frequent Ordered Products..."
                                        xaxisData = {frequentlyOrderProducts.frequentProduct}
                                        yaxisData = { [
                                                    {
                                                        name: 'Quantity',
                                                        data: frequentlyOrderProducts.frequentValue
                                                    }, 
                                                  //   {
                                                  //     name: 'Amount',
                                                  //     data: frequentlyOrderProducts.frequentAmount
                                                  // }, 
                                                    
                                                  ]
                                              }
                                        stacked = {false}
                                        horizontal = {true}
                                    />}
                            </div>

                          </div>


                          <div className = "row">
                            <div className = 'col-sm-6 shadow p-3 mb-5 bg-white rounded'>
                            <h6 className="text-muted font-weight-normal mt-0" >Percentage of orders processed </h6>
                                {hourlyOrtderProcessing && hourlyOrtderProcessing.rate &&
                                  <AreaChart
                                // title = "Percentage of orders processed"
                                xaxisData = {hourlyOrtderProcessing.date}
                                  yaxisData = {[
                                                    { name: "Orders",
                                                      data: hourlyOrtderProcessing.rate
                                                    }
                                              ]
                                                                  
                                              }
                                            
                                  xDataType = "datetime"
                                  dateFormant = "DD MMM YYYY"
                                  
                                  />}
                              </div>
                              <div className = "col-sm-6 shadow p-3 mb-5 bg-white rounded">
                              <h6 className="text-muted font-weight-normal mt-0" >Daily orders per category</h6>
                              { dailyOrderPerVertical && dailyOrderPerVertical.items &&         
                                  <BarChart
                                    // title = "Daily product orders per category"
                                      xaxisData = {dailyOrderPerVertical.items}
                                      yaxisData = {dailyOrderPerVertical.data}

                                      stacked = {true}
                                      horizontal = {false}
                                    //  xDataType = "datetime"
                                      dateFormant = "DD MMM YYYY"
                                  />}
                                  
                              </div>
                          </div>

                            <div className = "row">
                              <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                              <h6 className="text-muted font-weight-normal mt-0" >Orders Table</h6>
                                <Table title ="" columns={columns} data={orders}/>
                              </div>
                            
                          </div>
                    </div>}

                    {canViewOrder &&
                    
                        <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                          <button type="button" className="close" aria-label="Close" onClick={onCloseOrderDetails}>
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <h6 className="text-muted font-weight-normal mt-0" > Order Details</h6>
                          <OrderDetails order={order} />
                        </div>
                    
                    
                    }

                    
                   
       
       </>
    
 )
}
    

export default OrdersSummary