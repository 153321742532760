import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  filterOrdersAction,
  getAllOrdersSummaryAction,
  getOrdersAction,
  getOrderSummaryAction,
} from "../../store/actions/OrderAction";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import OutletOrderForm from "./OutletOrderForm";
import DailyOrdersPage from "./DailyOrdersPage";
import OrdersTablePage from "./OrdersTablePage";
import { TabsWrapper } from "../../styles/Tabs";
import { PageTitle, PageButton } from "../../styles/App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePermissionChecker } from "../../utils/user";
import LoadingIndicator from "../../components/dom/LoadingIndicator";
import { scrollToTop } from "../../utils/window";
import Tile from "../../components/dom/Tile";
import DatePicker from "react-datepicker";
import { DateSearchtWrapper } from "../../styles/schedule";
import { ButtonWithIcon } from "../../styles/Forms";
import OrderDetails from "./Order/OrderDetails";
import "react-tabs/style/react-tabs.css";
import "react-datepicker/dist/react-datepicker.css";
import { SEARCH_LIMIT } from "../../constants";

const OrdersPage = () => {
  const location = useLocation();
  const { orderReport } = location.state ?? {};
  const [showHome, setShowHome] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [showOrderFrom, setShowOrderForm] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [canViewOrder, setCanViewOrder] = useState(false);
  const [canShowHome, setCanShowHome] = useState(true);
  const [order, setOrder] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.OrderManager.orders);
  const summary = useSelector((state) => state.OrderManager.orderSummary);
  const isLoading = useSelector((state) => state.OrderManager.loading);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const canResetView = useSelector((state) => state.stateManager.canResetView);
  const userObj = useSelector((state) => state.authManager.user);

  useEffect(() => {
    if (canResetView) {
      setmodalIsOpen(false);
      if (success || error) {
        scrollToTop();
      }
    }
  }, [success]);

  const createOrderForm = () => {
    setShowHome(false);
    setShowOrderForm(true);
  };

  const resetView = () => {
    let filter = {
      orderDir: "desc",
      orderBy: "id",
    };
    setShowHome(true);
    setShowOrderForm(false);
    setEditMode(false);
    setTimeout(() => dispatch(getOrdersAction(filter)), 7000);
  };

  useEffect(() => {
    console.log("useParams orderReport = ", orderReport);

    if (orderReport) {
      setOrder(orderReport);
      setCanViewOrder(true);
      setCanShowHome(false);
    }

    if (success) {
      setShowHome(true);
      setCanShowHome(true);
      setShowOrderForm(false);
      setEditMode(false);
      //setCanViewOrder(false);
      let filter = {
        orderDir: "desc",
        orderBy: "id",
      };

      setTimeout(() => {
        dispatch(getOrdersAction(filter));
      }, 7000);
    }
  }, [success]);

  useEffect(() => {
    dispatch(getAllOrdersSummaryAction({}));
  }, []);

  const viewOrderItems = (order) => {
    setOrder(order);
    setmodalIsOpen(true);
  };

  const viewOrder = (order) => {
    setOrder(order);
    setCanViewOrder(true);
    setCanShowHome(false);
  };

  const editOrder = (order) => {
    console.log("editOrder order", order);
    setOrder(order);
    setEditMode(true);
    setShowOrderForm(true);
    setCanShowHome(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let summaryPayload = {
      startDate: moment(startDate).format("DD/MM/YYYY"),
      endDate: moment(endDate).format("DD/MM/YYYY"),
    };
    let searches = [
      {
        key: "startDate",
        value: moment(startDate).format("DD/MM/YYYY"),
      },
      {
        key: "endDate",
        value: moment(endDate).format("DD/MM/YYYY"),
      },
    ];
    let data = {
      limit: SEARCH_LIMIT,
      searches,
    };

    console.log(data);
    scrollToTop();
   // dispatch(getOrderSummaryAction(summaryPayload));
    //dispatch(filterOrdersAction(data));
    dispatch(getOrdersAction({searches}));
  };

  useEffect(() => {
    if (success) {
      console.log("SUCCESS AGAIN show home");
      setShowHome(true);
    }
  }, [success]);

  console.log("Show home  = ", showHome);
  return (
    <>
      {isLoading && <LoadingIndicator />}

      <>
        <div className="row mb-2">
          <div className="col-sm-4">
            {!isLoading && (
              <PageTitle>
                {canViewOrder && (
                  <span className="col-md-1 back-arrow ">
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      size="1x"
                      className="mr-2 primary"
                      onClick={() => {
                        setCanShowHome(true);
                        setCanViewOrder(false);
                      }}
                    />
                  </span>
                )}
                Orders
              </PageTitle>
            )}
          </div>
          <div className="col-sm-8">
            {usePermissionChecker("create_order") && !isLoading && (
              <PageButton className="float-right" onClick={createOrderForm}>
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Create Order
              </PageButton>
            )}
          </div>
        </div>
        {showHome && (
          <>
            {success && <p className="alert alert-success">{message}</p>}
            {error && <p className="alert alert-danger">{message}</p>}
            {canShowHome && (
              <div className="row">
                <Tile
                  title="total Orders"
                  inlineStyle="col-sm-4"
                  value={summary.total}
                />
                <Tile
                  title="Placed Orders"
                  inlineStyle="col-sm-4"
                  value={summary.placed}
                />
                <Tile
                  title="Processed Orders"
                  inlineStyle="col-sm-4"
                  value={summary.processed}
                />
              </div>
            )}

            <TabsWrapper>
              <Tabs>
                <TabList>
                  <Tab>Orders</Tab>
                  <Tab>Daily Orders</Tab>
                </TabList>

                <TabPanel>
                  <>
                    {canShowHome && (
                      <>
                        <DateSearchtWrapper>
                          <form onSubmit={onSubmit} role="form">
                            <div className="row">
                              <div className="form-group col-sm-5">
                                <div className="datePicker">
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    placeholderText="from"
                                    className="date-picker-input"
                                  />
                                </div>
                              </div>
                              <div className="form-group col-sm-5">
                                <div className="datePicker">
                                  <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    placeholderText="to"
                                    className="date-picker-input"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-2">
                                <ButtonWithIcon type="submit">
                                  filter
                                </ButtonWithIcon>
                              </div>
                            </div>
                          </form>
                        </DateSearchtWrapper>

                        <OrdersTablePage
                          viewOder={viewOrder}
                          view={viewOrderItems}
                          edit={editOrder}
                        />
                      </>
                    )}
                    {canViewOrder && <OrderDetails order={order} />}
                  </>
                </TabPanel>
                <TabPanel>
                  <>
                    <DailyOrdersPage view={viewOrderItems} />
                  </>
                </TabPanel>
              </Tabs>
            </TabsWrapper>
          </>
        )}

        {showOrderFrom && (
          <>
            <OutletOrderForm
              resetView={resetView}
              order={order}
              editMode={editMode}
            />
          </>
        )}
      </>
    </>
  );
};

export default OrdersPage;
