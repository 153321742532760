import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import StatsRow from "./StatsRow";
import { PageButton } from "../../styles/App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Table from "../../components/dom/Table/Table";
import TargetForm from "./TargetForm";
import {
  getPerformanceTargetAction,
  getPerformanceHistoryAction,
} from "../../store/actions/SalesAction";
import { ButtonWithIcon } from "../../styles/Forms";
import { performanceHistoryColumns } from "./columns";
import { scrollToTop } from "../../utils/window";
import Tile from "../../components/dom/Tile";
import "../../components/general/StatsRow/StatsRow.css";
import PageModal from "../../components/dom/modal";
import CurrencyFormat from "react-currency-format";

const UserPerformancePage = ({ username }) => {
  const [showHome, setShowHome] = useState(true);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [showTargetForm, setShowTargetForm] = useState(false);
  const success = useSelector((state) => state.stateManager.success);
  const selectedUser = useSelector((state) => state.userManager.user);
  const message = useSelector((state) => state.stateManager.message);
  const error = useSelector((state) => state.stateManager.error);
  const targets = useSelector((state) => state.salesManager.userTargets);
  const history = useSelector(
    (state) => state.salesManager.userPerformanceHistory
  );
  const totalTarget = useSelector((state) => state.salesManager.totalTarget);
  const totalScore = useSelector((state) => state.salesManager.totalScore);
  const dispatch = useDispatch();

  const performanceHistoryColumns = [
    {
      Header: "Created At",
      accessor: (d) => moment(d.createdAt).format("LLL"),
      key: ["createdAt"],
    },
    {
      Header: "start Date",
      accessor: "startDate",
      key: ["date"],
    },
    {
      Header: "End Date",
      accessor: "endDate",
      key: ["endDate"],
    },
    {
      Header: "Target Type",
      accessor: "type",
      key: ["type"],
    },
    {
      Header: "Target Name",
      accessor: "typeName",
      key: ["typeName"],
    },
    {
      Header: "Target Amount",

      accessor: (d) =>
        d.target ? (
          <CurrencyFormat
            value={d.target}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          0
        ),
      key: ["target"],
    },
    {
      Header: "Achieved Amount",
      accessor: (d) => (
        <CurrencyFormat
          value={d.achieved}
          displayType={"text"}
          thousandSeparator={true}
        />
      ),
      key: ["achieved"],
    },

    {
      Header: "Created By",
      accessor: "fullName",
      key: ["fullName"],
    },
  ];

  let data = {
    date: moment().format("DD-MM-YYYY"),
    username,
  };
  let historyData = {
    endDate: moment().format("DD-MM-YYYY"),
    startDate: moment()
      .subtract("10", "days")
      .format("DD-MM-YYYY"),
    username,
  };
  useEffect(() => {
    dispatch(getPerformanceTargetAction(selectedUser.username));
    //dispatch(getPerformanceHistoryAction(historyData));
  }, []);

  useEffect(() => {
    if (success) {
      setShowTargetForm(false);
      setShowHome(true);
      scrollToTop();
      setTimeout(() => {
        dispatch(getPerformanceTargetAction(selectedUser.username));
      }, 4000);
    }
  }, [success]);

  const resetView = () => {
    setShowTargetForm(false);
    setShowHome(true);
    scrollToTop();
    setTimeout(() => dispatch(getPerformanceTargetAction(data)), 6000);
    //dispatch(getProductsAction());
  };

  const viewItems = (items) => {
    setItems(items.productPerformances);
    setmodalIsOpen(true);
  };

  return (
    <>
      {!showTargetForm && (
        <>
          {success && <p className="alert alert-success">{message}</p>}
          {error && <p className="alert alert-danger">{message}</p>}
          {/* <div className="row">
            <Tile
              title="Today’s Total Target"
              inlineStyle="col-sm-6"
              value={totalTarget}
            />
            <Tile
              title="Today’s Total Achievement so far"
              inlineStyle="col-sm-6"
              value={totalScore}
            />
          </div> */}

          <div className="row">
            <div className="col-sm-2">
              <h4 className="mt-3">Today’s Target</h4>
            </div>
            <div className="col-sm-10">
              <PageButton
                className="float-right"
                onClick={() => setShowTargetForm(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Set Target
              </PageButton>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <Table data={targets} columns={performanceHistoryColumns} />
            </div>
          </div>

          {/* <div className="row">
            <div className="col-sm-6">
              <h4 className="mt-3">Performance History</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <Table data={history} columns={performanceHistoryColumns} />
            </div>
          </div> */}

          {/* <div className="row">
            <div className="col-sm-2">
              <h4 className="mt-3">Performance History</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <Table
                data={performanceHistoryData}
                columns={performanceHistoryColumns}
              />
            </div>
          </div> */}
        </>
      )}

      {showTargetForm && (
        <TargetForm username={username} resetView={resetView} />
      )}
    </>
  );
};

export default UserPerformancePage;
