import {
  getDailySales,
  getAllSales,
  getAllCredits,
  updateReconStatus,
  setPerformanceTarget,
  getPerformanceTarget,
  getPerformanceHistory,
  getReturnedExpiredProducts,
  getUserUnsoldProducts,
  getUserCredits,
  updateCreditRequest,
} from "../../services/SalesService";
import { getOrders } from "../../services/OrderService";
import { handleErrorAction } from "./UtilsAction";
import {
  GET_DAILY_SALES_SUCCESS,
  GET_ALL_SALES_SUCCESS,
  GET_CREDITS_SUCCESS,
  ACTION_SUCCESS,
  GET_USER_TARGETS_SUCCESS,
  GET_USER_PERFORMANCE_HISTORY_SUCCESS,
  GET_USER_SALES_SUCCESS,
  GET_EXPIRED_PRODUCTS_SUCCESS,
  GET_OUTLET_CREDITS_SUCCESS,
  IS_LOADING,
  GET_USER_UNSOLD_PRODUCTS_SUCCESS,
  GET_USER_CREDITS_SUCCESS,
  ACTION_FAIL,
  GET_CREDITS_COLLECTED_SUCCESS,
} from "../constants/ActionTypes";
import _ from "lodash";

export const getDailySalesAction = (startDate, endDate) => {
  return (dispatch) => {
    return getDailySales(startDate, endDate).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_DAILY_SALES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getAllSalesAction = (data) => {
  return (dispatch) => {
    return getAllSales(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_ALL_SALES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUserSalesAction = (data) => {
  return (dispatch) => {
    dispatch({ type: IS_LOADING });
    return getAllSales(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_USER_SALES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_USER_SALES_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getCreditsAction = (data) => {
  return (dispatch) => {
    return getAllCredits(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_CREDITS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_CREDITS_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOutletCreditsAction = (data) => {
  return (dispatch) => {
    return getAllCredits(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_OUTLET_CREDITS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_OUTLET_CREDITS_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const updateReconStatusAction = (data) => {
  return (dispatch) => {
    return updateReconStatus(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const setPerformanceTargetAction = (data) => {
  return (dispatch) => {
    return setPerformanceTarget(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getPerformanceTargetAction = (username) => {
  return (dispatch) => {
    return getPerformanceTarget(username).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({
            type: GET_USER_TARGETS_SUCCESS,
            payload:
              response.data.responseObject == null
                ? []
                : response.data.responseObject,
            totalTarget:
              response.data.responseObject == null
                ? []
                : response.data.responseObject.target,
            totalScore:
              response.data.responseObject == null
                ? []
                : response.data.responseObject.score,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getPerformanceHistoryAction = (data) => {
  return (dispatch) => {
    return getPerformanceHistory(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_USER_PERFORMANCE_HISTORY_SUCCESS,
            payload: response.data.responseObject,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getReturnedExpiredProductsAction = (data) => {
  return (dispatch) => {
    return getReturnedExpiredProducts(data).then(
      (response) => {
        if (response.data.code === "200") {
          let expiredProducts = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          // expiredProducts = expiredProducts.map((product) => product.items);

          // expiredProducts = _.flatten(expiredProducts);

          return dispatch({
            type: GET_EXPIRED_PRODUCTS_SUCCESS,
            payload: expiredProducts,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUserUnsoldProductsAction = (data) => {
  return (dispatch) => {
    dispatch({ type: IS_LOADING });
    return getUserUnsoldProducts(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_USER_UNSOLD_PRODUCTS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_USER_UNSOLD_PRODUCTS_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUserCreditsAction = (data) => {
  return (dispatch) => {
    dispatch({ type: IS_LOADING });
    return getUserCredits(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_USER_CREDITS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_USER_CREDITS_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const updateCreditRequestAction = (data) => {
  return (dispatch) => {
    return updateCreditRequest(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getCreditsCollectedAction = (data) => {
  return (dispatch) => {
    return getOrders(data).then(
      (response) => {
        if (response.data.code === "200") {
          let orders = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          return dispatch({
            type: GET_CREDITS_COLLECTED_SUCCESS,
            payload: orders,
          });
        } else {
          return dispatch({
            type: GET_CREDITS_COLLECTED_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};
