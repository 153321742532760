import styled from "styled-components";

export const FormWrapper = styled.div`
  color: #373d42 !important;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 16px;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #00c400;
  }
  h3 {
    font-size: 14px;
    color: #373d42;
    text-transform: capitalize;
    font-weight: bold;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 12px !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px !important;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 12px !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px !important;
  }
  .react-datepicker-wrapper {
    display: block;
  }
  input::-webkit-input-placeholder,
  input::placeholder {
    font-size: 16px;
  }
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 10px 10px;
  padding-left: 0px;
  color: #373d42;
  font-size: 16px;
  font-weight: 400;
  outline: none;
`;

export const Label = styled.label`
  color: #757575;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 1px;
`;

export const Select = styled.select`
  display: block;
  padding: 0px;
  width: 100%;
  font-size: 22px;
  border: none;
  color: #373d42;
`;

export const FormSelect = styled.select`
  display: block;
  padding: 0px;
  width: 100%;
  font-size: 22px;
  border: none;
  color: #373d42;
`;

export const InputWrapper = styled.div`
  padding: 12px 0px;
  border-bottom: 1px solid #ccc;
`;

export const Button = styled.button`
  background: #00c400;
  padding: 40px;
  width: 100%;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 80px;
`;

export const ButtonSmall = styled.button`
  background: #00c400;
  padding: 15px;
  width: 100%;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ButtonBordered = styled.button`
  background: #fff;
  padding: 15px;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 40px;
  border: 1px solid rgba(56, 64, 30, 0.25);
  border-radius: 2px;
  color: #00c400;
  font-size: 15px;
  font-weight: 900;
`;
export const ButtonWithIcon = styled.button`
  background: none;
  padding: 5px;
  border: 1px solid #e6e6e2;
  border-radius: 2px;
  font-weight: bold;
  font-size: 10px;
  color: #00c400;
  text-transform: uppercase;
  cursor: pointer;
  margin: 3px;

  svg {
    margin-right: 5px;
  }
`;

export const ButtonWithIconSmall = styled.button`
  border: 1px solid #ccc;
  color: #00c400;
  font-size: 11px;
  padding: 7px 12px;
  color: #00c400;
  font-weight: 500;
  border-radius: 1px;
  background-color: white;
  margin-right: 20px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const ButtonHover = styled.button`
  background: #caa8a0;
  padding: 10px;
  border: 1px solid #e6e6e2;
  border-radius: 2px;
  font-weight: bold;
  font-size: 10px;
  color: #00c400;
  text-transform: uppercase;
`;
