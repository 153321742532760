import React from "react";
import moment from "moment";

export const allSalesColumns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Sales date",
    accessor: (d) => moment(d.saleDate).format("LLL"),
  },
  {
    Header: "Product Name",
    accessor: "productName",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Sale Type",
    accessor: s=>{
      if (s.type==="DIRECT_SALE"){
        return "Direct Sale"
      }
      if (s.type==="ORDER_SALE"){
        return "Delivery Sale"
      }
      return "None"
      }
  },
  {
    Header: "Sold By",
    accessor: "soldBy",
  },

  {
    Header: "Customer Name",
    accessor: "customer",
  },
  {
    Header: "Route",
    accessor: "routeCode",
  },
  {
    Header: "Amount",
    accessor: "cost",
  },
];

export const cratesColumns = [
  {
    Header: "Items",
    accessor: "crateName",
  },
  {
    Header: "Taken qty",
    accessor: "assigned",
  },
  {
    Header: "Brought qty",
    accessor: "returned",
  },
  {
    Header: "Damaged qty",
    accessor: "damaged",
  },
];

export const unsoldColumns = [
  {
    Header: "Return Date",
    accessor: (d) => moment(d.returnedAt).format("LLL"),
  },
  {
    Header: "Order Number",
    accessor: "orderNumber",
  },

  
  {
    Header: "Product Name",
    accessor: "productName",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Received By",
    accessor: (d) => (d.receivedBy ? d.receivedBy : "Not Received Yet"),
  },

  {
    Header: "Amount",
    accessor: "cost",
  },
];
