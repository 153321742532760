import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import LoginLeftColumn from "./LoginLeftColumn";
import EDOLogo from "../../assets/img/logo.png";
import moment from "moment";

const PasswordResetPage = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <LoginLeftColumn />
        <div className="col-sm-6">
          <ResetPasswordForm />
        </div>
      </div>
    </div>
  );
};
export default PasswordResetPage;
