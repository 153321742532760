import { handleErrorAction } from "./UtilsAction";
import {
  ACTION_SUCCESS,
  GET_SUPPLIERS_OPTIONS_SUCCESS,
  GET_SUPPLIERS_SUCCESS,
} from "../constants/ActionTypes";
import {
  addSupplier,
  getSuppliers,
  updateSupplier,
} from "../../services/SupplierService";

export const addSupplierAction = (data) => {
  return function(dispatch) {
    return addSupplier(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const updateSupplierAction = (data) => {
  return function(dispatch) {
    return updateSupplier(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getSuppliersAction = (data) => {
  return (dispatch) => {
    return getSuppliers({}).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({
            type: GET_SUPPLIERS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getSupplierOptionsAction = () => {
  return (dispatch) => {
    return getSuppliers({}).then(
      (response) => {
        if (response.data.responseObject) {
          let suppliers = response.data.responseObject;

          let supplierOptions = suppliers.map((supplier) => ({
            value: supplier.id,
            label: supplier.name,
            isTaxExempted:
              supplier.taxExempt && supplier.taxExempt === "Y" ? true : false,
          }));
          console.log("supplierOptions = ", supplierOptions);
          dispatch({
            type: GET_SUPPLIERS_OPTIONS_SUCCESS,
            payload: supplierOptions,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};
