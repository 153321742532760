import React from "react";
import { useState } from "react";
import { ReactFormBuilder } from "react-form-builder2";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "react-form-builder2/dist/app.css";
import { useSelector } from "react-redux";

const SurveySchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const AddSurveyPage = () => {
  const [fromContent, setFromContent] = useState([]);
  const userObj = useSelector((state) => state.authManager.user);

  const onLoad = () => {
    console.log(" Load From Data");
    return new Promise((resolve, reject) => {
      resolve([]);
    });
  };

  const onPost = (data) => {
    console.log("Post Data", data);
    setFromContent(data);
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
        }}
        validationSchema={SurveySchema}
        onSubmit={(values) => {
          let surveyData = {
            ...values,
            form: fromContent,
            createdBy: userObj.username,
          };
          // same shape as initial values
          console.log("surveyData = ", surveyData);
          console.log(JSON.stringify(surveyData));
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row">
              <div className="col-sm-9">
                <div className="row mb-2">
                  <div className="col-sm-9">
                    <Field
                      name="name"
                      className="form-control"
                      placeholder="Enters survey name"
                    />
                    {errors.name && touched.name ? (
                      <div>{errors.name}</div>
                    ) : null}
                  </div>
                  <div className="col-sm-3">
                    <button type="submit" className="btn btn-primary">
                      Save Form
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ReactFormBuilder onLoad={onLoad} onPost={onPost} />
    </div>
  );
};

export default AddSurveyPage;
