export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const START_LOADING = "START_LOADING";
export const BULK_EDIT_SUCCESS = "BULK_EDIT_SUCCESS";
export const ACTION_FAIL = "ACTION_FAIL";
export const RESET_VIEW = "RESET_VIEW";
export const TOKEN_VERIFY_SUCCESS = "TOKEN_VERIFY_SUCCESS";
export const RESET_PASSWORD_ACTION_SUCCESS = "RESET_PASSWORD_ACTION_SUCCESS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const USER_LOADING = "USER_LOADING";
export const FETCH_USER_BY_ROLE_SUCCESS = "FETCH_USER_BY_ROLE_SUCCESS";
export const SET_USERNAME = "SET_USERNAME";

export const GET_OUTLETS_SUCCESS = "GET_OUTLETS_SUCCESS";
export const GET_OUTLET_SUMMARY_SUCCESS = "GET_OUTLET_SUMMARY_SUCCESS";
export const GET_TERRITORY_OUTLETS_SUCCESS = "GET_TERRITORY_OUTLETS_SUCCESS";
export const GET_OUTLETS_CATEGORIES_SUCCESS = "GET_OUTLETS_CATEGORIES_SUCCESS";
export const GET_OUTLETS_CATEGORY_OPTIONS_SUCCESS =
  "GET_OUTLETS_CATEGORY_OPTIONS_SUCCESS";
export const GET_OUTLETS_SUBCATEGORY_OPTIONS_SUCCESS =
  "GET_OUTLETS_SUBCATEGORY_OPTIONS_SUCCESS";
export const GET_OUTLETS_SUB_CATEGORIES_SUCCESS =
  "GET_OUTLETS_SUB_CATEGORIES_SUCCESS";
export const GET_OUTLETS_OPTIONS_SUCCESS = "GET_OUTLETS_OPTIONS_SUCCESS";
export const GET_OUTLETS_PRODUCT_PRICES_SUCCESS =
  "GET_OUTLETS_PRODUCT_PRICES_SUCCESS";
export const GET_CUSTOM_PRODUCT_PRICES_SUCCESS =
  "GET_CUSTOM_PRODUCT_PRICES_SUCCESS";
export const OUTLET_DETAILS_RETRIEVED_SUCCESS =
  "OUTLET_DETAILS_RETRIEVED_SUCCESS";

export const OUTLET_VERTICALS_RETRIEVED_SUCCESS =
  "OUTLET_VERTICALS_RETRIEVED_SUCCESS";

export const S3_UPLOAD_SUCCESS = "S3_UPLOAD_SUCCESS";
export const S3_UPLOAD_STARTED = "S3_UPLOAD_STARTED";
export const RESET_SELECTED_USER_OUTLETS = "RESET_SELECTED_USER_OUTLETS";

export const USER_OUTLETS_AND_AREAS_RETRIEVED =
  "USER_OUTLETS_AND_AREAS_RETRIEVED";
export const GET_USER_ASSIGNED_AREAS_SUCCESS =
  "GET_USER_ASSIGNED_AREAS_SUCCESS";
export const ADD_EVENT = "ADD_EVENT";
export const CLEAR_CALENDAR_EVENTS = "CLEAR_CALENDAR_EVENTS";
export const ROUTE_PLAN_RETRIEVED = "ROUTE_PLAN_RETRIEVED";
export const GET_TASK_LIST_OPTIONS_SUCCESS = "GET_TASK_LIST_OPTIONS_SUCCESS";
export const UPDATE_CALENDAR_EVENT = "UPDATE_CALENDAR_EVENT";
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";
export const SELECT_EVENT = "SELECT_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const TOGGLE_EVENT_MODAL = "TOGGLE_EVENT_MODAL";
export const CLEAR_USER_OUTLETS = "CLEAR_USER_OUTLETS";

export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS";
export const GET_SUPPLIERS_OPTIONS_SUCCESS = "GET_SUPPLIERS_OPTIONS_SUCCESS";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const LOCATION_OUT_OF_BOUND = "LOCATION_OUT_OF_BOUND";
export const GET_CURRENT_LOCATION = "GET_CURRENT_LOCATION";
export const RESTORE_DEFAULT_CONFIG = "RESTORE_DEFAULT_CONFIG";
export const CHANGE_LOCATION_PERMISSION_STATUS =
  "CHANGE_LOCATION_PERMISSION_STATUS";
export const GET_USER_LOCATION_SUCCESS = "GET_USER_LOCATION_SUCCESS";
export const GET_USER_LOCATION_FAIL = "GET_USER_LOCATION_FAIL";
export const LOAD_CURRENT_LOCATION = "LOAD_CURRENT_LOCATION";
export const UPDATE_MAP_POSITION = "UPDATE_MAP_POSITION";

export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const UPDATE_IMAGE_URL = "UPDATE_IMAGE_URL";
export const USER_RETRIVAL_SUCCESS = "USER_RETRIVAL_SUCCESS";
export const GET_USER_VERTICALS_SUCCESS = "GET_USER_VERTICALS_SUCCESS";
export const GET_PRODUCT_CATEGORIES_SUCCESS = "GET_PRODUCT_CATEGORIES_SUCCESS";
export const GET_PRODUCT_VERTICALS_SUCCESS = "GET_PRODUCT_VERTICALS_SUCCESS";
export const GET_PRODUCT_VERTICAL_OPTIONS_SUCCESS =
  "GET_PRODUCT_VERTICAL_OPTIONS_SUCCESS";
export const GET_MEASURING_UNITS_SUCCESS = "GET_MEASURING_UNITS_SUCCESS";
export const GET_PACKAGING_UNIT_SUCCESS = "GET_PACKAGING_UNIT_SUCCESS";
export const GET_PACKAGING_UNIT_OPTIONS_SUCCESS =
  "GET_PACKAGING_UNIT_OPTIONS_SUCCESS";
export const GET_MEASUREMENT_UNIT_OPTIONS_SUCCESS =
  "GET_MEASUREMENT_UNIT_OPTIONS_SUCCESS";
export const GET_PRODUCT_SUBCATEGORIES_SUCCESS =
  "GET_PRODUCT_SUBCATEGORIES_SUCCESS";
export const GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS =
  "GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS";
export const GET_PRODUCT_SUBCATEGORY_OPTIONS_SUCCESS =
  "GET_PRODUCT_SUBCATEGORY_OPTIONS_SUCCESS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const RESET_ORDER_ITEMS = "RESET_ORDER_ITEMS";
export const GET_PRODUCT_OPTIONS_SUCCESS = "GET_PRODUCT_OPTIONS_SUCCESS";
export const GET_BLACKLISTED_OUTLETS_SUCCESS =
  "GET_BLACKLISTED_OUTLETS_SUCCESS";
export const GET_OUTLET_ORDERS_SUCCESS = "GET_OUTLET_ORDERS_SUCCESS";
export const GET_FAIL_TO_ORDER_CUSTOMERS_SUCCESS =
  "GET_FAIL_TO_ORDER_CUSTOMERS_SUCCESS";

export const GET_DAILY_ORDERS_SUCCESS = "GET_DAILY_ORDERS_SUCCESS";

export const GET_TAX_CODES_SUCCESS = "GET_TAX_CODES_SUCCESS";
export const GET_TAX_CODE_OPTIONS_SUCCESS = "GET_TAX_CODE_OPTIONS_SUCCESS";

export const GET_FAIL_TO_DELIVER_ORDERS_SUCCESS =
  "GET_FAIL_TO_DELIVER_ORDERS_SUCCESS";
export const GET_OUTLETS_CREDIT_LIMIT_SUCCESS =
  "GET_OUTLETS_CREDIT_LIMIT_SUCCESS";
export const GET_OUTLET_CREDIT_LIMIT_SUCCESS =
  "GET_OUTLET_CREDIT_LIMIT_SUCCESS";

export const UPDATE_OUTLET_TERRITORIES = "UPDATE_OUTLET_TERRITORIES";
export const RESET_TERRITORY_OUTLETS = "RESET_TERRITORY_OUTLETS";

export const GET_LOCATED_OUTLETS_SUCCESS = "GET_LOCATED_OUTLETS_SUCCESS";

export const SET_TERRITORY_COORDIBATES = "SET_TERRITORY_COORDIBATES";
export const UPDATE_TERRITORY_COORDIBATES = "UPDATE_TERRITORY_COORDIBATES";
export const TOGGLE_TERRITORY_FORM = "TOGGLE_TERRITORY_FORM";
export const GET_TERRITORIES_SUCCESS = "GET_TERRITORIES_SUCCESS";
export const GET_TERRITORY_POLYGONS_SUCCESS = "GET_TERRITORY_POLYGONS_SUCCESS";
export const GET_TERRITORY_OPTIONS_SUCCESS = "GET_TERRITORY_OPTIONS_SUCCESS";

export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_OPTIONS_SUCCESS = "GET_ROLES_OPTIONS_SUCCESS";
export const ROLE_UPDATE_SUCCESS = "ROLE_UPDATE_SUCCESS";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const PERMISSION_UPDATE_SUCCESS = "PERMISSION_UPDATE_SUCCESS";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";

export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_DELIVERIES_SUCCESS = "GET_ORDER_DELIVERIES_SUCCESS";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_DRIVER_ORDERS_SUCCESS = "GET_DRIVER_ORDERS_SUCCESS";
export const GET_AM_ORDERS_SUCCESS = "GET_AM_ORDERS_SUCCESS";
export const GET_PM_ORDERS_SUCCESS = "GET_PM_ORDERS_SUCCESS";
export const GET_ORDER_SUMMARY_SUCCESS = "GET_ORDER_SUMMARY_SUCCESS";
export const GET_ALL_ORDER_SUMMARY_SUCCESS = "GET_ALL_ORDER_SUMMARY_SUCCESS";
export const GET_ORDERS_IN_ROUTE_SUCCESS = "GET_ORDERS_IN_ROUTE_SUCCESS";
export const GET_RETURNED_PRODUCTS_SUCCESS = "GET_RETURNED_PRODUCTS_SUCCESS";
export const GET_ROUTE_ORDERS_SUMMARY_SUCCESS =
  "GET_ROUTE_ORDERS_SUMMARY_SUCCESS";
export const GET_EXTRA_ORDERS_SUCCESS = "GET_EXTRA_ORDERS_SUCCESS";
export const GET_RECON_SUMMARY_SUCCESS = "GET_RECON_SUMMARY_SUCCESS";
export const CALL_TRIGGER_SUCCESS = "CALL_TRIGGER_SUCCESS";
export const LOAD_CALL_CONNECTION = "LOAD_CALL_CONNECTION";
export const RESET_CALL_CONNECTION = "RESET_CALL_CONNECTION";

export const REQUEST_LOADING = "REQUEST_LOADING";
export const REQUEST_COMPLETE = "REQUEST_COMPLETE";

export const LOADING = "LOADING";
export const DONE = "DONE";
export const RESET_UTILS = "RESET_UTILS";
export const START_FETCHING = "START_FETCHING";

export const DRIVERS_FETCH_SUCCESS = "DRIVERS_FETCH_SUCCESS";

export const GET_TRUCKS_SUCCESS = "GET_TRUCKS_SUCCESS";
export const GET_TRUCK_SUCCESS = "GET_TRUCK_SUCCESS";
export const GET_TRUCKS_OPTIONS_SUCCESS = "GET_TRUCKS_OPTIONS_SUCCESS";
export const GET_TRUCK_SURVEYS_SUCCESS = "GET_TRUCK_SURVEYS_SUCCESS";

export const GET_PROVINCES_SUCCESS = "GET_PROVINCES_SUCCESS";
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS";
export const GET_WARDS_SUCCESS = "GET_WARDS_SUCCESS";
export const GET_SAVED_PROVINCES_SUCCESS = "GET_SAVED_PROVINCES_SUCCESS";
export const GET_SAVED_DISTRICTS_SUCCESS = "GET_SAVED_DISTRICTS_SUCCESS";
export const GET_SAVED_WARDS_SUCCESS = "GET_SAVED_WARDS_SUCCESS";
export const GOT_EMPTY_WARDS = "GOT_EMPTY_WARDS";
export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS";
export const CHANGE_FILTER_WARDS_STATUS = "CHANGE_FILTER_WARDS_STATUS";
export const SET_AREA_LISTS = "SET_AREA_LISTS";
export const SET_SAVED_AREA_LISTS = "SET_SAVED_AREA_LISTS";
export const SET_ROUTE_AREA_LISTS = "SET_ROUTE_AREA_LISTS";
export const SET_SELECTED_WARD = "SET_SELECTED_WARD";
export const SET_SELECTED_DISTRICT = "SET_SELECTED_DISTRICT";
export const SET_PROVINCE_IDENTITY = "SET_PROVINCE_IDENTITY";

export const MOVE_AREA_TO_ROUTE = "MOVE_AREA_TO_ROUTE";
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS";
export const GET_ROUTE_OPTIONS_SUCCESS = "GET_ROUTE_OPTIONS_SUCCESS";
export const GET_ROUTE_SUCCESS = "GET_ROUTE_SUCCESS";
export const SEARCH_ROUTE_NUMBER_SUCCESS = "SEARCH_ROUTE_NUMBER_SUCCESS";
export const CLEAR_LOCATION_DATA = "CLEAR_LOCATION_DATA";
export const GET_ROUTE_ORDERS_SUCCESS = "GET_ROUTE_ORDERS_SUCCESS";
export const GET_ROUTE_PRODUCT_PRICES_SUCCESS =
  "GET_ROUTE_PRODUCT_PRICES_SUCCESS";
export const GET_ROUTE_DETAILS_SUCCESS = "GET_ROUTE_DETAILS_SUCCESS";
export const GET_ROUTE_DRIVER = "GET_ROUTE_DRIVER";

export const GET_ROUTE_HISTORY_SUCCESS = "GET_ROUTE_HISTORY_SUCCESS";

export const GET_CRATES_SUCCESS = "GET_CRATES_SUCCESS";
export const GET_CRATE_SUCCESS = "GET_CRATE_SUCCESS";
export const GET_CRATE_OPTIONS_SUCCESS = "GET_CRATE_OPTIONS_SUCCESS";
export const GET_USER_RETURNED_CRATES_SUCCESS =
  "GET_USER_RETURNED_CRATES_SUCCESS";
export const GET_USER_DAMAGED_CRATES_SUCCESS =
  "GET_USER_DAMAGED_CRATES_SUCCESS";
export const GET_PRODUCTION_SCHEDULE_SUCCESS =
  "GET_PRODUCTION_SCHEDULE_SUCCESS";

export const GET_DAILY_SALES_SUCCESS = "GET_DAILY_SALES_SUCCESS";
export const GET_ALL_SALES_SUCCESS = "GET_ALL_SALES_SUCCESS";
export const GET_USER_SALES_SUCCESS = "GET_USER_SALES_SUCCESS";
export const GET_USER_UNSOLD_PRODUCTS_SUCCESS =
  "GET_USER_UNSOLD_PRODUCTS_SUCCESS";
export const GET_CREDITS_SUCCESS = "GET_CREDITS_SUCCESS";
export const GET_OUTLET_CREDITS_SUCCESS = "GET_OUTLET_CREDITS_SUCCESS";
export const GET_USER_CREDITS_SUCCESS = "GET_USER_CREDITS_SUCCESS";
export const GET_CREDITS_COLLECTED_SUCCESS = "GET_CREDITS_COLLECTED_SUCCESS";
export const GET_USER_TARGETS_SUCCESS = "GET_USER_TARGETS_SUCCESS";
export const GET_USER_PERFORMANCE_HISTORY_SUCCESS =
  "GET_USER_PERFORMANCE_HISTORY_SUCCESS";
export const IS_LOADING = "IS_LOADING";

export const GET_CAGES_SUCCESS = "GET_CAGES_SUCCESS";

export const GET_NEW_NOTIFICATION = "GET_NEW_NOTIFICATION";
export const NOTIFICATIONS_READ_SUCCESS = "NOTIFICATIONS_READ_SUCCESS";
export const GET_EXPIRED_PRODUCTS_SUCCESS = "GET_EXPIRED_PRODUCTS_SUCCESS";
export const GET_ORDER_ITEM_DSICOUNT_SUCCESS =
  "GET_ORDER_ITEM_DSICOUNT_SUCCESS";
export const GET_ORDERED_PRODUCT_DETAILS_SUCCESS =
  "GET_ORDERED_PRODUCT_DETAILS_SUCCESS";
export const GET_ORDER_ITEMS_DSICOUNT_SUCCESS =
  "GET_ORDER_ITEMS_DSICOUNT_SUCCESS";
export const CLEAR_DISCOUNT = "CLEAR_DISCOUNT";
export const ADD_ORDER_ITEMS = "ADD_ORDER_ITEMS";
export const REMOVE_ORDER_ITEM = "REMOVE_ORDER_ITEM";

export const TOGGLE_ORDER_MODAL = "TOGGLE_ORDER_MODAL";

export const GET_DAILY_SALES_REPORT_SUCCESS = "GET_DAILY_SALES_REPORT_SUCCESS";
export const GET_OUTLETS_COUNT_BY_TYPE_SUCCESS =
  "GET_OUTLETS_COUNT_BY_TYPE_SUCCESS";
export const GET_LEADS_COUNT_BY_TYPE_SUCCESS =
  "GET_LEADS_COUNT_BY_TYPE_SUCCESS";
export const GET_ORDERS_BREAKDOWN_SUCCESS = "GET_ORDERS_BREAKDOWN_SUCCESS";
export const GET_OUTLET_AVERAGE_ORDER_VALUE_SUCCESS =
  "GET_OUTLET_AVERAGE_ORDER_VALUE_SUCCESS";
export const GET_USERS_BY_ROLE_SUCCESS = "GET_USERS_BY_ROLE_SUCCESS";
export const GET_USERS_BY_STATUS_SUCCESS = "GET_USERS_BY_STATUS_SUCCESS";
export const GET_OUTLETS_BY_USERS_SUCCESS = "GET_OUTLETS_BY_USERS_SUCCESS";
export const GET_OUTLETS_CREATION_TREND_SUCCESS =
  "GET_OUTLETS_CREATION_TREND_SUCCESS";
export const GET_OUTLETS_ORDERING_TREND_SUCCESS =
  "GET_OUTLETS_ORDERING_TREND_SUCCESS";
export const GET_ORDER_STATUS_SUCCESS = "GET_ORDER_STATUS_SUCCESS";
export const GET_DAILY_ORDER_TREND_SUCCESS = "GET_DAILY_ORDER_TREND_SUCCESS";
export const GET_FREQUENTLY_ORDERED_PRODUCT_SUCCESS =
  "GET_FREQUENTLY_ORDERED_PRODUCT_SUCCESS";
export const GET_ORDER_PROPCESS_BY_USER_SUCCESS =
  "GET_ORDER_PROPCESS_BY_USER_SUCCESS";
export const GET_PERCENT_ORDER_PROCESS_SUCCESS =
  "GET_PERCENT_ORDER_PROCESS_SUCCESS";
export const GET_TOP_VERTICAL_SUCCESS = "GET_TOP_VERTICAL_SUCCESS";
export const GET_TOP_VERTICAL_BY_MARGIN_SUCCESS =
  "GET_TOP_VERTICAL_BY_MARGIN_SUCCESS";
export const GET_PRODUCT_MARGIN_TREND_SUCCESS =
  "GET_PRODUCT_MARGIN_TREND_SUCCESS";
export const GET_TOP_CUSTOMERS_SUCCESS = "GET_TOP_CUSTOMERS_SUCCESS";
export const GET_TOP_CUSTOMERS_BY_OUTLET_TYPE_SUCCESS =
  "GET_TOP_CUSTOMERS_BY_OUTLET_TYPE_SUCCESS";
export const GET_SALES_REPORT_SUCCESS = "GET_SALES_REPORT_SUCCESS";
export const GET_OUTLET_SALES_DISTRIBUTION_SUCCESS =
  "GET_OUTLET_SALES_DISTRIBUTION_SUCCESS";
export const GET_TOTAL_REVENUE_SUCCESS = "GET_TOTAL_REVENUE_SUCCESS";
export const GET_TOTAL_ORDER_COUNT_SUCCESS = "GET_TOTAL_ORDER_COUNT_SUCCESS";
export const GET_USERS_REPORT_SUCCESS = "GET_USERS_REPORT_SUCCESS";
export const GET_OUTLETS_PURCHASE_HISTORY_SUCCESS =
  "GET_OUTLETS_PURCHASE_HISTORY_SUCCESS";
export const GET_DAILY_ORDER_SUMMARY_REPORT_SUCCESS =
  "GET_DAILY_ORDER_SUMMARY_REPORT_SUCCESS";
export const GET_DAILY_OUTLET_CREATION_SUCCESS =
  "GET_DAILY_OUTLET_CREATION_SUCCESS";
export const GET_HOURLY_ORDER_PROCESSIGNG_SUCCESS =
  "GET_HOURLY_ORDER_PROCESSIGNG_SUCCESS";
export const GET_SALES_DISTRIBUTION_SUCCESS = "GET_SALES_DISTRIBUTION_SUCCESS";
export const GET_TOP_PRODUCTS_SALES_BY_VERTICAL_SUCCESS =
  "GET_TOP_PRODUCTS_SALES_BY_VERTICAL_SUCCESS";
export const GET_TOP_PRODUCTS_MARGIN_BY_VERTICAL_SUCCESS =
  "GET_TOP_PRODUCTS_MARGIN_BY_VERTICAL_SUCCESS";

export const ON_CLICK_SUCCESS = "ON_CLICK_SUCCESS";
export const GET_TREEMAP_EVENT_SUCCESS = "GET_TREEMAP_EVENT_SUCCESS";
export const GET_TREEMAP_MARGIN_EVENT_SUCCESS =
  "GET_TREEMAP_MARGIN_EVENT_SUCCESS";
export const CLEAR_STATE_SUCCESS = "CLEAR_STATE_SUCCESS";
export const GET_DAILY_ORDER_PER_VERTICAL_SUCCESS =
  "GET_DAILY_ORDER_PER_VERTICAL_SUCCESS";
export const GET_OUTLETS_STATUS_SUCCESS = "GET_OUTLETS_STATUS_SUCCESS";
export const GET_USERS_STATUS_SUCCESS = "GET_USERS_STATUS_SUCCESS";
export const GET_ALL_TARGETS_SUCCESS = "GET_ALL_TARGETS_SUCCESS";
export const UPDATE_START_DATE = "UPDATE_START_DATE";
export const UPDATE_END_DATE = "UPDATE_END_DATE";
export const UPDATE_VET_ID = "UPDATE_VET_ID";
export const TOGGLE_TOP_PRODUCTS_VISIBILITY = "TOGGLE_TOP_PRODUCTS_VISIBILITY";
export const CLEAR_OUTLET_DETAILS = "CLEAR_OUTLET_DETAILS";
export const CLEAR_OUTLET_VERTICALS = "CLEAR_OUTLET_VERTICALS";
