import React,{useEffect} from "react";
import TrucksSurveyTable from "../trucks/TrucksSurveyTable";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const TruckViewer = ({truck,reset})=>{
   
    useEffect(()=>{
         console.log(truck)
    },[])

    return  <>
    <h2>  <FontAwesomeIcon
          icon={faArrowLeft}
          size="1x"
          className="mr-2 primary pointer"
          onClick={() => reset()}

        /> {truck.name} Details </h2>
    <div className="row">
         <div className="col-sm-4">
             <strong>Created At : </strong> {truck.created_at}
         </div>
         <div className="col-sm-4">
             <strong>Name : </strong> {truck.name}
         </div>
         <div className="col-sm-4">
             <strong>Plate Number : </strong>  {truck.plateNumber}
         </div>
    </div>

    <div className="row mt-3 mb-3">
         <div className="col-sm-4">
             <strong>Truck Number : </strong> {truck.truckNumber}
         </div>
         <div className="col-sm-4">
             <strong>Type : </strong> {truck.type}
         </div>
         
    </div>
    
    <div className="row mb-3">
         <div className="col-sm-4">
             <strong>Status : </strong> {truck.status}
         </div>
         <div className="col-sm-8">
             <img src={truck.image} className="img-fluid"/>
         </div>
         
    </div>

    <div className="row mt-3">
        <div className="col-sm-12">
            {truck&& truck.truckNumber&& 
             <TrucksSurveyTable
             payload={{ key: "truckNumber", value: truck.truckNumber }}
            
           />
            }
       
        </div>
    </div>
    </>
}

export default TruckViewer;