import React, { useEffect, useState } from "react";
import {getUserIframeURL} from '../metabase/metabase.embed.js';
import { useDispatch, useSelector } from "react-redux"
import { getAgentRouteAction } from "../../store/actions/RouteAction";
import ColumnChart from './charts/ColumnChart';
import DonutChart from './charts/DonutChart';
import AreaChart from './charts/areaChart';
import BarChart from './charts/BarChart';
import Table from "../../components/dom/Table/Table";
import MultipeYGraph from "./charts/DoubleYaxisGraph";
import MultipleYNoDate from "./charts/DoubleYaxisNoDate";
import {
    getOutletsAction,
  } from "../../store/actions/OutletAction";
import {
  getDailySalesReportAction,
  geOutletsCountByTypeAction,
  geLeadsCountByTypeAction,
  getOrdersBreakDownAction,
  getAverageOutletOrderValueAction,
  geOutletsByUsersAction,
  geOutletsCreationTrendAction,
  geOutletsOrderingTrendAction,
  geOutletsPurchaseHistoryAction,
  geOutletsCreationDailyAction,

} from "../../store/actions/ReportsAction";
import {
  getUsersAction,
} from "../../store/actions/UserAction";
  import moment from "moment";
  import _ from "lodash";
  import DatePicker from "react-datepicker";
  import { BoxWrapper,ButtonBox } from "../../styles/schedule";
  

const OutletSummary = (params) => {
    const [outlet, setOutlet] = useState({});
    const outlets = useSelector((state) => state.outletManager.outlets);
    const outletsCountByType = useSelector((state) => state.reportManager.outletsCountByType);
    const leadsCountByType = useSelector((state) => state.reportManager.leadsCountByType);
    const avgOutletOrderValue = useSelector((state) => state.reportManager.avgOutletOrderValue);
    const outletsByUser = useSelector((state) => state.reportManager.outletsByUser);
    const outletCreationTrend = useSelector((state) => state.reportManager.outletCreationTrend);
    const outletOrderingTrend = useSelector((state) => state.reportManager.outletOrderingTrend);
    const outletPurchaseHistory = useSelector((state) => state.reportManager.outletPurchaseHistory);
    const dailyOutletCreationSummary = useSelector((state) => state.reportManager.dailyOutletCreationSummary);
    const users = useSelector((state) => state.userManager.users);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);


    const [data, setData] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(null);
    const [outletType, setOutletType] = useState([]);
    const [outletTypeCount, setOutletCount] = useState([]);
    const [leadType, setLeadType] = useState([]);
    const [leadCount, setLeadCount] = useState([]);

    const [totalLeads, setTotalLeads] = useState([]);
    const [wholesaleAvgOrderValue, setWholesaleAvgOrderValue] = useState([]);
    const [retailAvgOrderValue, setRetailAvgOrderValue] = useState([]);
    const [shopAvgOrderValue, setShopAvgOrderValue] = useState([]);
    const [individualAvgOrderValue, setIndividualAvgOrderValue] = useState([]);
    const [avgOrdeValueDate, setAvgOrderValueDate] = useState([]);
    const [repsName, setRepsName] = useState([]);
    const [repsWholesale, setRepsWholeSale] = useState([]);
    const [repsRetail, setRepsRetail] = useState([]);
    const [repsShop, setRepsShop] = useState([]);
    const [repsIndividual, setRepsIndividual] = useState([]);

    const [outletsCreatedDate, setOutletCreationDate] = useState([]);
    const [createdWholesale, setCreatedWholeSale] = useState([]);
    const [createdRetail, setCreatedRetail] = useState([]);
    const [createdShop, setCreatedShop] = useState([]);
    const [createdIndividual, setCreatedIndividual] = useState([]);
    const [outletOrderingDate, setOutletOrderingDate] = useState([]);
    const [outletOrderingderingValue, setOutletOderingValue] = useState([]);
  
    const onChange = dates => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };
  
    const changeData = () => {
     // setData(generateData());
    };
  
  
    const onSubmit = (e) => {
      e.preventDefault();
      setLoading(true) ;
      let filter = {
         startDate : moment(startDate).format("DD/MM/YYYY"),
         endDate : moment(endDate).format("DD/MM/YYYY"),
         vertical: "",
         username:"",
         area:"",
  
      }
      dispatch(getOutletsAction(filter));
      dispatch(geOutletsCountByTypeAction(filter));
      dispatch(geLeadsCountByTypeAction(filter));

      dispatch(getAverageOutletOrderValueAction(filter));
    //  dispatch(geOutletsByUsersAction(usernames));

      dispatch(geOutletsCreationTrendAction(filter));
      dispatch(geOutletsOrderingTrendAction(filter));
      dispatch(geOutletsPurchaseHistoryAction({filter}));
      dispatch(geOutletsCreationDailyAction(filter));
      setTimeout(
        () =>
        setLoading(false) ,
        5000
      );

    };
  
  
  

    let grouped = _.groupBy(outlets, function(cat) {
        return cat.categoryName;
    })
    console.log("grouped=>",grouped)

    let activeOutlets = _.filter(outlets, function(o) {
        return o.status === 'ACTIVE' ; });

    let inactiveOutlets  = _.filter(outlets, function(o) {
         return o.status === 'INACTIVE' ; });

    useEffect(() => {
       const reps =  users.filter(item => {
                          return item.role === "sales-representative"
                        }).map((item) => item.username)
                          .join()
       let usernames = {
                         username :reps
                       }
        dispatch(getOutletsAction({}));
        dispatch(geOutletsCountByTypeAction({}));
        dispatch(geLeadsCountByTypeAction({}));

        dispatch(getAverageOutletOrderValueAction({}));
        dispatch(geOutletsByUsersAction(usernames));

        dispatch(geOutletsCreationTrendAction({}));
        dispatch(geOutletsOrderingTrendAction({}));
        dispatch(geOutletsPurchaseHistoryAction({}));
        dispatch(geOutletsCreationDailyAction({}));
      }, []);

      useEffect(() => {
        dispatch(getUsersAction({}));
      }, [dispatch]);

     
     
    useEffect(() => {
      getReports()
      },[])
    
      const getReports = () => {
  
      }
  

    const filterOutlets  = () => {

    }

    const columns = [
        {
          Header: "Created Date",
          accessor: (d) => moment(d.createdAt).format("ddd D MMM YYYY"),
          key: ["createdAt"],
        },
        {
          Header: "Outlet Name",
          accessor: "name",
          key: ["name"],
        },
        {
          Header: "Outlet Type",
          accessor: "categoryName",
          key: ["categoryName"]
        },
        {
          Header: "Reg status",
          accessor: "registrationStatus",
          key:["registrationStatus"]
        },
        {
          Header: "Counts to Outlets",
          accessor: " ",
        },
        {
          Header: "Owner Name",
          accessor: "owner",
          key:["owner"]
        },
        {
          Header: "Added By",
          accessor: "createdBy",
          key: ["createdBy"]
        },
        {
         Header: "Region",
         accessor: "region",
         key:["region"]
        },
        {
         Header: "District",
         accessor: "district",
         key: ["district"]
        },
        {
        Header: "Ward",
        accessor: "ward",
        key:["ward"]
        },
        {
        Header: "Latitude",
        accessor: "latitude",
        key:["latitude"]
        },
        {
        Header: "Longitude",
        accessor: "longitude",
        key:["longitude"]
        },
    
      ];
    
    return (
       <> 
         
                    <h3>outlet summary</h3>

                    { loading &&
                      <div className="alert alert-primary" role="alert">
                          Loading...
                        </div>
                      }
                    <div className = "col-sm-12 shadow-sm p-3 mb-5">
                    <BoxWrapper>
                          <form onSubmit={onSubmit} role="form">
                            <div className="row">
                                  <div className="col-sm-2">
                                    <DatePicker
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      placeholderText="from"
                                      className="date-picker-input"
                                    />
                                  </div>
                               
                                  <div className="col-sm-2">
                                    <DatePicker
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      placeholderText="to"
                                      className="date-picker-input"
                                    />
                                  </div>

                                <div className="col-sm-2">
                                  <ButtonBox type="submit">
                                    filter
                                  </ButtonBox>
                                </div>
                            
                            </div>
                          </form>
                        </BoxWrapper>
                      </div>
                    

                    

                    <div className = "row">
                        <div className = "col-sm-4 shadow p-3 mb-5 bg-white rounded">
                        <h6 className="text-muted font-weight-normal mt-0" >Outlets by types</h6>
                            {outletsCountByType && outletsCountByType.outletType &&
                              <DonutChart 
                              //  title = "Outlets by Types"
                                dataLabels = {outletsCountByType.outletType}
                                dataValues = {outletsCountByType.outletTypeCount}
                            />}
                        </div>
                      

                  <div className = "col-sm-8 shadow p-3 mb-5 bg-white rounded">
                  <h6 className="text-muted font-weight-normal mt-0" >Outlets Purchase History</h6>
                           {outletPurchaseHistory && outletPurchaseHistory.items &&
                            <BarChart
                               // title = "Outlets Status"
                                xaxisData = {outletPurchaseHistory.items}
                                yaxisData = { outletPurchaseHistory.data}
                                stacked = {true}
                            />}
                        </div>
                    </div>
                    <div className="row">
                      <div className = "col-sm-4 shadow p-3 mb-5 bg-white rounded">
                      <h6 className="text-muted font-weight-normal mt-0" >Leads by types</h6>
                       {leadsCountByType && leadsCountByType.leadType && 
                       <DonutChart
                         // title = "Leads by Types"
                          dataLabels = {leadsCountByType.leadType}
                          dataValues = {leadsCountByType.leadCount}
                        />}
                      </div>
                      <div className = 'col-sm-8 shadow p-3 mb-5 bg-white rounded'>
                      <h6 className="text-muted font-weight-normal mt-0" >Average Order value per Outlet type</h6>
                      { avgOutletOrderValue &&  avgOutletOrderValue.items &&
                        <AreaChart 
                        //  title = "Average Order value per outlet type"
                          yaxisData = {avgOutletOrderValue.data}
                          xaxisData = {avgOutletOrderValue.items}
                          xDataType = "datetime"
                          dateFormant = "DD MMM YYYY"
                          
                        /> }
                      </div>
            
                    </div>
                    <div className = "row">
                        <div className = 'col-sm-12 shadow p-3 mb-5 bg-white rounded'>
                        <h6 className="text-muted font-weight-normal mt-0" >Parcentage of Outlets made purchase</h6>
                             {outletOrderingTrend && outletOrderingTrend.outletOrderingderingValue &&
                              <AreaChart 
                              // = "Percentage of outlets made purchase"
                              yaxisData = {[{
                                              name: 'Percentage',
                                              data: outletOrderingTrend.outletOrderingderingValue
                                            }]}
                              xaxisData = {outletOrderingTrend.outletOrderingDate}
                              xDataType = "datetime"
                              dateFormant = "DD MMM YYYY"
                              
                              />}
                          </div>
                        {/* <div className = 'col-sm-6 shadow p-3 mb-5 bg-white rounded'>
                         <h6 className="text-muted font-weight-normal mt-0" >Today Outlets creation trend</h6>
                          {outletCreationTrend && outletCreationTrend.createdWholesale &&
                            <AreaChart
                           // title = "Outlets creation trend"
                            yaxisData = {[{
                                            name: 'Wholesale',
                                            data: outletCreationTrend.createdWholesale
                                          }, {
                                            name: 'Retail',
                                            data: outletCreationTrend.createdRetail
                                          },
                                          {
                                            name: 'Shop',
                                            data: outletCreationTrend.createdShop
                                          },
                                          {
                                            name: 'Individual',
                                            data: outletCreationTrend.createdIndividual
                                          },
                                        
                                        ]}
                            xaxisData = {outletCreationTrend.outletsCreatedDate}
                            xDataType = "datetime"
                            dateFormant = "hh:mm"
                          />}
                        </div>  */}
                    </div>

                    <div className = "row">

                        <div className = 'col-sm-12 shadow p-3 mb-5 bg-white rounded'>
                         <h6 className="text-muted font-weight-normal mt-0" >Daily Trend of Outlets creation</h6>
                          {dailyOutletCreationSummary && dailyOutletCreationSummary.items &&
                            <AreaChart
                           // title = "Outlets creation trend"
                            yaxisData = {dailyOutletCreationSummary.data}
                            xaxisData = {dailyOutletCreationSummary.items}
                            xDataType = "datetime"
                            dateFormant = "DD MMM YYYY"
                          />}
                        </div> 

                       <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                       <h6 className="text-muted font-weight-normal mt-0" >Outlets creation per sales represenatives</h6>
                          {outletsByUser && outletsByUser.repsName && 
                           <BarChart
                              //  title = "Outlets creation per sales rep"
                                xaxisData = {outletsByUser.repsName}
                                yaxisData = { [{
                                                name: 'Wholesale',
                                                data: outletsByUser.repsWholesale
                                            }, 
                                            {
                                                name: 'Retail',
                                                data: outletsByUser.repsRetail
                                            },
                                              {
                                                name: 'Shop',
                                                data: outletsByUser.repsShop
                                            },
                                            {
                                                name: 'Individual',
                                                data: outletsByUser.repsIndividual
                                          },
                                          ]
                                      }
                                stacked = {true}
                                horizontal = {false}
                            />}
                        </div>
                       
                       
                    </div>
                   
                    <div className = "row">
                        <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                        <h6 className="text-muted font-weight-normal mt-0" >Active Outlets</h6>
                           <Table title ="" columns={columns} data={activeOutlets}/>
                        </div>
                        <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                        <h6 className="text-muted font-weight-normal mt-0" >Inactive Outlets</h6>
                           <Table title ="" columns={columns} data={inactiveOutlets}/>
                        </div>
                    </div>

                    
                   
       
       </>
    
 )
}
    

export default OutletSummary