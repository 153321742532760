import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFailToOrderCustomersAction,
  getCouldNotDeliverOrdersAction,
} from "../../store/actions/OrderAction";
import Table from "../../components/dom/Table/Table";
import moment from "moment";
import { ButtonWithIcon } from "../../styles/Forms";
import PageModal from "../../components/dom/modal";

const DriverCouldNotDeliver = ({ username }) => {
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [delivery, setDelivery] = useState(null);
  const couldntOrderCustomers = useSelector(
    (state) => state.OrderManager.couldntOrderCustomers
  );
  const failToDeliverOrders = useSelector(
    (state) => state.OrderManager.failToDeliverOrders
  );
  const userObj = useSelector((state) => state.authManager.user);
  const dispatch = useDispatch();

  const viewMore = (data) => {
    setmodalIsOpen(true);
    setDelivery(data);
  };

  const failedToDeliverColumns = [
    {
      Header: "Date",
      accessor: (d) => moment(d.date).format("DD-MM-YYYY"),
    },

    {
      Header: "Customer Name",
      accessor: "customer",
      key:["customer"]
    },
    {
      Header: "Product Name",
      accessor: "productName",
      key:["productName"]
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      key:["quantity"]
    },
    {
      Header: "Shift",
      accessor: "shift",
      key:["shift"]
    },
    {
      Header: "Order  Number",
      accessor: "orderNumber",
      key:["orderNumber"]
    },
    {
      Header: " ",

      Cell: (row) => (
        <ButtonWithIcon
          className="primary text-uppercase font-weight-normal"
          onClick={() => viewMore(row.row.original)}
        >
          More Info
        </ButtonWithIcon>
      ),
    },
  ];

  let filter = {};

  if (userObj.role === "call-centre-agent") {
    filter = {
      orderDir: "desc",
      orderBy: "id",
      searches: [
        {
          key: "agent",
          value: userObj.username,
        },
      ],
    };
  }

  useEffect(() => {
    let driverFilter = {
      searches: [
        {
          key: "driver",
          value: username,
        },
      ],
    };
    //dispatch(getFailToOrderCustomersAction(filter));
    dispatch(getCouldNotDeliverOrdersAction(driverFilter));
  }, []);
  return (
    <>
      {failToDeliverOrders && failToDeliverOrders.length > 0 && (
        <Table data={failToDeliverOrders} columns={failedToDeliverColumns} />
      )}
      {failToDeliverOrders && failToDeliverOrders.length === 0 && (
        <p className="alert alert-info">No orders failed to be delivered</p>
      )}

      <PageModal
        isOpen={modalIsOpen}
        component={
          <>
            <div className="row">
              <div className="col-sm-12">
                <p>
                  <strong>Additional comments :</strong>
                  {delivery && delivery.comments ? delivery.comments : "None"}
                </p>
                <br />

                <p className="font-weight-bold">Image : </p>
                {delivery && delivery.images && delivery.images.length>0 ? (
                  delivery.images.map(image=>(
                    <img
                    src={image}
                    className="img-fluid img-medium"
                    alt="delivery "
                  />
                  ))
                 
                ) : (
                  <p className="alert alert-info">No image taken</p>
                )}
              </div>
            </div>
          </>
        }
        setIsOpen={setmodalIsOpen}
      />
    </>
  );
};

export default DriverCouldNotDeliver;
