import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Table from "../../components/dom/Table/Table";
import {
  getCategoriesAction,
  getPackagingUnitAction,
  getVerticalsAction,
} from "../../store/actions/ProductAction";
import moment from "moment";
import { PageButton } from "../../styles/App";
import CreateIcon from "@material-ui/icons/Create";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import { resetUtils } from "../../store/actions/UtilsAction";

const PackagingUnitPage = ({ add, edit, showCutOffTimeForm }) => {
  const categoryColumn = [
    {
      Header: "Id",
      accessor: "id",
      key: ["id"],
    },
    {
      Header: " Name",
      accessor: (d) => (
        <>
          <img
            src={
              d.image
                ? d.image
                : "https://s3.amazonaws.com/aimrough/potraits/default-avatar.png"
            }
            alt={d.name}
            className="img-fluid pr-2"
            width="40px"
            height="40px"
          />{" "}
          {d.name}
        </>
      ),
      key: ["name"],
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      key: ["quantity"],
    },
    {
      Header: "Created BY",
      accessor: "createdBy",
      key: ["createdBy"],
    },

    {
      Header: "Action",

      Cell: (row) => (
        <div className="row">
          <div onClick={() => edit(row.row.original)} className="col-5 pointer">
            <CreateIcon className="primary " />{" "}
            <span className="primary">Edit </span>
          </div>
        </div>
      ),
    },
  ];

  const packagingUnits = useSelector(
    (state) => state.productManager.packagingUnits
  );
  const verticals = useSelector((state) => state.productManager.verticals);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPackagingUnitAction());
    dispatch(resetUtils());
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {success && <p className="alert alert-success">{message}</p>}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <h4 className="mt-3">All Packaging Units</h4>
        </div>
        <div className="col-sm-10">
          <PageButton className="float-right" onClick={add}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Create Packaging Unit
          </PageButton>
        </div>
      </div>
      {packagingUnits && packagingUnits.length === 0 && (
        <p className="alert alert-info mt-3">No Packaging Unit at the moment</p>
      )}
      {packagingUnits && packagingUnits.length > 0 && (
        <Table
          data={packagingUnits}
          columns={categoryColumn}
          rowInfo={() => {}}
        />
      )}
    </>
  );
};

export default PackagingUnitPage;
