import React, { useState, useEffect } from "react";
import {
  addProductMeasuringUnitAction,
  addProductPackagingUnitAction,
  addProductSubcategoryAction,
  createTaxCodeAction,
  getProductCategoryOptionsAction,
  updateProductCategoryAction,
  updateProductSubcategoryAction,
  updateTaxCodeAction,
} from "../../store/actions/ProductAction";
import { useDispatch, useSelector } from "react-redux";
import { FormWrapper, Input, Label, ButtonSmall } from "../../styles/Forms";
import ImageUploader from "../../components/utils/ImageUploader";
import { Formik } from "formik";
import * as Yup from "yup";

import _ from "lodash";
import Select from "react-select";

const nameRegExp = /^([^0-9]*)$/;

const AddMeasurementUnitForm = ({ editMode, measurementUnit }) => {
  const [selectedCategory, setSelectedCategory] = useState({});
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const data = useSelector((state) => state.stateManager.data);
  const userObj = useSelector((state) => state.authManager.user);
  const categoryOptions = useSelector(
    (state) => state.productManager.categoryOptions
  );

  useEffect(() => {
    if (success) {
      dispatch({ type: "RESET_VIEW" });
    }
  });

  useEffect(() => {}, []);

  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        name: editMode ? measurementUnit.name : "",
        type: editMode ? measurementUnit.type : "",
        description: editMode ? measurementUnit.description : "",
        createdBy: userObj.username,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setIsButtonDisabled(true);
        setTimeout(() => {
          console.log("TAX Form Submittet");
          //console.log(values);
          let formData = _.clone(values);

          if (editMode) {
            formData["id"] = measurementUnit.id;

            //console.log(formData);
            // dispatch(updateTaxCodeAction(formData));
          } else {
            // console.log(formData);
            dispatch(addProductMeasuringUnitAction(formData));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(" Name is required "),
        type: Yup.string().required(" Type is required "),
        description: Yup.string().required("Description is required "),
      })}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <div>
            <FormWrapper>
              {success && <p className="alert alert-success">{message}</p>}
              {/* <code>{JSON.stringify(values)}</code>
              <code>{JSON.stringify(selectedCategory)}</code> */}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row mb-1">
                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="name">Name; </Label>
                    <Input
                      id="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? " is-invalid" : ""
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error ml-2 mt-2"> {errors.name}</p>
                    )}
                  </div>

                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="code">Type; </Label>
                    <Input
                      id="type"
                      type="text"
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.code && touched.code ? " is-invalid" : ""
                      }
                    />
                    {errors.type && touched.type && (
                      <p className="error ml-2 mt-2"> {errors.type}</p>
                    )}
                  </div>

                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="value">Description: </Label>
                    <Input
                      id="description"
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.description && touched.description
                          ? " is-invalid"
                          : ""
                      }
                    />
                    {errors.description && touched.description && (
                      <p className="error ml-2 mt-2"> {errors.description}</p>
                    )}
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3">
                  <ButtonSmall type="submit" disabled={isButtonDisabled}>
                    {editMode && <span>Update Measurement Unit </span>}
                    {!editMode && <span>Create Measurement Unit</span>}
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddMeasurementUnitForm;
