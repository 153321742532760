import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import { addUserAction, editUserAction } from "../../store/actions/UserAction";
import { getUsersAction, getRolesAction } from "../../store/actions/UserAction";
import { resetUtils } from "../../store/actions/UtilsAction";
import { useDispatch, useSelector } from "react-redux";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "react-select";
import {
  FormWrapper,
  Input,
  Label,
  FormSelect,
  InputWrapper,
  Button,
  ButtonSmall,
} from "../../styles/Forms";
import ImageUploader from "../../components/utils/ImageUploader";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { scrollToTop } from "../../utils/window";
import LocationPicker from "../../components/location/LocationPicker/LocationPicker";
import { getVerticalOptionsAction } from "../../store/actions/ProductAction";
import { USER_LOADING } from "../../store/constants/ActionTypes";

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const nameRegExp = /^([^0-9]*)$/;
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AddUserForm = ({ editMode, user, resetView }) => {
  const [double, setDisableDouble] = useState(false);
  const roles = useSelector((state) => state.userManager.roles);
  const routeNumbers = useSelector(
    (state) => state.locationManager.routeNumbers
  );
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const [verticalOptions, setVerticalOptions] = useState("");
  const savedVerticalOptions = useSelector(
    (state) => state.productManager.verticalOptions
  );

  const [selectedVerticals, setSelectedVerticals] = useState("");
  const imageUploaded = useSelector(
    (state) => state.utilsManager.imageUploadSuccess
  );
  var imgUrl = useSelector((state) => state.utilsManager.imgUrl);
  const [coordinates] = useState({
    lat: -6.7430267,
    lng: 39.2789343,
  });
  const address = useSelector((state) => state.locationManager.address);

  const data = useSelector((state) => state.stateManager.data);
  const userObj = useSelector((state) => state.authManager.user);
  imgUrl = !imageUploaded && editMode ? user.picturePath : imgUrl;

  const userVerticals = useSelector((state) => state.userManager.userVerticals);
  
  useEffect(() => {
    if (success) {
      resetView();
      setTimeout(() => dispatch(getUsersAction()), 5000);
    }
    if (error) {
      scrollToTop();
    }
  }, [success, error]);

  useEffect(() => {
    dispatch(resetUtils());
    dispatch(getRolesAction());
    dispatch(getVerticalOptionsAction());
  }, []);

  useEffect(() => {
    let options = [
      // { label: "All",value: savedVerticalOptions.map((opt) => opt.value) },
       ...savedVerticalOptions,
    ];
    setVerticalOptions(options);
    let userAssignedVerticals = userVerticals.map((vertical) => ({
          label: vertical.name,
          value: vertical.id,
       }));
    setSelectedVerticals([
     // { label: "All", value: savedVerticalOptions.map((opt) => opt.value) },
      ...userAssignedVerticals
    ]);
  }, [savedVerticalOptions.length]);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        firstName: editMode ? user.firstName : "",
        lastName: editMode ? user.lastName : "",
        email: editMode ? user.email : "",
        mobile: editMode ? user.mobile : "",
        role: editMode ? user.role : null,
        address: editMode ? user.address : "",
        idType: editMode ? user.idType : "national-id",
        idNumber: editMode ? user.idNumber : "",
        language: editMode ? user.language : "en",
        picturePath: imgUrl,
        createdBy: userObj.username,
        region: "dar-es-salaam",
      }}
      onSubmit={(values, { setSubmitting }) => {
        var formData = _.clone(values);
        setTimeout(() => {
          formData["picturePath"] = imgUrl;
          formData["address"] = address;
          let userVerticals = [];
          if (
            formData.role === "sales-representative" &&
            selectedVerticals.length > 0
          ) {
            userVerticals = selectedVerticals.map((vertical) =>
              Array.isArray(vertical.value)
                ? _.flatten(vertical.value)
                : vertical.value
            );
          }
          console.log();
          if (editMode) {
            formData["id"] = user.id;
            setDisableDouble(true);
            dispatch(editUserAction(formData, userVerticals, user.username));
            resetView();
          } else {
            console.log(formData);
            setDisableDouble(true);
            dispatch(addUserAction(formData, userVerticals));
            resetView();
          }
          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .matches(nameRegExp, "First Name cannot contain number")
          .required("First Name is required "),
        lastName: Yup.string()
          .matches(nameRegExp, "Last Name cannot contain number")
          .required("Last Name is required "),
        mobile: Yup.string()
          .matches(phoneRegExp, "Mobile number is not valid")
          .min(9)
          .max(14)
          .required("Must enter a Mobile number"),
        email: Yup.string()
          .email("You must enter a valid email address")
          .required("Email is required"),

        idType: Yup.string().required("Identity Type is required "),
        idNumber: Yup.string().required("Identity Number is required "),
        role: Yup.string().nullable().required("Role is required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div>
            <code>{JSON.stringify(selectedVerticals)}</code>
            {/* <code>{JSON.stringify(errors)}</code> */}
            <FormWrapper>
              {error && <p className="alert alert-danger">{message}</p>}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div class="col-lg-6">
                    <div class="row form-group">
                      <label class="col-form-label required col-lg-4">
                        First Name *
                      </label>
                      <div class="col-lg-8">
                        <input
                          id="firstName"
                          type="text"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.firstName && touched.firstName
                              ? " is-invalid form-control"
                              : "form-control"
                          }
                        />
                        {errors.firstName && touched.firstName && (
                          <p className="error ml-2 mt-2">{errors.firstName}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="row form-group">
                      <label class="col-form-label required col-lg-4">
                        <span> Last Name *</span>
                      </label>
                      <div class="col-lg-8">
                        <input
                          id="lastName"
                          type="text"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.lastName && touched.lastName
                              ? " is-invalid form-control"
                              : "form-control"
                          }
                        />
                        {errors.lastName && touched.lastName && (
                          <p className="error ml-2 mt-2"> {errors.lastName}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-lg-6">
                    <div class="row form-group">
                      <label class="col-form-label required col-lg-4">
                        Email Address *
                      </label>
                      <div class="col-lg-8">
                        <input
                          id="email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? " is-invalid form-control"
                              : "form-control"
                          }
                        />
                        {errors.email && touched.email && (
                          <p className="error ml-2 mt-2">{errors.email}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="row form-group">
                      <label class="col-form-label required col-lg-4">
                        <span> Mobile Number *</span>
                      </label>
                      <div class="col-lg-8">
                        <input
                          id="mobile"
                          type="text"
                          value={values.mobile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.mobile && touched.mobile
                              ? " is-invalid form-control"
                              : "form-control"
                          }
                        />
                        {errors.mobile && touched.mobile && (
                          <p className="error ml-2 mt-2"> {errors.mobile}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-lg-6">
                    <div class="row form-group">
                      <label class="col-form-label required col-lg-4">
                        Language *
                      </label>
                      <div class="col-lg-8">
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="language"
                            name="language"
                            value={values.language}
                            onChange={handleChange}
                            row
                          >
                            <FormControlLabel
                              value="en"
                              control={<Radio />}
                              label="English"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="sw"
                              control={<Radio />}
                              label="Kiswahili"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-group">
                      <label class="col-form-label required col-lg-4">
                        <span> Role *</span>
                      </label>
                      <div class="col-lg-8">
                        <select
                          id="role"
                          name="role"
                          value={values.role}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.role && touched.role
                              ? " is-invalid form-control"
                              : "form-control"
                          }
                          required
                        >
                          <option disabled selected value>
                            -- select role --
                          </option>

                          {roles &&
                            roles.map((role) => (
                              <option value={role.name} key={role.id}>
                                {role.description}
                              </option>
                            ))}
                        </select>
                        {errors.role && touched.role && (
                          <p className="error ml-2 mt-2"> {errors.role}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-lg-6">
                    <div class="row form-group">
                      <label class="col-form-label required col-lg-4">
                        Identity Type *
                      </label>
                      <div class="col-lg-8">
                        <select
                          id="idType"
                          name="idType"
                          value={values.idType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.idType && touched.idType
                              ? "is-invalid form-control"
                              : "form-control"
                          }
                        >
                          <option disabled>-- select an option--</option>
                          <option value="national-id">National ID</option>
                          <option value="voters-id">Voters ID</option>
                          <option value="dire">DIRE</option>
                          <option value="employment-id">Employment ID</option>
                          <option value="Passport">Passport</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-group">
                      <label class="col-form-label required col-lg-4">
                        <span> ID Number *</span>
                      </label>
                      <div class="col-lg-8">
                        <Input
                          id="idNumber"
                          placeholder="Enter ID Number"
                          type="text"
                          value={values.idNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.idNumber && touched.idNumber
                              ? "is-invalid form-control"
                              : "form-control"
                          }
                        />
                        {errors.idNumber && touched.idNumber && (
                          <p className="error ml-2 mt-2"> {errors.idNumber}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-lg-6">
                    <div class="row form-group">
                      <label class="col-form-label required col-lg-4">
                        Physical Address *
                      </label>
                      <div class="col-lg-8">
                        <LocationPicker
                          center={coordinates}
                          height="0px"
                          zoom={9}
                          editMode={editMode}
                          address=""
                          showMap={false}
                        />
                      </div>
                    </div>
                  </div>

                  {values.role === "sales-representative" && (
                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span> Assign Verticals:</span>
                        </label>

                        <div class="col-lg-8">
                            <Select
                              value={selectedVerticals}
                              onChange={(selectedOption) => {
                                console.log(
                                  "selectedOption = ",
                                  selectedOption
                                );
                                setFieldValue("vertical", selectedOption);

                                setSelectedVerticals(selectedOption);
                              }}
                              isSearchable={true}
                              options={verticalOptions}
                              name="vertical"
                              isLoading={false}
                              loadingMessage={() => "Fetching vertical"}
                              noOptionsMessage={() => "No vertical option"}
                              isMulti
                            />
                          {errors.vertical && touched.vertical && (
                            <p className="error ml-2 mt-2">
                              {errors.vertical.label}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {values.role === "sales-representative" && (
                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span> Assign Verticals:</span>
                          <code>{JSON.stringify(verticalOptions)}</code>
                        </label>
                        <div class="col-lg-8">
                          <Select
                            value={selectedVerticals}
                            onChange={(value) => {
                              if (value && value.length > 1) {
                                if (value[value.length - 1].label === "All") {
                                  setFieldValue(
                                    "vertical",
                                    verticalOptions.filter(
                                      (option) => option.label === "All"
                                    )
                                  );

                                  setSelectedVerticals(
                                    value.filter(
                                      (option) => option.label === "All"
                                    )
                                  );
                                } else {
                                  setFieldValue(
                                    "vertical",
                                    verticalOptions.filter(
                                      (option) => option.label === "All"
                                    )
                                  );
                                  setSelectedVerticals(
                                    value.filter(
                                      (option) => option.label !== "All"
                                    )
                                  );
                                }
                              } else {
                                setFieldValue("vertical", value);
                                setSelectedVerticals(value);
                              }
                            }}
                            isSearchable={true}
                            options={verticalOptions}
                            name="vertical"
                            isLoading={false}
                            loadingMessage={() => "Fetching vertical"}
                            noOptionsMessage={() => "No vertical option"}
                            isMulti
                          />
                          {errors.vertical && touched.vertical && (
                            <p className="error ml-2 mt-2">
                              {errors.vertical.label}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <h3>Portrait</h3>
                  </div>
                  <div className="col-sm-12 ">
                    <ImageUploader success={imageUploaded} imgUrl={imgUrl} />
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3 mt-2">
                  <ButtonSmall type="submit" disabled={isSubmitting || double}>
                    Submit
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddUserForm;
