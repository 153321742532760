import {
  createOrder,
  getOrders,
  updateOrder,
  getOrderDetails,
  assignOrderDelivery,
  getOrderDeliveries,
  getPayments,
  failtToCreateOrder,
  getFailToOrderCustomers,
  getOrderSummary,
  getRouteOrders,
  getReturnedProducts,
  getRouteOrdersSummary,
  getCouldNotDeliverOrders,
  getReconSummary,
  receiveExpiredProducts,
  getOrderItemDiscountPrice,
  getOrderItemsDiscountPrice,
  triggerPhoneCall,
  changeOrderStatus,
  filterOrders,
  getAllOrdersSummary,
  getDailyOrders,
} from "../../services/OrderService";
import { handleErrorAction } from "./UtilsAction";
import {
  ACTION_FAIL,
  ACTION_SUCCESS,
  GET_ORDERS_SUCCESS,
  GET_ORDER_SUCCESS,
  GET_ORDER_DELIVERIES_SUCCESS,
  GET_PAYMENTS_SUCCESS,
  GET_FAIL_TO_ORDER_CUSTOMERS_SUCCESS,
  GET_DRIVER_ORDERS_SUCCESS,
  GET_ORDER_SUMMARY_SUCCESS,
  GET_ORDERS_IN_ROUTE_SUCCESS,
  GET_RETURNED_PRODUCTS_SUCCESS,
  GET_ROUTE_ORDERS_SUMMARY_SUCCESS,
  GET_EXTRA_ORDERS_SUCCESS,
  GET_FAIL_TO_DELIVER_ORDERS_SUCCESS,
  GET_RECON_SUMMARY_SUCCESS,
  GET_ORDER_ITEM_DSICOUNT_SUCCESS,
  GET_ORDER_ITEMS_DSICOUNT_SUCCESS,
  CLEAR_DISCOUNT,
  CALL_TRIGGER_SUCCESS,
  LOAD_CALL_CONNECTION,
  RESET_CALL_CONNECTION,
  ADD_ORDER_ITEMS,
  GET_ORDERED_PRODUCT_DETAILS_SUCCESS,
  GET_DAILY_ORDERS_SUCCESS,
  GET_ALL_ORDER_SUMMARY_SUCCESS,
} from "../constants/ActionTypes";
import _, { isEmpty } from "lodash";
import moment from "moment";
import { getProductsByIds } from "../../services/ProductService";
import { isInteger } from "formik";

export const createOrderAction = (data) => {
  return function (dispatch) {
    return createOrder(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const updateOrderAction = (data) => {
  return function (dispatch) {
    return updateOrder(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getDailyOrdersAction = (data) => {
  return (dispatch) => {
    return getDailyOrders(data).then(
      (response) => {
        if (response.data && response.status === 200) {
          dispatch({
            type: GET_DAILY_ORDERS_SUCCESS,
            payload: response.data ?? [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOrderItemDiscountPriceAction = (data, index) => {
  return (dispatch) => {
    return getOrderItemDiscountPrice(data).then(
      (response) => {
        if (response.status === 200) {
          if (response.data) {
            return dispatch({
              type: GET_ORDER_ITEM_DSICOUNT_SUCCESS,
              payload: { key: index, data: response.data },
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOrderedProductsDetailsAction = (productIds) => {
  return (dispatch) => {
    return getProductsByIds(productIds).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({
            type: GET_ORDERED_PRODUCT_DETAILS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOrderItemsDiscountPriceAction = (data, products) => {
  return (dispatch) => {
    return getOrderItemsDiscountPrice(data).then(
      (response) => {
        if (response.status === 200) {
          if (response.data) {
            if (response.data.responseObject) {
              let itemsArr = Array.isArray(response.data.responseObject)
                ? response.data.responseObject
                : [response.data.responseObject];
              let itemsObj = {};
              itemsArr.map((item) => {
                let index = _.findIndex(products, [
                  "productNumber",
                  item.productNumber,
                ]);
                if (index !== -1) {
                  itemsObj[index] = item;
                }
              });

              return dispatch({
                type: GET_ORDER_ITEMS_DSICOUNT_SUCCESS,
                payload: itemsObj,
              });
            }
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

// export const updateOrderAction = (data) => {
//   return function (dispatch) {
//     return updateOrder(data).then(
//       (response) =>
//         dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
//       (error) => dispatch(handleErrorAction(error))
//     );
//   };
// };

export const changeOrderStatusAction = (data) => {
  return function (dispatch) {
    return changeOrderStatus(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOrdersAction = (data) => {
  return (dispatch) => {
    return getOrders(data).then(
      (response) => {
        if (response.data.code === 200) {
          let orders = response.data.responseObject
            ? response.data.responseObject
            : [];

          return dispatch({
            type: GET_ORDERS_SUCCESS,
            payload: orders,
          });
        } else {
          return dispatch({
            type: GET_ORDERS_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const filterOrdersAction = (data) => {
  return (dispatch) => {
    return filterOrders(data).then(
      (response) => {
        if (response.data.code === 200) {
          let orders = response.data.responseObject ?? [];

          return dispatch({
            type: GET_ORDERS_SUCCESS,
            payload: orders,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getExtraOrdersAction = (data) => {
  return (dispatch) => {
    return getOrders(data).then(
      (response) => {
        if (response.data.code === "200") {
          let orders = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          return dispatch({
            type: GET_EXTRA_ORDERS_SUCCESS,
            payload: orders,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getRouteOrdersSummaryAction = (data) => {
  return (dispatch) => {
    return getRouteOrdersSummary(data).then(
      (response) => {
        if (response.data.code === "200") {
          let orders = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          return dispatch({
            type: GET_ROUTE_ORDERS_SUMMARY_SUCCESS,
            payload: orders,
          });
        }
        if (response.data.code === "400") {
          return dispatch({
            type: GET_ROUTE_ORDERS_SUMMARY_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getDriverOrdersAction = (data) => {
  return (dispatch) => {
    return getOrders(data).then(
      (response) => {
        if (response.data.code === "200") {
          let orders = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          return dispatch({
            type: GET_DRIVER_ORDERS_SUCCESS,
            payload: orders,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOrderDetailsAction = (data) => {
  return (dispatch) => {
    return getOrderDetails(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_ORDER_SUCCESS,
            payload: response.data.responseObject,
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_ORDER_SUCCESS,
            payload: {},
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const assignOrderDeliveryAction = (data) => {
  return function (dispatch) {
    return assignOrderDelivery(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOrderDeliveriesAction = (data) => {
  return (dispatch) => {
    return getOrderDeliveries(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_ORDER_DELIVERIES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_ORDER_DELIVERIES_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getPaymentsAction = (data) => {
  return (dispatch) => {
    return getPayments(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_PAYMENTS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_PAYMENTS_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const reportFailToCreateOrderAction = (data) => {
  return function (dispatch) {
    return failtToCreateOrder(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getFailToOrderCustomersAction = (data) => {
  return (dispatch) => {
    return getFailToOrderCustomers(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_FAIL_TO_ORDER_CUSTOMERS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_FAIL_TO_ORDER_CUSTOMERS_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOrderSummaryAction = (data) => {
  return (dispatch) => {
    return getOrderSummary(data).then(
      (response) => {
        if (response.status === 200) {
          let dataObj = {};
          let orderSummary = _.flatten(response.data ?? []);
          orderSummary = orderSummary.map((order) => ({
            placed: order.placed,
            processed: order.processed,
          }));
          let aggregateSummary = orderSummary.reduce((accumulator, item) => {
            Object.keys(item).forEach((key) => {
              accumulator[key] = (accumulator[key] || 0) + item[key];
            });
            return accumulator;
          }, {});

          aggregateSummary["total"] =
            aggregateSummary.placed + aggregateSummary.processed;

          dispatch({
            type: GET_ORDER_SUMMARY_SUCCESS,
            payload: aggregateSummary,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getAllOrdersSummaryAction = (data) => {
  return (dispatch) => {
    return getAllOrdersSummary(data).then(
      (response) => {
        console.log(response);
        if (response.status === 200) {
          let dataObj = {};
          let orderSummary = response.data ?? [];
          let total = 0;
          orderSummary.map((summary) => {
            dataObj[summary.status.toLowerCase()] = summary.count;
            total += summary.count;
          });
          dataObj["total"] = total;

          dispatch({
            type: GET_ALL_ORDER_SUMMARY_SUCCESS,
            payload: dataObj,
          });
        }
        if (response.data.code === "400") {
          return dispatch({
            type: GET_ORDERS_IN_ROUTE_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getRouteOrdersAction = (data) => {
  return (dispatch) => {
    return getRouteOrders(data).then(
      (response) => {
        if (response.data.code === "200") {
          let orders = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          return dispatch({
            type: GET_ORDERS_IN_ROUTE_SUCCESS,
            payload: orders,
          });
        }
        if (response.data.code === "400") {
          return dispatch({
            type: GET_ORDERS_IN_ROUTE_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getReturnedProductsAction = (data) => {
  return (dispatch) => {
    return getReturnedProducts(data).then(
      (response) => {
        if (response.data.code === "200") {
          let products = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          return dispatch({
            type: GET_RETURNED_PRODUCTS_SUCCESS,
            payload: products,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getCouldNotDeliverOrdersAction = (data) => {
  return (dispatch) => {
    return getCouldNotDeliverOrders(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_FAIL_TO_DELIVER_ORDERS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_FAIL_TO_DELIVER_ORDERS_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getReconSummaryAction = (params) => {
  return (dispatch) => {
    return getReconSummary(params).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_RECON_SUMMARY_SUCCESS,
            payload: response.data.responseObject,
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_RECON_SUMMARY_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const triggerPhoneCallAction = (params) => {
  return (dispatch) => {
    return triggerPhoneCall(params).then(
      (response) => {
        if (response.data.status === 200) {
          dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }
        if (response.data.status === 400) {
          dispatch({
            type: ACTION_FAIL,
            payload: response.data.desc,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const loadCallConnectionAction = () => {
  return (dispatch) => {
    return dispatch({
      type: LOAD_CALL_CONNECTION,
    });
  };
};

export const resetCallConnectionAction = () => {
  return (dispatch) => {
    return dispatch({
      type: RESET_CALL_CONNECTION,
    });
  };
};

export const receiveExpiredProductsAction = (data) => {
  return (dispatch) => {
    return receiveExpiredProducts(data).then(
      (response) => {
        if (response.data.code === "200") {
          return dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }
        if (response.data.code === "400") {
          return dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const clearDiscountsAction = (data, index) => {
  return (dispatch) => {
    return dispatch({
      type: CLEAR_DISCOUNT,
      payload: {},
    });
  };
};
