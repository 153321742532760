import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { FormWrapper, ButtonSmall } from "../../styles/Forms";
import { useDispatch, useSelector } from "react-redux";
import {
  addOutletAction,
  getOutletCategoryOptionsAction,
  getOutletsAction,
  getOutletSubcategoryOptionsAction,
  updateOutletAction,
} from "../../store/actions/OutletAction";
import { resetUtils } from "../../store/actions/UtilsAction";
import ImageUploader from "../../components/utils/ImageUploader";
import OutletsPage from "./OutletsPage";
import Select from "react-select";
import * as Yup from "yup";
import _ from "lodash";
import { reactSelect } from "../../styles/App";
import { scrollToTop } from "../../utils/window";
import LocationPicker from "../../components/location/LocationPicker/LocationPicker";
import { getVerticalOptionsAction } from "../../store/actions/ProductAction";

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AddOutletForm = ({ editMode, outlet, resetView }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedVertical, setSelectedVertical] = useState("");
  const [isSubcategoryEnabled, setIsSubcategoryEnabled] = useState(true);
  const outletCategoryOptions = useSelector(
    (state) => state.outletManager.outletCategoryOptions
  );
  const outletSubcategoryOptions = useSelector(
    (state) => state.outletManager.outletSubcategoryOptions
  );
  const [coordinates] = useState({
    lat: -6.7430267,
    lng: 39.2789343,
  });

  const userObj = useSelector((state) => state.authManager.user);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const driver = useSelector((state) => state.userManager.username);
  const message = useSelector((state) => state.stateManager.message);
  const markerPosition = useSelector(
    (state) => state.locationManager.markerPosition
  );
  const address = useSelector((state) => state.locationManager.address);
  const verticalOptions = useSelector(
    (state) => state.productManager.verticalOptions
  );

  const imageUploadSuccess = useSelector(
    (state) => state.utilsManager.imageUploadSuccess
  );
  var imgUrl = useSelector((state) => state.utilsManager.imgUrl);
  imgUrl =
    !imageUploadSuccess && editMode
      ? outlet.images.length > 0 && outlet.images[0].path
      : imgUrl;

  const [showOutletsPage, setShowOutletsPage] = useState(false);
  const dispatch = useDispatch();
  const formikRef = useRef();

  useEffect(() => {
    if (success) {
      resetView();
      return () => setTimeout(() => dispatch(getOutletsAction({})), 10000);
    }
    if (error) {
      scrollToTop();
    }
  }, [success, error]);

  useEffect(() => {
    dispatch(resetUtils());
    dispatch(getOutletCategoryOptionsAction());
    dispatch(getVerticalOptionsAction());
    if (editMode) {
      console.log("EditMode true outlet   = ", outlet);
    }
  }, []);

  useEffect(() => {
    setCategoryOptions(outletCategoryOptions);
    setSubcategoryOptions(outletSubcategoryOptions);

    if (editMode) {
      console.log(
        "Check Selected Outlet Category outletCategoryOptions +  outlet.categoryId= ",
        outletCategoryOptions,
        outlet.categoryId
      );
      let selected = outletCategoryOptions.filter(
        (cat) => cat.value === outlet.categoryId
      );

      if (selected && selected.length > 0) {
        setSelectedCategory(selected[0]);
        if (formikRef.current) {
          formikRef.current.setFieldValue("category", selected[0]);
        }
        console.log("Selected  = ", selected[0]);
      }
    }
  }, [outletCategoryOptions]);

  if (showOutletsPage) {
    return <OutletsPage />;
  }
  return (
    <div>
      <div className="form-group ">
        <Formik
          innerRef={formikRef}
          initialValues={{
            id: editMode ? outlet.id : 0,
            category: selectedCategory,
            name: editMode ? outlet.name : "",
            owner: editMode ? outlet.owner : "",
            ownerPhone: editMode ? outlet.ownerPhone : "",
            phone: editMode ? outlet.phone : "",
            taxNumber: editMode ? outlet.taxNumber : "",
            vertical: selectedVertical,
            comments: editMode ? outlet.comments : "",
            createdBy: userObj.username,
            updatedBy: editMode ? userObj.username : "",
            username: userObj.username,
            image: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              var formData = _.clone(values);
              formData["category"] = values.category.value;
              let verticals =
                values.vertical === ""
                  ? verticalOptions.map((v) => v.value)
                  : values.vertical.map((vert) => vert.value);
              formData = _.omit(formData, "vertical");
              formData["latitude"] = markerPosition ? markerPosition.lat : 0;
              formData["longitude"] = markerPosition ? markerPosition.lng : 0;
              formData["address"] = address;
              formData["images"] = [
                {
                  path: imgUrl,
                  title: values.name,
                  metadata: "{}",
                  createdBy: userObj.username,
                },
              ];

              console.log(formData);

              if (editMode) {
                formData["id"] = outlet.id;
                console.log(formData);
                dispatch(
                  updateOutletAction(formData, verticals, outlet.outletNumber)
                );
              } else {
                console.log(formData);
                dispatch(addOutletAction(formData, verticals));
              }

              setSubmitting(false);
            }, 500);
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Outlet Name is required "),
            // owner: Yup.string().required("Outlet Owner Name is required "),
            // phone: Yup.string()
            //   .matches(phoneRegExp, "Phone number is not valid")
            //   .min(9)
            //   .max(14)
            //   .required("outlet phone number is required"),
            // ownerPhone: Yup.string()
            //   .matches(phoneRegExp, "Phone number is not valid")
            //   .min(9)
            //   .max(14)
            //   .required("outlet owner phone number is required"),
            category: Yup.object().shape({
              label: Yup.string().required("You must select category"),
            }),
            // taxNumber: Yup.string()
            //   .min(9)
            //   .max(9)
            //   .required("TIN Number is required"),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              setFieldValue,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <div>
                {error && <p className="alert alert-danger">{message}</p>}
                {/* <code>{JSON.stringify(errors)}</code> */}
                {/* <code>{JSON.stringify(userRoute)}</code>  */}
                {/* <code>{JSON.stringify(errors)}</code> */}

                <FormWrapper>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="row">
                      <div class="col-lg-6">
                        <div class="row form-group">
                          <label class="col-form-label required col-lg-4">
                            <span>Outlet Name *</span>
                          </label>
                          <div class="col-lg-8">
                            <input
                              id="name"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.name && touched.name
                                  ? " is-invalid form-control"
                                  : "form-control"
                              }
                            />
                            {errors.name && touched.name && (
                              <p className="error ml-2 mt-2"> {errors.name}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="row form-group">
                          <label class="col-form-label required col-lg-4">
                            <span
                              id="ember669"
                              class="tooltip-container text-dashed-underline ember-view"
                            >
                              Type *
                            </span>
                          </label>{" "}
                          <div class="col-lg-8">
                            <Select
                              id="category"
                              value={selectedCategory}
                              onChange={(value) => {
                                let filteredOptions = outletSubcategoryOptions.filter(
                                  (option) => option.parent === value.value
                                );
                                //console.log("filteredOptions = ", filteredOptions);
                                setFieldValue("category", value);
                                setFieldValue("subcategory", null);
                                setSubcategoryOptions(filteredOptions);
                                setSelectedCategory(value);
                                setIsSubcategoryEnabled(false);
                              }}
                              isSearchable={true}
                              options={outletCategoryOptions}
                              name="category"
                              isLoading={false}
                              noOptionsMessage={() => "No categories found"}
                            />

                            {errors.category && touched.category && (
                              <p className="error ml-2 mt-2">
                                {errors.category.label}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-lg-6">
                        <div class="row form-group">
                          <label class="col-form-label required col-lg-4">
                            <span
                              id="ember669"
                              class="tooltip-container text-dashed-underline ember-view"
                            >
                              Vertical *
                            </span>
                          </label>{" "}
                          <div class="col-lg-8">
                            <Select
                              value={selectedVertical}
                              placeholder="All selected"
                              onChange={(selectedOption) => {
                                console.log(
                                  "selectedOption = ",
                                  selectedOption
                                );
                                setFieldValue("vertical", selectedOption);

                                setSelectedVertical(selectedOption);
                              }}
                              isSearchable={true}
                              options={verticalOptions}
                              name="vertical"
                              isLoading={false}
                              loadingMessage={() => "Fetching vertical"}
                              noOptionsMessage={() => "No vertical option"}
                              isMulti
                            />
                            {errors.vertical && touched.vertical && (
                              <p className="error ml-2 mt-2">
                                {errors.vertical.label}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="row form-group">
                          <label class="col-form-label required col-lg-4">
                            <span> Phone Number *</span>
                          </label>
                          <div class="col-lg-8">
                            <input
                              id="phone"
                              type="text"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.phone && touched.phone
                                  ? " is-invalid form-control"
                                  : "form-control"
                              }
                            />
                            {errors.phone && touched.phone && (
                              <p className="error ml-2 mt-2"> {errors.phone}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-lg-6">
                        <div class="row form-group">
                          <label class="col-form-label required col-lg-4">
                            <span
                              id="ember669"
                              class="tooltip-container text-dashed-underline ember-view"
                            >
                              Owner Name: *
                            </span>
                          </label>{" "}
                          <div class="col-lg-8">
                            <input
                              id="owner"
                              outletName="Outlet Owner"
                              type="text"
                              value={values.owner}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.owner && touched.owner
                                  ? "is-invalid form-control"
                                  : "form-control"
                              }
                            />
                            {errors.owner && touched.owner && (
                              <p className=" mt-2 ml-2 error">
                                {" "}
                                {errors.owner}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="row form-group">
                          <label class="col-form-label required col-lg-4">
                            <span> Owner Mobile </span>
                          </label>
                          <div class="col-lg-8">
                            <input
                              id="ownerPhone"
                              type="text"
                              value={values.ownerPhone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.ownerPhone && touched.ownerPhone
                                  ? " is-invalid form-control"
                                  : "form-control"
                              }
                            />
                            {errors.ownerPhone && touched.ownerPhone && (
                              <p className="error ml-2 mt-2">
                                {" "}
                                {errors.ownerPhone}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-lg-6">
                        <div class="row form-group">
                          <label class="col-form-label required col-lg-4">
                            <span> TIN Number* </span>
                          </label>
                          <div class="col-lg-8">
                            <input
                              id="taxNumber"
                              type="text"
                              value={values.taxNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.taxNumber && touched.taxNumber
                                  ? "is-invalid form-control"
                                  : "form-control"
                              }
                            />
                            {errors.taxNumber && touched.taxNumber && (
                              <p className="error ml-2 mt-2">
                                {errors.taxNumber}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="row form-group">
                          <label class="col-form-label required col-lg-4">
                            <span> Any additional Information </span>
                          </label>
                          <div class="col-lg-8">
                            <input
                              id="comments"
                              type="text"
                              value={values.comments}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.comments && touched.comments
                                  ? "is-invalid form-control"
                                  : "form-control"
                              }
                            />
                            {errors.comments && touched.comments && (
                              <p className="error ml-2 mt-2">
                                {errors.comments}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div class="row form-group">
                          <label class="col-form-label required col-lg-4">
                            Outlet Location: *
                          </label>{" "}
                          <div class="col-lg-8">
                            <LocationPicker
                              center={coordinates}
                              height="300px"
                              zoom={9}
                              editMode={editMode}
                              address={outlet.address}
                              showMap={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <h3>Image upload</h3>
                      </div>
                      <div className="col-sm-12 ">
                        <ImageUploader
                          success={imageUploadSuccess}
                          imgUrl={imgUrl}
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-12 mb-3">
                      <ButtonSmall
                        type="submit"
                        className="mt-2"
                        disabled={isSubmitting}
                        onClick={() => {
                          scrollToTop();
                        }}
                      >
                        Submit
                      </ButtonSmall>
                    </div>
                  </form>
                </FormWrapper>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddOutletForm;
