import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/dom/Table/Table";
import { ButtonWithIcon } from "../../styles/Forms";
import Tile from "../../components/dom/Tile";
import moment from "moment";
import {
  getOrdersAction,
  getOrderSummaryAction,
  getRouteOrdersSummaryAction,
} from "../../store/actions/OrderAction";

const DistributionOrderTablePage = ({ viewOrders, viewExras, shift }) => {
  const orders = useSelector((state) => state.OrderManager.routeOrdersSummary);
  const summary = useSelector((state) => state.OrderManager.orderSummary);
  const dispatch = useDispatch();

  useEffect(() => {
    getOrders(shift);
    getOrdersSummary(shift);
  }, []);

  const getOrders = (shift) => {
    let routeOrderFilter = {
      orderDir: "desc",
      orderBy: "id",
      searches: [
        {
          key: "shift",
          value: shift,
        },
        {
          key: "deliveryDateInt",
          value: moment().format("YYYYMMDD"),
        },
      ],
    };
    let orderFilter = {
      orderDir: "desc",
      orderBy: "id",
      searches: [
        {
          key: "orderType",
          value: "DRIVER",
        },
        {
          key: "shift",
          value: shift,
        },
        {
          key: "deliveryDateInt",
          value: moment().format("YYYYMMDD"),
        },
      ],
    };
    dispatch(getOrdersAction(orderFilter));
    dispatch(getRouteOrdersSummaryAction(routeOrderFilter));
  };

  const getOrdersSummary = (shift) => {
    let orderFilter = {
      shift,
      date: moment().format("YYYYMMDD"),
    };
    dispatch(getOrderSummaryAction(orderFilter));
  };
  const columns = [
    {
      Header: "Driver",
      accessor: "driver",
      key:["driver"]
    },
    {
      Header: "Route",
      accessor: "routeId",
      key:["routeId"]
    },
    {
      Header: "Orders",

      Cell: (row) => (
        <ButtonWithIcon
          className="primary"
          onClick={() => viewOrders(row.row.original)}
        >
          View Orders
        </ButtonWithIcon>
      ),
    },
    {
      Header: "EXTRAS",

      Cell: (row) => (
        <ButtonWithIcon
          className="primary"
          onClick={() => viewExras(row.row.original)}
        >
          View Extras
        </ButtonWithIcon>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <Tile
          title="total Orders"
          inlineStyle="col-sm-3"
          value={summary.orders ? summary.orders : 0}
        />
        <Tile
          title="ready for consumption"
          inlineStyle="col-sm-3"
          value={summary.readyProducts ? summary.readyProducts : 0}
        />
        <Tile
          title="Damaged Goods"
          inlineStyle="col-sm-3"
          value={summary.damagedProducts ? summary.damagedProducts : 0}
        />
        <Tile
          title="Extras Goods"
          inlineStyle="col-sm-3"
          value={summary.extras ? summary.extras : 0}
        />
      </div>

      {orders && orders.length > 0 && <Table data={orders} columns={columns} />}
      {orders && orders.length === 0 && (
        <p className="alert alert-info">No orders at the moment</p>
      )}
    </>
  );
};

export default DistributionOrderTablePage;
