import React, { useEffect } from "react";
import {
  addPermissionAction,
  editPermissionAction,
  getPermissionsAction,
} from "../../store/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  FormWrapper,
  Input,
  Label,
  Select,
  InputWrapper,
  ButtonSmall,
} from "../../styles/Forms";

const AddPermissionForm = ({ editMode, permission, resetView }) => {
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);

  useEffect(() => {
    if (success) {
      resetView();
    }
  });

  useEffect(() => {
    return () => setTimeout(() => dispatch(getPermissionsAction()), 10000);
  });

  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        name: editMode ? permission.name : "",
        description: editMode ? permission.description : "",
        category: editMode ? permission.category : "USER_MANAGEMENT",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          if (editMode) {
            values["id"] = permission.id;
            //console.log(values);
            dispatch(editPermissionAction(values));
          } else {
            // console.log(values);
            dispatch(addPermissionAction(values));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Permission Name is required "),
        description: Yup.string().required(
          "Permission description is required "
        ),
        category: Yup.string().required("Permission category is required "),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div>
            {error && <p className="alert alert-danger">{message}</p>}
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="form-row">
                <div className="form-group col-md-12 mb-3">
                  <Label htmlFor="firstName">Permission Name</Label>
                  <Input
                    id="name"
                    placeholder="Enter Permission Name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.name && touched.name
                        ? "text-input form-control is-invalid"
                        : "text-input form-control"
                    }
                    disabled={editMode}
                  />
                  {errors.name && touched.name && (
                    <p className="error mt-2 ml-2"> {errors.name}</p>
                  )}
                </div>
                <div className="form-group col-md-12 mb-3">
                  <Label htmlFor="lastName">Permission Description </Label>
                  <Input
                    id="description"
                    placeholder="Enter permission description"
                    type="text"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.description && touched.description
                        ? "text-input form-control is-invalid"
                        : "text-input form-control"
                    }
                  />
                  {errors.description && touched.description && (
                    <p className="error mt-2 ml-2"> {errors.description}</p>
                  )}
                </div>

                <div className="form-group col-md-12 mb-3">
                  <Label htmlFor="idType">Category</Label>
                  <Select
                    id="category"
                    name="category"
                    value={values.category}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.category && touched.category
                        ? "text-input form-control is-invalid"
                        : "text-input form-control"
                    }
                  >
                    <option disabled>-- select an option--</option>
                    <option value="USER_MANAGEMENT">User Management</option>
                    <option value="SALES_MANAGEMENT">Sales Management</option>
                    <option value="ORDER_MANAGEMENT">Order Management</option>
                    <option value="OUTLETS_MANAGEMENT">
                      Outlets Management
                    </option>
                    <option value="CALL_CYCLE_MANAGEMENT">
                      Call Cycle Management
                    </option>
                    <option value="TERRITORY_MANAGEMENT">
                      Territory Management
                    </option>
                    <option value="SUPPLIER_MANAGEMENT">
                      Supplier Management
                    </option>

                    <option value="REPORTING">Reporting </option>

                    <option value="PRODUCT_MANAGEMENT">
                      Product Management
                    </option>
                  </Select>

                  {errors.category && touched.category && (
                    <p className="error ml-2 mt-2"> {errors.category}</p>
                  )}
                </div>
              </div>

              <div className="form-group col-md-12 mb-3">
                <ButtonSmall type="submit" disabled={isSubmitting}>
                  Submit
                </ButtonSmall>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddPermissionForm;
