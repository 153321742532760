import React, { useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const SurveyDetails = ({ data,reset }) => {
  const [survey, setSurvey] = useState({});
  useEffect(() => {
    console.log(data)
    delete data["@type"];
    data["date"] = moment(data["date"]).format("LLL");
    
    setSurvey(data);
  }, []);
  return (
    <>
    
      <h3>  <FontAwesomeIcon
          icon={faArrowLeft}
          size="1x"
          className="mr-2 primary"
          onClick={() => reset()}

        />Survey details for {survey.truckName}</h3>
      {survey&&Object.keys(survey).map((key, index) => {
        const item = data[key];

        return (
          <div key={index} className="row mb-3">
            <div className="col-sm-3">
              <strong>{key}</strong>
            </div>
            <div className="col-sm-9">{item}</div>
          </div>
        );
      })}
    </>
  );
};

export default SurveyDetails;
