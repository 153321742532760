import {
  TOGGLE_TERRITORY_FORM,
  SET_TERRITORY_COORDIBATES,
  GET_TERRITORIES_SUCCESS,
  GET_TERRITORY_OPTIONS_SUCCESS,
  UPDATE_TERRITORY_COORDIBATES,
  GET_TERRITORY_POLYGONS_SUCCESS,
} from "../constants/ActionTypes";
const initialState = {
  coordinates: [],
  canShowTerritoryCreationForm: false,
  territories: [],
  territoryOptions: [],
  territoryPolygons: [],
};

function TerritoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TERRITORY_COORDIBATES:
      return {
        ...state,
        coordinates: action.payload,
        canShowTerritoryCreationForm: true,
      };

    case TOGGLE_TERRITORY_FORM:
      return {
        ...state,
        canShowTerritoryCreationForm: action.payload,
      };
    case GET_TERRITORY_POLYGONS_SUCCESS:
      return {
        ...state,
        territoryPolygons: action.payload,
      };
    case GET_TERRITORIES_SUCCESS:
      return {
        ...state,
        territories: action.payload,
      };
    case GET_TERRITORY_OPTIONS_SUCCESS:
      return {
        ...state,
        territoryOptions: action.payload,
      };
    case UPDATE_TERRITORY_COORDIBATES:
      return {
        ...state,
        coordinates: action.payload,
        canShowTerritoryCreationForm: false,
      };

    default:
      return state;
  }
}

export default TerritoryReducer;
