import {
  createProduct,
  createProductCategory,
  getProductCategories,
  getProducts,
  editProduct,
  updateProductCategory,
  setCutOffTime,
  setOutletProductPrice,
  createProductSubcategory,
  updateProductSubcategory,
  getProductSubcategories,
  createTaxCode,
  updateTaxCode,
  getTaxCodes,
  createProductSKU,
  addProductVertical,
  getProductVerticals,
  createProductPackagingUnit,
  getPackagingUnit,
  createProductMeasuringUnit,
  getProductMeasuringUnits,
  uploadProducts,
  uploadS3Excel,
  uploadProductsZip,
  deleteProduct,
  getProductsByIds,
  changeProductVisibility,
  bulkEdit,
  bulkEditFileUpload,
  updateProductPackagingUnit,
  deleteProducts,
  getAllProducts,
} from "../../services/ProductService";
import { handleErrorAction } from "./UtilsAction";
import {
  ACTION_FAIL,
  ACTION_SUCCESS,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_OPTIONS_SUCCESS,
  GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS,
  GET_PRODUCT_SUBCATEGORIES_SUCCESS,
  GET_PRODUCT_SUBCATEGORY_OPTIONS_SUCCESS,
  GET_TAX_CODES_SUCCESS,
  GET_TAX_CODE_OPTIONS_SUCCESS,
  ADD_ORDER_ITEMS,
  ADD_PRODUCT_SUCCESS,
  GET_PACKAGING_UNIT_SUCCESS,
  GET_PRODUCT_VERTICALS_SUCCESS,
  GET_PACKAGING_UNIT_OPTIONS_SUCCESS,
  GET_MEASURING_UNITS_SUCCESS,
  GET_MEASUREMENT_UNIT_OPTIONS_SUCCESS,
  GET_PRODUCT_VERTICAL_OPTIONS_SUCCESS,
  START_LOADING,
  S3_UPLOAD_STARTED,
  RESET_ORDER_ITEMS,
  BULK_EDIT_SUCCESS,
  REMOVE_ORDER_ITEM,
} from "../constants/ActionTypes";

export const getProductsAction = (data) => {
  return (dispatch) => {
    dispatch({ type: START_LOADING });
    return getProducts(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({
            type: GET_PRODUCTS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getAllProductsAction = (data) => {
  return (dispatch) => {
    dispatch({ type: START_LOADING });
    return getAllProducts(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({
            type: GET_PRODUCTS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addProductInfoAction = (data) => {
  return (dispatch) => {
    return dispatch({
      type: ADD_PRODUCT_SUCCESS,
      payload: data,
    });
  };
};

export const resetOrderItemsAction = (data) => {
  return (dispatch) => {
    return dispatch({
      type: RESET_ORDER_ITEMS,
    });
  };
};

export const uploadProductsAction = (file, fileName) => {
  return function (dispatch) {
    dispatch({
      type: S3_UPLOAD_STARTED,
      payload: "Products are being uploaded,please wait....",
    });

    return uploadProductsZip(file, fileName).then(
      (response) => {
        console.log(response);
        if (response.data.code === 200) {
          dispatch({
            type: ACTION_SUCCESS,
            payload: "Products have been added successfully",
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const uploadEditProductsAction = (file, fileName) => {
  return function (dispatch) {
    dispatch({
      type: S3_UPLOAD_STARTED,
      payload: "Products are being uploaded,please wait....",
    });
    return bulkEditFileUpload(file, fileName).then(
      (response) => {
        if (response.data.code === 200) {
          dispatch({
            type: ACTION_SUCCESS,
            payload: "Products have been edited successfully",
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const uploadS3ExcelAction = (data) => {
  return function (dispatch) {
    return uploadS3Excel(data).then(
      (response) =>
        dispatch({
          type: ACTION_SUCCESS,
          payload: "Products have been updated successfully",
        }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addProductAction = (data) => {
  return function (dispatch) {
    return createProduct(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const deleteProductAction = (productId) => {
  return function (dispatch) {
    return deleteProduct(productId).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const deleteProductsAction = (data) => {
  return function (dispatch) {
    return deleteProducts(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const changeProductVisibilityAction = (data) => {
  return function (dispatch) {
    return changeProductVisibility(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};
export const addProductSKUAction = (data) => {
  return function (dispatch) {
    return createProductSKU(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const editProductAction = (data) => {
  return function (dispatch) {
    return editProduct(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const bulkEditAction = (data) => {
  return function (dispatch) {
    return bulkEdit(data).then(
      (response) => {
        let href = response.data.responseObject;
        dispatch({
          type: BULK_EDIT_SUCCESS,
          payload: { message: response.data.message, link: href },
        });
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addProductVerticalAction = (data) => {
  return function (dispatch) {
    return addProductVertical(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addProductPackagingUnitAction = (data) => {
  return function (dispatch) {
    return createProductPackagingUnit(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const updateProductPackagingUnitAction = (data) => {
  return function (dispatch) {
    return updateProductPackagingUnit(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addProductMeasuringUnitAction = (data) => {
  return function (dispatch) {
    return createProductMeasuringUnit(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addProductCategoryAction = (data) => {
  return function (dispatch) {
    return createProductCategory(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({ type: ACTION_SUCCESS, payload: response.data.message });
        }
      },
      (error) =>
        dispatch({
          type: ACTION_FAIL,
          payload:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Oops! Somthing went wrong",
        })
    );
  };
};

export const updateProductCategoryAction = (data) => {
  return function (dispatch) {
    return updateProductCategory(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) =>
        dispatch({
          type: ACTION_FAIL,
          payload:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Oops! Somthing went wrong",
        })
    );
  };
};

export const updateProductSubcategoryAction = (data) => {
  return function (dispatch) {
    return updateProductSubcategory(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) =>
        dispatch({
          type: ACTION_FAIL,
          payload:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Oops! Somthing went wrong",
        })
    );
  };
};

export const addProductSubcategoryAction = (data) => {
  return function (dispatch) {
    return createProductSubcategory(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({ type: ACTION_SUCCESS, payload: response.data.message });
        }
      },
      (error) =>
        dispatch({
          type: ACTION_FAIL,
          payload:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Oops! Somthing went wrong",
        })
    );
  };
};

export const getCategoriesAction = () => {
  return (dispatch) => {
    return getProductCategories().then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_PRODUCT_CATEGORIES_SUCCESS,
            payload: response.data.responseObject ?? [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getVerticalsAction = () => {
  return (dispatch) => {
    return getProductVerticals().then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_PRODUCT_VERTICALS_SUCCESS,
            payload: response.data.responseObject ?? [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getVerticalOptionsAction = () => {
  return (dispatch) => {
    return getProductVerticals().then(
      (response) => {
        if (response.status === 200) {
          let options = response.data.responseObject ?? [];
          options = options.map((option) => ({
            label: option.name,
            value: option.id,
          }));
          dispatch({
            type: GET_PRODUCT_VERTICAL_OPTIONS_SUCCESS,
            payload: options,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getProductMeasuringUnitsAction = () => {
  return (dispatch) => {
    return getProductMeasuringUnits().then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_MEASURING_UNITS_SUCCESS,
            payload: response.data.responseObject ?? [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getPackagingUnitAction = () => {
  return (dispatch) => {
    return getPackagingUnit().then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_PACKAGING_UNIT_SUCCESS,
            payload: response.data.responseObject ?? [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getPackagingUnitOptionsAction = () => {
  return (dispatch) => {
    return getPackagingUnit().then(
      (response) => {
        if (response.status === 200) {
          let options = response.data.responseObject ?? [];
          options = options.map((option) => ({
            label: option.name,
            value: option.id,
            quantity: option.quantity,
          }));
          dispatch({
            type: GET_PACKAGING_UNIT_OPTIONS_SUCCESS,
            payload: options,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getMeasurementUnitOptionsAction = () => {
  return (dispatch) => {
    return getProductMeasuringUnits().then(
      (response) => {
        if (response.status === 200) {
          let options = response.data.responseObject ?? [];
          options = options.map((option) => ({
            label: option.name,
            value: option.id,
          }));
          dispatch({
            type: GET_MEASUREMENT_UNIT_OPTIONS_SUCCESS,
            payload: options,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getProductCategoryOptionsAction = () => {
  return (dispatch) => {
    return getProductCategories().then(
      (response) => {
        if (response.data.responseObject) {
          let categories = response.data.responseObject;

          let categoryOptions = categories.map((category) => ({
            value: category.id,
            label: category.name,
            verticalId: category.verticalId,
          }));
          //console.log("categoryOptions = ", categoryOptions);
          dispatch({
            type: GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS,
            payload: categoryOptions,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getProductSubcategoryOptionsAction = () => {
  return (dispatch) => {
    return getProductSubcategories().then(
      (response) => {
        if (response.data.responseObject) {
          let categories = response.data.responseObject;

          let categoryOptions = categories.map((category) => ({
            value: category.id,
            label: category.description,
            categoryId: category.mainCategoryId,
          }));
          console.log("subcategoryOptions = ", categoryOptions);
          dispatch({
            type: GET_PRODUCT_SUBCATEGORY_OPTIONS_SUCCESS,
            payload: categoryOptions,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addOrderItemsAction = (data) => {
  return (dispatch) => {
    return dispatch({
      type: ADD_ORDER_ITEMS,
      payload: data,
    });
  };
};

export const removeOrderItemAction = (index) => {
  return (dispatch) => {
    return dispatch({
      type: REMOVE_ORDER_ITEM,
      payload: index,
    });
  };
};

export const getSubcategoriesAction = () => {
  return (dispatch) => {
    return getProductSubcategories().then(
      (response) =>
        dispatch({
          type: GET_PRODUCT_SUBCATEGORIES_SUCCESS,
          payload: response.data.responseObject ?? [],
        }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTaxCodeAction = () => {
  return (dispatch) => {
    return getTaxCodes().then(
      (response) =>
        dispatch({
          type: GET_TAX_CODES_SUCCESS,
          payload: response.data.responseObject ?? [],
        }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTaxOptionsAction = () => {
  return (dispatch) => {
    return getTaxCodes().then(
      (response) => {
        if (response.data.responseObject) {
          let categories = response.data.responseObject;

          let taxOptions = categories.map((category) => ({
            value: category.name,
            label: category.name,
            taxCode: category.code,
          }));
          console.log("subcategoryOptions = ", taxOptions);
          dispatch({
            type: GET_TAX_CODE_OPTIONS_SUCCESS,
            payload: taxOptions,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const createTaxCodeAction = (data) => {
  return function (dispatch) {
    return createTaxCode(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const updateTaxCodeAction = (data) => {
  return function (dispatch) {
    return updateTaxCode(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const setCutOffTimeAction = (data) => {
  return function (dispatch) {
    return setCutOffTime(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({ type: ACTION_SUCCESS, payload: response.data.message });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getProductOptionsAction = () => {
  return (dispatch) => {
    return getProducts().then(
      (response) => {
        if (response.data && response.data.code === 200) {
          let products = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];
          let options = products.map((product) => ({
            value: product.id,
            label: product.name,
          }));
          dispatch({
            type: GET_PRODUCT_OPTIONS_SUCCESS,
            payload: options,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const setOutletProductPriceAction = (data) => {
  return function (dispatch) {
    return setOutletProductPrice(data).then(
      (response) => {
        if (response.data.code === "200") {
          return dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }

        if (response.data.code === "400" || response.data.code === "401") {
          return dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};
