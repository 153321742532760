import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTrucksAction,
  getTruckSurveysAction,
} from "../../store/actions/TrackAction";
import Table from "../../components/dom/Table/Table";
import SurveyDetails from "./SurveyDetails";
import moment from "moment";
import "react-tabs/style/react-tabs.css";

const TrucksSurveyTable = ({ payload }) => {
  
  const surveys = useSelector((state) => state.truckManager.surveys);
  const [showSurvey, setShowSurvey] = useState(false);
  const [survey, setSurvey] = useState({});
  const dispatch = useDispatch();

  const view = (data) => {
    setShowSurvey(true);
    setSurvey(data);
  };

  const resetView = ()=>{
    setShowSurvey(false)
  }
  
  const columns = [
    {
      Header: "Date Taken",
      accessor: (d) => moment(d.date).format("LLL"),
    },
    {
      Header: "Truck Number",
      accessor: "truckNumber",
    },
    {
      Header: "Collected By",
      accessor: "driver",
    },
    {
      Header: "Truck Name",
      accessor: "truckName",
    },

    {
      Header: " ",

      Cell: (row) => (
        <div
          onClick={() => view(row.row.original)}
          className="btn-outline text-uppercase font-weight-normal text-centre"
        >
          View Survey
        </div>
      ),
    },
  ];

  useEffect(() => {
    let data = {
      searches: [payload],
    };

    dispatch(getTruckSurveysAction(data));
  }, []);

  return (
    <>
      {!showSurvey && (
        <>
          {surveys && surveys.length > 0 && (
            <Table data={surveys} columns={columns} />
          )}
          {surveys && surveys.length === 0 && (
            <p className="alert alert-info">No survey at the moment</p>
          )}
        </>
      )}
      {showSurvey && (
        <>
          <SurveyDetails data={survey} reset = {resetView} />
        </>
      )}
    </>
  );
};

export default TrucksSurveyTable;
