import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { PageTitle } from "../../styles/App";
import { getTrucksAction } from "../../store/actions/TrackAction";
import Table from "../../components/dom/Table/Table";
import { PageButton } from "../../styles/App";
import TruckViewer from "./TruckViewer";
import { usePermissionChecker } from "../../utils/user";

const TrucksTablePage = ({ addTruck, edit }) => {
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const trucks = useSelector((state) => state.truckManager.trucks);
  const [canViewTruck, setCanViewTruck] = useState(false)
  const [truck, setTruck] = useState(null)
  const dispatch = useDispatch();


  const viewTruck = (data)=>{
    console.log("View Truck",data)
    setCanViewTruck(true)
    setTruck(data)

  }

  const resetView = ()=>{
    
    setCanViewTruck(false)
    setTruck(null)
    dispatch(getTrucksAction());
  }

  const productColumn = [
    {
      Header: "Truck",
      name: "name",
      accessor: (d) => (
        <>
          <img
            src={
              d.image
                ? d.image
                : "https://s3.amazonaws.com/aimrough/potraits/default-avatar.png"
            }
            alt={d.name}
            className="img-fluid pr-2"
            width="40px"
            height="40px"
          />{" "}
          {d.name}
        </>
      ),
    },

    {
      Header: "Number Plate",
      accessor: "plateNumber",
    },
    {
      Header: "Truck Type",
      accessor: "type",
    },

    {
      Header: () => (
        <div style={{ textAlign: "center" }}>Loading Capacity (Crates)</div>
      ),
      accessor: "age",

      Cell: (row) => {
        return (
          <div style={{ textAlign: "center" }}>{row.row.original.crates}</div>
        );
      },
    },
    {
      Header: "Driver",
      accessor: "assignedTo",
    },
    {
      Header: "Name",
      accessor: "name",
    },

    {
      Header: " ",

      Cell: (row) => (
        <>
          <div
          onClick={() => viewTruck(row.row.original)}
          className="btn-outline text-uppercase font-weight-normal text-centre"
        >
          View 
        </div>
        <div
          onClick={() => edit(row.row.original)}
          className="btn-outline text-uppercase font-weight-normal text-centre"
        >
          Edit
        </div>
        
          
          </>
      ),
    },
  ];

  return (
    <>
      {success && <p className="alert alert-success">{message}</p>}
     
      {!canViewTruck && 
         <PageTitle>Truck Management</PageTitle>
      }
    
            <div className="row">
            {!canViewTruck && 
            <div className="col-sm-2">
              <h4 className="mt-3">All Trucks</h4>
            </div>
            }

            <div className="col-sm-10">
            {usePermissionChecker("can_create_truck") && 
              <PageButton className="float-right" onClick={addTruck}>
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Create Truck
              </PageButton>
              }

            </div>
            </div>
      
       
            {!canViewTruck && 
            <>
      {trucks && trucks.length > 0 && (
        <Table data={trucks} columns={productColumn} rowInfo={null} />
      )}
      {trucks && trucks.length === 0 && (
        <p className="alert alert-info">No tracks at the moment</p>
      )}
      </>
    }

{canViewTruck && truck&&
           <TruckViewer truck={truck} reset={resetView} />
      }
     
    </>
  );
};

export default TrucksTablePage;
