import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Select from "react-select";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import Table from "../../components/dom/Table/Table";
import { allCreditsColumns, blackListedColumns } from "./columns";
import { TabsWrapper } from "../../styles/Tabs";
import {
  getCreditsAction,
  getOutletCreditsAction,
  getUserCreditsAction,
  updateCreditRequestAction,
} from "../../store/actions/SalesAction";

import { usePermissionChecker } from "../../utils/user";
import { ButtonWithIcon } from "../../styles/Forms";
import PageModal from "../../components/dom/modal";
import CreditInfo from "./CreditInfo";
import CreditProvisionPage from "./CreditProvisionPage"

import {
  getOutletsAction,
  changeOutletCreditStatusAction,
} from "../../store/actions/OutletAction";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CreditLimitPage from "./CreditLimit";

import { scrollToTop } from "../../utils/window";
import "react-tabs/style/react-tabs.css";
import moment from "moment";

const CreditPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [creditStatus, setCreditStatus] = useState("PENDING");
  const credits = useSelector((state) => state.salesManager.credits);
  const outlets = useSelector((state) => state.outletManager.outlets);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const userObj = useSelector((state) => state.authManager.user);
  
  const userRoute = useSelector((state) => state.routeManager.route);
  const dispatch = useDispatch();

  

  const blackListedColumns = [
    {
      Header: "ID",
      accessor: "outletNumber",
      key:["outletNumber"]
    },
    {
      Header: "Customer Name",
      accessor: "name",
      key:["name"]
    },
    {
      Header: "Customer Mobile",
      accessor: "telephone",
      key:["telephone"]
    },
    {
      Header: "Province",
      accessor: "province",
      key:["province"]
    },
    {
      Header: "Route",
      accessor: "routeIdentity",
      key:["routeIdentity"]
    },
    {
      Header: "Credit Info",
      Cell: (row) => (
        <ButtonWithIcon
          className="primary text-uppercase font-weight-normal"
          onClick={() => viewInfo(row.row.original)}
        >
          View Info
        </ButtonWithIcon>
      ),
    },
    {
      Header: " ",
      Cell: (row) => {
        return (
          <div
            onClick={() => removeOutlet(row.row.original)}
            className="pointer"
          >
            {usePermissionChecker("can_unblacklist_customer") && <CancelIcon />}
          </div>
        );
      },
    },
  ];
  let outletFilter = {
    searches: [
      {
        key: "creditStatus",
        value: "BLACKLISTED",
      },
    ],
  };
  useEffect(() => {
    let creditPayload = {
      searches: [
        {
          key: "routeCode",
          value: userRoute&&userRoute.route&&userRoute.route.routeCode ? userRoute.route.routeCode : "",
        },
      ],
    };
    dispatch(getCreditsAction({}));
    dispatch(getUserCreditsAction(creditPayload));
    dispatch(getOutletsAction(outletFilter));
  }, []);

  useEffect(() => {
    if (success) {
      scrollToTop();
      setTimeout(() => dispatch(getOutletsAction(outletFilter)), 5000);
    }
  });

  const handleChange = (selectedOption) => {
    let filter = {
      searches: [
        {
          key: "paymentStatus",
          value: selectedOption.value,
        },
      ],
    };
    //console.log(filter);
    dispatch(getCreditsAction(filter));
  };

  const removeOutlet = (outlet) => {
    let data = {
      outletNumber: outlet.outletNumber,
      status: "ALLOWED",
    };
    dispatch(changeOutletCreditStatusAction(data));
  };

  const viewInfo = (outlet) => {
    let filter = {
      searches: [
        {
          key: "clientId",
          value: outlet.outletNumber,
        },
        {
          key: "paymentStatus",
          value: "PENDING",
        },
      ],
    };
    dispatch(getOutletCreditsAction(filter));
    setIsModalOpen(true);
  };

  return (
    <>
      <PageTopHeader title="Credit Management" />
      {success && <p className="alert alert-success">{message}</p>}
      {error && <p className="alert alert-danger">{message}</p>}
      <TabsWrapper>
        <Tabs>
          <TabList>
            <Tab>ALL CREDITS</Tab>
            <Tab>BLACKLISTED</Tab>
            <Tab>Credit Limit</Tab>
            <Tab>Credit Provision</Tab>
          </TabList>

          <TabPanel>
            <>
              {credits && credits.length > 0 && (
                <>
                  <div className="row">
                    <div className="col-sm-5">
                      <Select
                        options={[
                          { value: "PENDING", label: "Pending" },
                          { value: "PAID", label: "Paid" },
                        ]}
                        placeholder="Filter by status"
                        onChange={(selectedOption) =>
                          handleChange(selectedOption)
                        }
                      />
                    </div>
                  </div>

                  <Table
                    data={credits}
                    columns={
                     
                         allCreditsColumns
                    }
                  />
                </>
              )}
              {credits && credits.length === 0 && (
                <p className="alert alert-info">
                  No credits to show at the moment
                </p>
              )}
            </>
          </TabPanel>
          <TabPanel>
            <>
              {outlets && outlets.length > 0 && (
                <Table data={outlets} columns={blackListedColumns} />
              )}
              {outlets && outlets.length === 0 && (
                <p className="alert alert-info">
                  No Blacklisted customers to show at the moment
                </p>
              )}
            </>
          </TabPanel>

          <TabPanel>
            <CreditLimitPage />
          </TabPanel>
          <TabPanel>
            <CreditProvisionPage />
          </TabPanel>
        </Tabs>
      </TabsWrapper>

      <PageModal
        isOpen={isModalOpen}
        component={<CreditInfo />}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
};

export default CreditPage;
