import axios from "axios";
import {
  APP_URL,
  contentTypeApplicationJson,
  contentTypeUrlEncoded,
} from "../constants";
import qs from "qs";

export const getRoutes = async () => {
  const result = await axios
    .post(`${APP_URL}distribution/fetch/all/routes`)
    .then((res) => res);
  return result;
};

export const createRoute = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/create/route`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const assignUserRoute = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/assign/driver/route`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateRoute = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/update/route`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const assignRouteToDriver = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/assign/driver/route`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const assignRouteToAgent = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/assign/route/agent`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getUserRoute = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/driver/route`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getAgentRoute = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/agent/route`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getRouteOrders = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/daily/tasks`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getRouteProductPrices = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/fetchRouteProductPrices`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getDriverRouteHistory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}fetch/driver/route/history`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getAgentRouteHistory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}fetch/agent/route/history`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getRouteDetails = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/route`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getRouteDriver = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/driver/route`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};
