import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { logoutAction } from "../../store/actions/AuthAction";

export const Logout = () => {
  const isAuthenticated = useSelector(state => state.authManager.authenticated);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logoutAction());
  });

  return isAuthenticated ? <Redirect to="/" /> : <Redirect to="/dashboard" />;
};
