import React, { useState, useEffect } from "react";
import { getCategoriesAction } from "../../store/actions/ProductAction";
import { resetUtils } from "../../store/actions/UtilsAction";
import { useDispatch, useSelector } from "react-redux";
import {
  FormWrapper,
  Input,
  Label,
  FormSelect,
  ButtonSmall,
} from "../../styles/Forms";
import _ from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import { setCreditLimitAction } from "../../store/actions/OutletAction";
import { types } from "./data";

const AddCreditLimitForm = ({ editMode, outletType, resetView, limits }) => {
  const [outletTypes, setOutletTypes] = useState([]);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const userObj = useSelector((state) => state.authManager.user);
  const categories = useSelector((state) => state.productManager.categories);
  const creditLimits = useSelector((state) => state.outletManager.creditLimits);

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      resetView();
    }
  });

  useEffect(() => {
    console.log(editMode);
    console.log(creditLimits);
    console.log(types);
    let filteredTypes = types.filter((type) => {
      if (!_.find(limits, { outletType: type.value })) {
        return type;
      }
    });
    console.log(filteredTypes);
    dispatch(getCategoriesAction());
    dispatch(resetUtils());
    setOutletTypes(filteredTypes);
  }, []);

  return (
    <Formik
      initialValues={{
        outletType: editMode ? outletType.outletType : "mamanas",
        limit: editMode ? outletType.limit : "",
        status: editMode ? outletType.status : "ACTIVE",
        username: userObj.username,
        gracePeriod: editMode ? outletType.gracePeriod : null,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          console.log(values);
          if (editMode) {
            values["id"] = outletType.id;
            dispatch(setCreditLimitAction(values));
          } else {
            dispatch(setCreditLimitAction(values));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        limit: Yup.number()
          .typeError("Limit must be a number")
          .min(1, "Limit must be greater than or equal to 1")
          .required("Limit is required"),
        gracePeriod: Yup.number()
          .typeError("Grace Period must be a number")
          .min(1, "Grace Period must be greater than or equal to 1")
          .required("Grace Period is required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <div>
            {success && <p className="alert alert-success">{message}</p>}
            {error && <p className="alert alert-danger">{message}</p>}
            <FormWrapper>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-group col-md-12 mb-3">
                  <Label htmlFor="idType">Outlet Type</Label>

                  <FormSelect
                    id="outletType"
                    name="outletType"
                    value={values.outletType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.type && touched.type ? "is-invalid" : ""}
                    disabled={editMode}
                  >
                    <option disabled>-- select an option--</option>

                    {outletTypes &&
                      outletTypes.length > 0 &&
                      outletTypes.map((outletType) => (
                        <option value={outletType.value}>
                          {outletType.label}
                        </option>
                      ))}
                  </FormSelect>

                  {errors.type && touched.type && (
                    <p className="error ml-2 mt-2">{errors.type}</p>
                  )}
                </div>

                <div className="form-row mb-4">
                  <div className="form-group col-md-12 mb-3">
                    <Label htmlFor="limit">Credit Limit </Label>

                    <Input
                      id="limit"
                      placeholder="Credit Limit in MZN"
                      type="text"
                      value={values.limit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.limit && touched.limit ? " is-invalid" : ""
                      }
                    />
                    {errors.limit && touched.limit && (
                      <p className="error ml-2 mt-2"> {errors.limit}</p>
                    )}
                  </div>
                </div>

                <div className="form-row mb-4">
                  <div className="form-group col-md-12 mb-3">
                    <Label htmlFor="limit">Grace Period</Label>

                    <Input
                      id="gracePeriod"
                      placeholder="Grace Period in days"
                      type="text"
                      value={values.gracePeriod}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.gracePeriod && touched.gracePeriod
                          ? " is-invalid"
                          : ""
                      }
                    />
                    {errors.gracePeriod && touched.gracePeriod && (
                      <p className="error ml-2 mt-2"> {errors.gracePeriod}</p>
                    )}
                  </div>
                </div>

                {editMode && (
                  <div className="form-group col-md-12 mb-3">
                    <Label htmlFor="idType">Status</Label>

                    <FormSelect
                      id="status"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.type && touched.type ? "is-invalid" : ""
                      }
                      required
                    >
                      <option
                        value="ACTIVE"
                        selected={
                          editMode ? outletType.status === "ACTIVE" : false
                        }
                      >
                        ACTIVE
                      </option>
                      <option
                        value="INACTIVE"
                        selected={
                          editMode ? outletType.status === "INACTIVE" : false
                        }
                      >
                        INACTIVE
                      </option>
                    </FormSelect>

                    {errors.status && touched.status && (
                      <p className="error ml-2 mt-2">{errors.status}</p>
                    )}
                  </div>
                )}

                <div className="form-group col-md-12 mb-3">
                  <ButtonSmall type="submit" disabled={isSubmitting}>
                    {!editMode && <span>Set Limit</span>}
                    {editMode && <span>Update Limit</span>}
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddCreditLimitForm;
