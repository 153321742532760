import {
  getUsers,
  createUser,
  updateUser,
  fetchUsers,
  uploadUserProfilePicture,
  getUserDetails,
  addRole,
  getRoles,
  updateRole,
  addPermission,
  updatePermission,
  getPermissions,
  addPermissionsToRole,
  passwordUpdate,
  sendPasswordRecoveryToken,
  filterUsers,
  deactivateUser,
  activateUser,
  assignVerticalToUser,
  getUserVerticals,
} from "../../services/UserService";
import {
  assignRouteToDriver,
  getUserRoute,
  assignRouteToAgent,
  getAgentRoute,
} from "../../services/RouteService";
import {
  GET_USERS_SUCCESS,
  ACTION_FAIL,
  ACTION_SUCCESS,
  USER_RETRIVAL_SUCCESS,
  GET_ROLES_SUCCESS,
  GET_PERMISSIONS_SUCCESS,
  GET_NEW_NOTIFICATION,
  NOTIFICATIONS_READ_SUCCESS,
  DRIVERS_FETCH_SUCCESS,
  FETCH_USER_BY_ROLE_SUCCESS,
  SET_USERNAME,
  GET_ROLES_OPTIONS_SUCCESS,
  USER_LOADING,
  GET_USER_VERTICALS_SUCCESS,
} from "../constants/ActionTypes";
import { handleErrorAction } from "./UtilsAction";
import _ from "lodash";

export const getUsersAction = (data) => {
  return (dispatch) => {
    return fetchUsers(data).then(
      (response) =>
        dispatch({
          type: GET_USERS_SUCCESS,
          payload: Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject],
          total: response.data.total,
          totalActive: response.data.totalActive,
          totalInactive: response.data.totalInactive,
        }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUserDetailsAction = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_LOADING });
    return getUserDetails(data).then(
      (response) =>
        dispatch({
          type: USER_RETRIVAL_SUCCESS,
          payload: response.data.responseObject.user,
        }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUserVerticalsAction = (username) => {
  return (dispatch) => {
    return getUserVerticals(username).then(
      (response) => {
        if (response.data.code === 200) {
          let verticals = response.data.responseObject ?? [];
          let verticalOptions =
            verticals.length > 0
              ? verticals.map((vertical) => ({
                  label: vertical.name,
                  value: vertical.id,
                }))
              : [];
          return dispatch({
            type: GET_USER_VERTICALS_SUCCESS,
            payload: response.data.responseObject,
            options: verticalOptions,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addUserAction = (data, verticals) => {
  return function(dispatch) {
    return createUser(data).then(
      (response) => {
        if (response.status === 200) {
          if (response.data && response.data.responseObject) {
            if (data.role === "sales-representative") {
              let userData = {
                username: response.data.responseObject.username,
                verticals: _.flatten(verticals),
              };
              assignVerticalToUser(userData)
                .then((res) => console.log(res))
                .catch((error) => dispatch(handleErrorAction(error)));
            }

            if (data.role === "call-centre-agent") {
              let agentData = {
                agent: response.data.responseObject.username,
                routeNumber: data.routeNumber,
                createdBy: data.createdBy,
                shift: data.shift,
              };
              assignRouteToAgent(agentData)
                .then((res) => console.log(res))
                .catch((error) => dispatch(handleErrorAction(error)));
            }

            dispatch({ type: ACTION_SUCCESS, payload: response.data.message });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const editUserAction = (data, verticals, username) => {
  return function(dispatch) {
    return updateUser(data).then(
      (response) => {
        console.log(data, verticals);
        if (data.role === "sales-representative") {
          let userData = {
            username: username,
            verticals: _.flatten(verticals),
          };
          assignVerticalToUser(userData)
            .then((res) => console.log(res))
            .catch((error) => dispatch(handleErrorAction(error)));
        }
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message });
      },
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: error.response.data.message })
    );
  };
};

export const deactivateUserAction = (data) => {
  return function(dispatch) {
    return deactivateUser(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: error.response.data.message })
    );
  };
};

export const activateUserAction = (data) => {
  return function(dispatch) {
    return activateUser(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: error.response.data.message })
    );
  };
};

export const addRoleAction = (data) => {
  return function(dispatch) {
    return addRole(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: "Role added succesfully" }),
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: error.response.data.message })
    );
  };
};

export const addPermissionToRoleAction = (data) => {
  return function(dispatch) {
    return addPermissionsToRole(data).then(
      (response) =>
        dispatch({
          type: ACTION_SUCCESS,
          payload: "Permissions updated succesfully",
        }),
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: error.response.data.message })
    );
  };
};

export const editRoleAction = (data) => {
  return function(dispatch) {
    return updateRole(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getRolesAction = () => {
  return (dispatch) => {
    return getRoles().then(
      (response) => {
        if (response && response.data && response.data.responseObject) {
          return dispatch({
            type: GET_ROLES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
      },
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: error.response.data.message })
    );
  };
};

export const getRolesOptionsAction = () => {
  return (dispatch) => {
    return getRoles().then(
      (response) => {
        if (response && response.data && response.data.responseObject) {
          let roles = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];
          roles = roles.map((role) => ({
            value: role.name,
            label: role.description,
          }));
          return dispatch({
            type: GET_ROLES_OPTIONS_SUCCESS,
            payload: roles,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addPermissionAction = (data) => {
  return function(dispatch) {
    return addPermission(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      // dispatch({ type: ADD_PERMISSION_SUCCESS, payload: data }),
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: error.response.data.message })
    );
  };
};

export const editPermissionAction = (data) => {
  return function(dispatch) {
    return updatePermission(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      //dispatch({ type: PERMISSION_UPDATE_SUCCESS, payload: data }),
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: error.response.data.message })
    );
  };
};

export const getPermissionsAction = () => {
  return (dispatch) => {
    return getPermissions().then(
      (response) =>
        dispatch({
          type: GET_PERMISSIONS_SUCCESS,
          payload: response.data.responseObject
            ? Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject]
            : [],
        }),
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: error.response.data.message })
    );
  };
};

export const updatePassword = (data) => {
  return function(dispatch) {
    return passwordUpdate(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const sendPasswordRecoveryTokenAction = (data) => {
  return function(dispatch) {
    return sendPasswordRecoveryToken(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getDriversAction = (data) => {
  return (dispatch) => {
    return filterUsers(data).then(
      (response) => {
        if (response && response.data && response.data.responseObject) {
          let drivers = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];
          let driversOptions = drivers.map((driver) => ({
            value: driver.username,
            label: `${driver.firstName} ${driver.lastName}`,
            shift: `${driver.shift}`,
          }));
          return dispatch({
            type: DRIVERS_FETCH_SUCCESS,
            payload: driversOptions,
          });
        }
      },
      (error) => (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUsersByRoleAction = (data) => {
  return (dispatch) => {
    return filterUsers(data).then(
      (response) => {
        if (response && response.data && response.data.responseObject) {
          let drivers = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];
          let driversOptions = drivers.map((driver) => ({
            value: `${driver.firstName} ${driver.lastName}`,
            label: `${driver.firstName} ${driver.lastName}`,
            username: `${driver.username}`,
            shift: driver.shift,
          }));
          return dispatch({
            type: FETCH_USER_BY_ROLE_SUCCESS,
            payload: driversOptions,
          });
        }
      },
      (error) => (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getRouteDriversFromAgentAction = (data) => (dispatch) => {
  getAgentRoute(data).then(
    (response) => {
      if (response.data.code === "200") {
        if (response.data.responseObject.route) {
          let routePayload = {
            route: response.data.responseObject.route.routeCode,
            shift: data.shift,
          };
          getUserRoute(routePayload).then((response) => {
            if (response.data.code === "200") {
              if (response.data.responseObject.driver) {
                let driverName = response.data.responseObject.driver;
                dispatch({ type: SET_USERNAME, payload: driverName });
              }
            } else {
              dispatch({ type: SET_USERNAME, payload: null });
            }
          });
        }
      }
      if (response.data.code === "400") {
        dispatch({ type: SET_USERNAME, payload: null });
      }
    },

    (error) => dispatch(handleErrorAction(error))
  );
};

export const addNewNotificationAction = (data) => {
  return function(dispatch) {
    console.log("addNewNotificationAction ", data);
    return dispatch({ type: GET_NEW_NOTIFICATION, payload: data });
  };
};

export const markReadAction = () => {
  return function(dispatch) {
    return dispatch({ type: NOTIFICATIONS_READ_SUCCESS });
  };
};
