import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const CreditInfo = () => {
  const credits = useSelector((state) => state.salesManager.outletCredits);
  console.log(credits);
  return (
    <>
      <h2>Credit Infomation</h2>
      <hr />
      {credits &&
        credits.length > 0 &&
        credits.map((credit) => (
          <>
            <p>
              <strong>Order Number : </strong>
              {credit.orderNummber}
            </p>

            <p>
              <strong>Overdue Amount :</strong> {credit.amount}
            </p>
            <p>
              <strong>Days Delayed : </strong>
              {credit.overDueDays}
            </p>
          </>
        ))}
    </>
  );
};

export default CreditInfo;
