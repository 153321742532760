import React, { useState, useEffect } from "react";
import {
  addProductSubcategoryAction,
  getProductCategoryOptionsAction,
  updateProductCategoryAction,
  updateProductSubcategoryAction,
} from "../../store/actions/ProductAction";
import { useDispatch, useSelector } from "react-redux";
import { FormWrapper, Input, Label, ButtonSmall } from "../../styles/Forms";
import ImageUploader from "../../components/utils/ImageUploader";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import Select from "react-select";

const nameRegExp = /^([^0-9]*)$/;

const AddSubcategoryForm = ({ editMode, subcategory }) => {
  const [selectedCategory, setSelectedCategory] = useState({});
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);

  const data = useSelector((state) => state.stateManager.data);
  const userObj = useSelector((state) => state.authManager.user);
  const categoryOptions = useSelector(
    (state) => state.productManager.categoryOptions
  );

  useEffect(() => {
    if (success) {
      dispatch({ type: "RESET_VIEW" });
    }
  });

  useEffect(() => {
    if (editMode) {
      let selected = categoryOptions.filter(
        (cat) => cat.value === subcategory.mainCategoryId
      );

      if (selected && selected.length > 0) {
        setSelectedCategory(selected[0]);
        // console.log("Selected  = ", selected[0]);
      }
    }
    dispatch(getProductCategoryOptionsAction());
  }, []);

  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        name: editMode ? subcategory.name : "",
        category: selectedCategory,
        createdBy: userObj.username,
        description: editMode ? subcategory.description : "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          //console.log(values);
          let formData = _.clone(values);

          formData["mainCategoryId"] = values.category.value;
          formData = _.omit(formData, "category");
          if (editMode) {
            formData["id"] = subcategory.id;
            formData["updatedBy"] = userObj.username;
            formData = _.omit(formData, "createdBy");
            //console.log(formData);
            dispatch(updateProductSubcategoryAction(formData));
          } else {
            //console.log(formData);
            dispatch(addProductSubcategoryAction(formData));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .matches(nameRegExp, " Name cannot contain number")
          .required(" Name is required "),
        description: Yup.string().required(" Description is required "),
        category: Yup.object().shape({
          label: Yup.string().required("You must select category"),
        }),
      })}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <div>
            <FormWrapper>
              {success && <p className="alert alert-success">{message}</p>}
              {/* <code>{JSON.stringify(values)}</code>
              <code>{JSON.stringify(selectedCategory)}</code> */}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row mb-4">
                  <div className="form-group col-md-12 mb-1">
                    <Label htmlFor="name">Category: </Label>
                    <Select
                      placeholder="category"
                      value={props.values.category}
                      onChange={(value) => setFieldValue("category", value)}
                      isSearchable={true}
                      options={categoryOptions}
                      name="category"
                      isLoading={false}
                      loadingMessage={() => "Fetching category"}
                      noOptionsMessage={() => "No categories option"}
                    />
                    {errors.category && touched.category && (
                      <p className="error ml-2 mt-2">{errors.category.label}</p>
                    )}
                  </div>
                </div>

                <div className="form-row mb-1">
                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="name">Name; </Label>
                    <Input
                      id="name"
                      placeholder="Enter Subcategory Name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? " is-invalid" : ""
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error ml-2 mt-2"> {errors.name}</p>
                    )}
                  </div>

                  <div className="form-group col-md-12 mb-1">
                    <Label htmlFor="name">Description; </Label>
                    <Input
                      id="description"
                      placeholder="Enter Subcategory Name"
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.description && touched.description
                          ? " is-invalid"
                          : ""
                      }
                    />
                    {errors.description && touched.description && (
                      <p className="error ml-2 mt-2"> {errors.description}</p>
                    )}
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3">
                  <ButtonSmall type="submit" disabled={isSubmitting}>
                    {editMode && <span>Update subcategory</span>}
                    {!editMode && <span>Create subcategory</span>}
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddSubcategoryForm;
