import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTrucksAction } from "../../store/actions/TrackAction";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import TrucksTablePage from "./TrucksTablePage";
import AddTruckPage from "./AddTruckPage";
import { scrollToTop } from "../../utils/window";

import "react-tabs/style/react-tabs.css";

const TrucksPage = () => {
  const [canShowCreateTruckForm, setCanShowCreateTruckForm] = useState(false);
  const [canShowHome, setCanShowHome] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [truck, setTruck] = useState({});
  const canResetView = useSelector(state => state.stateManager.canResetView);
  const dispatch = useDispatch();

  const showAddTruckForm = () => {
    setCanShowHome(false);
    setCanShowCreateTruckForm(true);
    setEditMode(false);
  };

  const resetView = () => {
    setCanShowHome(true);
    setCanShowCreateTruckForm(false);
    scrollToTop();
  };

  const editProduct = row => {
    setEditMode(true);
    setTruck(row);
    setCanShowHome(false);
    setCanShowCreateTruckForm(true);
  };

  useEffect(() => {
    dispatch(getTrucksAction());
  }, []);

  return (
    <>
      {canShowHome && (
        <>
          <TrucksTablePage addTruck={showAddTruckForm} edit={editProduct} />
        </>
      )}

      {canShowCreateTruckForm && (
        <AddTruckPage resetView={resetView} truck={truck} editMode={editMode} />
      )}
    </>
  );
};

export default TrucksPage;
