import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddSupplierForm from "./AddSupplierForm";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import TerritoryCreator from "../../components/map/TerritoryCreator";
import TerritoryInfoDialog from "../../components/dialogs/TerritoryInfoDialog/TerritoryInfoDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  getTerritoryPolygonsAction,
  toggleTerritoryFormAction,
} from "../../store/actions/TerritoryAction";
import { getOutletsWithCoordinatesAction } from "../../store/actions/OutletAction";

const AddTerritoryPage = ({ editMode, territory, resetView }) => {
  const dispatch = useDispatch();
  const [isopen, setIsOpen] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState("Robert");
  const success = useSelector((state) => state.stateManager.success);
  const showModal = useSelector(
    (state) => state.territoryManager.canShowTerritoryCreationForm
  );
  const territoryPolygons = useSelector(
    (state) => state.territoryManager.territoryPolygons
  );

  const handleClose = (value) => {
    //setSelectedValue(value);
    setIsOpen(false);
  };

  useEffect(() => {
    if (success) {
      setIsOpen(false);
    }
    if (showModal) {
      setIsOpen(true);
    }
  }, [success, showModal]);

  useEffect(() => {
    dispatch(getTerritoryPolygonsAction({}));
    dispatch(getOutletsWithCoordinatesAction({}));
  }, []);

  return (
    <div className="row">
      <div className="col-md-1 back-arrow">
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="2x"
          className="mr-2"
          onClick={() => resetView()}
        />
      </div>
      <div className="col-md-11">
        {!editMode && <PageTopHeader title="Create  Territory" />}
        {editMode && <PageTopHeader title="Edit  Territory" />}
        <TerritoryInfoDialog
          selectedValue={selectedValue}
          open={isopen}
          onClose={handleClose}
        />
        <TerritoryCreator
          editMode={editMode}
          territory={territory}
          polygons={territoryPolygons}
        />
      </div>
    </div>
  );
};

export default AddTerritoryPage;
