import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import EventForm from "../../forms/EventForm/EventForm";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteEventAction,
  toggleEventModalAction,
} from "../../../store/actions/routePlanActions";

function EventInfoDialog({ onClose, event, open }) {
  const selectedEvent = useSelector((state) => state.routePlanManager.event);
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose(event);
  };
  useEffect(() => {
    console.log("EventInfoDialog event = ", event);
  });

  const deleteEvent = (event) => {
    dispatch(deleteEventAction(selectedEvent.id));
    dispatch(toggleEventModalAction(false));
    //console.log("Delete Event", event);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <div>
        {event && (
          <EventForm
            event={event}
            onClose={handleClose}
            deleteEvent={deleteEvent}
          />
        )}
      </div>
    </Dialog>
  );
}

export default EventInfoDialog;
