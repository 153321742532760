import styled from "styled-components";
export const TableStyle = styled.div`
  h4 {
    color: #757575;
    font-size: 14px;
    text-transform: capitalize;
    padding-top: 15px;
  }
  padding: 1rem;

  table {
    width: 100%;
  }

  th {
    color: #ecece9;

    background-color: #373d42;
    border: none;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }
  table {
    border-spacing: 0;
    border: none;

    tr {
      color: #373d42;
      font-size: 12px;
      font-weight: 500;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 20px;
      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  select {
    width: 90%;
    height: 50px;
    border: none;
  }

  .table-select-wrapper {
    border: 1px solid #ccc;
  }

  .search-input {
    border: none;
    padding-left: 10px;
  }

  .search-input::placeholder {
    color: rgba(56, 64, 30, 0.8);
    font-size: 14px;
  }
  .search-input-group {
    border: 1px solid #ccc;
    padding: 12px 5px 12px 20px;
    width: 70%;
    float: right;
  }
  .input-group-prepend {
    padding-top: 7px;
  }
  .entries-count {
    color: #757575;
    font-size: 12px;
  }
  .prev-next-btn {
    font-size: 12px;
    font-weight: bold;
    float: right;
  }
  .prev-next-btn button {
    text-transform: uppercase;
    font-weight: 500;
    border: none;
  }
  .Table__visiblePagesWrapper {
    display: inline-block;
  }
  .btn-next,
  .btn-prev {
    color: #00c400;
  }
  .Table__pageButton {
    color: #757575;
    border: none;
  }
  .Table__pageButton--active {
    color: #000;
  }
  .prev-next-btn button:disabled {
    color: rgb(127, 127, 127);
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: #00c400;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #00c400;
    opacity: 0.5;
  }

  .no-records {
    background: #f9f9f9;
  }
  div.export-icon-wrapper {
    border: 1px solid #ecece9;
    padding: 6px 5px 6px 5px;
    cursor: pointer;
  }
`;
