import axios from "axios";
import {
  APP_URL,
  contentTypeUrlEncoded,
  contentTypeApplicationJson,
} from "../constants";
import qs from "qs";

export const getDailySales = async (startDate, endDate) => {
  const result = await axios
    .post(
      `https://compliance.aimgroup.co.tz:8443/edo/ordermanagementservice/fetch/sales?startDate=${startDate}&endDate=${endDate}`,
      {},
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getUserTruck = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/driver/truck`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getAllSales = async (data) => {
  const result = await axios
    .post(
      `https://compliance.aimgroup.co.tz:8443/edo/ordermanagementservice/fetch/product/sales`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getUserUnsoldProducts = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/returned/products`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
export const getUserCredits = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/driver/credit/request`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getAllCredits = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/credits`,
      data,
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateReconStatus = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/update/payment/status`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const updateCreditRequest = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/update/driver/credit/request`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const setPerformanceTarget = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}salesmanagement/salesService/create/sale/target`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getPerformanceTarget = async (username) => {
  const result = await axios
    .get(`${APP_URL}salesmanagement/salesService/fetch/sale/target/${username}`)
    .then((data) => data);
  return result;
};

export const getPerformanceHistory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/user/performance/summary`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getReturnedExpiredProducts = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/expired/products`,
      data,
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
