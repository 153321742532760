import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import {
  getUserLocationAction,
  loadCurrentLocationAction,
} from "../../store/actions/LocationAction";
import { getOutletsWithCoordinatesAction } from "../../store/actions/OutletAction";
import { LeafLetContainer } from "../../styles/Map";
import CloseIcon from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

const RealtimeMap = () => {
  const [canShowPopup, setCanShowPopup] = useState(false);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [showOutletInfo, setShowOutletInfo] = useState(false);
  const [state, setState] = React.useState({
    showInfo: true,
    showOutlets: false,
  });
  const user = useSelector((state) => state.userManager.user);
  const userRoute = useSelector((state) => state.routeManager.route);
  const locatedOutlets = useSelector(
    (state) => state.outletManager.locatedOutlets
  );

  const iconMarkup = renderToStaticMarkup(
    <i className=" fas fa-store fa-2x secondary" />
  );
  const userIconMarkup = renderToStaticMarkup(
    <i className=" fas fa-user fa-2x primary" />
  );
  const customMarkerIcon = divIcon({
    html: iconMarkup,
  });
  const userIcon = divIcon({
    html: userIconMarkup,
  });

  const userLocation = useSelector(
    (state) => state.locationManager.userLocation
  );
  console.log(userLocation);
  const isLoading = useSelector((state) => state.locationManager.isLoading);
  const isError = useSelector((state) => state.locationManager.isError);
  const message = useSelector((state) => state.locationManager.message);
  const dispatch = useDispatch();

  const handleOutletClick = (outlet) => {
    console.log("Show outlet", outlet);
    setSelectedOutlet(outlet);
    setCanShowPopup(true);
  };

  useEffect(() => {
    let data = {
      appName: "SFA_INALIPA_B2B",
      queryValueType: "username",
      queryValues: user.username,
    };
    dispatch(loadCurrentLocationAction());
    dispatch(getUserLocationAction(data));
    dispatch(getOutletsWithCoordinatesAction({}));
  }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <LeafLetContainer>
      {isLoading && (
        <p className="alert alert-info">
          <CircularProgress size={18} /> Real-time map is being loaded . please
          wait...
        </p>
      )}
      {!isLoading && isError && <p className="alert alert-danger">{message}</p>}
      {!isError && !isLoading && (
        <>
          <div className="filter ">
            <div className="row">
              <div className="col-sm-2">
                <h3>Filter</h3>
              </div>
              <div className="col-sm-3">
                <span className="info">Show Info</span>
                <Switch
                  checked={state.showInfo}
                  onChange={handleChange}
                  name="showInfo"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>

              <div className="col-sm-3">
                <span className="info">Show Outlets</span>
                <Switch
                  checked={state.showOutlets}
                  onChange={handleChange}
                  name="showOutlets"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            </div>
          </div>
          <Map
            center={
              userLocation
                ? [userLocation.latitude, userLocation.longitude]
                : [-6.802353, 39.279556]
            }
            zoom={userLocation ? 13 : 10}
          >
            {userLocation && (
              <Marker
                position={[userLocation.latitude, userLocation.longitude]}
                icon={userIcon}
              />
            )}

            {state.showOutlets &&
              locatedOutlets.length > 0 &&
              locatedOutlets.map((outlet) => {
                let latLong = [
                  parseFloat(outlet.latitude),
                  parseFloat(outlet.longitude),
                ];

                return (
                  <Marker
                    key={outlet.id}
                    position={latLong}
                    icon={customMarkerIcon}
                    onClick={() => {
                      handleOutletClick(outlet);
                    }}
                  />
                );
              })}

            {state.showInfo && (
              <div className="mapInfo pd-2">
                <div className="row ">
                  <div className="col-sm-3">
                    <img
                      src={user.picturePath}
                      alt={user.firstName}
                      class="rounded-circle img-thumbnail"
                    />
                  </div>

                  <div className="col-sm-7 pl-0">
                    <h5 className="logout-title">
                      {`${user.firstName} ${user.lastName}`}
                    </h5>
                    <p className="text-capitalize">{user.role}</p>
                  </div>
                  <div
                    className="col-sm-2 pointer"
                    onClick={() => setState({ showInfo: false })}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <strong>Device Model</strong>
                  </div>
                  <div className="col-sm-6 text-right">
                    {userLocation &&
                    userLocation.device &&
                    userLocation.device.deviceModel
                      ? userLocation.device.deviceModel
                      : ""}
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-sm-6">
                    <strong>IMEI Number</strong>
                  </div>
                  <div className="col-sm-6 text-right">
                    {userLocation &&
                    userLocation.device &&
                    userLocation.device.imeiNumber
                      ? userLocation.device.imeiNumber
                      : "-"}
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-sm-4">
                    <strong>App Version</strong>
                  </div>
                  <div className="col-sm-8 text-right">
                    {userLocation &&
                    userLocation.device &&
                    userLocation.device.appVersion
                      ? userLocation.device.appVersion
                      : "-"}
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-sm-4">
                    <strong>OS Version</strong>
                  </div>
                  <div className="col-sm-8 text-right">
                    {userLocation &&
                    userLocation.device &&
                    userLocation.device.osVersion
                      ? userLocation.device.osVersion
                      : "-"}
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-sm-4">
                    <strong>Last Ping</strong>
                  </div>
                  <div className="col-sm-8 text-right">
                    {userLocation && userLocation.createdDate
                      ? moment(userLocation.createdDate).format("LLL")
                      : "Never"}
                  </div>
                </div>
              </div>
            )}

            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />

            {canShowPopup && (
              <Popup
                position={[selectedOutlet.latitude, selectedOutlet.longitude]}
                onClose={() => {
                  setCanShowPopup(false);
                }}
              >
                <div>
                  <p>{selectedOutlet.name}</p>
                </div>
              </Popup>
            )}
          </Map>
        </>
      )}
    </LeafLetContainer>
  );
};

export default RealtimeMap;
