import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CreateIcon from "@material-ui/icons/Create";
import { OrderFormWrapper, Label, Input } from "../../styles/Order";
import { ButtonSmall, ButtonWithIcon } from "../../styles/Forms";
import {
  updateOrderAction,
  getOrdersAction,
} from "../../store/actions/OrderAction";
import { usePermissionChecker } from "../../utils/user";
import _ from "moment";

const RouteOrderItems = ({ order, edit, resetView }) => {
  const [editMode, setEditMode] = useState(false);
  const [quantities, setQuantites] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [totalObj, setTotalObj] = useState({});
  const [total, setTotal] = useState(0);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const extraOrders = useSelector((state) => state.OrderManager.extraOrders);
  //const order = extraOrders[0];

  const dispatch = useDispatch();

  const updateOrderItems = () => {
    let items = order.items.map((product, index) => {
      let quantity = quantities[index]
        ? quantities[index]
        : order.items[index].quantity;

      return {
        orderItemId: product.orderItemId,
        quantity: quantity,
      };
    });
    items = items.filter((item) => item.quantity !== 0);

    let data = {
      orderNumber: order.orderNumber,
      items,
    };

    dispatch(updateOrderAction(data));
  };

  useEffect(() => {
    console.log("ROute Order Items = ", order);
    if (success) {
      dispatch({ type: "RESET_VIEW" });
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    }
  });

  useEffect(() => {
    console.log(order);
    if (success) {
      dispatch({ type: "RESET_VIEW" });
    }
    var totalObj = {};

    order.items &&
      order.items.map((item, index) => {
        totalObj[index] = item.cost;
      });
    setTotalObj(totalObj);
    setTotal(order.cost);
  }, []);

  const handleQuantityChange = (index, event, price) => {
    if (event.target.value === "") {
      setQuantites({ ...quantities, [index]: event.target.value });

      setIsSubmitDisabled(true);
    } else {
      let quantity = parseInt(event.target.value);
      setQuantites({ ...quantities, [index]: event.target.value });
      setTotalObj({ ...totalObj, [index]: quantity * price });
      console.log(quantities, totalObj, quantity, price, index);

      let totalArr = Object.keys(totalObj).map((key) => totalObj[key]);
      totalArr[index] = quantity * price;
      let total = totalArr.reduce((a, b) => a + b, 0);
      setTotal(total);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12"></div>
      </div>
      {order && !order.items && (
        <p className="alert alert-info">No orders to show yet</p>
      )}
      {order && order.items && (
        <div className="row pb-3">
          <div className="col-sm-9">
            <h4 className="fs-14 font-weight-bold mt-3">
              Customer Name : {order.clientName}
            </h4>
          </div>
          <div className="col-sm-3">
            <ButtonWithIcon
              onClick={() => setEditMode(true)}
              className="float-right pointer sfa-btn-bordered"
            >
              <CreateIcon className="primary pointer fs-12 " />{" "}
              <span className="primary font-weight-bold">Edit QTY</span>
            </ButtonWithIcon>
          </div>
        </div>
      )}
      {order && order.items && (
        <OrderFormWrapper>
          <div className="row row-bg">
            <div className="col-sm-4 ">Items</div>
            <div className="col-sm-4 text-center">qty</div>
            <div className="col-sm-4 ">amount</div>
          </div>

          {order.items &&
            order.items.length > 0 &&
            order.items.map((item, index) => (
              <div className="row row-items" key={item.orderItemId}>
                <div className="col-sm-4 ">
                  <span className="pl-2">{item.productName}</span>
                </div>
                {!editMode && (
                  <div className="col-sm-4 text-center">{item.quantity}</div>
                )}

                {editMode && (
                  <div className="col-sm-4 text-center">
                    <Input
                      type="number"
                      key={item.identity}
                      name={`quantity-${item.identity}`}
                      placeholder={item.quantity}
                      min="1"
                      value={quantities[index]}
                      onChange={(e) =>
                        handleQuantityChange(index, e, item.price)
                      }
                      disabled={quantities[index] === 0}
                    />
                  </div>
                )}

                <div className="col-sm-4">
                  {quantities[index]
                    ? item.price * quantities[index]
                    : item.cost}
                </div>
              </div>
            ))}

          <div className="row pt-3">
            <div className="col-sm-4 total-small text-uppercase font-weight-bold">
              <span className="pl-3">total</span>
            </div>

            <div className="col-sm-4 offset-sm-4  total-small ">
              <span className="pl-1"> {total} MZN</span>
            </div>
          </div>

          {editMode && (
            <div className="row">
              <div className="col-sm-12">
                <ButtonSmall
                  type="submit"
                  className="pointer"
                  onClick={updateOrderItems}
                >
                  Update Items
                </ButtonSmall>
              </div>
            </div>
          )}
        </OrderFormWrapper>
      )}
    </>
  );
};

export default RouteOrderItems;
