import styled from "styled-components";
export const OrderFormWrapper = styled.div`
  div.row-bg {
    background: #373d42;
    color: white;
    padding: 20px 10px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    margin: 10px 0px;
  }
  div.row-items {
    color: #373d42;
    font-size: 12px;
    font-weight: 500;
  }
  div.row-items > div {
    padding: 20px;
  }
  .faded {
    color: #757575;
  }
  input {
    font-size: 12px;
  }
  ::-webkit-input-placeholder,
  input::-moz-placeholder,
  input:-ms-input-placeholder,
  input:-moz-placeholder {
    font-size: 12px !important;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #00c400;
  }
  .total {
    color: #373d42;
    font-size: 24px;
    font-weight: 400 !important;
  }
  .gray-bg {
    background: #fafafa;
  }
  .total-small {
    color: #373d42;
    font-size: 12px;
    font-weight: 700;
  }
  .total-amount-small {
    font-size: 12px;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 12px !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px !important;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 12px !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px !important;
  }
`;

export const OrderLabel = styled.label`
  padding: 26px 10px !important;
`;

export const ButtonBordered = styled.button`
  border: 1px solid #ccc;
  padding: 10px;
  margin-right: 15px;
`;

export const Input = styled.input`
  border: none;
  border-left: 1px solid #00c400;
  padding: 5px;
`;

export const InputDefault = styled.input`
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 10px 10px;
  padding-left: 0px;
  color: #373d42;
  font-size: 22px;
  font-weight: 400;
  outline: none;
`;

export const Value = styled.div`
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding: 6px 8px;
  text-transform: uppercase;
`;
