import React, { useState, useEffect, useRef } from "react";

import {
  addProductCategoryAction,
  getProductCategoryOptionsAction,
  getVerticalOptionsAction,
  updateProductCategoryAction,
} from "../../store/actions/ProductAction";

import { useDispatch, useSelector } from "react-redux";

import { FormWrapper, Input, Label, ButtonSmall } from "../../styles/Forms";
import Select from "react-select";
import ImageUploader from "../../components/utils/ImageUploader";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";

const nameRegExp = /^([^0-9]*)$/;

const AddCategoryForm = ({ editMode, category }) => {
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const [verticalOptions, setVerticalOptions] = useState([]);
  const productVerticalOptions = useSelector(
    (state) => state.productManager.verticalOptions
  );

  const data = useSelector((state) => state.stateManager.data);
  const userObj = useSelector((state) => state.authManager.user);
  const [selectedVertical, setSelectedVertical] = useState({});
  const formikRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVerticalOptionsAction());
  }, []);

  useEffect(() => {
    setVerticalOptions(productVerticalOptions);

    if (editMode) {
      console.log(
        "EditMode productVerticalOptions + category",
        productVerticalOptions,
        category
      );
      let selected = productVerticalOptions.filter(
        (cat) => cat.value === category.verticalId
      );

      if (selected && selected.length > 0) {
        setSelectedVertical(selected[0]);
        if (formikRef.current) {
          formikRef.current.setFieldValue("vertical", selected[0]);
        }
        console.log("Selected  = ", selected[0]);
      }
    }
  }, [productVerticalOptions]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        name: editMode ? category.name : "",
        description: editMode ? category.description : "",
        createdBy: userObj.username,
        vertical: selectedVertical,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          let formData = _.clone(values);
          //console.log(values);
          formData["vertical"] = formData.vertical.value;
          if (editMode) {
            formData["id"] = category.id;
            formData["updatedBy"] = userObj.username;
            formData = _.omit(formData, "createdBy");
            dispatch(updateProductCategoryAction(formData));
          } else {
            // console.log(values);
            dispatch(addProductCategoryAction(formData));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .matches(nameRegExp, " Name cannot contain number")
          .required(" Name is required "),
        description: Yup.string().required("Description is required "),
        vertical: Yup.object().shape({
          label: Yup.string().required("You must select a vertical"),
        }),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div>
            <FormWrapper>
              {success && <p className="alert alert-success">{message}</p>}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row mb-4">
                  <div className="form-group col-md-12 mb-1">
                    <Label htmlFor="name">Vertical: </Label>
                    <Select
                      value={selectedVertical}
                      onChange={(value) => {
                        setFieldValue("vertical", value);
                        setSelectedVertical(value);
                      }}
                      isSearchable={true}
                      options={verticalOptions}
                      name="vertical"
                      isLoading={false}
                      loadingMessage={() => "Fetching vertical"}
                      noOptionsMessage={() => "No vertical option"}
                    />
                    {errors.vertical && touched.vertical && (
                      <p className="error ml-2 mt-2">{errors.vertical.label}</p>
                    )}
                  </div>
                </div>

                <div className="form-row mb-4">
                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="name">Category Name: </Label>
                    <Input
                      id="name"
                      placeholder="Enter Category Name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? " is-invalid" : ""
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error ml-2 mt-2"> {errors.name}</p>
                    )}
                  </div>
                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="name">Category Description: </Label>
                    <Input
                      id="description"
                      placeholder="Enter Category Description"
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.description && touched.description
                          ? " is-invalid"
                          : ""
                      }
                    />
                    {errors.description && touched.description && (
                      <p className="error ml-2 mt-2"> {errors.description}</p>
                    )}
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3">
                  <ButtonSmall type="submit" disabled={isSubmitting}>
                    {editMode && <span>Update category</span>}
                    {!editMode && <span>Create category</span>}
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddCategoryForm;
