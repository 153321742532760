

import React from "react";
import { Tile } from "../../styles/App";

const CountsCard = ({ title,totalValue,subValue,description,inlineStyle,counts,countsTitle,countsTotal,totalTitle, }) => {
  return (
    <div class={inlineStyle}>
    <div class="">
        <div class="card-body">
            <div class="float-right">
                <i class="mdi mdi-account-multiple widget-icon"></i>
            </div>
            <h5 class="text-muted font-weight-normal mt-0" >{title}</h5>
            <h2 class="mt-3 mb-3">{totalValue}</h2>
            <p class="mb-0 text-muted">
                <span class="text-success mr-2"><i class="mdi mdi-arrow-up-bold"></i> {subValue}</span>
                <span class="text-nowrap">{description}</span>  
            </p>
        </div> 
    </div> 
    </div> 
  );
};

export default CountsCard;
