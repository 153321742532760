import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOutletStatusAction,
  getOutletDetailsAction,
} from "../../store/actions/OutletAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import { Label } from "../../styles/Forms";
import { TabsWrapper } from "../../styles/Tabs";
import { ProfilePicture } from "../../styles/User";
import { getOrdersAction } from "../../store/actions/OrderAction";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import { imagePlaceholder } from "../../constants";
import PriceListPage from "./SKUManager";
import SKUManager from "./SKUManager";
import AddSKUForm from "./AddSKUForm";
import CurrencyFormat from "react-currency-format";

const ProductPage = ({ product, edit, goBack }) => {
  const [canShowSKUHome, setCanShowSKUHome] = useState(true);
  const [canAddSKU, setCanAddSKU] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [sku, setSKU] = useState(null);
  const outlet = useSelector((state) => state.outletManager.outlet);
  const userObj = useSelector((state) => state.authManager.user);
  const orders = useSelector((state) => state.outletManager.outletOrders);
  const message = useSelector((state) => state.stateManager.message);

  const success = useSelector((state) => state.stateManager.success);
  const outletProductPrices = useSelector(
    (state) => state.outletManager.outletProductPrices
  );
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Product  = ", product);
  }, []);
  const addSKU = () => {
    setCanShowSKUHome(false);
    setCanAddSKU(true);
    setEditMode(false);
  };

  const editSKU = (sku) => {
    setCanShowSKUHome(false);
    setCanAddSKU(true);
    setEditMode(true);
    setSKU(sku);
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          {/* <code>{JSON.stringify(outlet)}</code> */}
          {success && (
            <p className="alert alert-success mt-3">
              {"Status updated succesfully"}
            </p>
          )}
        </div>
        <div className="col-md-1 back-arrow" onClick={goBack}>
          <FontAwesomeIcon icon={faArrowLeft} size="2x" className="mr-2" />
        </div>

        <div className="col-md-11">
          <div className="">
            <PageTopHeader title={product.name} />
          </div>
          <div className="float-right">
            {/* <DeleteIcon className="pointer i-p" onClick={() => deactivateOutlet(outlet) } /> */}
          </div>
        </div>
      </div>
      {outlet && (
        <>
          <div className="row mt-4">
            <div className="form-group col-md-4 mb-3">
              <ProfilePicture
                src={
                  product.images[0] ? product.images[0].path : imagePlaceholder
                }
                alt={`${outlet.name}`}
                width="300"
                height="350"
              />
            </div>

            <div className="form-group col-md-8 mb-3">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <Label htmlFor="username">Product Name (English)</Label>

                  <div>{product.name}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label htmlFor="username">Product Name (Swahili)</Label>

                  <div>{product.nameSwahili ?? "-"}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Supplier</Label>

                  <div>{product.supplierName}</div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <Label>Vertical</Label>
                  <div>{product.vertical}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Category:</Label>
                  <div>{product.categoryName}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Subcategory</Label>
                  <div>{product.subcategoryName}</div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <Label htmlFor="username">Brand </Label>
                  <div>{product.brand}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label htmlFor="username">Unit Cost (VAT Inc) </Label>
                  <div>{product.costVatInc ?? "-"}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label htmlFor="username">Unit Cost (VAT Exl) </Label>
                  <div>{product.costVatExl ?? "-"}</div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <Label>Carton Size</Label>
                  <div>{product.cartonSize ?? "-"}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Carton Cost</Label>
                  <div>
                    {product.cartonCost ? (
                      <CurrencyFormat
                        value={product.cartonCost}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <Label>TAX Code </Label>
                  <div>{product.taxCode ?? "-"}</div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <Label>Opening Stock</Label>
                  <div>{product.inventory}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Minimum Stock</Label>
                  <div>{product.minStockB2B ?? "-"}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Bar Code </Label>
                  <div>{product.productCode ?? "-"}</div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <Label>Is Visible</Label>
                  <div>{product.visibleB2B === "Y" ? "Yes" : "No"}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Can Track </Label>
                  <div>{product.trackB2B === "Y" ? "Yes" : "No"}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Out of Stock </Label>
                  <div>{product.outOfStockB2B === "Y" ? "Yes" : "No"}</div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <Label>Featured </Label>
                  <div>{product.featuredB2B === "Y" ? "Yes" : "No"}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Created By </Label>
                  <div>{product.createdBy ? product.createdBy : "-"}</div>
                </div>
                <div className="form-group col-md-4">
                  <Label>Created Date</Label>
                  <div>{moment(product.createdAt).format("MMMM DD , Y")}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12 mb-3">
              <h3>Packages </h3>
              <hr />
              <div>
                {product.packages &&
                  product.packages.length > 0 &&
                  product.packages.map((p) => (
                    <div className="col-md-12">
                      <h3>
                        {p.name} ({p.quantity})
                      </h3>

                      {p.sellingBands && p.sellingBands.length > 0 && (
                        <table class="table table-striped">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Minimum Quantity</th>
                              <th scope="col">Maximum Quantity</th>
                              <th scope="col"> Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {p.sellingBands &&
                              p.sellingBands.length > 0 &&
                              p.sellingBands.map((band) => (
                                <tr>
                                  <th>{band.minQty}</th>
                                  <td>{band.maxQty}</td>
                                  <td>
                                    {
                                      <CurrencyFormat
                                        value={
                                          band && band.bandPrice
                                            ? band.bandPrice
                                            : "0"
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                    }
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      <br />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductPage;
