// *https://www.registers.service.gov.uk/registers/country/use-the-api*

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { SearcButton, SearchBox } from "../../styles/Form";
import { Link } from "react-router-dom";

import { AUTOCOMPLETE_LIMIT } from "../../../constants";
import { searchProducts } from "../../../services/ProductService";
import { addOrderItemsAction } from "../../../store/actions/ProductAction";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function SFAAutocomplete() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const loading = searchTerm && open && options.length === 0;
  const [canGoBack, setCanGoBack] = useState(true);

  useEffect(() => {
    //console.log('InalipaAutocomplete page = ', page);
    return () => setSearchTerm("");
  }, []);

  const onChangeHandle = async (value) => {
    // use the changed value to make request and then use the result. Which
    // console.log('onChangeHandle', value);
    setSearchTerm(value);
    const response = await searchProducts(value, 1, AUTOCOMPLETE_LIMIT);

    const products = response.data.responseObject ?? [];
    // console.log(products, response, value);
    if (products && products.length > 0) {
      const options = products.map((product) => ({
        title: product.name,
        value: product._id,
        ...product,
      }));

      // console.log('options ', options);

      setOptions(options);
    }
  };

  const checkInputChange = (event, value) => {
    if (!value || value === "") {
      //history.goBack();
    }
  };

  const onProductChange = (event, values) => {
    let isObject = _.isPlainObject(values);
    let pathname = history.location.pathname.split("/")[1];
    // console.log('onProductChange values + pathname', values, pathname);
    if (values == null) {
      if (pathname === "product") {
        //history.goBack();
        //history.goBack();
      } else {
        //history.goBack();
      }
    }
    if (isObject) {
      //history.push(`/product/${values.value}`);
      dispatch(addOrderItemsAction(values));
      console.log("Add product to Order Items ", values);
      document.activeElement.blur();
    } else {
    }

    // if (values == null) {
    //   history.push(`/`);
    // } else {
    //   history.push(`/results?search_query=${values}`);
    // }
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <SearchBox>
      <div className="search-input-group">
        <Autocomplete
          style={{ width: 300, fontSize: "16px" }}
          freeSolo
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.title}
          options={options}
          loading={loading}
          onChange={onProductChange}
          onInputChange={checkInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="search product..."
              className="form-control"
              InputLabelProps={{ shrink: false }}
              onChange={(ev) => {
                if (ev.target.value !== "" || ev.target.value !== null) {
                  onChangeHandle(ev.target.value);
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && !searchTerm ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              onKeyDown={(e) => {
                let code;
                if (e.key !== undefined) {
                  code = e.key;
                } else if (e.keyIdentifier !== undefined) {
                  code = e.keyIdentifier;
                } else if (e.keyCode !== undefined) {
                  code = e.keyCode;
                }

                if ((code === 13 || code === "Enter") && e.target.value) {
                  //history.push(`/results?search_query=${e.target.value}`);
                }
              }}
            />
          )}
        />
        <div className="input-group-append">
          <Link
            to={
              searchTerm.length > 0
                ? `/results?search_query=${searchTerm}`
                : "#"
            }
          ></Link>
        </div>
      </div>
    </SearchBox>
  );
}
