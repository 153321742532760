import { RepeatOneSharp } from "@material-ui/icons";
import {
  getUserOutlets,
  createRoutePlan,
  getRoutePlan,
  getTaskList,
  updateRoutePlan,
} from "../../services/routePlanService";
import { groupOutletsByArea, sortOutlets } from "../../utils/routePlanUtils";
import {
  ACTION_SUCCESS,
  ADD_EVENT,
  CLEAR_CALENDAR_EVENTS,
  CLEAR_USER_OUTLETS,
  DELETE_EVENT,
  GET_TASK_LIST_OPTIONS_SUCCESS,
  GET_USER_ASSIGNED_AREAS_SUCCESS,
  ROUTE_PLAN_RETRIEVED,
  SELECT_EVENT,
  SET_CALENDAR_EVENTS,
  TOGGLE_EVENT_MODAL,
  UPDATE_CALENDAR_EVENT,
  USER_OUTLETS_AND_AREAS_RETRIEVED,
} from "../constants/ActionTypes";
import { handleErrorAction } from "./UtilsAction";
import _ from "lodash";
import moment from "moment";

export const getUserOutletsAction = (username) => {
  return (dispatch) => {
    return getUserOutlets(username).then(
      (response) => {
        if (response.data.code === 200) {
          console.log(response.data);
          let userOutlets =
            response.data.responseObject &&
            response.data.responseObject.outlets &&
            response.data.responseObject.outlets
              ? response.data.responseObject.outlets
              : [];
          let userAreas =
            response.data.responseObject &&
            response.data.responseObject.areas &&
            response.data.responseObject.areas
              ? response.data.responseObject.areas
              : [];

          userOutlets = userOutlets.map((outlet) => {
            let outletRes = outlet.outletResponse;
            outletRes["assignmentId"] = outlet.id;
            return outletRes;
          });

          userOutlets = sortOutlets(userOutlets);
          userOutlets = groupOutletsByArea(userOutlets, userAreas);
          console.log("Outlets grouped by area  = ", userOutlets);

          dispatch({
            type: USER_OUTLETS_AND_AREAS_RETRIEVED,
            payload: userOutlets,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const AddEventAction = (eventInfo) => {
  console.log("AddEventAction eventInfo= ", eventInfo);
  return (dispatch) => {
    let eventData = {
      ...(eventInfo.extendedProps.entityType === "outlet"
        ? { outletId: parseInt(eventInfo.extendedProps.entityId) }
        : { areaId: parseInt(eventInfo.extendedProps.entityId) }),
      activities: "",
      repeatType: eventInfo.extendedProps.repeatType,
      start: eventInfo.start,
      end: eventInfo.start,
      comment: "",
      title: eventInfo.title,
      id: parseInt(eventInfo.id),
      entityType: eventInfo.extendedProps.entityType,
      backgroundColor: eventInfo.backgroundColor,
      borderColor: eventInfo.borderColor,
    };
    console.log("Add event", eventData);
    dispatch({ type: ADD_EVENT, payload: eventData });
  };
};

export const changeEventDateAction = (eventInfo) => {
  return (dispatch) => {
    let eventData = {
      ...(eventInfo.extendedProps.entityType === "outlet"
        ? { outletId: eventInfo.id }
        : { areaId: eventInfo.id }),
      activities: "",
      repeatType: eventInfo.extendedProps.repeatType,
      startDate: eventInfo.start,
      endDate: eventInfo.start,
      comment: "",
    };
    console.log(eventData);
    //dispatch({ type: TOGGLE_ORDER_MODAL, payload: value });
  };
};

export const createRoutePlanAction = (data) => {
  return function (dispatch) {
    return createRoutePlan(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const updateRoutePlanAction = (data) => {
  return function (dispatch) {
    return updateRoutePlan(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUserRoutePlanAction = (data) => {
  return function (dispatch) {
    return getRoutePlan(data).then(
      (response) => {
        if (response.data.code === 200 && response.data.responseObject) {
          let calendarEvents = [];

          let routePlan =
            response.data.responseObject[
              response.data.responseObject.length - 1
            ];
          let areaCalendarEvents = routePlan.routeAreas
            ? routePlan.routeAreas.map((area) => {
                let daysOfWeek = null;
                let startRecur = null;
                let endRecur = null;

                if (area.repeatType === "WEEKLY") {
                  daysOfWeek = [moment(area.startDate).day()];
                  startRecur = area.startDate;
                  endRecur = area.endDate;
                }

                if (area.repeatType === "DAILY") {
                  startRecur = area.startDate;
                  endRecur = moment(area.endDate)
                    .add(1, "days")
                    .format("YYYY-MM-DD");
                }
                return {
                  areaId: area.areaId,
                  activities: area.activities,
                  repeatType: area.repeatType,
                  start: area.startDate,
                  end: area.endDate,
                  comments: area.comments,
                  title: area.areaName,
                  id: area.id,
                  backgroundColor: "#1ab394",
                  borderColor: "#1ab394",
                  daysOfWeek,
                  startRecur,
                  endRecur,
                };
              })
            : [];

          let outletCalendarEvents = routePlan.routeOutlets
            ? routePlan.routeOutlets.map((area) => {
                let daysOfWeek = null;
                let startRecur = null;
                let endRecur = null;
                if (area.repeatType === "WEEKLY") {
                  daysOfWeek = [moment(area.startDate).day()];
                  startRecur = area.startDate;
                  endRecur = area.endDate;
                }

                if (area.repeatType === "DAILY") {
                  startRecur = area.startDate;
                  endRecur = moment(area.endDate)
                    .add(1, "days")
                    .format("YYYY-MM-DD");
                }
                return {
                  outletId: area.outletId,
                  activities: area.activities,
                  repeatType: area.repeatType,
                  start: area.startDate,
                  end: area.endDate,
                  comments: area.comments,
                  title: area.outletName,
                  id: area.id,
                  backgroundColor: "#00c400",
                  borderColor: "#00c400",
                  daysOfWeek,
                  startRecur,
                  endRecur,
                };
              })
            : [];

          calendarEvents = [...areaCalendarEvents, ...outletCalendarEvents];
          console.log(
            "areaCalendarEvents + outletCalendarEvents = ",
            calendarEvents
          );
          //let Outlets = resObj.
          // let areas = data.map((routePlanNew) =>
          //   routePlanNew.routeAreas ? routePlanNew.routeAreas : []
          // );
          dispatch({
            type: ROUTE_PLAN_RETRIEVED,
            payload: calendarEvents,
            isPlanPresent: calendarEvents.length > 0 ? true : false,
            planId: routePlan.id,
          });
        } else {
          dispatch({
            type: ROUTE_PLAN_RETRIEVED,
            payload: [],
            isPlanPresent: false,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const resetCalendarEventsAction = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CALENDAR_EVENTS });
  };
};

export const resetUserOutletsAction = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_USER_OUTLETS });
  };
};

export const updateCalendarEventAction = (event) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CALENDAR_EVENT, payload: event });
  };
};

export const setCalendarEventsAction = (events) => {
  return (dispatch) => {
    dispatch({ type: SET_CALENDAR_EVENTS, payload: events });
  };
};

export const selectEventAction = (event) => {
  return (dispatch) => {
    dispatch({ type: SELECT_EVENT, payload: event });
  };
};

export const toggleEventModalAction = (value) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_EVENT_MODAL, payload: value });
  };
};

export const deleteEventAction = (eventId) => {
  return (dispatch) => {
    dispatch({ type: DELETE_EVENT, payload: parseInt(eventId) });
  };
};

export const getTaskListAction = (data) => {
  return (dispatch) => {
    return getTaskList(data).then(
      (response) => {
        let taskList = response.data.responseObject ?? [];
        let taskListOptions =
          taskList.length > 0
            ? taskList.map((task) => ({
                value: task.action,
                label: task.actionDescription,
              }))
            : [];
        dispatch({
          type: GET_TASK_LIST_OPTIONS_SUCCESS,
          payload: taskListOptions,
        });
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUserAreasAction = (username) => {
  return (dispatch) => {
    return getUserOutlets(username).then(
      (response) => {
        if (response.data.code === 200) {
          let userAreas =
            response.data.responseObject &&
            response.data.responseObject.areas &&
            response.data.responseObject.areas
              ? response.data.responseObject.areas
              : [];

          let userOutlets =
            response.data.responseObject &&
            response.data.responseObject.outlets &&
            response.data.responseObject.outlets
              ? response.data.responseObject.outlets
              : [];
          userAreas = userAreas.map((area) => area.areaId);
          userOutlets = userOutlets.map(
            (outlet) => outlet.outletResponse.outletNumber
          );
          console.log("userAreas + userOutlets = ", userAreas, userOutlets);

          dispatch({
            type: GET_USER_ASSIGNED_AREAS_SUCCESS,
            payload: userAreas,
            assignedOutlets: userOutlets,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};
