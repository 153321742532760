import axios from "axios";
import qs from "qs";
import { APP_URL, GENERICS_URL } from "../constants";

export function uploadImages(data) {
  let url = `https://compliance.aimgroup.co.tz:8443/filesmanager/upload`;
  let headers = {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json"
    }
  };

  return axios.post(url, JSON.stringify(data), headers);
}
