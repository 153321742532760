import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  verifyTokenAction,
  changePasswordAction
} from "../../store/actions/AuthAction";
import { Formik } from "formik";
import * as Yup from "yup";
import qs from "qs";

const ResetPasswordForm = () => {
  const [username, setUsername] = useState("");
  const [isEyeOpen, setIsEyeOpen] = useState(true);
  const [isConfirmEyeOpen, setIsConfirmEyeOpen] = useState(true);
  const [inputType, setInputType] = useState("password");
  const [confirmInputType, setConfirmInputType] = useState("password");

  const error = useSelector(state => state.stateManager.error);
  const success = useSelector(state => state.stateManager.success);
  const message = useSelector(state => state.stateManager.message);
  const user = useSelector(state => state.authManager.user);
  const resetSuccess = useSelector(state => state.stateManager.resetSuccess);

  let location = useLocation();
  const dispatch = useDispatch();

  const token = useSelector(state => state.authManager.token);

  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  let data = {
    token: parsed.token
  };

  useEffect(() => {
    dispatch(verifyTokenAction(data));
  }, []);

  const toggleEye = () => {
    if (isEyeOpen) {
      setInputType("text");
      setIsEyeOpen(false);
    } else {
      setInputType("password");
      setIsEyeOpen(true);
    }
  };

  const toggleConfirmEye = () => {
    if (isConfirmEyeOpen) {
      setConfirmInputType("text");
      setIsConfirmEyeOpen(false);
    } else {
      setConfirmInputType("password");
      setIsConfirmEyeOpen(true);
    }
  };
  return resetSuccess ? (
    <Redirect to="/" />
  ) : (
    <div className="login-wrapper">
      <h2 className="mb-5">Reset Password</h2>
      {error && <p className="alert alert-danger mt-3">{message}</p>}
      {success && <p className="alert alert-success mt-3">{message}</p>}
      {(success || error) && (
        <Formik
          initialValues={{
            password: ""
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              if (token && token.token) {
                const resetEvent = {
                  id: user.id,
                  username: user.username,
                  token: token.token,
                  password: values.password,
                  confirmPassword: values.password
                };
                dispatch(changePasswordAction(resetEvent));
              }

              setSubmitting(false);
            }, 500);
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required("New password is required")
              .min(6, "Password is too short - should be 6 chars minimum."),
            confirmPassword: Yup.string()
              .required("Confirm password is required.")
              .min(6, "Password is too short - should be 6 chars minimum.")
              .oneOf([Yup.ref("password"), null], "Passwords must match")
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;

            return (
              <div>
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="form-group">
                    {errors.password && touched.password && (
                      <p className="alert alert-danger mt-2 ml-2">
                        {" "}
                        {errors.password}
                      </p>
                    )}
                    <label className="mb-2">New Password</label>
                    <div className="input-group">
                      <input
                        type={inputType}
                        name="password"
                        placeholder="Enter Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autocomplete="off"
                        className={
                          errors.password && touched.password
                            ? "auth-input  mb-2 is-invalid"
                            : "auth-input mb-2"
                        }
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text clickable show-hide-password"
                          id="basic-addon2"
                        >
                          <FontAwesomeIcon
                            icon={isEyeOpen ? faEye : faEyeSlash}
                            className="material-icons float-left status-gray"
                            onClick={toggleEye}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="line"></div>
                  </div>

                  <div className="form-group">
                    {errors.confirmPassword && touched.confirmPassword && (
                      <p className="alert alert-danger mt-2 ml-2">
                        {" "}
                        {errors.confirmPassword}
                      </p>
                    )}
                    <label className="mb-2">Confirm Password</label>
                    <div className="input-group">
                      <input
                        type={confirmInputType}
                        name="confirmPassword"
                        placeholder="Enter Password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autocomplete="off"
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? "auth-input  mb-2 is-invalid"
                            : "auth-input mb-2"
                        }
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text clickable show-hide-password"
                          id="basic-addon2"
                        >
                          <FontAwesomeIcon
                            icon={isConfirmEyeOpen ? faEye : faEyeSlash}
                            className="material-icons float-left status-gray"
                            onClick={toggleConfirmEye}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="line"></div>
                  </div>

                  <div className="form-group col-md-12 mb-3">
                    <button
                      className="btn btn-primary btn-block btn-lg btn-auth"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
export default ResetPasswordForm;
