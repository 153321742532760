import React, { useState, useEffect } from "react";
import {
  editTruckAction,
  addTruckAction,
  getTrucksAction,
  assignTruckToDriverAction,
} from "../../store/actions/TrackAction";
import { resetUtils } from "../../store/actions/UtilsAction";
import { getDriversAction } from "../../store/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  FormWrapper,
  Input,
  Label,
  FormSelect,
  InputWrapper,
  ButtonSmall,
} from "../../styles/Forms";
import ImageUploader from "../../components/utils/ImageUploader";

import { Formik } from "formik";
import * as Yup from "yup";

const AddTruckForm = ({ editMode, truck, resetView }) => {
  const [double, setDisableDouble] = useState(false);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const drivers = useSelector((state) => state.userManager.drivers);
  const [selectedDriver, setSelectedDriver] = useState({});

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      borderBottom: "1px solid #ccc",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const imageUploadSuccess = useSelector(
    (state) => state.stateManager.imageUploadSuccess
  );
  const imageUploaded = useSelector(
    (state) => state.utilsManager.imageUploadSuccess
  );
  var imgUrl = useSelector((state) => state.utilsManager.imgUrl);
  imgUrl = !imageUploaded && editMode ? truck.image : imgUrl;

  const userObj = useSelector((state) => state.authManager.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      resetView();
      return () => setTimeout(() => dispatch(getTrucksAction()), 6000);
    }
  });

  useEffect(() => {
    dispatch(getDriversAction({ role: "driver" }));
    dispatch(resetUtils());
    if (editMode) {
      setSelectedDriver({ label: truck.driver, value: truck.driver });
    }
  }, []);

  const handleDriverChange = (driver) => {
    setSelectedDriver(driver);
  };

  return (
    <Formik
      initialValues={{
        name: editMode ? truck.name : "",
        plateNumber: editMode ? truck.plateNumber : "",
        capacity: editMode ? truck.crates : "",
        type: editMode ? truck.type : "Heavy Truck",
        createdBy: userObj.username,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          values["weightUnit"] = "TON";
          values["driver"] = selectedDriver.value;
          values["image"] = imgUrl;

          if (editMode) {
            let keyValueArr = Object.keys(values).map((value) => ({
              key: value,
              value: values[value],
            }));
            let data = {
              id: truck.truckNumber,
              updateItemList: keyValueArr,
            };
            let truckPayload = {
              truckNumber: truck.truckNumber,
              driver: selectedDriver.value,
              shift: selectedDriver.shift,
            };
            //console.log(data);
            //console.log(truckPayload);
            setDisableDouble(true);
            dispatch(assignTruckToDriverAction(truckPayload));
            dispatch(editTruckAction(data));
          } else {
            setDisableDouble(true);
            console.log(values);
            dispatch(addTruckAction(values));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(" Name is required "),
        plateNumber: Yup.string().required("Number Plate is required"),
        capacity: Yup.number()
          .typeError("Loading Capacity must be a number")
          .min(1, "Loading Capacity must be greater than or equal to 1")
          .required("Loading Capacity  is required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div>
            {success && <p className="alert alert-success">{message}</p>}
            {error && <p className="alert alert-danger">{message}</p>}
            {/* <code>{JSON.stringify(values)}</code> */}
            <FormWrapper>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row mb-4">
                  <div className="form-group col-md-6 mb-4">
                    <Label htmlFor="name">Truck Name </Label>
                    <Input
                      id="name"
                      placeholder="Enter Truck Name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? " is-invalid" : ""
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error ml-2 mt-2"> {errors.name}</p>
                    )}
                  </div>

                  <div className="form-group col-md-6 mb-4">
                    <Label htmlFor="name">Number Plate </Label>
                    <Input
                      id="plateNumber"
                      placeholder="Enter Number Plate"
                      type="text"
                      value={values.plateNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.plateNumber && touched.plateNumber
                          ? " is-invalid"
                          : ""
                      }
                    />
                    {errors.plateNumber && touched.plateNumber && (
                      <p className="error ml-2 mt-2"> {errors.plateNumber}</p>
                    )}
                  </div>
                </div>

                <div className="form-row mb-4">
                  <div className="form-group col-md-6 mb-4">
                    <Label htmlFor="type">Truck Type</Label>
                    <InputWrapper>
                      <FormSelect
                        id="type"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.type && touched.type ? "is-invalid" : ""
                        }
                      >
                        <option disabled>-- select an option--</option>

                        <option value="Heavy Truck">Heavy Truck</option>
                        <option value="Light Truck">Light Truck</option>
                      </FormSelect>
                    </InputWrapper>

                    {errors.category && touched.category && (
                      <p className="error ml-2 mt-2"> {errors.category}</p>
                    )}
                  </div>

                  <div className="form-group col-md-4 mb-3">
                    <Label htmlFor="capacity">Loading Capacity (Crates) </Label>
                    <Input
                      id="capacity"
                      placeholder="Loading capacity in crates"
                      type="text"
                      value={values.capacity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.capacity && touched.capacity ? " is-invalid" : ""
                      }
                    />
                    {errors.capacity && touched.capacity && (
                      <p className="error ml-2 mt-2"> {errors.capacity}</p>
                    )}
                  </div>

                  <div className="form-group col-md-12 mb-3">
                    <Label htmlFor="maxPrice">Driver</Label>
                    <Select
                      options={drivers}
                      styles={style}
                      value={selectedDriver}
                      onChange={(selectedOption) => {
                        handleDriverChange(selectedOption);
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <h3>Image</h3>
                  </div>
                  <div className="col-sm-8 ">
                    <ImageUploader
                      success={imageUploadSuccess}
                      imgUrl={imgUrl}
                      editMode={editMode}
                    />
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3">
                  <ButtonSmall type="submit" disabled={isSubmitting || double}>
                    {!editMode && <span>Create a truck</span>}
                    {editMode && <span>Edit truck</span>}
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddTruckForm;
