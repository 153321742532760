import { GET_PRODUCTION_SCHEDULE_SUCCESS } from "../constants/ActionTypes";
const initialState = {
  schedule: []
};

function ProductionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTION_SCHEDULE_SUCCESS:
      return { ...state, schedule: action.payload };

    default:
      return state;
  }
}

export default ProductionReducer;
