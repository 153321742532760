import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "../../components/molecules/Calendar/Calendar";
import DraggableUserOutlets from "../../components/molecules/DraggableUserOutlets/DraggableUserOutlets";
import { getUserVerticalsAction } from "../../store/actions/UserAction";

const RoutePlanner = () => {
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const selectedUser = useSelector((state) => state.userManager.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserVerticalsAction(selectedUser.username));
  }, []);

  return (
    <>
      {success && <p className="alert alert-success">{message}</p>}
      {error && <p className="alert alert-danger">{message}</p>}
      <div className="routePlanner row mb-5">
        <div className="col-md-3">
          <DraggableUserOutlets />
        </div>
        <div className="col-md-9">
          <Calendar />
        </div>
      </div>
    </>
  );
};

export default RoutePlanner;
