import { TOGGLE_ORDER_MODAL } from "../constants/ActionTypes";

const initialState = {
  isModalOpen: false,
};

function UtilsReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_ORDER_MODAL:
      return { ...state, isModalOpen: action.payload };

    default:
      return state;
  }
}

export default UtilsReducer;
