import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { TabsWrapper } from "../../styles/Tabs";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import DistributionOrderTablePage from "./DistributionOrderTablePage";
import RouteOrdersPage from "./RouteOrdersPage";
import PageModal from "../../components/dom/modal";
import RouteOrderItems from "./RouteOrderItems";
import {
  getOrdersAction,
  getExtraOrdersAction,
} from "../../store/actions/OrderAction";

const DistributionOrderManager = () => {
  const [showHome, setShowHome] = useState(true);
  const [canShowRouteOrders, setCanShowRouteOrders] = useState(false);
  const [order, setOrder] = useState({});
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const extraOrders = useSelector((state) => state.OrderManager.extraOrders);
  const [shift, setShift] = useState("am");
  const dispatch = useDispatch();

  const resetView = () => {
    setShowHome(true);
    setCanShowRouteOrders(false);
    setOrder({});
    setmodalIsOpen(false);
  };

  const viewOrders = (order) => {
    console.log(order);
    setShowHome(false);
    setCanShowRouteOrders(true);
    setOrder(order);
  };
  const viewExras = (order) => {
    let orderFilter = {
      searches: [
        {
          key: "routeId",
          value: order.routeId,
        },
        {
          key: "orderType",
          value: "DRIVER",
        },
        {
          key: "shift",
          value: order.shift,
        },
        {
          key: "deliveryDateInt",
          value: moment().format("YYYYMMDD"),
        },
      ],
    };
    dispatch(getExtraOrdersAction(orderFilter));
    setOrder(order);
    setmodalIsOpen(true);
  };

  return (
    <>
      {showHome && (
        <>
          <PageTopHeader title="Order Management" />
          <TabsWrapper>
            <Tabs>
              <TabList>
                <Tab>AM SHIFT</Tab>
                <Tab>PM SHIFT</Tab>
              </TabList>
              <TabPanel>
                <DistributionOrderTablePage
                  viewOrders={viewOrders}
                  viewExras={viewExras}
                  shift="am"
                />
              </TabPanel>
              <TabPanel>
                <DistributionOrderTablePage
                  viewOrders={viewOrders}
                  viewExras={viewExras}
                  shift="pm"
                />
              </TabPanel>
            </Tabs>
          </TabsWrapper>
        </>
      )}
      {canShowRouteOrders && (
        <RouteOrdersPage order={order} resetView={resetView} />
      )}

      <PageModal
        isOpen={modalIsOpen}
        component={
          <>
            {extraOrders &&
              extraOrders.length > 0 &&
              extraOrders.map((order) => (
                <div className="col-sm-12 mt-2 mb-2">
                  <RouteOrderItems order={order} resetView={resetView} />
                </div>
              ))}

            {extraOrders && extraOrders.length === 0 && (
              <p className="alert alert-info mt-3"> No orders placed yet</p>
            )}
          </>
        }
        setIsOpen={setmodalIsOpen}
      />
    </>
  );
};

export default DistributionOrderManager;
