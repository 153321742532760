import _ from "lodash";
import {
  ADD_EVENT,
  CLEAR_CALENDAR_EVENTS,
  CLEAR_USER_OUTLETS,
  DELETE_EVENT,
  GET_TASK_LIST_OPTIONS_SUCCESS,
  GET_USER_ASSIGNED_AREAS_SUCCESS,
  ROUTE_PLAN_RETRIEVED,
  SELECT_EVENT,
  SET_CALENDAR_EVENTS,
  TOGGLE_EVENT_MODAL,
  UPDATE_CALENDAR_EVENT,
  USER_OUTLETS_AND_AREAS_RETRIEVED,
} from "../constants/ActionTypes";
const initialState = {
  userOutlets: [],
  calendarEvents: [],
  routePlans: [],
  taskListOptions: [],
  event: {},
  isPlanExist: false,
  isModalOpen: false,
  routePlanId: null,
  userAssignedAreas: [],
  userOutletAndAreas: [],
};

function RoutePlanReducer(state = initialState, action) {
  switch (action.type) {
    case USER_OUTLETS_AND_AREAS_RETRIEVED:
      return {
        ...state,
        userOutlets: action.payload,
        isModalOpen: false,
        userOutletAndAreas: action.payload,
      };

    case GET_USER_ASSIGNED_AREAS_SUCCESS:
      return {
        ...state,
        userAssignedAreas: action.payload,
        assignedOutlets: action.assignedOutlets,
        isModalOpen: false,
      };
    case ADD_EVENT:
      return {
        ...state,
        calendarEvents: [...state.calendarEvents, action.payload],
      };
    case CLEAR_CALENDAR_EVENTS:
      return {
        ...state,
        calendarEvents: [],
      };

    case CLEAR_USER_OUTLETS:
      return {
        ...state,
        userOutlets: [],
        userAssignedAreas: [],
      };
    case SET_CALENDAR_EVENTS:
      return {
        ...state,
        calendarEvents: action.payload,
      };
    case ROUTE_PLAN_RETRIEVED:
      return {
        ...state,
        calendarEvents: action.payload,
        isPlanExist: action.isPlanPresent,
        routePlanId: action.planId,
      };
    case TOGGLE_EVENT_MODAL:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    case GET_TASK_LIST_OPTIONS_SUCCESS:
      return {
        ...state,
        taskListOptions: action.payload,
      };
    case SELECT_EVENT:
      return {
        ...state,
        event: action.payload,
      };

    case DELETE_EVENT:
      return {
        ...state,
        calendarEvents: state.calendarEvents.filter(
          (event) => event.id !== action.payload
        ),
      };
    case UPDATE_CALENDAR_EVENT:
      //Check if event already exists
      let updatedEvents = [];
      var index = _.findIndex(state.calendarEvents, {
        title: action.payload.title,
        start: action.payload.start,
      });
      if (index === -1) {
        updatedEvents = [...state.calendarEvents, action.payload];
      } else {
        updatedEvents = [
          ...state.calendarEvents.slice(0, index),
          Object.assign({}, state.calendarEvents[index], action.payload),
          ...state.calendarEvents.slice(index + 1),
        ];
      }
      //console.log("UPDATE_CALENDAR_EVENT updatedEvents = ", updatedEvents);
      return { ...state, calendarEvents: updatedEvents };
    // return {
    //   ...state,
    //   taskListOptions: action.payload,
    // };
    default:
      return state;
  }
}

export default RoutePlanReducer;
