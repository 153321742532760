import React, { useEffect, useState } from "react";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { useDispatch, useSelector } from "react-redux";
import {
  assignOutletsAction,
  getOutletsByTerritoryAction,
  resetTerritoryOutletsAction,
} from "../../../store/actions/OutletAction";
import {
  getUserAreasAction,
  getUserOutletsAction,
  resetUserOutletsAction,
} from "../../../store/actions/routePlanActions";
import { getTerritoryOptionsAction } from "../../../store/actions/TerritoryAction";
import "./OutletAssignment.css";

const OutletAssignment = () => {
  const [selectedTerritory, setSelectedTerritory] = useState([]);
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [selectedTerritoryOutlets, setSelectedTerritoryOutlets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isItemChecked, setIsItemChecked] = useState(false);
  const selectedUser = useSelector((state) => state.userManager.user);
  const userObj = useSelector((state) => state.authManager.user);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const userAssignedAreas = useSelector(
    (state) => state.routePlanManager.userAssignedAreas
  );
  const userVerticals = useSelector((state) => state.userManager.userVerticals);
  const assignedOutlets = useSelector(
    (state) => state.routePlanManager.assignedOutlets
  );

  const options = useSelector(
    (state) => state.territoryManager.territoryOptions
  );
  const territoryOutlets = useSelector(
    (state) => state.outletManager.territoryOutlets
  );
  const searchResults = useSelector(
    (state) => state.outletManager.outletSearchResults
  );

  const [userOutlets, setUserOutlets] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(resetUserOutletsAction());
    setSelectedTerritoryOutlets([]);
    dispatch(getTerritoryOptionsAction());
    dispatch(getUserAreasAction(selectedUser.username));
    setUserOutlets(territoryOutlets);
    //return () => dispatch(resetUserOutletsAction());
  }, []);

  useEffect(() => {
    setSelectedTerritoryOutlets(territoryOutlets);
  }, [territoryOutlets]);

  useEffect(() => {
    console.log("userAssignedAreas changed", userAssignedAreas);
    onTerritoryChange(userAssignedAreas);
    setSelectedOutlets(assignedOutlets);
  }, [userAssignedAreas]);

  const onTerritoryChange = (selected) => {
    console.log("onTerritoryChange selected = ", selected);
    let userAssignedVerticals =
      userVerticals && userVerticals.length > 0
        ? userVerticals.map((vertical) => vertical.id)
        : null;
    setSelectedTerritory(selected);
    if (selected && selected.length > 0) {
      let data = {
        areas: selected,
      };
      if (userAssignedVerticals) {
        data["verticals"] = userAssignedVerticals;
      }

      dispatch(getOutletsByTerritoryAction(data));
    } else {
      setSelectedTerritoryOutlets([]);
      setSelectedOutlets([]);
      dispatch(resetTerritoryOutletsAction());
    }
  };

  const assignOutlets = () => {
    var data = {};
    data = {
      assignedAreas: selectedTerritory,
      outlets: selectedOutlets,
      username: selectedUser.username,
      fullName: `${selectedUser.firstName} ${selectedUser.lastName}`,
      createdBy: userObj.username,
      createdByName: `${userObj.firstName} ${userObj.lastName}`,
    };

    dispatch(assignOutletsAction(data));
  };

  const selectItem = (e) => {
    const { checked } = e.target;
    const collection = [];

    if (checked) {
      for (const outlet of selectedTerritoryOutlets) {
        collection.push(outlet.outletNumber);
      }
      setSelectedOutlets(collection);
    } else {
      setSelectedOutlets([]);
    }

    setIsItemChecked(checked);
  };

  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setSelectedOutlets((prevState) => [...prevState, value]);
    } else {
      setSelectedOutlets((prevState) =>
        prevState.filter((item) => item !== value)
      );
    }
  };

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    var filteredOutlets = [];

    if (searchValue !== "") {
      filteredOutlets = territoryOutlets.filter((outlet) => {
        if (
          outlet.name &&
          outlet.name.toLowerCase().indexOf(searchValue) >= 0
        ) {
          return outlet;
        } else {
          return false;
        }
      });
      //dispatch(updateTerritoryOutletsAction(filteredOutlets));
      setSelectedTerritoryOutlets(filteredOutlets);
    } else {
      setSelectedTerritoryOutlets(territoryOutlets);
    }
  };

  console.log(
    "territoryOutlets changed selectedTerritoryOutlets=",
    selectedTerritoryOutlets
  );

  return (
    <>
      {/* <code>{JSON.stringify(selectedTerritoryOutlets)}</code> */}
      {success && <p className="alert alert-success">{message}</p>}
      {error && <p className="alert alert-danger">{message}</p>}
      <div className="row">
        <div className="col-lg-6">
          <DualListBox
            options={options}
            selected={selectedTerritory}
            onChange={onTerritoryChange}
          />
        </div>
        <div className="col-lg-6 outlets">
          <form>
            <input
              placeholder="Search outlets.."
              className="form-control m-1"
              onChange={(e) => handleInputChange(e)}
            />
          </form>

          <div className="Scrollable__div outlet-selection-container m-1 p-2">
            {!isLoading &&
              territoryOutlets &&
              territoryOutlets.length === 0 && <p>No outlets to show yet . </p>}
            {!isLoading &&
              selectedTerritoryOutlets &&
              selectedTerritoryOutlets.length > 0 && (
                <table className="table table-striped table-bordered table-hover dataTables-example">
                  <thead>
                    <tr>
                      <th>Outlet Name</th>
                      <th>Last user Assigned</th>
                      <th>
                        <label>
                          <input
                            type="checkbox"
                            checked={isItemChecked}
                            onClick={(e) => selectItem(e)}
                          />
                          Select all
                        </label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedTerritoryOutlets.map((outlet) => (
                      <tr>
                        <td>
                          <span
                            className={
                              outlet.areaName === "Undefined Territory"
                                ? "undefinedTerritory"
                                : ""
                            }
                          >
                            {outlet.name}
                          </span>{" "}
                          <br />{" "}
                          <small>
                            <em>
                              {outlet.address
                                ? outlet.address
                                : "Outlet address not found"}
                            </em>
                          </small>
                        </td>
                        <td>
                          {outlet.assingedToName
                            ? outlet.assingedToName
                            : "None"}
                        </td>
                        <td>
                          <input
                            className="outletCheckbox"
                            type="checkbox"
                            key={outlet.outletNumber}
                            value={outlet.outletNumber}
                            onChange={handleCheckboxClick}
                            label={outlet.label}
                            checked={selectedOutlets.includes(
                              outlet.outletNumber
                            )}
                          />{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
          </div>
          {!isLoading && territoryOutlets && territoryOutlets.length > 0 && (
            <div className="w-100">
              <button
                className="btn btn-primary float-right"
                onClick={assignOutlets}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OutletAssignment;
