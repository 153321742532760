import styled from "styled-components";

export const LeafLetContainer = styled.div`
  width: 100%;
  height: 200vh;
  padding: 20px;
  background-color: #fafafa;

  hr {
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    border: 0.5px solid rgba(0, 196, 0, 0.2);
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .filter {
    background: #fff;
    padding: 10px;
    margin: 20px 0px;
  }
  .filter h3 {
    font-size: 14px;
    padding-top: 11px;
  }

  .filter span.info {
    color: #4a4a4a;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 15px;
    display: inline !mportant;
  }

  .leaflet-container {
    width: 100%;
    height: 100vh;
  }
  .mapInfo {
    position: relative;
    z-index: 2000;
    top: 70px;
    left: 10px;
    background: rgba(255, 255, 255, 0.7);
    width: 30%;
    height: 80%;
    border-radius: 2px;
    padding: 10px;
  }
  h5 {
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.58px;
    line-height: 17px;
    text-transform: uppercase;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #00c400 !important;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #00c400 !important;
    opacity: 0.5;
  }

  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background-color: none !important;
  }
  .MuiCircularProgress-svg {
    color: #0c5460 !important;
  }
`;
