import React, { useState } from "react";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddSurveyPage from "./AddSurveyPage";

const Surveys = () => {
  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [survey, setSurvey] = useState(null);

  const addSurvey = () => {
    setShowForm(true);
    setEditMode(false);
  };

  return (
    <div>
      <PageTopHeader
        title="Survey Management"
        icon={faPlus}
        actionText="Add Survey"
        action={addSurvey}
        permission="can_create_outlet"
      />
      {showForm && <AddSurveyPage survey={survey} editMode={editMode} />}
    </div>
  );
};

export default Surveys;
