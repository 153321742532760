import React from "react";
import { useSelector } from "react-redux";
import { UserButton } from "../../styles/App";
import AddIcon from "@material-ui/icons/Add";
import { OrderFormWrapper } from "../../styles/Order";
import Table from "../../components/dom/Table/Table";
import moment from "moment";

const UserRouteHistory = ({ viewModal }) => {
  const routeHistory = useSelector((state) => state.routeManager.routeHistory);

  const columns = [
    {
      Header: "DATE",
      accessor: (d) => moment(d.createdAt).format("ddd D MMM YYYY"),
    },
    {
      Header: "Assigned by",
      accessor: "createdBy",
      key:["createdBy"]
    },
    {
      Header: "route",
      accessor: "route.routeCode",
      key:["routeCode"]
    },
    {
      Header: "route name",
      accessor: "route.routeName",
      key:["routeName"]
    },
  ];

  return (
    <div className="row mt-9">
      <div className="form-group col-md-11 mb-3">
        <div className="row pb-3">
          <div className="col-sm-6">
            <h4 className="p-3">Route History</h4>
          </div>
          <div className="col-sm-6">
            <div className="float-right pointer sfa-btn-bordered">
              <UserButton onClick={viewModal}>
                <AddIcon className=" " />
                Assigning Route
              </UserButton>
            </div>
          </div>
        </div>
        {routeHistory && routeHistory.length > 0 && (
          <Table data={routeHistory} columns={columns} />
        )}

        {routeHistory && routeHistory.length === 0 && (
          <p className="alert alert-info">No route history at the moment</p>
        )}
      </div>
    </div>
  );
};

export default UserRouteHistory;
