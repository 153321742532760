import React,{useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    getCreditsAction,
    getOutletCreditsAction,
    getUserCreditsAction,
    updateCreditRequestAction,
  } from "../../store/actions/SalesAction";
import { ButtonWithIcon } from "../../styles/Forms";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import CreditProvision from "./CreditProvision";
import CreditProvisionHistory from "./CreditProvisionHistory";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const CreditProvisionPage = ({})=>{
    const userCredits = useSelector((state) => state.salesManager.userCredits);
    const userRoute = useSelector((state) => state.routeManager.route);
    const userObj = useSelector((state) => state.authManager.user);
    const dispatch = useDispatch();
    const agentCreditsColumns = [
        {
          Header: "Created Date",
          accessor: (d) => moment(d.createdAt).format("ddd D MMM YYYY"),
          key:["createdAt"]
        },
        
        {
          Header: "Customer",
          accessor: "clientName",
          key:["clientName"]
        },
        {
          Header: "Submitted by",
          accessor: "assignedBy",
          key:["assignedBy"]
        },
        
    
        {
          Header: "Route ID",
          accessor: "routeId",
          key:["routeId"]
        },
    
     
    
        {
          Header: "Shift",
          accessor: "shift",
          key:["shift"]
        },
        {
          Header: "Product (Quantity) ",
          accessor: p=>p.items&&p.items.length>0&&p.items.map(product=>`${product.productName} (${product.quantity}) , `),
          key:["items"]
        },
        {
          Header: " ",
    
          Cell: (row) => (
            <>
              <ButtonWithIcon
                className="primary text-uppercase font-weight-normal"
                onClick={() => ChangeCreditStatus(row.row.original, "ACCEPTED")}
              >
                <CheckCircleIcon />
                Approve
              </ButtonWithIcon>
              <ButtonWithIcon
                className="primary text-uppercase font-weight-normal"
                onClick={() => ChangeCreditStatus(row.row.original, "DECLINED")}
              >
                <CancelIcon />
                Reject
              </ButtonWithIcon>
            </>
          ),
        },
      ];



  const ChangeCreditStatus = (data, status) => {
    
    console.log(data)
    let payload = {
      requestId: data.orderNumber,
      username: userObj.username,
      status: status,
      comments: status,
    };
    dispatch(updateCreditRequestAction(payload));
  };


    return (
        <Tabs>
        <TabList>
          <Tab>Credit Provision</Tab>
          <Tab>Approved Credits</Tab>
          <Tab>Declined Credits</Tab>
        </TabList>
    
        <TabPanel>
          <CreditProvision />
        </TabPanel>
        <TabPanel>
          <CreditProvisionHistory status="ACCEPTED"/>
        </TabPanel>
        <TabPanel>
         <CreditProvisionHistory status="DECLINED"/>
        </TabPanel>
      </Tabs>
    )
}
export default CreditProvisionPage