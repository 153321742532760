import moment from "moment";
import {
  GET_DAILY_SALES_REPORT_SUCCESS,
  IS_LOADING,
  GET_OUTLETS_COUNT_BY_TYPE_SUCCESS,
  GET_LEADS_COUNT_BY_TYPE_SUCCESS,
  GET_ORDERS_BREAKDOWN_SUCCESS,
  GET_OUTLET_AVERAGE_ORDER_VALUE_SUCCESS,
  GET_USERS_BY_ROLE_SUCCESS,
  GET_USERS_BY_STATUS_SUCCESS,
  GET_OUTLETS_BY_USERS_SUCCESS,
  GET_OUTLETS_CREATION_TREND_SUCCESS,
  GET_OUTLETS_ORDERING_TREND_SUCCESS,
  GET_ORDER_STATUS_SUCCESS,
  GET_DAILY_ORDER_TREND_SUCCESS,
  GET_FREQUENTLY_ORDERED_PRODUCT_SUCCESS,
  GET_PERCENT_ORDER_PROCESS_SUCCESS,
  GET_ORDER_PROPCESS_BY_USER_SUCCESS,
  GET_TOP_VERTICAL_SUCCESS,
  GET_TOP_VERTICAL_BY_MARGIN_SUCCESS,
  GET_PRODUCT_MARGIN_TREND_SUCCESS,
  GET_TOP_CUSTOMERS_SUCCESS,
  GET_TOP_CUSTOMERS_BY_OUTLET_TYPE_SUCCESS,
  GET_SALES_REPORT_SUCCESS,
  GET_TOTAL_REVENUE_SUCCESS,
  GET_TOTAL_ORDER_COUNT_SUCCESS,
  GET_USERS_REPORT_SUCCESS,
  GET_OUTLETS_PURCHASE_HISTORY_SUCCESS,
  ON_CLICK_SUCCESS,
  GET_TREEMAP_EVENT_SUCCESS,
  GET_DAILY_ORDER_SUMMARY_REPORT_SUCCESS,
  GET_DAILY_OUTLET_CREATION_SUCCESS,
  GET_HOURLY_ORDER_PROCESSIGNG_SUCCESS,
  GET_SALES_DISTRIBUTION_SUCCESS,
  GET_TOP_PRODUCTS_SALES_BY_VERTICAL_SUCCESS,
  CLEAR_STATE_SUCCESS,
  GET_TOP_PRODUCTS_MARGIN_BY_VERTICAL_SUCCESS,
  GET_TREEMAP_MARGIN_EVENT_SUCCESS,
  GET_DAILY_ORDER_PER_VERTICAL_SUCCESS,
  GET_OUTLETS_STATUS_SUCCESS,
  GET_USERS_STATUS_SUCCESS,
  GET_ALL_TARGETS_SUCCESS,
  GET_OUTLET_SALES_DISTRIBUTION_SUCCESS,
  UPDATE_START_DATE,
  UPDATE_END_DATE,
  UPDATE_VET_ID,
  TOGGLE_TOP_PRODUCTS_VISIBILITY,
} from "../constants/ActionTypes";
const initialState = {
  dailySalesReport: [],
  isLoading: true,
  outletsCountByType: [],
  leadsCountByType: [],
  ordersBreakDown: [],
  avgOutletOrderValue: [],
  usersByRole: [],
  usersByStatus: [],
  outletsByUser: [],
  outletCreationTrend: [],
  outletOrderingTrend: [],
  dailyOrderTrend: [],
  frequentlyOrderProducts: [],
  orderProcessByUser: [],
  percenOrderProcess: [],
  topVertical: [],
  topVerticalByMargin: [],
  productMarginTrend: [],
  topCustomers: [],
  topCustomersByOutlet: [],
  sales: [],
  totalRevenue: [],
  ordersCount: [],
  userReport: [],
  outletPurchaseHistory: [],
  verticalArr: [],
  verticalMarginArr: [],
  dailyOrderSummary: [],
  dailyOutletCreationSummary: [],
  hourlyOrtderProcessing: [],
  salesDistribution: [],
  topProductsSales: [],
  topProductsMargin: [],
  clearState: [],
  dailyOrderPerVertical: [],
  userStatus: [],
  outletStatus: [],
  allTargets: [],
  outletSalesDistribution: [],
  startDate: moment().subtract(30, "days").format("DD/MM/YYYY"),
  endDate: moment().format("DD/MM/YYYY"),
  vetID: [],
  canShowTopProducts: false,
};

function ReportsReducer(state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, isLoading: true };
    case GET_DAILY_SALES_REPORT_SUCCESS:
      return { ...state, dailySalesReport: action.payload };
    case GET_OUTLETS_COUNT_BY_TYPE_SUCCESS:
      return { ...state, outletsCountByType: action.payload };
    case GET_LEADS_COUNT_BY_TYPE_SUCCESS:
      return { ...state, leadsCountByType: action.payload };
    case GET_ORDERS_BREAKDOWN_SUCCESS:
      return { ...state, ordersBreakDown: action.payload };
    case GET_OUTLET_AVERAGE_ORDER_VALUE_SUCCESS:
      return { ...state, avgOutletOrderValue: action.payload };
    case GET_USERS_BY_ROLE_SUCCESS:
      return { ...state, usersByRole: action.payload };
    case GET_USERS_BY_STATUS_SUCCESS:
      return { ...state, usersByStatus: action.payload };
    case GET_OUTLETS_BY_USERS_SUCCESS:
      return { ...state, outletsByUser: action.payload };
    case GET_OUTLETS_CREATION_TREND_SUCCESS:
      return { ...state, outletCreationTrend: action.payload };
    case GET_OUTLETS_ORDERING_TREND_SUCCESS:
      return { ...state, outletOrderingTrend: action.payload };
    case GET_ORDER_STATUS_SUCCESS:
      return { ...state, orderStatusSummary: action.payload };
    case GET_DAILY_ORDER_TREND_SUCCESS:
      return { ...state, dailyOrderTrend: action.payload };
    case GET_FREQUENTLY_ORDERED_PRODUCT_SUCCESS:
      return { ...state, frequentlyOrderProducts: action.payload };
    case GET_ORDER_PROPCESS_BY_USER_SUCCESS:
      return { ...state, orderProcessByUser: action.payload };
    case GET_PERCENT_ORDER_PROCESS_SUCCESS:
      return { ...state, percenOrderProcess: action.payload };
    case GET_TOP_VERTICAL_SUCCESS:
      return { ...state, topVertical: action.payload };
    case GET_TOP_VERTICAL_BY_MARGIN_SUCCESS:
      return { ...state, topVerticalByMargin: action.payload };
    case GET_PRODUCT_MARGIN_TREND_SUCCESS:
      return { ...state, productMarginTrend: action.payload };
    case GET_TOP_CUSTOMERS_SUCCESS:
      return { ...state, topCustomers: action.payload };
    case GET_TOP_CUSTOMERS_BY_OUTLET_TYPE_SUCCESS:
      return { ...state, topCustomersByOutlet: action.payload };
    case GET_SALES_REPORT_SUCCESS:
      return { ...state, sales: action.payload };
    case GET_TOTAL_REVENUE_SUCCESS:
      return { ...state, totalRevenue: action.payload };
    case GET_TOTAL_ORDER_COUNT_SUCCESS:
      return { ...state, ordersCount: action.payload };
    case GET_USERS_REPORT_SUCCESS:
      return { ...state, userReport: action.payload };
    case GET_OUTLETS_PURCHASE_HISTORY_SUCCESS:
      return { ...state, outletPurchaseHistory: action.payload };
    case GET_TREEMAP_EVENT_SUCCESS:
      return { ...state, verticalArr: action.payload };
    case GET_DAILY_ORDER_SUMMARY_REPORT_SUCCESS:
      return { ...state, dailyOrderSummary: action.payload };
    case GET_DAILY_OUTLET_CREATION_SUCCESS:
      return { ...state, dailyOutletCreationSummary: action.payload };
    case GET_HOURLY_ORDER_PROCESSIGNG_SUCCESS:
      return { ...state, hourlyOrtderProcessing: action.payload };
    case GET_SALES_DISTRIBUTION_SUCCESS:
      return { ...state, salesDistribution: action.payload };
    case GET_TOP_PRODUCTS_SALES_BY_VERTICAL_SUCCESS:
      return { ...state, topProductsSales: action.payload };
    case CLEAR_STATE_SUCCESS:
      return { ...state, clearState: action.payload };
    case GET_TOP_PRODUCTS_MARGIN_BY_VERTICAL_SUCCESS:
      return { ...state, topProductsMargin: action.payload };
    case GET_TREEMAP_MARGIN_EVENT_SUCCESS:
      return { ...state, verticalMarginArr: action.payload };
    case GET_DAILY_ORDER_PER_VERTICAL_SUCCESS:
      return { ...state, dailyOrderPerVertical: action.payload };
    case GET_OUTLETS_STATUS_SUCCESS:
      return { ...state, outletStatus: action.payload };
    case GET_USERS_STATUS_SUCCESS:
      return { ...state, userStatus: action.payload };
    case GET_ALL_TARGETS_SUCCESS:
      return { ...state, allTargets: action.payload };
    case UPDATE_START_DATE:
      return { ...state, startDate: action.payload };
    case UPDATE_END_DATE:
      return { ...state, endDate: action.payload };
    case UPDATE_VET_ID:
      return { ...state, vetID: action.payload };
    case GET_OUTLET_SALES_DISTRIBUTION_SUCCESS:
      return { ...state, outletSalesDistribution: action.payload };
    case TOGGLE_TOP_PRODUCTS_VISIBILITY:
      return { ...state, canShowTopProducts: action.payload };

    default:
      return state;
  }
}

export default ReportsReducer;
