import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddRoleForm from "./AddRoleForm";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";

const AddRolePage = ({ editMode, role, resetView }) => {
  return (
    <div className="row">
      <div className="col-md-1 back-arrow">
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="2x"
          className="mr-2"
          onClick={() => resetView()}
        />
      </div>
      <div className="col-md-11">
        {!editMode && <PageTopHeader title="Add new role" />}
        {editMode && <PageTopHeader title="Edit  role" />}
        <AddRoleForm resetView={resetView} editMode={editMode} role={role} />
      </div>
    </div>
  );
};

export default AddRolePage;
