import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDailySalesAction,
  getAllSalesAction,
} from "../../../store/actions/SalesAction";
import axios from "axios";
import qs from "qs";
import moment from "moment"
import ReactApexChart from 'react-apexcharts';

//Bar Graph withought dates

const BarChart = ({title,yaxisData,xaxisData,stacked,xDataType,dateFormant,horizontal}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dailySales = useSelector((state) => state.salesManager.dailySales);
  const allSales = useSelector((state) => state.salesManager.allSales);
  const dispatch = useDispatch();

  useEffect(() => {
    let allSalesFilter = {
      orderDir: "desc",
      orderBy: "id",
      searches: [
        {
          key: "deliveryDateInt",
          value: moment().format("YYYYMMDD"),
        },
      ],
    };

    let startDate = moment().add(-3,"days").format("DD-MM-YYYY"); 
    let endDate = moment().format("DD-MM-YYYY");

    // dispatch(getDailySalesAction(startDate,endDate));
    // dispatch(getAllSalesAction(allSalesFilter));
  }, []);


  let date = [];
  let totalAmount = [];
  let totalSales = [];

  for(const element of dailySales){
    date.push(moment(element.date).format("DD/MM/YYYY"))  ;
    totalAmount.push(parseInt(element.totalAmount))
    totalSales.push(parseInt(element.totalSales))
    
  }
 
  console.log("reault",date,totalAmount)

  const series = yaxisData

  const options =  {
        chart: {
          height: 350,
          type: 'bar',
          stacked:stacked
        },
        dataLabels: {
          enabled: true
        },
        plotOptions: {
            bar: {
              horizontal: horizontal,
            }
        },
        title: {
            text: title,
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
         // type:xDataType,
          categories:xaxisData,
           labels: {
             rotate: -15,
             rotateAlways: true,
        //     formatter: function(val, timestamp) {
        //      return moment(new Date(timestamp)).format(dateFormant)
        //  //    return moment(new Date(timestamp)).format("DD MMM YYYY hh:mm")
        //     }
         },
        // tooltip: {
        //   x: {
        //     format: 'dd/MM/yy'
        //   },
        // },
      },
      noData: { //checks if there us no data to display
        text: "No results!",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      }
      };

    

  return (
    <>
        <ReactApexChart 
            options={options} 
            series={series} 
            type="bar"
            height={300} />

    
    </>
  );
};

export default BarChart;
