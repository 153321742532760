import React, {useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDailySalesAction,
  getAllSalesAction,
} from "../../store/actions/SalesAction";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import Table from "../../components/dom/Table/Table";
import { TabsWrapper } from "../../styles/Tabs";
import moment from "moment";
import { allSalesColumns } from "./columns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateSearchtWrapper } from "../../styles/schedule";
import { ButtonWithIcon } from "../../styles/Forms";


const SalesPage = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dailySales = useSelector((state) => state.salesManager.dailySales);
  const allSales = useSelector((state) => state.salesManager.allSales);
  const dispatch = useDispatch();

  console.log(dailySales);

  useEffect(() => {
    let allSalesFilter = {
      orderDir: "desc",
      orderBy: "id",
      searches: [
        {
          key: "deliveryDateInt",
          value: moment().format("YYYYMMDD"),
        },
      ],
    };
    dispatch(getDailySalesAction());
    dispatch(getAllSalesAction(allSalesFilter));
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
 //   scrollToTop();
    console.log("startDate:",startDate)
    console.log("endDate",endDate)
   let payload=[
      {
        key: "orderType",
        value: "AGENT,MANAGER",
      },

      {
        key: "deliveryDateInt",
        comparisonType :"BETWEEN_LONG_VALUES",
        lowerLimit : moment(startDate).add(1, "days").format("YYYYMMDD"),
        upperLimit :moment(endDate).add(1, "days").format("YYYYMMDD"),
       
      },
    ]
    
    let orderFilter = {
      orderDir: "desc",
      orderBy: "id",
      searches: payload,
    };

    dispatch(getAllSalesAction(orderFilter));
  };

  const DailySaleColumn = [
    {
      Header: "Items",
      accessor: (d) => moment(d.date).format("LLL"),
    },

    {
      Header: "Item Name",
      accessor: "productName",
    },
    {
      Header: "QTY",
      accessor: "totalSales",
    },
    {
      Header: "Total",
      accessor: "totalAmount",
    },
  ];

  return (
    <>
      <PageTopHeader title="Sales Management" />
      <TabsWrapper>
        <Tabs>
          <TabList>
            <Tab>All Sales</Tab>
            <Tab>Daily Sales</Tab>
          </TabList>
          <TabPanel>
          <DateSearchtWrapper>
                    <form onSubmit={onSubmit} role="form" >
                      <div className="row">
                         <div className="form-group col-sm-5">
                            <div className="datePicker">
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  placeholderText="from"
                                  className="date-picker-input"
                                />
                          </div>
                         </div>
                         <div className="form-group col-sm-5">
                          <div className="datePicker">
                            <DatePicker 
                                selected={endDate} 
                                onChange={date => setEndDate(date)}
                                placeholderText="to"
                                className="date-picker-input"
                                />
                            </div>
                        </div>
                        <div className="col-sm-2">
                          <ButtonWithIcon type="submit">filter</ButtonWithIcon>
                        </div> 
                    
                      </div>
                    </form>
                    </DateSearchtWrapper>
            <Table columns={allSalesColumns} data={allSales} />
          </TabPanel>
          <TabPanel>
            <Table columns={DailySaleColumn} data={dailySales} />
          </TabPanel>
        </Tabs>
      </TabsWrapper>
    </>
  );
};

export default SalesPage;
