import {
  GET_SUPPLIERS_OPTIONS_SUCCESS,
  GET_SUPPLIERS_SUCCESS,
} from "../constants/ActionTypes";
const initialState = {
  suppliers: [],
  supplierOptions: [],
};

function supplierReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUPPLIERS_SUCCESS:
      return { ...state, suppliers: action.payload };
    case GET_SUPPLIERS_OPTIONS_SUCCESS:
      return { ...state, supplierOptions: action.payload };
    default:
      return state;
  }
}

export default supplierReducer;
