import _ from "lodash";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const sortOutlets = (outlets) => {
  return outlets.sort(function (a, b) {
    var trimmedOutletName = a.name;
    var trimmedOutletCompareName = b.name;

    if (typeof b.name !== "undefined" || b.name != null) {
      trimmedOutletCompareName = trimmedOutletCompareName
        ? trimmedOutletCompareName.trim()
        : "No Name";
    }

    if (
      (trimmedOutletName && typeof a.name !== "undefined") ||
      a.name != null
    ) {
      trimmedOutletName = trimmedOutletName
        .trim()
        .localeCompare(trimmedOutletCompareName);
    }
    //return a.name.trim().localeCompare(b.name.trim());
    return trimmedOutletName;
  });
};

// Group the Outlet  by area
export const groupOutletsByArea = (outlets, areas) => {
  let obj = {};
  areas.map((area) => {
    let outletInArea = _.filter(outlets, { areaId: area.areaId });

    if (outletInArea) {
      obj[area.areaName] = {
        outlets: outletInArea,
        areaId: area.areaId,
        id: area.id,
      };
    } else {
      obj[area.areaName] = { areaId: area.areaId, outlets: [], id: area.id };
    }
  });

  //var outletsGroupedByArea = _.groupBy(outlets, (location) => location.areaId);
  return obj;
};

// Get Areas and Outlers from calendar events

export const getOutletsAndAreasFromEvents = (calendarEvents) => {
  console.log("getOutletsAndAreasFromEvents");
  if (calendarEvents.length > 0) {
    let outlets = [];
    let areas = [];
    let areasAndOutletsOrdered = _.orderBy(calendarEvents, "start", "asc");
    let areasAndOutletsOrderedEndDate = _.orderBy(
      calendarEvents,
      "end",
      "desc"
    );
    areasAndOutletsOrderedEndDate = areasAndOutletsOrderedEndDate.filter(
      (endDateAreaOutlet) => endDateAreaOutlet.end !== null
    );
    console.log(
      "areasAndOutletsOrderedEndDate   = ",
      areasAndOutletsOrderedEndDate
    );

    calendarEvents.map((event) => {
      event["startDate"] = event.start;
      event["endDate"] = event.end;
      if (event.repeatType === "DAILY") {
        event["endDate"] = moment(event.end)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        event["endRecur"] = moment(event.end)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        event["end"] = moment(event.end)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      }

      if (event.outletId) {
        outlets.push(event);
      } else if (event.areaId) {
        areas.push(event);
      } else {
        return false;
      }
    });

    return {
      outlets: outlets,
      areas: areas,
      startDate: areasAndOutletsOrdered[0].start,
      endDate: areasAndOutletsOrderedEndDate[0].end,
    };
  } else {
    return {
      outlets: [],
      areas: [],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
  }
};

export const generateEventData = (eventInfo) => {
  let eventData = {
    ...(eventInfo.extendedProps.entityType === "outlet"
      ? { outletId: parseInt(eventInfo.extendedProps.outletId) }
      : { areaId: eventInfo.id }),
    activities: eventInfo.extendedProps.activities,
    repeatType: eventInfo.extendedProps.repeatType,
    start: eventInfo.start,
    end: eventInfo.start,
    comments: eventInfo.extendedProps.comments,
    title: eventInfo.title,
    id: parseInt(eventInfo.id),
  };
  return eventData;
};

export const getCalendarEventsFromToday = (calendarEvents) => {
  var today = moment().subtract(1, "days").format("YYYY-MM-DD");
  const CalendarEventsFromToday = _.filter(calendarEvents, (event) => {
    let isEventAfterToday = moment(event.start).isAfter(today);
    if (isEventAfterToday) {
      return true;
    }
  });

  return CalendarEventsFromToday;
};

export const createPdf = (columns, rows, title) => {
  var doc = new jsPDF("p", "pt");
  doc.setFontSize(20);
  doc.setTextColor(40);
  doc.setFontStyle("normal");
  doc.text(title, 10, 20);

  doc.autoTable(columns, rows, {
    startY: 70,
    margin: { horizontal: 10 },
    styles: { overflow: "linebreak" },
    bodyStyles: { valign: "top" },
    columnStyles: { email: { columnWidth: "wrap" } },
    theme: "striped",
  });

  doc.save(`${title}.pdf`);
};
