import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import {
  faArrowLeft,
  faBell,
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import "./TopBar.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LogoutModal from "../../../components/dom/LogoutModal";
import NotificationModal from "../../../components/dom/NotificationModal";
import {
  addNewNotificationAction,
  markReadAction,
} from "../../../store/actions/UserAction";
import LogoutBubble from "../../../components/auth/LogoutBubble";
import NotificationBubble from "../../../components/general/NotificationBubble";
import { NOTIFICATION_SERVICE } from "../../../constants";

import MenuIcon from '@material-ui/icons/Menu';

const TopBar = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState(false);
  const user = useSelector((state) => state.authManager.user);
  const isNewNotification = useSelector(
    (state) => state.userManager.isNewNotification
  );
  const newNotificationCount = useSelector(
    (state) => state.userManager.newNotificationCount
  );
  const subscriptionToken = useSelector(
    (state) => state.authManager.subscriptionToken
  );

  let message_o = {
    activity: "ORDER",
    identity: "202103100000001",
    title: "Order Processed",
    body: "We are delighted to inform you that order number 202103100000001 placed on 2021-03-10 17:52:46 has been processed.",
    params: {},
  };

  console.log(JSON.stringify(JSON.stringify(message_o)));
  var stompClient = null;
  const dispatch = useDispatch();

  const handleSidebarToggle = () => {
    setToggleSidebar(!toggleSidebar);
  };

  const showLogoutPopup = () => {
    setmodalIsOpen(true);
  };

  const showNotificationPopup = () => {
    dispatch(markReadAction());
    setNotificationModalIsOpen(true);
  };

  useEffect(() => {
    connect();
  }, []);

  var stompClient = null;
  var stompFailureCallback = function (error) {
    console.log("STOMP: " + error);

    console.log("STOMP: Reconecting in 10 seconds");
  };
  var stompSuccessCallback = function (success) {
    console.log("--->>> Connected");
    subscribe();
  };

  function connect() {
    setTimeout(function () {
      var socket = new SockJS("https://publication.aymzz.com/real-time-server");
      stompClient = Stomp.over(socket);
      stompClient.debug = null;
      stompClient.connect({}, stompSuccessCallback, function () {});
      socket.onclose = function () {
        stompClient.disconnect();
        // connect();
      };
    }, 1000);
  }

  function subscribe() {
    stompClient.subscribe(
      "/subscribe/" + NOTIFICATION_SERVICE + "/" + user.username,
      function (message) {
        console.log(message.body);
        message.ack();
        dispatch(addNewNotificationAction(message.body));
      }
    );
  }

  // function connect() {
  //   var socket = new SockJS("https://publication.aymzz.com/real-time-server");

  //   stompClient = Stomp.over(socket);
  //   stompClient.connect(
  //     {},
  //     function(frame) {
  //       console.log("Connected!" + frame);

  //       stompClient.subscribe(
  //         "/notifications/" + user.username + "/" + NOTIFICATION_SERVICE,
  //         function(notification) {
  //           console.log("Show notification");
  //           console.log(notification.body);
  //           dispatch(addNewNotificationAction(JSON.parse(notification.body)));
  //         }
  //       );
  //     },
  //     function(err) {
  //       console.log("err", err);
  //     }
  //   );
  // }

  const collapseMenu = () => {
    console.log("collapes menu!!")
  }
  return (
    <div className="">
      <div className="row">
      <div className="col-sm-2">
        {/* <MenuIcon
          onClick={collapseMenu}
        /> */}       
      </div>
        {user && (
          <div className="col-sm-10">
            <div className="float-right top-nav-right-div">
              <span
                className={
                  isNewNotification
                    ? "fa-layers fa-fwprimary"
                    : "fa-layers fa-fw"
                }
                onClick={showNotificationPopup}
              >
                <i className="fas fa-bell"></i>
                {newNotificationCount > 0 && (
                  <span class="fa-layers-counter">{newNotificationCount}</span>
                )}
              </span>

              <h5>
                {user.firstName.charAt(0)}.{user.lastName}
              </h5>

              <ExpandMoreIcon
                style={{ fontSize: "28px" }}
                className="pointer primary"
                onClick={showLogoutPopup}
              />
            </div>
          </div>
        )}
      </div>

      <LogoutModal
        isOpen={modalIsOpen}
        component={<LogoutBubble user={user} />}
        setIsOpen={setmodalIsOpen}
      />

      <NotificationModal
        isOpen={notificationModalIsOpen}
        component={<NotificationBubble user={user} />}
        setIsOpen={setNotificationModalIsOpen}
      />
    </div>
  );
};

export default TopBar;
