import { LOGIN, LOGOUT, TOKEN_VERIFY_SUCCESS } from "../constants/ActionTypes";
const initialState = {
  authenticated: false,
  permissions: [],
  accessToken: null,
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        authenticated: true,
        user: action.payload.user.user,
        permissions: action.payload.user.permissions,
        subscriptionToken: action.payload.user.subscriptionToken,
      };
    case LOGOUT:
      return { ...state, authenticated: false };
    case TOKEN_VERIFY_SUCCESS:
      return {
        ...state,
        user: action.user,
        token: action.token,
      };
    default:
      return state;
  }
}

export default AuthReducer;
