import axios from "axios";
import {
  APP_URL,
  contentTypeUrlEncoded,
  contentTypeApplicationJson,
  AUTOCOMPLETE_LIMIT,
  contentTypeFormData,
} from "../constants";
import qs from "qs";

export const getProducts = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/fetch/products`,
      JSON.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getAllProducts = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/fetch/all/products
      `,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const searchProducts = async (searchTerm, page) => {
  const result = await axios
    .get(
      `${APP_URL}productmanagement/productService/search/products/${searchTerm}?limit=${AUTOCOMPLETE_LIMIT}&page=${page}`
    )
    .then((data) => data);
  return result;
};

export const createProduct = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/add/product`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const deleteProduct = async (productId) => {
  const result = await axios
    .delete(
      `${APP_URL}productmanagement/productService/delete/product/${productId}`
    )
    .then((data) => data);
  return result;
};

export const deleteProducts = async (data) => {
  console.log("deleteProducts data = ", data);
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/delete/products`,
      data,
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const changeProductVisibility = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/change/product/status`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const uploadS3Excel = async (data) => {
  //http://sfainalipadevservice.aymzz.com/Products/products/bulk/upload
  const result = await axios
    .post(
      `http://sfainalipadevservice.aymzz.com/Products/products/bulk/upload`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const uploadProducts = async (file, fileName) => {
  var bodyFormData = new FormData();

  bodyFormData.append("file", file);

  const result = await axios
    .post(`${APP_URL}upload/file`, bodyFormData, contentTypeFormData)
    .then((data) => data);
  return result;
};

//`${APP_URL}product/bulk/upload/edit/details

export const getProductsByIds = async (productIds) => {
  var bodyFormData = new FormData();

  bodyFormData.append("productIds", productIds);

  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/fetch/bulk/products`,
      bodyFormData,
      contentTypeFormData
    )
    .then((data) => data);
  return result;
};

export const uploadProductsZip = async (file, fileName) => {
  console.log("uploadProductsZip service file = ", file);
  var bodyFormData = new FormData();

  bodyFormData.append("file", file);
  bodyFormData.append(
    "callbackUrl",
    `${APP_URL}productmanagement/productService/process/bulk/upload`
  );
  bodyFormData.append("isBatchCallback", true);
  bodyFormData.append("batchSize", 10);

  const result = await axios
    .post(`${APP_URL}product/bulk/upload`, bodyFormData, contentTypeFormData)
    .then((data) => data);
  return result;
};

export const bulkEditFileUpload = async (file, fileName) => {
  console.log("uploadProductsZip service file = ", file);
  var bodyFormData = new FormData();

  bodyFormData.append("file", file);
  bodyFormData.append(
    "callbackUrl",
    "https://compliancedev.aimgroup.co.tz:8443/sas/inalipa/productmanagement/productService/process/bulk/edit"
  );
  bodyFormData.append("isBatchCallback", true);
  bodyFormData.append("batchSize", 10);

  const result = await axios
    .post(`${APP_URL}product/bulk/edit`, bodyFormData, contentTypeFormData)
    .then((data) => data);
  return result;
};

export const createTaxCode = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/add/tax/code`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const setCutOffTime = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/updateCategoryTimeLimit`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const editProduct = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/edit/product`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const bulkEdit = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/create/bulk/edit/template`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const addProductVertical = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/create/product/vertical`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const createProductPackagingUnit = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/create/packaging/unit`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateProductPackagingUnit = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/update/packaging/unit`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const createProductMeasuringUnit = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/create/measurement/unit`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getProductVerticals = async (data) => {
  const result = await axios
    .get(`${APP_URL}productmanagement/productService/fetch/product/verticals`)
    .then((data) => data);
  return result;
};

export const getProductMeasuringUnits = async (data) => {
  const result = await axios
    .get(`${APP_URL}productmanagement/productService/fetch/measurement/units`)
    .then((data) => data);
  return result;
};

export const getPackagingUnit = async (data) => {
  const result = await axios
    .get(`${APP_URL}productmanagement/productService/fetch/packaging/units`)
    .then((data) => data);
  return result;
};

export const updateTaxCode = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/update/tax/code`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const createProductCategory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/add/product/category`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateProductCategory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/update/product/category`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const createProductSubcategory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/add/product/subcategory`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateProductSubcategory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/update/product/subcategory`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getProductCategories = async (data) => {
  const result = await axios
    .get(`${APP_URL}productmanagement/productService/fetch/product/category`)
    .then((data) => data);
  return result;
};

export const getProductSubcategories = async (data) => {
  const result = await axios
    .get(`${APP_URL}productmanagement/productService/fetch/product/subcategory`)
    .then((data) => data);
  return result;
};

export const setOutletProductPrice = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/createOutletPrice`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const setRouteProductPrice = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/createRoutePrice`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getTaxCodes = async (data) => {
  const result = await axios
    .get(`${APP_URL}productmanagement/productService/fetch/all/tax/codes`)
    .then((data) => data);
  return result;
};

export const createProductSKU = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/add/product/sku`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateProductSKU = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/add/product/sku`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
