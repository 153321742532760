import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import {
  getProductsAction,
  resetOrderItemsAction,
} from "../../store/actions/ProductAction";
import { getCreditsAction } from "../../store/actions/SalesAction";
import { getOutletOptionsAction } from "../../store/actions/OutletAction";

import { OrderFormWrapper, Value } from "../../styles/Order";
import { ButtonWithIcon, Label } from "../../styles/Forms";
import OrderForm from "./NewOrderForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import SFAAutocomplete from "../../components/molecules/Autocomplete/Autocomplete";
import "./order.css";
import { getOrderedProductsDetailsAction } from "../../store/actions/OrderAction";

const OutletOrderForm = ({ resetView, editMode, order }) => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const outletOptions = useSelector(
    (state) => state.outletManager.outletOptions
  );
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const userObj = useSelector((state) => state.authManager.user);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const userRoute = useSelector((state) => state.routeManager.route);

  const error = useSelector((state) => state.stateManager.error);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("OutletOrderForm order = ", order);
    dispatch(getOutletOptionsAction({}));
    dispatch(resetOrderItemsAction());
    console.log("Order Form editMode + Order", editMode, order);
    if (editMode) {
      let orderIds = order.orderItems.map((item) => item.productId).join(",");
      dispatch(getOrderedProductsDetailsAction(orderIds));
      console.log("orderIds = ", orderIds);
      let outlet = _.find(outletOptions, ["outletNumber", order.outletNumber]);
      console.log("outletOptions = ", outletOptions);
      if (outlet) {
        console.log("Edit Mode outlet  = ", outlet);
        setSelectedOutlet(outlet);
      }
      console.log(JSON.stringify(order));
    }
  }, []);

  const handleOutletChange = (outlet) => {
    console.log("handleOutletChange=>");

    setSelectedOutlet(outlet);
  };

  return (
    <OrderFormWrapper>
      <div className="row">
        <div className="col-md-6">
          {/* {error && <p className="alert alert-danger">{error}</p>} */}
          {/* <code>{JSON.stringify(selectedOutlet)}</code> */}
          <h2>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="mr-3 primary"
              onClick={() => resetView()}
            />
            Create an Order
          </h2>
        </div>
        <div className="col-md-6"></div>
      </div>

      {isError && <p className="alert alert-danger mt-2"> {errorMessage}</p>}
      {userObj.role === "call-centre-agent" && outletOptions.length === 0 && (
        <p className="alert alert-danger mt-2">You do not have any outlets</p>
      )}

      <div className="row mt-2 mb-3">
        <div className="col-sm-6">
          <Label>Outlet</Label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            name="color"
            options={outletOptions}
            isSearchable={true}
            placeholder="Select an outlet to continue..."
            onChange={(selectedOption) => {
              handleOutletChange(selectedOption);
            }}
            value={selectedOutlet}
            isDisabled={editMode}
          />
        </div>
        <div className="col-sm-6">
          <Label>Product List</Label>
          <SFAAutocomplete />
        </div>
      </div>

      {selectedOutlet && selectedOutlet.orderTaken === "true" && (
        <p className="alert alert-info">
          Order already taken from this customer
        </p>
      )}
      {error && <p className="alert alert-danger">{message}</p>}

      {selectedOutlet && (
        <OrderForm
          customer={selectedOutlet}
          editMode={editMode}
          order={order}
        />
      )}
    </OrderFormWrapper>
  );
};

export default OutletOrderForm;
