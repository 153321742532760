import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { TabsWrapper } from "../../styles/Tabs";
import OutletSummary from "../reports/outletSummary";
import OrderSummary from "../reports/OrdersSummary";
import CreditSummary from "../../pages/metabase/creditSummary";
import ReturnsSummary from "../../pages/metabase/returnsSummary";
import UserSummary from "../reports/UserSummary";
//import SalesMap from "../reports/SalesByRegionMap";
import SalesSummary from "../reports/SalesSummary";
import SalesDistribution from "./mapComponents/SalesDistribution";
import MapTest from "./MapTest";
// import SalesDistribution from "./NewMap"

const ReportsPage = () => {
    return (

        <div>
            <div><h2>Reports</h2></div>
            <div className="col-sm-12">
                <TabsWrapper>
                <Tabs>
                    <TabList>
                        <Tab>Users</Tab>
                        <Tab>Outlets</Tab>
                        <Tab>Orders</Tab>
                        <Tab>Sales</Tab>
                        {/* <Tab>Credits </Tab>
                        <Tab>Returns</Tab> */}
                        {/* <Tab>Sales Distribution</Tab> */}
                        <Tab>Distribution Map</Tab>
                        {/* <Tab>New Map</Tab> */}
                    </TabList>

                        <TabPanel>
                          <UserSummary/>
                        </TabPanel>
                        <TabPanel>
                          <OutletSummary/>
                        </TabPanel>
                        <TabPanel>
                          <OrderSummary />
                        </TabPanel>
                        <TabPanel>
                          <SalesSummary/>
                        </TabPanel>
                        {/* <TabPanel>
                          <CreditSummary /> 
                        </TabPanel>
                        <TabPanel>
                          <ReturnsSummary />
                        </TabPanel> */}
                        {/* <TabPanel>
                           <SalesMap/> 
                          <SalesDistribution/>
                        </TabPanel> */}
                        <TabPanel>
                          <MapTest/>
                        </TabPanel>
                        {/* <TabPanel>
                          <SalesDistribution/>
                        </TabPanel> */}
                </Tabs>
                </TabsWrapper>
            </div>
        </div>
    )
}

export default ReportsPage;
