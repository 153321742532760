import axios from "axios";
import {
  APP_URL,
  contentTypeUrlEncoded,
  contentTypeApplicationJson,
} from "../constants";
import qs from "qs";

//sales
export const getDailySalesReport = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/daily/sales`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getTopMarginProducts = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/top/margin/products`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getTopVertical = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/top/vertical`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
export const getTopVerticalByMargin = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/top/margin/vertical`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
export const getProductMarginTrend = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/margin/trend`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
export const getTopCustomers = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/top/customers`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
export const getTopCustomersByOutletType = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/top/customers/by/type`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
export const getSalesReport = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}salesmanagement/salesService/fetch/sales`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getOutletSalesDistribution = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/outlet/sales`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getTotalRevenue = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/total/revenue`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getDailyOrderSummary = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/daily/order/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getHourlyOrderProcessing = async (data) => {
  const result = await axios
    .post(
     `${APP_URL}analytics/reports/fetch/daily/order/processing/summary`,
      //`${APP_URL}analytics/reports/fetch/hourly/order/processing/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

//orders
export const getOrdersBreakDown = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/daily/orders/breakdown`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getAverageOutletOrderValue = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/average/outlet/order/value`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getOrderStatusSummary = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/orders/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getDailyOrderTrend = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/daily/orders/trend`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getDailyOrderPerVertical = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/product/order/by/vertical`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getFrequentlyOrderProduct = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/frequently/ordered/products`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const geOrderProcessByUser = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/order/process/by/user`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getPercentOfOrderProcess = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/daily/orders/trend`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getSalesDistributionMap = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/order/distribution/map`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

//outlets
export const geOutletsCountByType = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/outlets/by/type`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const geOutletsStatus = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/outlets/status/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const geUsersStatus = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/users/status/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const geLeadsCountByType = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/outlet/leads/by/type`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const geOutletsByUsers = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/outlets/by/users`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const geOutletsCreationTrend = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/outlets/by/trend`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const geOutletsCreationDaily = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/daily/outlet/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const geOutletsOrderingTrend = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/outlet/ordering/trend`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const geOutletsPurchaseHistory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/outlets/by/purchase/history`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

//users
export const getUsersByRole = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/users/by/role`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getUsersByStatus = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/users/by/status`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const fetchUsers = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/fetchUsers`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const fetchAllTargets = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}salesmanagement/salesService/fetch/sale/targets`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
