import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux"
import ReactApexChart from 'react-apexcharts';
import moment from "moment";

import { LOADING, ON_CLICK_SUCCESS } from "../../../store/constants/ActionTypes";
import {
  getTreeMapMarginEventAction,
  clearSateAction,
  getTopProductsMarginByVericalAction
} from "../../../store/actions/ReportsAction";
import {
  getVerticalOptionsAction,
} from "../../../store/actions/ProductAction";



const TreeMapChartMargin = ({title,yaxisData,startDate,endDate}) => {
  const [from, setFrom] = useState(null);
  const [dataSelectedIndex, setDataSelectedIndex] = useState(null);
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [to, setTo] = useState(null);
  const [options, setOptions] = useState(null);
  const verticalOptions = useSelector(
    (state) => state.productManager.verticalOptions
  );
  const topProductsSales = useSelector((state) => state.reportManager.topProductsSales);
 
  const savedStartDate = useSelector((state) => state.reportManager.startDate);
  const savedEndDate = useSelector((state) => state.reportManager.endDate);

  const topProductsMargin = useSelector((state) => state.reportManager.topProductsMargin);
  console.log("topProductsMargin!!!!222",topProductsMargin)
  const dispatch = useDispatch();

  useEffect(() => {
    //setOptions(graphOptions);
    setFrom(startDate);
    setTo(endDate);
  }, [savedStartDate, startDate, topProductsMargin]);

  useEffect(() => {
    console.log(
      "Tree Data changed",
      topProductsMargin.treedata,
      selectedVerticals,
      dataSelectedIndex
    );
    dispatch(
      getTreeMapMarginEventAction(
        selectedVerticals,
        dataSelectedIndex,
        topProductsMargin.treedata
      )
    );
  }, [topProductsMargin.treedata]);
  

  console.log("Changes to  startDate + endDate -->", startDate, endDate);

   const series = [
    {
      data : yaxisData
    }
  ]     
        
   const graphOptions =  {
        legend: {
            show: true
            },

        chart: {
          height: 350,
          type: 'treemap',
          events: {
            click: function(event, chartContext, config) {
              console.log(
                "Vertical clicked startDate + endDate -->",
                savedStartDate,
                savedEndDate
              );
             var  eventArr = config.globals.categoryLabels 
             var  selectedIndex = config.dataPointIndex
             setDataSelectedIndex(selectedIndex);
             setSelectedVerticals(eventArr);
           // dispatch(getTreeMapMarginEventAction(eventArr,selectedIndex));
           // dispatch(getTreeMapEventAction(eventArr,selectedIndex));
           dispatch(getVerticalOptionsAction());
            if(eventArr && eventArr.length !== 0 ){ 
   
              const selectedVertical = eventArr.filter((item,index) => {
                return index === selectedIndex
               })
               console.log("VET 2 SELECTED::=>",selectedVertical)
               console.log("VET 2 OPTIONS::=>",verticalOptions)
               console.log("VET START::-->",startDate)
               console.log("VET END::-->", endDate)
               
              var verticalId = verticalOptions.filter(item => {
                                               return item.label === selectedVertical[0]
                                             }).map((item) => item.value)
              console.log("VET 2 ID",verticalId[0])

              if((startDate === null || startDate === undefined) && (endDate !== null || endDate !== undefined) ){
                console.log("UNDEFINED -->")
                console.log("startDate::-->",startDate)
                console.log("endDate::-->", endDate)
                let fetchtProducts = {
                  "vertical":verticalId[0],  
                  "startDate": null,
                  "endDate": null
                }
                dispatch(getTopProductsMarginByVericalAction(fetchtProducts));
                dispatch(
                  getTreeMapMarginEventAction(
                    eventArr,
                    selectedIndex,
                    topProductsMargin.treedata
                    )
                  );

            } else if((startDate !== null || startDate !== undefined) && 
                      (endDate !== null || endDate !== undefined) ){
              console.log("OK-->")
                let fetchtProducts = {
                vertical: verticalId[0],
                startDate: savedStartDate,
                endDate: savedEndDate,
              };
              console.log("fetchProducts->>",fetchtProducts)
              dispatch(getTopProductsMarginByVericalAction(fetchtProducts));
             // dispatch(getTreeMapMarginEventAction(eventArr,selectedIndex,topProductsMargin.treedata));
            }

              //////////////////////////////////
              // let fetchtProducts = {
              //    "vertical":verticalId[0],
              //    "startDate": moment(startDate).format("DD/MM/YYYY"),
              //    "endDate": moment(startDate).format("DD/MM/YYYY")
              //  }
              //  dispatch(getTopProductsMarginByVericalAction(fetchtProducts));
              //  dispatch(getTreeMapMarginEventAction(eventArr,selectedIndex,topProductsMargin.treedata));
          
            }
            
             setTimeout(
              () =>
              dispatch(clearSateAction()),
              5000
            );
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        title: {
            text: title,
            align: 'center'
        },
        colors: [
            '#3B93A5',
            '#F7B844',
            '#ADD8C7',
            '#EC3C65',
            '#CDD7B6',
            '#C1F666',
            '#D43F97',
            '#1E5D8C',
            '#421243',
            '#7F94B0',
            '#EF6537',
            '#C0ADDB'
            ],

            plotOptions: {
                treemap: {
                    distributed: true,
                    enableShades: false
                }
                },


      
      };
    
   
     

  return (
    <>
        <ReactApexChart 
            options={graphOptions} 
            series={series} 
            type="treemap"
            height={300} />

    
    </>
  );
};

export default TreeMapChartMargin;
