import React from "react";
import EDOLogo from "../../assets/img/logo_white.png";
import moment from "moment";
import bg from "../../assets/img/login_bg.png";
import bg2 from "../../assets/img/login_bg_woman.png";
import bg3 from "../../assets/img/login_bg_3.png";
import _ from "lodash";

const LoginLeftColumn = () => {
  let bgs = [bg, bg2, bg3];
  var randomBgImage = _.sample(bgs);

  return (
    <div className="col-sm-6 pl-0 pr-0">
      <div
        className="bg-left-side"
        style={{ backgroundImage: `url(${randomBgImage})` }}
      >
        <div className="bg-overlay">
          <a href="/">
            <img src={EDOLogo} alt="EDO Logo" style={{ width: "200px" }} />
          </a>
          <div className="footer row">
            <div className="col-sm-6">
              &copy; {moment().format("Y")} All Rights Reserved
            </div>
            <div className="col-sm-6 text-right pr-1">
              A product by AIMGROUP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLeftColumn;
