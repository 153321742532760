import { METABASE_SITE_URL, METABASE_SECRET_KEY } from "../../constants/index";
var jwt = require("jsonwebtoken");

export function getIframeURL() {
  var payload = {
    resource: { dashboard: 125 },
    params: {},
  };
  var token = jwt.sign(payload, METABASE_SECRET_KEY);

  var iframeUrl =
    METABASE_SITE_URL +
    "/embed/dashboard/" +
    token +
    "#bordered=true&titled=true";

  return iframeUrl;
}

export function getUserIframeURL(reportId, user, userRoute) {
  console.log("getUserIframeURL", reportId, user, userRoute);

  var payload = {
    resource: { dashboard: reportId },
    params: {},
  };

  if (user.role === "call-centre-agent") {
    if (
      (reportId === 158 || reportId === 159 || reportId === 131) &&
      userRoute.route &&
      userRoute.route.routeCode
    ) {
      if (user.shift === "AM") {
        console.log("show shift:", user.shift);
        payload["params"] = {
          shift: "AM",
          username: user.username,
          route: userRoute.route.routeCode,
        };
      } else if (user.shift === "PM") {
        console.log(user.shift);
        payload["params"] = {
          shift: "PM",
          username: user.username,
          route: userRoute.route.routeCode,
        };
      }
    } else if (
      reportId === 133 ||
      (reportId === 132 && userRoute.route && userRoute.route.routeCode)
    ) {
      if (user.shift === "AM") {
        payload["params"] = { shift: "AM", route: userRoute.route.routeCode };
      } else if (user.shift === "PM") {
        console.log(user.shift);
        payload["params"] = { shift: "PM", route: userRoute.route.routeCode };
      }
    } else {
      if (user.shift === "AM") {
        console.log(user.shift);
        payload["params"] = { shift: "AM", username: user.username };
      } else if (user.shift === "PM") {
        console.log(user.shift);
        payload["params"] = { shift: "PM", username: user.username };
      }
    }
  } else if (user.role === "call-centre-manager") {
    /////
    if (reportId === 134) {
      if (user.shift === "AM") {
        payload["params"] = { shift: "AM", role: "call-centre-agent" };
      } else if (user.shift === "PM") {
        payload["params"] = { shift: "PM", role: "call-centre-agent" };
      }
    } else {
      if (user.shift === "AM") {
        console.log(user.shift);
        payload["params"] = { shift: "AM" };
      } else if (user.shift === "PM") {
        console.log(user.shift);
        payload["params"] = { shift: "PM" };
      }
    }
  } else if (user.role === "cash_office") {
    payload["params"] = {};
  } else if (user.role === "admin") {
    payload["params"] = {};
  }

  var token = jwt.sign(payload, METABASE_SECRET_KEY);

  var iframeUrl =
    METABASE_SITE_URL +
    "/embed/dashboard/" +
    token +
    "#bordered=true&titled=true";

  console.log("IFRAME URL ", iframeUrl);
  return iframeUrl;
}
