import {
  GET_USERS_SUCCESS,
  USER_RETRIVAL_SUCCESS,
  GET_ROLES_SUCCESS,
  GET_PERMISSIONS_SUCCESS,
  ROLE_UPDATE_SUCCESS,
  PERMISSION_UPDATE_SUCCESS,
  ADD_PERMISSION_SUCCESS,
  DRIVERS_FETCH_SUCCESS,
  FETCH_USER_BY_ROLE_SUCCESS,
  SET_USERNAME,
  GET_NEW_NOTIFICATION,
  NOTIFICATIONS_READ_SUCCESS,
  GET_ROLES_OPTIONS_SUCCESS,
  USER_LOADING,
  GET_USER_VERTICALS_SUCCESS,
  CLEAR_USER_OUTLETS,
} from "../constants/ActionTypes";
const initialState = {
  users: [],
  user: {},
  roles: [],
  permissions: [],
  drivers: [],
  roleUsers: [],
  username: null,
  loading: true,
  total: 0,
  totalActive: 0,
  totalInactive: 0,
  notifications: [],
  isNewNotification: false,
  newNotificationCount: 0,
  roleOptions: [],
  isLoading: true,
  userVerticals: [],
};

function UserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        total: action.total,
        totalActive: action.totalActive,
        totalInactive: action.totalInactive,
      };
    case DRIVERS_FETCH_SUCCESS:
      return { ...state, drivers: action.payload };
    case GET_USER_VERTICALS_SUCCESS:
      return {
        ...state,
        userVerticals: action.payload,
        verticalOptions: action.options,
      };
    case CLEAR_USER_OUTLETS:
      return {
        ...state,
        userVerticals: [],
        verticalOptions: [],
      };
    case USER_LOADING:
      return { ...state, isLoading: true };
    case FETCH_USER_BY_ROLE_SUCCESS:
      return { ...state, roleUsers: action.payload, username: null };
    case USER_RETRIVAL_SUCCESS:
      return { ...state, user: action.payload, isLoading: false };
    case SET_USERNAME:
      return { ...state, username: action.payload };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };
    case GET_ROLES_OPTIONS_SUCCESS:
      return {
        ...state,
        roleOptions: action.payload,
      };

    case ROLE_UPDATE_SUCCESS:
      var index = state.roles.findIndex((x) => x.id === action.payload.id);
      if (index === -1) {
        // handle error
      } else {
        return {
          roles: [
            ...state.roles.slice(0, index),
            Object.assign({}, state.roles[index], action.payload),
            ...state.roles.slice(index + 1),
          ],
        };
      }
      break;
    case PERMISSION_UPDATE_SUCCESS:
      var permission_index = state.permissions.findIndex(
        (x) => x.id === action.payload.id
      );
      if (permission_index === -1) {
        // handle error
      } else {
        return {
          permissions: [
            ...state.permissions.slice(0, permission_index),
            Object.assign(
              {},
              state.permissions[permission_index],
              action.payload
            ),
            ...state.permissions.slice(permission_index + 1),
          ],
        };
      }
      break;
    case ADD_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: [...state.permissions, action.payload],
      };
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      };
    case GET_NEW_NOTIFICATION:
      console.log(state, action.payload);
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
        isNewNotification: true,
        newNotificationCount: state.newNotificationCount + 1,
      };
    case NOTIFICATIONS_READ_SUCCESS:
      return {
        ...state,
        isNewNotification: false,
        newNotificationCount: 0,
      };
    default:
      return state;
  }
}

export default UserReducer;
