import React, { useState, useEffect } from "react";
import { updateProductCategoryAction } from "../../store/actions/ProductAction";
import { useDispatch, useSelector } from "react-redux";
import { FormWrapper, Input, Label, ButtonSmall } from "../../styles/Forms";
import ImageUploader from "../../components/utils/ImageUploader";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  addOutletCategoryAction,
  updateOutletCategoryAction,
} from "../../store/actions/OutletAction";
import _ from "lodash";

const nameRegExp = /^([^0-9]*)$/;

const AddCategoryForm = ({ editMode, category }) => {
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);

  const data = useSelector((state) => state.stateManager.data);
  const userObj = useSelector((state) => state.authManager.user);

  useEffect(() => {
    if (success) {
      dispatch({ type: "RESET_VIEW" });
    }
  });

  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        name: editMode ? category.name : "",

        createdBy: userObj.username,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          //console.log(values);
          if (editMode) {
            let formData = _.clone(values);
            formData["id"] = category.id;
            formData["updatedBy"] = userObj.username;
            formData = _.omit(formData, "createdBy");
            //console.log(formData);
            dispatch(updateOutletCategoryAction(formData));
          } else {
            console.log(values);
            dispatch(addOutletCategoryAction(values));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .matches(nameRegExp, " Name cannot contain number")
          .required(" Name is required "),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div>
            <FormWrapper>
              {success && <p className="alert alert-success">{message}</p>}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row mb-4">
                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="name">Name </Label>
                    <Input
                      id="name"
                      placeholder="Enter  Name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? " is-invalid" : ""
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error ml-2 mt-2"> {errors.name}</p>
                    )}
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3">
                  <ButtonSmall type="submit" disabled={isSubmitting}>
                    {editMode && <span>Update Outlet Type</span>}
                    {!editMode && <span>Create Outlet Type</span>}
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddCategoryForm;
