import {
  GET_TRUCKS_SUCCESS,
  GET_TRUCK_SUCCESS,
  GET_TRUCKS_OPTIONS_SUCCESS,
  GET_TRUCK_SURVEYS_SUCCESS,
} from "../constants/ActionTypes";
const initialState = {
  trucks: [],
  truck: {},
  truckOptions: [],
  surveys: [],
};

function TruckReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRUCKS_SUCCESS:
      return { ...state, trucks: action.payload };
    case GET_TRUCK_SUCCESS:
      return { ...state, truck: action.payload };
    case GET_TRUCKS_OPTIONS_SUCCESS:
      return { ...state, truckOptions: action.payload };
    case GET_TRUCK_SURVEYS_SUCCESS:
      return { ...state, surveys: action.payload };
    default:
      return state;
  }
}

export default TruckReducer;
