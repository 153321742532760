import axios from "axios";
import {
  APP_URL,
  contentTypeApplicationJson,
  contentTypeUrlEncoded
} from "../constants";
import qs from "qs";

export const getProductionSchedule = async data => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetchProductionSchedule`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then(res => res);
  return result;
};
