import React, { useEffect, useState } from "react";
import DateTime from "react-datetime";
import moment from "moment";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Select from "react-select";
import { IconButton } from "@material-ui/core";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import StoreIcon from "@material-ui/icons/Store";
import RepeatIcon from "@material-ui/icons/Repeat";
import MessageIcon from "@material-ui/icons/Message";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  getTaskListAction,
  selectEventAction,
  toggleEventModalAction,
  updateCalendarEventAction,
} from "../../../store/actions/routePlanActions";
import { useDispatch, useSelector } from "react-redux";
import { getCalendarEventsFromToday } from "../../../utils/routePlanUtils";
import "./EventForm.css";
import "react-datetime/css/react-datetime.css";

const EventForm = ({ event, onClose, deleteEvent }) => {
  const [comments, setComments] = useState("");
  const [canShoweRepeatUntilDate, setCanShoweRepeatUntilDate] = useState(false);
  const [repeatOptions, setRepeatOptions] = useState([
    { value: "DAILY", label: "Daily" },
    { value: "WEEKLY", label: "Weekly" },
  ]);
  const [repeatUntil, setRepeatUntil] = useState(
    moment()
      .add("14", "days")
      .format("YYYY-MM-DD")
  );
  const [selectedTask, setSelectedTask] = useState([]);
  const [selectedRepeatType, setSelectedRepeatType] = useState("");
  const [taskList, setTaskList] = useState([]);

  const selectedUser = useSelector((state) => state.userManager.user);
  const savedTaskListOptions = useSelector(
    (state) => state.routePlanManager.taskListOptions
  );
  const [taskListOptions, setTaskListOptions] = useState(savedTaskListOptions);

  const calendarEvents = useSelector(
    (state) => state.routePlanManager.calendarEvents
  );
  const selectedEvent = useSelector((state) => state.routePlanManager.event);
  const isopen = useSelector((state) => state.routePlanManager.isModalOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaskListAction({ roleName: selectedUser.role }));
    let activities = selectedEvent.activities.split(",");
    console.log("activities arr =", activities);
    activities.map((activity) => {
      let savedActivity = _.find(taskListOptions, { value: activity });
      console.log(
        "savedActivity activity+ taskListOptions",
        activity,
        taskListOptions
      );
      if (savedActivity) {
        handleTasksChange(savedActivity);
        console.log("savedActivity Found = ", savedActivity);
      }
    });
    let storedRepeatType = _.find(repeatOptions, {
      value: selectedEvent.repeatType,
    });
    setComments(selectedEvent.comments);
    if (storedRepeatType) {
      setSelectedRepeatType(storedRepeatType);
    }
  }, []);

  const handleTasksChange = (option) => {
    setSelectedTask(null);
    let newOptions = taskListOptions.filter(
      (repeatOption) => repeatOption !== option
    );

    setTaskListOptions(newOptions);
    let tasks = [...taskList, option];

    let updatedEvent = _.clone(selectedEvent);
    updatedEvent.activities = tasks.map((task) => task.value).join(",");
    setTaskList(tasks);
    dispatch(selectEventAction(updatedEvent));
    console.log(
      " handleTasksChange updateCalendarEventAction updatedEvent= ",
      updatedEvent
    );
    dispatch(updateCalendarEventAction(updatedEvent));
  };

  const handleRepeatTypeChange = (option) => {
    console.log("handleRepeatTypeChange event =", event);
    let repeatType = option.value;
    let events = getCalendarEventsFromToday(calendarEvents);
    let singleEvent = _.clone(event);
    if (repeatType === "DAILY") {
      singleEvent.dow = [0, 1, 2, 3, 4, 5, 6];
      singleEvent.repeatType = "DAILY";
      singleEvent.startRecur = singleEvent.start;
      singleEvent.end = repeatUntil;
      singleEvent.endRecur = repeatUntil;
      dispatch(selectEventAction(singleEvent));
      dispatch(updateCalendarEventAction(singleEvent));
      setCanShoweRepeatUntilDate(true);

      // this.updateCalendarEvents(singleEvent);
    } else if (repeatType === "WEEKLY") {
      singleEvent.daysOfWeek = [moment(singleEvent.start).day()];
      singleEvent.repeatType = "WEEKLY";
      singleEvent.startRecur = singleEvent.start;
      singleEvent.end = repeatUntil;
      singleEvent.endRecur = repeatUntil;
      console.log("WEEKLY REPEAT singleEvent  = ", singleEvent);
      dispatch(selectEventAction(singleEvent));
      dispatch(updateCalendarEventAction(singleEvent));
      setCanShoweRepeatUntilDate(true);
    } else {
    }
  };
  const removeTask = (taskToBeRemoved) => {
    let updatedEvent = _.clone(selectedEvent);
    let tasks = taskList.filter((task) => {
      return task !== taskToBeRemoved;
    });
    setTaskList(tasks);
    setTaskListOptions([...taskListOptions, taskToBeRemoved]);
    updatedEvent.activities = tasks.map((task) => task.value).join(",");
    dispatch(selectEventAction(updatedEvent));
    dispatch(updateCalendarEventAction(updatedEvent));

    //console.log(taskToBeRemoved);
  };

  const handleRepeatDateChange = (repeatEndDate) => {
    let untilDate = moment(repeatEndDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
    let singleEvent = _.clone(selectedEvent);
    singleEvent.end = untilDate;
    singleEvent.endRecur = untilDate;
    dispatch(selectEventAction(singleEvent));
    dispatch(updateCalendarEventAction(singleEvent));
    console.log("handleRepeatDateChange singleEvent = ", singleEvent);
  };

  const close = () => {
    onClose();
  };

  const updateEventComment = (comment) => {
    console.log("updateEventComment comment = ", comment);
    setComments(comment);
    let singleEvent = _.clone(selectedEvent);
    singleEvent.comment = comment;
    dispatch(selectEventAction(singleEvent));
    dispatch(updateCalendarEventAction(singleEvent));
  };

  return (
    <div className="EventForm ">
      <div className="EventForm__header p-2">
        <div className="EventForm__header_left">
          <StoreIcon />
          {event.title}
        </div>
        <div className="EventForm__header_right">
          <IconButton onClick={deleteEvent}>
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="EventForm__content p-2">
        <div className="EventForm__content__section">
          <div className="EventForm__content__sectionLeft">
            <FormatListBulletedIcon />
          </div>
          <div className="EventForm__content__sectionRight">
            <Select
              options={taskListOptions}
              onChange={handleTasksChange}
              placeholder="Add tasks..."
              value={selectedTask || ""}
            />
          </div>
        </div>

        {taskList.map((task) => (
          <div className="EventForm__content__tasks" key={task.value}>
            <div className="EventForm__content__tasksLeft">
              <i className="fa fa-check-circle"></i> {task.label}
            </div>
            <div className="EventForm__content__tasksRight">
              <i
                className="fa fa-times task-remover"
                onClick={() => removeTask(task)}
              ></i>
            </div>
          </div>
        ))}

        <div className="EventForm__content__section">
          <div className="EventForm__content__sectionLeft">
            <RepeatIcon />
          </div>
          <div className="EventForm__content__sectionRight">
            <Select
              options={repeatOptions}
              onChange={handleRepeatTypeChange}
              placeholder="Repeat..."
              value={selectedRepeatType}
            />
          </div>
        </div>

        {canShoweRepeatUntilDate && (
          <div className="EventForm__content__section">
            <div className="EventForm__content__sectionLeft">
              <AccessTimeIcon />
            </div>
            <div className="EventForm__content__sectionRight">
              <DateTime
                onChange={(e) => handleRepeatDateChange(e._d, "startDate")}
                closeOnSelect={true}
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                defaultValue={selectedEvent.end}
                className="datetime"
                inputProps={{ placeholder: "Repeat until..." }}
              />
            </div>
          </div>
        )}

        <div className="EventForm__content__section">
          <div className="EventForm__content__sectionLeft">
            <MessageIcon />
          </div>
          <div className="EventForm__content__sectionRight">
            <textarea
              value={comments}
              onChange={(e) => updateEventComment(e.target.value)}
              placeholder="Add description"
              class="form-control"
              rows="3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventForm;
