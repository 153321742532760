import React, { useEffect, useState } from "react";
import {getUserIframeURL} from '../metabase/metabase.embed.js';
import { useDispatch, useSelector } from "react-redux"
import { getAgentRouteAction } from "../../store/actions/RouteAction";

const ScheduleUpload = (params) => {
    const [reportId, setReportId] = useState(null);
    const user = useSelector(state => state.authManager.user);
    const userRoute = useSelector((state) => state.routeManager.route);

    const dispatch = useDispatch();

    useEffect(()=>{
        getUserRoute(user)
        renderReport()
    },[])
    const getUserRoute = (user) => {
        setTimeout(
            () =>
              dispatch(
                getAgentRouteAction({ agent: user.username, shift: user.shift })
              ),
            6000
          );
    }
    const renderReport = () =>{
        if(user.role === "admin"){
            return setReportId(163)
        }else if(user.role === "distribution-manager"){
            return setReportId(163)
        }else {
            return setReportId(163)
        }
    }
    
    return (
        <div className="metabase-frame"> 
             <iframe
                 src={getUserIframeURL(reportId,user,userRoute)}
                 frameBorder={0}
                 width={"100%"}
                 height={"100%"}
                 title={"d1"}
                 allowtransparency="true"
                 />  
          
         </div>
    
 )
}
    

export default ScheduleUpload