import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Table from "../../components/dom/Table/Table";
import { getCategoriesAction } from "../../store/actions/ProductAction";
import { getCreditLimitAction } from "../../store/actions/OutletAction";
import moment from "moment";
import { PageButton } from "../../styles/App";
import CreateIcon from "@material-ui/icons/Create";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import AddCreditLimitForm from "./AddCreditLimitForm";
import { usePermissionChecker } from "../../utils/user";

import PageModal from "../../components/dom/modal";

const CreditLimit = ({ add, edit, showCutOffTimeForm }) => {
  const categories = useSelector((state) => state.productManager.categories);
  const creditLimits = useSelector((state) => state.outletManager.creditLimits);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [outletType, setOutletType] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const categoryColumn = [
    {
      Header: "Created Date",
      accessor: (d) => moment(d.createdAt).format("LLL"),
    },
    {
      Header: "Created By",
      accessor: "createdBy",
      key:["createdBy"]
    },
    {
      Header: "Outlet Type",
      accessor: "outletType",
      key:["outletType"]
    },
    {
      Header: "Limit",
      accessor: "limit",
      key:["limit"]
    },
    {
      Header: "Grace Period",
      accessor: (d) => (d.gracePeriod ? d.gracePeriod : "-"),
      key:["gracePeriod"]
    },
    {
      Header: "Status",
      accessor: "status",
      key:["status"]
    },
    {
      Header: usePermissionChecker("can_manage_credit_limit") ? "Action" : " ",

      Cell: (row) => (
        <div className="row">
          {usePermissionChecker("can_manage_credit_limit") && (
            <div
              onClick={() => editLimit(row.row.original)}
              className="col-12 pointer"
            >
              <CreateIcon className="primary " />{" "}
              <span className="primary">Edit </span>
            </div>
          )}
          {!usePermissionChecker("can_manage_credit_limit") && <div> </div>}
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriesAction());
    dispatch(getCreditLimitAction());
  }, []);

  const addLimit = () => {
    setmodalIsOpen(true);
    setOutletType(null);
    setEditMode(false);
  };

  const editLimit = (type) => {
    setmodalIsOpen(true);
    setOutletType(type);
    setEditMode(true);
  };

  const resetView = () => {
    setmodalIsOpen(false);
    setOutletType({});
    setEditMode(false);
    setTimeout(() => dispatch(getCreditLimitAction()), 5000);
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12"></div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <h4 className="mt-3">Credit Limits</h4>
        </div>
        <div className="col-sm-10">
          {usePermissionChecker("can_manage_credit_limit") && (
            <PageButton className="float-right" onClick={addLimit}>
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Credit Limit
            </PageButton>
          )}
        </div>
      </div>
      {creditLimits && creditLimits.length === 0 && (
        <p className="alert alert-info mt-3">No credit limits at the moment</p>
      )}
      {creditLimits&& creditLimits.length>0 &&categories && categories.length > 0 && (
        <Table
          data={creditLimits}
          columns={categoryColumn}
          rowInfo={() => {}}
        />
      )}

      <PageModal
        isOpen={modalIsOpen}
        component={
          <AddCreditLimitForm
            outletType={outletType}
            editMode={editMode}
            resetView={resetView}
            limits={creditLimits}
          />
        }
        setIsOpen={setmodalIsOpen}
      />
    </>
  );
};

export default CreditLimit;
