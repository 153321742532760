import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  getOrdersAction,
  getOrderSummaryAction,
  get,
} from "../../store/actions/OrderAction";
import { getProductionScheduleAction } from "../../store/actions/ProductionAction";
import { getRouteDriversFromAgentAction } from "../../store/actions/UserAction";
import { getRouteDriverAction } from "../../store/actions/RouteAction";
import RouteOrderItems from "./RouteOrderItems";
import Tile from "../../components/dom/Tile";

const RouteOrdersPage = ({ order, resetView, shift }) => {
  const orders = useSelector((state) => state.OrderManager.orders);
  const summary = useSelector((state) => state.OrderManager.orderSummary);
  const schedule = useSelector((state) => state.productionManager.schedule);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);

  const dispatch = useDispatch();

  console.log(order);

  useEffect(() => {
    let date = moment().format("DD-MM-YYYY");
    let driverDetailsPayload = {
      route: order.routeId,
      shift: order.shift,
    };

    let data = {
      date,
      shift: order.shift,
      route: order.routeId,
      type: "customer",
    };
    let orderFilter = {
      searches: [
        {
          key: "routeId",
          value: order.routeId,
        },
        {
          key: "deliveryType",
          value: "delivery",
        },
        //Change request by Nercio to show all orders
        // {
        //   key: "orderType",
        //   value: "MANAGER,AGENT",
        //   comparisonType: "IN_LIST",
        // },
        {
          key: "shift",
          value: order.shift,
        },
        {
          key: "deliveryDateInt",
          value: moment().format("YYYYMMDD"),
        },
      ],
    };
    dispatch(getOrdersAction(orderFilter));
    dispatch(getProductionScheduleAction(data));
    // dispatch(getRouteDriverAction(driverDetailsPayload));
  }, []);

  return (
    <div className="row">
      <div className="col-md-1 back-arrow">
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="2x"
          className="mr-2"
          onClick={() => resetView()}
        />
      </div>
      <div className="col-md-11">
        <div className="row mb-2">
          <div className="col-sm-12 mt-2">
            <h2>Driver : {order.driver}</h2>

            {success && <p className="alert alert-success">{message}</p>}
            {error && <p className="alert alert-danger">{message}</p>}

            <div className="row">
              <Tile
                title="total Orders"
                inlineStyle="col-sm-4"
                value={orders.length ? orders.length : 0}
              />
              {schedule.items &&
                schedule.items.length > 0 &&
                schedule.items.map((product) => (
                  <Tile
                    title={product.name}
                    inlineStyle="col-sm-4"
                    value={product.quantity}
                  />
                ))}
            </div>
            <div className="row">
              {orders &&
                orders.length > 0 &&
                orders.map((order) => (
                  <div className="col-sm-6 mt-2 mb-2">
                    <RouteOrderItems order={order} />
                  </div>
                ))}
            </div>
            {orders && orders.length === 0 && (
              <p className="alert alert-info mt-3"> No orders placed yet</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteOrdersPage;
