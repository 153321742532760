import {
  MOVE_AREA_TO_ROUTE,
  ACTION_SUCCESS,
  ACTION_FAIL,
  GET_ROUTES_SUCCESS,
  GET_ROUTE_SUCCESS,
  GET_ROUTE_ORDERS_SUCCESS,
  GET_ROUTE_OPTIONS_SUCCESS,
  GET_WARDS_SUCCESS,
  CHANGE_FILTER_WARDS_STATUS,
  SET_AREA_LISTS,
  SET_SAVED_AREA_LISTS,
  GET_DISTRICTS_SUCCESS,
  SET_SELECTED_WARD,
  SET_SELECTED_DISTRICT,
  SET_PROVINCE_IDENTITY,
  GET_ROUTE_PRODUCT_PRICES_SUCCESS,
  GET_ROUTE_HISTORY_SUCCESS,
  LOADING,
  DONE,
  GET_ROUTE_DETAILS_SUCCESS,
  GET_AREAS_SUCCESS,
  GET_OUTLETS_SUCCESS,
  GET_PROVINCES_SUCCESS,
  SET_ROUTE_AREA_LISTS,
  GOT_EMPTY_WARDS,
  GET_ROUTE_DRIVER,
  GET_SAVED_DISTRICTS_SUCCESS,
  GET_SAVED_PROVINCES_SUCCESS,
  GET_SAVED_WARDS_SUCCESS,
} from "../constants/ActionTypes";
import update from "immutability-helper";
import { handleErrorAction } from "./UtilsAction";
import {
  createRoute,
  getRoutes,
  updateRoute,
  getUserRoute,
  getRouteOrders,
  assignRouteToDriver,
  assignRouteToAgent,
  getRouteProductPrices,
  getAgentRoute,
  getDriverRouteHistory,
  getAgentRouteHistory,
  getRouteDetails,
  getRouteDriver,
} from "../../services/RouteService";
import {
  getSubLocations,
  searchArea,
  getAreas,
} from "../../services/LocationService";
import { setRouteProductPrice } from "../../services/ProductService";
import { getOutletsAction } from "./OutletAction";
import { getOrdersAction } from "./OrderAction";
import _ from "lodash";

export const HandleAreaDrop = (id, column, hoverIndex, areaList) => {
  return (dispatch) => {
    let area = areaList.find((area) => area._id === id);
    const areaIndex = areaList.indexOf(area);
    area = {
      ...area,
      status: column,
    };
    let newAreaList = update(areaList, {
      [areaIndex]: {
        $set: area,
      },
    });

    dispatch({
      type: MOVE_AREA_TO_ROUTE,
      payload: newAreaList,
    });
  };
};

export const ChangeAreaOrderAction = (dragIndex, hoverIndex, areaList) => {
  return (dispatch) => {
    if (areaList && areaList.length > 0) {
      const routes = areaList.filter((area) => area && area.status === "route");
      const areas = areaList.filter((area) => area && area.status === "area");

      const dragArea = routes[dragIndex];
      let newAreaList = update(routes, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragArea],
        ],
      });
      newAreaList = [...areas, ...newAreaList];

      dispatch({
        type: MOVE_AREA_TO_ROUTE,
        payload: newAreaList,
      });
    }
    //dispatch({ type: MOVE_AREA_TO_ROUTE, payload: newAreaList });
  };
};

export const getRouteDetailsAction = (data) => {
  return (dispatch) => {
    return getRouteDetails(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_ROUTE_DETAILS_SUCCESS,
            payload: response.data.responseObject,
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_ROUTE_DETAILS_SUCCESS,
            payload: {},
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addRouteAction = (data) => {
  return (dispatch) => {
    return createRoute(data).then(
      (response) => {
        if (response && response.data && response.data.code === "200") {
          return dispatch({
            type: ACTION_SUCCESS,
            payload: "Route created successfully",
          });
        }

        if (response.data.code === "400") {
          dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const assignUserRouteAction = (data) => {
  return (dispatch) => {
    return assignRouteToDriver(data).then(
      (response) => {
        if (response && response.data.code === "200") {
          return dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }

        if (response && response.data.code === "400") {
          return dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUserRouteAction = (data) => {
  return (dispatch) => {
    return getUserRoute(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_ROUTE_SUCCESS,
            payload: response.data.responseObject,
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_ROUTE_SUCCESS,
            payload: {},
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getAgentRouteAction = (data) => {
  return (dispatch) => {
    return getAgentRoute(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_ROUTE_SUCCESS,
            payload: response.data.responseObject,
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_ROUTE_SUCCESS,
            payload: {},
          });
          dispatch({
            type: GET_ROUTE_DETAILS_SUCCESS,
            payload: {},
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getAgentRouteAndOutletsAction = (data) => {
  return (dispatch) => {
    return getAgentRoute(data).then(
      (response) => {
        if (response.data.code === "200") {
          let route = response.data.responseObject.route
            ? response.data.responseObject.route
            : null;
          if (route) {
            let outletFilter = {
              searches: [
                {
                  key: "routeIdentity",
                  value: route.routeCode,
                },
                {
                  key: "status",
                  value: "ACTIVE",
                },
              ],
            };

            let orderFilter = {
              searches: [
                {
                  key: "orderBy",
                  value: data.agent,
                },
                {
                  key: "routeId",
                  value: route.routeCode,
                },
              ],
            };

            dispatch(getOutletsAction(outletFilter));
            //dispatch(getOrdersAction(orderFilter));
          }
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_OUTLETS_SUCCESS,
            payload: [],
            total: 0,
            totalActive: 0,
            totalInactive: 0,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getDriverRouteHistoryAction = (data) => {
  return (dispatch) => {
    return getDriverRouteHistory(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_ROUTE_HISTORY_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_ROUTE_HISTORY_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getAgentRouteHistoryAction = (data) => {
  return (dispatch) => {
    return getAgentRouteHistory(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_ROUTE_HISTORY_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_ROUTE_HISTORY_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const assignAgentRouteAction = (data) => {
  return (dispatch) => {
    return assignRouteToAgent(data).then(
      (response) => {
        if (response && response.data.code === "200") {
          return dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }

        if (response && response.data.code === "400") {
          return dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const updateRouteAction = (data) => {
  return (dispatch) => {
    return updateRoute(data).then(
      (response) => {
        if (response && response.data) {
          return dispatch({
            type: ACTION_SUCCESS,
            payload: "Route updated successfully",
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getRoutesAction = () => {
  return (dispatch) => {
    dispatch({
      type: LOADING,
    });
    return getRoutes().then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: DONE,
          });
          dispatch({
            type: GET_ROUTES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }

        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_ROUTES_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getRouteOptionsAction = () => {
  return (dispatch) => {
    return getRoutes().then(
      (response) => {
        if (response.data && response.data.code === "200") {
          let routes = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];
          let options = routes.map((route) => ({
            value: route.routeCode,
            label: `${route.routeCode}   ${route.routeName}`,
          }));
          dispatch({
            type: GET_ROUTE_OPTIONS_SUCCESS,
            payload: options,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getRouteOrdersAction = (data) => {
  return (dispatch) => {
    return getRouteOrders(data).then(
      (response) => {
        if (response.data.code === "200") {
          if (response.data.responseObject.tasks) {
            dispatch({
              type: GET_ROUTE_ORDERS_SUCCESS,
              payload: Array.isArray(response.data.responseObject.tasks)
                ? response.data.responseObject.tasks
                : [response.data.responseObject.tasks],
            });
          } else {
            dispatch({
              type: GET_ROUTE_ORDERS_SUCCESS,
              payload: [],
            });
          }
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_ROUTE_ORDERS_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const setDefaultSelectedAreasAction = (route) => {
  let identities = route.areas.map((district) => district.identity).join();
  let data = {
    searchValue: identities,
  };

  return (dispatch) => {
    return getSubLocations(data).then((response) => {
      if (response.data.code === "200") {
        let areas = Array.isArray(response.data.responseObject)
          ? response.data.responseObject
          : [response.data.responseObject];

        let selectedAreaList = [];
        selectedAreaList = areas.map((location) => ({
          _id: location.id,
          title: `${location.parent.name} - ${location.name}`,
          status: "route",
          identity: location.identity,
          code: location.areaCode,
          parentId: location.parent.identity,
          parentName: location.parent.name,
        }));

        let uniqueAreaList = _.uniqBy(selectedAreaList, "parentId");
        let wardIdentites = uniqueAreaList.map((area) => area.parentId).join();
        let wardData = {
          searchValue: wardIdentites,
        };

        getSubLocations(wardData).then(
          (response) => {
            if (response.data.code === "200") {
              let areas = Array.isArray(response.data.responseObject)
                ? response.data.responseObject
                : [response.data.responseObject];

              let locations = areas.map((areaLevel) => {
                let subAreas = Array.isArray(areaLevel.subAreas)
                  ? areaLevel.subAreas
                  : [areaLevel.subAreas];
                var result = subAreas.map(function(area) {
                  var o = Object.assign({}, area);
                  o.ward = areaLevel.name;
                  o.districtId = areaLevel.parent.id;
                  return o;
                });
                return result;
              });
              locations = _.flattenDeep(locations);

              let newLocation = locations.map((location) => ({
                value: location.id,
                label: `${location.ward} - ${location.name}`,
                identity: location.identity,
                code: location.areaCode,
              }));

              let areaList = [];

              areaList = locations.map((location) => ({
                _id: location.id,
                title: `${location.ward} - ${location.name}`,
                status: "area",
                identity: location.identity,
                code: location.code,
              }));

              areaList = areaList.map((area) => {
                if (_.some(selectedAreaList, ["identity", area.identity])) {
                  area.status = "route";
                }
                return area;
              });

              return dispatch({
                type: SET_AREA_LISTS,
                payload: areaList,
              });
            }
          },
          (error) => dispatch(handleErrorAction(error))
        );
      }
    });
  };
};

export const setDefaultSelectedAreasAction2 = (areas) => {
  let identities = areas.map((district) => district.identity).join();
  console.log(identities);
  let data = {
    searchValue: areas[0].identity,
  };
  return (dispatch) => {
    return getSubLocations(data).then(
      (response) => {
        let lowestRegionObj = response.data.responseObject;
        if (lowestRegionObj.level === "AREA") {
          dispatch({
            type: CHANGE_FILTER_WARDS_STATUS,
            payload: true,
          });
          let lowestRegionData = {
            searchValue: lowestRegionObj.identity,
          };

          // Get a ward belonging to an area
          getSubLocations(lowestRegionData).then((res) => {
            const wardIdentity = res.data.responseObject.parent.identity
              ? res.data.responseObject.parent.identity
              : null;
            if (wardIdentity) {
              let data = {
                searchValue: wardIdentity,
              };
              // Get All areas in a ward
              getSubLocations(data).then((districtResponse) => {
                const districtIdentity = districtResponse.data.responseObject
                  .parent.identity
                  ? districtResponse.data.responseObject.parent.identity
                  : null;

                if (districtIdentity) {
                  let data = {
                    searchValue: districtIdentity,
                  };
                  // Get Wards using District Identity
                  getSubLocations(data).then((wardRes) => {
                    const provinceIdentity = wardRes.data.responseObject.parent
                      .identity
                      ? wardRes.data.responseObject.parent.identity
                      : null;

                    if (provinceIdentity) {
                      dispatch({
                        type: SET_PROVINCE_IDENTITY,
                        payload: provinceIdentity,
                      });
                      let provinceData = {
                        searchValue: provinceIdentity,
                      };
                      getSubLocations(provinceData).then((provinceResponse) => {
                        let districts = Array.isArray(
                          provinceResponse.data.responseObject.subAreas
                        )
                          ? provinceResponse.data.responseObject.subAreas
                          : [provinceResponse.data.responseObject.subAreas];
                        districts = districts.map((location) => ({
                          value: location.id,
                          label: location.name,
                          identity: location.identity,
                        }));

                        let selectedDistrict = districts.filter(
                          (option) => option.identity === districtIdentity
                        )[0];

                        if (selectedDistrict) {
                          dispatch({
                            type: SET_SELECTED_DISTRICT,
                            payload: selectedDistrict,
                          });
                        }

                        return dispatch({
                          type: GET_DISTRICTS_SUCCESS,
                          payload: districts,
                        });
                      });
                    }
                    if (wardRes.data.responseObject.subAreas) {
                      let wards = Array.isArray(
                        wardRes.data.responseObject.subAreas
                      )
                        ? wardRes.data.responseObject.subAreas
                        : [wardRes.data.responseObject.subAreas];

                      let locationOptions = wards.map((location) => ({
                        value: location.id,
                        label: location.name,
                        identity: location.identity,
                        code: location.areaCode,
                      }));

                      let selectedWard = locationOptions.filter(
                        (option) => option.identity === wardIdentity
                      )[0];

                      if (selectedWard) {
                        dispatch({
                          type: SET_SELECTED_WARD,
                          payload: selectedWard,
                        });
                      }

                      let wardList = [];

                      wardList = wards.map((location) => ({
                        _id: location.id,
                        title: location.name,
                        status: "area",
                        identity: location.identity,
                      }));

                      return dispatch({
                        type: GET_WARDS_SUCCESS,
                        payload: locationOptions,
                      });
                    }
                  });
                }

                let locations = Array.isArray(
                  districtResponse.data.responseObject.subAreas
                )
                  ? districtResponse.data.responseObject.subAreas
                  : [districtResponse.data.responseObject.subAreas];

                let areaList = [];

                areaList = locations.map((location) => ({
                  _id: location.id,
                  title: location.name,
                  status: "area",
                  identity: location.identity,
                  code: location.code,
                }));

                areaList = areaList.map((area) => {
                  if (_.some(areas, ["identity", area.identity])) {
                    area.status = "route";
                  }
                  return area;
                });

                return dispatch({
                  type: SET_SAVED_AREA_LISTS,
                  payload: areaList,
                });
              });
            }
          });
        } else if (lowestRegionObj.level === "ADMINISTRATIVE POST") {
          const wardIdentity = response.data.responseObject.identity
            ? response.data.responseObject.identity
            : null;
          const districtIdentity = response.data.responseObject.parent.identity
            ? response.data.responseObject.parent.identity
            : null;

          let data = {
            searchValue: districtIdentity,
          };
          // Get Wards using District Identity
          getSubLocations(data).then((wardRes) => {
            const provinceIdentity = wardRes.data.responseObject.parent.identity
              ? wardRes.data.responseObject.parent.identity
              : null;

            if (provinceIdentity) {
              dispatch({
                type: SET_PROVINCE_IDENTITY,
                payload: provinceIdentity,
              });
              let provinceData = {
                searchValue: provinceIdentity,
              };
              getSubLocations(provinceData).then((provinceResponse) => {
                let districts = Array.isArray(
                  provinceResponse.data.responseObject.subAreas
                )
                  ? provinceResponse.data.responseObject.subAreas
                  : [provinceResponse.data.responseObject.subAreas];

                districts = districts.map((location) => ({
                  value: location.id,
                  label: location.name,
                  identity: location.identity,
                }));

                let selectedDistrict = districts.filter(
                  (option) => option.identity === districtIdentity
                )[0];

                if (selectedDistrict) {
                  dispatch({
                    type: SET_SELECTED_DISTRICT,
                    payload: selectedDistrict,
                  });
                }

                return dispatch({
                  type: GET_DISTRICTS_SUCCESS,
                  payload: districts,
                });
              });
            }
            if (wardRes.data.responseObject.subAreas) {
              let wards = Array.isArray(wardRes.data.responseObject.subAreas)
                ? wardRes.data.responseObject.subAreas
                : [wardRes.data.responseObject.subAreas];

              let locationOptions = wards.map((location) => ({
                value: location.id,
                label: location.name,
                identity: location.identity,
                code: location.areaCode,
              }));

              let selectedWard = locationOptions.filter(
                (option) => option.identity === wardIdentity
              )[0];

              if (selectedWard) {
                dispatch({
                  type: SET_SELECTED_WARD,
                  payload: selectedWard,
                });
              }

              let wardList = [];

              wardList = wards.map((location) => ({
                _id: location.id,
                title: location.name,
                status: "area",
                identity: location.identity,
              }));

              wardList = wardList.map((area) => {
                if (_.some(areas, ["identity", area.identity])) {
                  area.status = "route";
                }
                return area;
              });

              dispatch({
                type: SET_AREA_LISTS,
                payload: wardList,
              });

              return dispatch({
                type: GET_WARDS_SUCCESS,
                payload: locationOptions,
              });
            }
          });
        } else {
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
  // return dispatch => {
  //   let areaList = areas.map(location => ({
  //     _id: location.id,
  //     title: location.name,
  //     status: "route",
  //     identity: location.identity
  //   }));
  //   dispatch({ type: MOVE_AREA_TO_ROUTE, payload: areaList });
  // };
};

export const setRoutePriceAction = (data) => {
  return function(dispatch) {
    return setRouteProductPrice(data).then(
      (response) => {
        if (response.data.code === "200") {
          return dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }

        if (response.data.code === "400") {
          return dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getRouteProductPricesAction = (data) => {
  return (dispatch) => {
    return getRouteProductPrices(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_ROUTE_PRODUCT_PRICES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_ROUTE_PRODUCT_PRICES_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getProvinces = (data) => {
  return (dispatch) => {
    return getAreas(data).then(
      (response) => {
        let provinces = Array.isArray(response.data.responseObject)
          ? response.data.responseObject
          : [response.data.responseObject];
        provinces = provinces.map((province) => ({
          value: province.id,
          label: province.name,
          identity: province.identity,
        }));
        dispatch({
          type: GET_PROVINCES_SUCCESS,
          payload: provinces,
        });
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getDistricsAction = (identity) => {
  let data = {
    searchValue: identity,
  };
  return (dispatch) => {
    return getSubLocations(data).then(
      (response) => {
        if (response.data.code === "200") {
          let provinces = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          let locations = provinces.map((province) => {
            var result = province.subAreas.map(function(area) {
              var o = Object.assign({}, area);
              o.province = province.name;
              return o;
            });
            return result;
          });
          locations = _.flattenDeep(locations);

          locations = locations.map((location) => ({
            value: location.id,
            label: `${location.province} - ${location.name}`,
            identity: location.identity,
            province: location.province,
            district: location.name,
          }));
          return dispatch({
            type: GET_DISTRICTS_SUCCESS,
            payload: locations,
          });
        }

        if (response.data.code === "400") {
          return dispatch({
            type: GET_DISTRICTS_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getWardsAction = (districts) => {
  console.log("Get wards from districts = ", districts);
  if (districts && Array.isArray(districts) && districts.length > 0) {
    let identities = districts.map((district) => district.identity).join();
    let data = {
      searchValue: identities,
    };
    return (dispatch) => {
      return getSubLocations(data).then(
        (response) => {
          if (response.data.code === "200") {
            let wards = Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject];

            let locations = wards.map((ward) => {
              let subAreas = ward.subAreas
                ? Array.isArray(ward.subAreas)
                  ? ward.subAreas
                  : [ward.subAreas]
                : [];

              console.log(subAreas);
              var result = subAreas.map(function(area) {
                var o = Object.assign({}, area);
                o.district = ward.name;
                o.provinceId = ward.parent.id;
                return o;
              });
              return result;
            });
            locations = _.flattenDeep(locations);
            console.log(locations);

            if (locations && locations.length > 0) {
              let locationOptions = locations.map((location) => {
                return {
                  value: location.id,
                  label: `${location.district} - ${location.name}`,
                  identity: location.identity,
                  code: location.areaCode,
                  provinceId: location.provinceId,
                  ward: location.name,
                };
              });

              locationOptions = locationOptions.filter((location) => {
                if (
                  location.provinceId === "1" ||
                  location.provinceId === "77"
                ) {
                  return location;
                }
              });

              let areaList = [];

              areaList = locations.map((location) => ({
                _id: location.id,
                title: `${location.district} - ${location.name}`,
                status: "area",
                identity: location.identity,
                provinceId: location.provinceId,
              }));

              areaList = areaList.filter((location) => {
                if (
                  location.provinceId !== "1" &&
                  location.provinceId !== "77"
                ) {
                  return location;
                }
              });

              if (areaList.length > 0) {
                dispatch({
                  type: SET_ROUTE_AREA_LISTS,
                  payload: areaList,
                });
              } else {
                dispatch({
                  type: SET_ROUTE_AREA_LISTS,
                  payload: [],
                });
              }

              return dispatch({
                type: GET_WARDS_SUCCESS,
                payload: locationOptions,
              });
            } else {
              return dispatch({
                type: GOT_EMPTY_WARDS,
                payload: [],
                areaList: [],
              });
            }
          }
        },
        (error) => dispatch(handleErrorAction(error))
      );
    };
  } else {
    return (dispatch) => {
      return dispatch({
        type: SET_ROUTE_AREA_LISTS,
        payload: [],
      });
    };
  }
};

export const getAreasAction = (wards, savedAreaList) => {
  console.log("Get Areas Action form wards =  ", wards);
  return (dispatch) => {
    if (wards && Array.isArray(wards) && wards.length > 0) {
      let identities = wards.map((district) => district.identity).join();
      let data = {
        searchValue: identities,
      };
      return getSubLocations(data).then(
        (response) => {
          if (response.data.code === "200") {
            let areas = Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject];
            areas = areas.filter((area) => area.subAreas);
            let combineList = [];
            let newLocation = [];
            let areaList = [];
            if (areas.length > 0) {
              let locations = areas.map((areaLevel) => {
                let subAreas = Array.isArray(areaLevel.subAreas)
                  ? areaLevel.subAreas
                  : [areaLevel.subAreas];
                var result = subAreas.map(function(area) {
                  var o = Object.assign({}, area);
                  o.ward = areaLevel.name;
                  o.districtId = areaLevel.parent.id;
                  return o;
                });
                return result;
              });
              locations = _.flattenDeep(locations);

              newLocation = locations.map((location) => ({
                value: location.id,
                label: `${location.ward} - ${location.name}`,
                identity: location.identity,
                code: location.areaCode,
              }));

              areaList = locations.map((location) => ({
                _id: location.id,
                title: `${location.ward} - ${location.name}`,
                status: "area",
                identity: location.identity,
                code: location.code,
              }));
            } else {
              areaList = wards.map((location) => ({
                _id: location.value,
                title: location.label,
                status: "area",
                identity: location.identity,
                code: location.code,
              }));
            }
            combineList = [...savedAreaList, ...areaList];

            return dispatch({
              type: GET_AREAS_SUCCESS,
              payload: newLocation,
              areaList: combineList,
            });
          }
        },
        (error) => dispatch(handleErrorAction(error))
      );
    } else {
      return dispatch({
        type: GET_AREAS_SUCCESS,
        payload: [],
        areaList: [],
      });
    }
  };
};

export const getRoleRoute = (user) => {
  console.log(user);
  return (dispatch) => {
    if (user.role === "driver") {
      dispatch(
        getUserRouteAction({
          driver: user.username,
          shift: user.shift,
        })
      );
      dispatch(
        getDriverRouteHistoryAction({
          driver: user.username,
        })
      );
    } else if (user.role === "call-centre-agent") {
      dispatch(
        getAgentRouteAction({
          agent: user.username,
          shift: user.shift,
        })
      );
      dispatch(
        getAgentRouteHistoryAction({
          agent: user.username,
        })
      );
    }
  };
};

export const getRouteDriverAction = (data) => {
  return (dispatch) => {
    return getRouteDriver(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_ROUTE_DRIVER,
            payload: response.data.responseObject,
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_ROUTE_DRIVER,
            payload: {},
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getSavedLocationsAction = (data) => {
  return (dispatch) => {
    console.log(data);
    let provinces = data.province.split(",");
    let districs = data.district.split(",");
    let wards = data.ward.split(",");
    console.log(provinces, districs, wards);
    dispatch({
      type: GET_SAVED_DISTRICTS_SUCCESS,
      payload: districs,
    });
    dispatch({
      type: GET_SAVED_WARDS_SUCCESS,
      payload: wards,
    });
    return dispatch({
      type: GET_SAVED_PROVINCES_SUCCESS,
      payload: provinces,
    });
  };
};

export const setSelectedAreasAction = (areaList, savedAreas) => {
  return (dispatch) => {
    let finalList = areaList.map((area) => {
      if (_.some(savedAreas, ["identity", area.identity])) {
        area.status = "route";
      }
      return area;
    });

    return dispatch({
      type: SET_SAVED_AREA_LISTS,
      payload: finalList,
    });
  };
};
