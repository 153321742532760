import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "../components/auth/PrivateRoute";
import LoginPage from "../pages/auth/LoginPage";
import PasswordResetPage from "../pages/auth/PasswordResetPage";
import Dashboard from "../pages/dashboard";
import UsersPage from "../pages/users/UsersPage";
import OutletsPage from "../pages/outlets/OutletsPage";
import OrdersPage from "../pages/orders/OrdersPage";
import DistributionOrdersPage from "../pages/orders/DistributionOrderManager";
import SalesPage from "../pages/sales/SalesPage";
import CreditPage from "../pages/credit/CreditPage";
import ReportsPage from "../pages/reports/reportsPage";
import DailyOrdersPage from "../pages/orders/DailyOrdersPage";
import ProductsPage from "../pages/products/ProductsPage";
import SettingsPage from "../pages/settings/SettingsPage";
import OutletMap from "../pages/map/OutletMap";
import AutoComplete from "../pages/map/autocomplete";
import { Logout } from "../components/auth/logout";
import TrucksPage from "../pages/trucks/TrucksPage";
import OrderSummary from "../pages/metabase/orderSummary";
import UserSummary from "../pages/metabase/userSummary";
import SalesSummary from "../pages/metabase/salesSummary";
import CreditSummary from "../pages/metabase/creditSummary";
import ReturnsSummary from "../pages/metabase/returnsSummary";
import ProductionSummary from "../pages/metabase/productionSummary";
import ReconciliationSummary from "../pages/metabase/reconciliationSummary";
import ScheduleUpload from "../pages/metabase/scheduleUpload";
import ProductionSchedule from "../pages/production/ProductionSchedule";
import ReconciliationPage from "../pages/reconciliation/reconciliationPage";
import MaterialsPage from "../pages/materials/MaterialsPage";
import SuppliersPage from "../pages/suppliers/SupplierPage";
import TerritoryManager from "../pages/territory/TerritoryManager";
import OutletDetails from "../pages/outlets/OutletDetails";
import Surveys from "../pages/surveys/Surveys";

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={LoginPage}></Route>
      <Route exact path="/pass_recovery" component={PasswordResetPage}></Route>
      <PrivateRoute path="/dashboard">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path="/users">
        <UsersPage />
      </PrivateRoute>
      <PrivateRoute path="/outlets">
        <OutletsPage />
      </PrivateRoute>
      <PrivateRoute path="/orders">
        <OrdersPage />
      </PrivateRoute>
      <PrivateRoute path="/surveys">
        <Surveys />
      </PrivateRoute>
      <PrivateRoute path="/territory-manager">
        <TerritoryManager />
      </PrivateRoute>
      <PrivateRoute path="/suppliers">
        <SuppliersPage />
      </PrivateRoute>

      <PrivateRoute path="/distribution-order-manager">
        <DistributionOrdersPage />
      </PrivateRoute>
      <PrivateRoute path="/daily-orders">
        <DailyOrdersPage />
      </PrivateRoute>
      <PrivateRoute path="/sales">
        <SalesPage />
      </PrivateRoute>
      <PrivateRoute path="/credits">
        <CreditPage />
      </PrivateRoute>
      <PrivateRoute path="/products">
        <ProductsPage />
      </PrivateRoute>
      <PrivateRoute path="/trucks">
        <TrucksPage />
      </PrivateRoute>

      <PrivateRoute path="/settings">
        <SettingsPage />
      </PrivateRoute>
      <PrivateRoute path="/materials">
        <MaterialsPage />
      </PrivateRoute>
      <PrivateRoute path="/reports">
        <ReportsPage />
      </PrivateRoute>
      <PrivateRoute path="/logout">
        <Logout />
      </PrivateRoute>
      <PrivateRoute path="/outletmap">
        <OutletMap />
      </PrivateRoute>
      <PrivateRoute path="/autocomplete">
        <AutoComplete />
      </PrivateRoute>
      <PrivateRoute path="/production-schedule">
        <ProductionSchedule />
      </PrivateRoute>
      <PrivateRoute path="/user-summary">
        <UserSummary />
      </PrivateRoute>
      <PrivateRoute path="/sales-summary">
        <SalesSummary />
      </PrivateRoute>
      <PrivateRoute path="/order-summary">
        <OrderSummary />
      </PrivateRoute>
      <PrivateRoute path="/credit-summary">
        <CreditSummary />
      </PrivateRoute>
      <PrivateRoute path="/returns-summary">
        <ReturnsSummary />
      </PrivateRoute>
      <PrivateRoute path="/production-summary">
        <ProductionSummary />
      </PrivateRoute>
      <PrivateRoute path="/recon-summary">
        <ReconciliationSummary />
      </PrivateRoute>
      <PrivateRoute path="/schecdule-upload">
        <ScheduleUpload />
      </PrivateRoute>
      <PrivateRoute path="/reconciliation">
        <ReconciliationPage />
      </PrivateRoute>
      <PrivateRoute path="/outletDetails">
        <OutletDetails />
      </PrivateRoute>
    </Switch>
  );
}

export default Routes;
