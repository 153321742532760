import React, { useCallback, useEffect, useRef, useState } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import {
  setTerritoryCoordinatesAction,
  updateTerritoryCoordinatesAction,
} from "../../store/actions/TerritoryAction";
import AddTerritoryForm from "../../pages/territory/AddTerritoryForm";
import { renderToStaticMarkup } from "react-dom/server";
import { updateMapPositionAction } from "../../store/actions/LocationAction";

const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const OutletLocationMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBwuLdw8RyUO2mNVZQ3f9ux34F_2duP_lU&v=3.exp&libraries=geometry,drawing,places ",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div
        style={{
          height: `600px`,
          display: "flex",
          flexDirection: "column-reverse",
        }}
      />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ editMode, polygons, center, coordinates, outlet }) => {
  const [path, setPath] = useState();
  const [mapRef, setMapRef] = useState(null);
  const [canShowOutlets, setCanShowOutlets] = useState(true);

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [infoOpen, setInfoOpen] = useState(false);
  const [zoom, setZoom] = useState(13);
  const mapPosition = useSelector((state) => state.locationManager.mapPosition);
  const locatedOutlets = useSelector(
    (state) => state.outletManager.locatedOutlets
  );
  const dispatch = useDispatch();

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onPolygonCompleted = (polygon) => {
    var coordinates = polygon.getPath().getArray();
    let LngLatArr = coordinates.map((coordinate) => [
      coordinate.lng(),
      coordinate.lat(),
    ]);

    LngLatArr.push(LngLatArr[0]);
    dispatch(setTerritoryCoordinatesAction(LngLatArr));
  };

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    if (zoom < 13) {
      setZoom(13);
    }

    // if you want to center the selected Marker
    //setCenter(place.pos)
  };

  const loadHandler = (map) => {
    // Store a reference to the google map instance in state
    setMapRef(map);
    // Fit map bounds to contain all markers
    //fitBounds(map);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  useEffect(() => {
    console.log("OutletLocationMap polygons = ", polygons);
  }, []);

  return (
    <>
      <GoogleMap defaultZoom={14} defaultCenter={center} center={center}>
        {coordinates && <Marker position={coordinates} />}
        {polygons &&
          polygons.length > 0 &&
          polygons.map((polygon) => (
            <Polygon
              // Make the Polygon editable / draggable

              path={polygon.path}
              ref={polygonRef}
              // Event used when manipulating and adding points
              onMouseUp={onEdit}
              // Event used when dragging the whole Polygon
              onDragEnd={onEdit}
              onLoad={onLoad}
              onUnmount={onUnmount}
            ></Polygon>
          ))}

        {polygons &&
          polygons.length > 0 &&
          polygons.map((polygon) => (
            <InfoWindow
              position={{ lat: polygon.path[0].lat, lng: polygon.path[0].lng }}
            >
              <div>{polygon.name} </div>
            </InfoWindow>
          ))}
      </GoogleMap>
    </>
  );
});

export default OutletLocationMap;
