import styled from "styled-components";

export const ImageUploaderWrapper = styled.div`
  .btn-file {
    width: 100%;
  }
  .btn-file > input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    width: 100%;
    direction: ltr;
    cursor: pointer;
  }
  .MuiCircularProgress-colorPrimary {
    color: #00c400;
    margin-left: 140px;
  }
`;
