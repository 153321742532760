import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersAction,
  getRolesOptionsAction,
  getUserDetailsAction,
  getUserVerticalsAction,
} from "../../store/actions/UserAction";
import UsersTablePage from "./UsersTablePage";
import AddUserPage from "../users/AddUserPage";
import UserProfilePage from "./UserProfilePage";
import { scrollToTop } from "../../utils/window";
import LoadingIndicator from "../../components/dom/LoadingIndicator";

const UsersPage = () => {
  const [showForm, setShowForm] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [canShowTable, setCanShowTable] = useState(true);
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [username, setUsername] = useState(null);
  const users = useSelector((state) => state.userManager.users);
  const success = useSelector((state) => state.stateManager.success);
  const userObj = useSelector((state) => state.authManager.user);
  const isLoading = useSelector((state) => state.userManager.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    let filter = {
      orderBy: "lastLogin",
      orderDir: "desc",
    };

    dispatch(getUsersAction({}));
    dispatch(getRolesOptionsAction());
  }, [dispatch]);

  const resetView = () => {
    setCanShowTable(true);
    setShowForm(false);
    setShowProfile(false);
    scrollToTop();
    setTimeout(() => {
      dispatch(getUsersAction({}));
    }, 5000);
  };

  const editProfile = (user) => {
    setUser(user);
    setCanShowTable(false);
    setShowForm(true);
    setShowProfile(false);
    setEditMode(true);
  };

  const addUser = () => {
    setCanShowTable(false);
    setShowForm(true);
    setShowProfile(false);
    setEditMode(false);
  };

  const viewUser = (userObj) => {
    dispatch(getUserDetailsAction({ username: userObj.username }));
    dispatch(getUserVerticalsAction(userObj.username));

    setUser(userObj);
    setShowProfile(true);
    setShowForm(false);
    setCanShowTable(false);
  };

  if (canShowTable) {
    return (
      <>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <UsersTablePage
            resetView={resetView}
            users={users}
            showForm={addUser}
            viewUser={viewUser}
          />
        )}
      </>
    );
  }

  if (showForm) {
    return (
      <AddUserPage resetView={resetView} user={user} editMode={editMode} />
    );
  }

  if (showProfile && user) {
    return <UserProfilePage resetView={resetView} edit={editProfile} />;
  }
};

export default UsersPage;
