import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOutletStatusAction,
  getOutletDetailsAction,
  getOutletsWithCoordinatesAction,
  getOutletVerticalsAction,
  clearOutletDetailsAction,
  clearOutletVerticalsAction
} from "../../store/actions/OutletAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import { Label } from "../../styles/Forms";
import { TabsWrapper } from "../../styles/Tabs";
import { ProfilePicture } from "../../styles/User";
import { getOrdersAction } from "../../store/actions/OrderAction";
import moment from "moment";
import CreateIcon from "@material-ui/icons/Create";
import { UserButton } from "../../styles/App";
import { usePermissionChecker } from "../../utils/user";
import { imagePlaceholder } from "../../constants";
import OutletLocationMap from "../../components/map/OutetLocationMap";
import {
  getTerritoriesAction,
  getTerritoryPolygonsAction,
} from "../../store/actions/TerritoryAction";
import OrdersTablePage from "../orders/OrdersTablePage";
import OrderDetails from "../orders/Order/OrderDetails";

import Loader from "react-loader-spinner";

const OutletDetails = ({ outletNumber, resetView, edit }) => {
  const [polygons, setPolygons] = useState([]);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [product, setProduct] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [canViewOrder, setCanViewOrder] = useState(false);
  const [canShowHome, setCanShowHome] = useState(true);
  const [order, setOrder] = useState({});
  const outlet = useSelector((state) => state.outletManager.outlet);
  const outletVerticals = useSelector(
    (state) => state.outletManager.outletVerticals
  );
  const userObj = useSelector((state) => state.authManager.user);
 // const orders = useSelector((state) => state.outletManager.outletOrders);
  const orders = useSelector((state) => state.OrderManager.orders);
  const message = useSelector((state) => state.stateManager.message);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const outletProductPrices = useSelector(
    (state) => state.outletManager.outletProductPrices
  );
  const territoryPolygons = useSelector(
    (state) => state.territoryManager.territoryPolygons
  );

  const dispatch = useDispatch();
  const canCreateOutlet = usePermissionChecker("can_edit_outlet");

  console.log("outletNumber received =>",outletNumber)
  console.log("view outlet:>",outlet)

  const showSetPriceModal = () => {
    setmodalIsOpen(true);
  };

  const editOutletProductPrice = (product) => {
    setEditMode(true);
    setProduct(product);
    setmodalIsOpen(true);
  };

  const viewOrderItems = (order) => {
    setOrder(order);
    setShowItems(true);
  };

  const closeMoodal = () => {
    setmodalIsOpen(false);
  };

  const viewOrder = (order) => {
     setOrder(order);
    setCanViewOrder(true);
    setCanShowHome(false);
  };

  const onCloseOutletOrder  = () => {
    setCanShowHome(true);
    setCanViewOrder(false);
   
  }

  useEffect(() => {
    setTimeout(() => {
      dispatch(getOutletDetailsAction(outletNumber));
    }, 7000);
    dispatch(clearOutletDetailsAction());
  }, [success]);

  useEffect(() => {
    dispatch(getOutletsWithCoordinatesAction({}));
    dispatch(getOutletDetailsAction(outletNumber));
    dispatch(getOutletVerticalsAction(outletNumber));

    dispatch(getTerritoryPolygonsAction({}));
    //dispatch(getOutletProductPricesAction({ outletNumber: outletNumber }));
    //dispatch(getOutletOrdersAction(data));
    dispatch(clearOutletVerticalsAction());
  }, []);

  useEffect(() => {
      let fetchOutletOrders = {
        "searches": [
          {
            "key": "outletNumber",
            "value": outletNumber
          }
        ]
      }
    dispatch(getOrdersAction(fetchOutletOrders)); 

  }, []);

  const deactivateOutlet = (outlet) => {
    let data = {
      identity: outlet.outletNumber,
      status: "INACTIVE",
      username: userObj.username,
    };

    dispatch(changeOutletStatusAction(data));
  };

  const activateOutlet = (outlet) => {
    let data = {
      identity: outlet.outletNumber,
      status: "ACTIVE",
      username: userObj.username,
    };
    dispatch(changeOutletStatusAction(data));
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          {/* <code>{JSON.stringify(outlet)}</code> */}
           {error && <p className="alert alert-danger">{message}</p>}
          {success && (
            <p className="alert alert-success mt-3">
              {"Status updated succesfully"}
            </p>
          )}
        </div>
        <div className="col-md-1 back-arrow">
          <FontAwesomeIcon
            icon={faArrowLeft}
            size="2x"
            className="mr-2"
            onClick={() => resetView()}
          />
        </div>

        <div className="col-md-11">
          <div className="">
            <PageTopHeader title="Outlet Management" />
          </div>
          <div className="float-right">
            {outlet && outlet.status === "ACTIVE" && (
              <UserButton onClick={() => deactivateOutlet(outlet)}>
                Deactivate
              </UserButton>
            )}
            {outlet && outlet.status === "INACTIVE" && (
              <UserButton onClick={() => activateOutlet(outlet)}>
                Activate
              </UserButton>
            )}
            {canCreateOutlet && (
              <CreateIcon
                className="pointer i-p"
                onClick={() => edit(outlet)}
              />
            )}
            {/* <DeleteIcon className="pointer i-p" onClick={() => deactivateOutlet(outlet) } /> */}
          </div>
        </div>
      </div>
      {outlet && (
        <div className="row mt-4">
          <div className="form-group col-md-4 mb-3">
            <ProfilePicture
              src={
                outlet.images &&
                outlet.images.length > 0 &&
                outlet.images[0].path &&
                outlet.images[0].path
                  ? outlet.images[0].path
                  : imagePlaceholder
              }
              alt={`${outlet.name}`}
              width="300"
              height="350"
            />
          </div>

          <div className="form-group col-md-8 mb-3">
            <div className="form-row">
              <div className="form-group col-md-4 mb-3">
                <Label htmlFor="username">Outlet Name </Label>
                <div>
                  {outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      (outlet.name ?? "-")}
                </div>
              </div>
              <div className="form-group col-md-4 mb-3">
                <Label>Outlet Type</Label>
                <div>
                  {outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      (outlet.categoryName ?? "-")}
                </div>
              </div>
              <div className="form-group col-md-4 mb-3">
                <Label>Territory</Label>
                <div>
                  {outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      (outlet.areaName ?? "-")}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4 mb-3">
                <Label>Outlet Mobile:</Label>
                <div>
                    {outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      (outlet.phone)}
                </div>
              </div>
              <div className="form-group col-md-4 mb-3">
                <Label> Owner name:</Label>
                <div>
                  {outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      (outlet.owner)}
                </div>
              </div>
              <div className="form-group col-md-4 mb-3">
                <Label>Owner Mobile:</Label>
                <div>
                  {outlet && outlet.length == 0 ? (
                    <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                    (outlet.ownerPhone)}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4 mb-3">
                <Label>Region</Label>
                <div>
                    {outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      (outlet.region)}
                </div>
              </div>
              <div className="form-group col-md-4 mb-3">
                <Label>District</Label>
                <div>
                    {outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      (outlet.district)}
                </div>
              </div>
              <div className="form-group col-md-4 mb-3">
                <Label>Ward</Label>
                <div>{outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      (outlet.ward)}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4 mb-3">
                <Label> Verticals</Label>
                <div>
                  {outletVerticals && outletVerticals.length > 0
                    ? outletVerticals.map(
                        (vertical, index) =>
                          `${vertical.name} ${
                            index < outletVerticals.length - 1 ? "," : ""
                          } `
                      )
                    :(outlet && outlet.length == 0) && (outletVerticals && outletVerticals.length == 0) ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                    "-" }

                  
                </div>
              </div>
              <div className="form-group col-md-4 mb-3">
                <Label>Last assigned User</Label>
                <div>
                  {outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      (outlet.assingedToName ? outlet.assingedToName : " None ")}
                </div>
              </div>
              <div className="form-group col-md-4 mb-3">
                <Label>TAX Number</Label>
                <div>
                    {outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      (outlet.taxNumber)}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4 mb-3">
                <Label>Created By </Label>
                <div>
                  {outlet && outlet.length == 0 ? (
                      <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                      ( outlet.createdBy ? outlet.createdBy : "-")}
                </div>
              </div>
              <div className="form-group col-md-4  mb-3">
                <Label>Created Date</Label>
                <div>
                  {outlet && outlet.length == 0 ? (
                        <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                        ( moment(outlet.createdAt).format("MMMM DD , Y"))}
                </div>
              </div>
              <div className="form-group col-md-4 mb-3">
                <Label>Additiional Information</Label>
                <div>
                  {outlet && outlet.length == 0 ? (
                    <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                    ( outlet.comments)}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12 mb-3">
                <Label>Physical Address</Label>
                <div>
                  {outlet && outlet.length == 0 ? (
                    <Loader type="ThreeDots" color="#5fe375" height={70} width={70} />) :
                    ( outlet.address)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {canShowHome && 

          <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
          <h6 className="text-muted font-weight-normal mt-0" > Orders</h6> 

          <OrdersTablePage 
              viewOder={viewOrder}
              outletNumber={outletNumber}/>
          </div>
        
        }

      {canViewOrder && 
      

      <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
          <button type="button" className="close" aria-label="Close" onClick={onCloseOutletOrder}>
            <span aria-hidden="true">&times;</span>
          </button>
          <h6 className="text-muted font-weight-normal mt-0" > Order Number : {order.orderNumber} </h6> 

          <OrderDetails order={order}/>
          </div>
      
      }

      {outlet && (
        <div className="row mb-3 mt-3">
          <div className="col-sm-12">
            {outlet && territoryPolygons && territoryPolygons.length > 0 && (
              <OutletLocationMap
                coordinates={{ lat: outlet.latitude, lng: outlet.longitude }}
                center={{
                  lat: parseFloat(outlet.latitude),
                  lng: parseFloat(outlet.longitude),
                }}
                name={outlet.name}
                polygons={territoryPolygons}
              />
            )}

            {/* <Map
              latitude={outlet.latitude}
              longitude={outlet.longitude}
              center={{
                lat: parseFloat(outlet.latitude ? outlet.latitude : 1),
                lng: parseFloat(outlet.longitude ? outlet.longitude : 1),
              }}
              name={outlet.name}
              isLocationPresent={
                outlet.longitude && outlet.longitude ? true : false
              }
              polygons={polygons}
            /> */}
          </div>
        </div>
      )}
      
    </div>
  );
};

export default OutletDetails;
