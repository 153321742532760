import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import moment from "moment";
import Select from "react-select";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductCategoryOptionsAction,
  getProductOptionsAction,
  getVerticalOptionsAction,
} from "../../store/actions/ProductAction";
import { getTerritoryOptionsAction } from "../../store/actions/TerritoryAction";
import { setPerformanceTargetAction } from "../../store/actions/SalesAction";
import "./TargetForm.css";
import { getSupplierOptionsAction } from "../../store/actions/SupplierAction";
import { getOutletOptionsAction } from "../../store/actions/OutletAction";
import AutoNumeric from "autonumeric";

const targetTypeOptions = [
  { value: "ALL", label: "General" },
  { value: "VERTICAL", label: "Vertical" },
  { value: "PRODUCT", label: "Product" },
  { value: "CATEGORY", label: "Category" },
  { value: "AREA", label: "Area" },
  { value: "SUPPLIER", label: "Supplier" },
  { value: "OUTLET", label: "Outlet Creation" },
];

const TargetForm = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [targetOptions, setTargetOptions] = useState("");
  const [selectedTargetType, setSelectedTargetType] = useState("");
  const [selectedTargetOption, setSelectedTargetOption] = useState("");
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);

  const userObj = useSelector((state) => state.authManager.user);
  const selectedUser = useSelector((state) => state.userManager.user);
  const verticalOptions = useSelector(
    (state) => state.userManager.verticalOptions
  );
  const categoryOptions = useSelector(
    (state) => state.productManager.categoryOptions
  );

  const productOptions = useSelector(
    (state) => state.productManager.productOptions
  );

  const territoryOptions = useSelector(
    (state) => state.territoryManager.territoryOptions
  );
  const supplierOptions = useSelector(
    (state) => state.supplierManager.supplierOptions
  );

  const outletOptions = useSelector(
    (state) => state.outletManager.outletOptions
  );
  const dispatch = useDispatch();
  const formikRef = useRef();

  useEffect(() => {
    dispatch(getVerticalOptionsAction());
    dispatch(getProductCategoryOptionsAction());
    dispatch(getProductOptionsAction());
    dispatch(getTerritoryOptionsAction());
    dispatch(getSupplierOptionsAction());
    dispatch(getOutletOptionsAction());
    new AutoNumeric(".amount");
  }, []);

  const handleTargetTypeChange = (selectedOption) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("target", "");
    }
    setSelectedTargetType(selectedOption);
    setSelectedTargetOption("");
    setIsSelectDisabled(false);
    if (selectedOption.value === "VERTICAL") {
      setTargetOptions(verticalOptions);
    } else if (selectedOption.value === "CATEGORY") {
      setTargetOptions(categoryOptions);
    } else if (selectedOption.value === "PRODUCT") {
      setTargetOptions(productOptions);
    } else if (selectedOption.value === "AREA") {
      setTargetOptions(territoryOptions);
    } else if (selectedOption.value === "SUPPLIER") {
      setTargetOptions(supplierOptions);
    } else if (selectedOption.value === "OUTLET") {
      setSelectedTargetOption({
        value: 0,
        label: "OUTLET",
      });
      setIsSelectDisabled(true);
    } else {
      setSelectedTargetOption({
        value: null,
        label: "ALL",
      });
    }
  };

  // const targetOptions = useSelector(
  //   (state) => state.salesManager.targetOptions
  // );

  //console.log("outletOptions = ", outletOptions);

  return (
    <div className="TargetForm">
      <Formik
        innerRef={formikRef}
        initialValues={{
          target: "",
          createdBy: userObj.username,
          username: selectedUser.username,
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            let formData = _.clone(values);
            formData["startTime"] = moment(startDate).format("DD/MM/YYYY");
            formData["endTime"] = moment(endDate).format("DD/MM/YYYY");
            formData["type"] = selectedTargetType.value;
            formData["typeId"] = selectedTargetOption.value;
            formData["typeName"] = selectedTargetOption.label;
            formData["target"] = parseInt(formData.target.replace(/,/g, ""));
            console.log(formData);
            dispatch(setPerformanceTargetAction(formData));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <label>Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control"
                />
              </div>
              <div className="col-sm-6">
                <label>End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-sm-6 ">
                <label>Target Type</label>
                <Select
                  options={targetTypeOptions}
                  value={selectedTargetType}
                  onChange={(selected) => handleTargetTypeChange(selected)}
                />
              </div>
              <div className="col-sm-6">
                <label>Target Options</label>
                <Select
                  options={targetOptions}
                  value={selectedTargetOption}
                  onChange={(selected) => setSelectedTargetOption(selected)}
                  isDisabled={isSelectDisabled}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <label>Target Amount</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      {selectedTargetType.value !== "OUTLET"
                        ? "TZS"
                        : "OUTLETS"}
                    </span>
                  </div>
                  <input
                    id="target"
                    type="text"
                    value={values.target}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group col-md-12 mb-3">
              <button
                type="submit"
                className="actionBtn"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default TargetForm;
