import React, { useState, useEffect, useRef } from "react";
import {
  addProductAction,
  getCategoriesAction,
  editProductAction,
  getSubcategoriesAction,
  getProductSubcategoryOptionsAction,
  getProductCategoryOptionsAction,
  getVerticalOptionsAction,
} from "../../store/actions/ProductAction";
import { resetUtils } from "../../store/actions/UtilsAction";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  FormWrapper,
  Input,
  Label,
  InputWrapper,
  ButtonSmall,
} from "../../styles/Forms";
import ImageUploader from "../../components/utils/ImageUploader";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { filterCategories } from "../../utils/basic";
import {
  addSupplierAction,
  updateSupplierAction,
} from "../../store/actions/SupplierAction";

const nameRegExp = /^([^0-9]*)$/;

const AddSupplierForm = ({ editMode, supplier }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState([]);
  const formikRef = useRef();

  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);

  const userObj = useSelector((state) => state.authManager.user);
  const productCategoryOptions = useSelector(
    (state) => state.productManager.categoryOptions
  );
  const productSubcategoryOptions = useSelector(
    (state) => state.productManager.subCategoryOptions
  );
  const supplierOptions = useSelector(
    (state) => state.supplierManager.supplierOptions
  );

  const verticalOptions = useSelector(
    (state) => state.productManager.verticalOptions
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductCategoryOptionsAction());
    dispatch(getProductSubcategoryOptionsAction());
    dispatch(getVerticalOptionsAction());
  }, []);

  useEffect(() => {
    setCategoryOptions(productCategoryOptions);
    setSubcategoryOptions(productSubcategoryOptions);

    if (editMode && supplier.verticals && supplier.verticals.length > 0) {
      let selectedVertical = supplier.verticals.filter((vertical) => {
        return _.find(verticalOptions, ["id", vertical.id]) === undefined;
      });

      if (selectedVertical && selectedVertical.length > 0) {
        let newSelectedVertical = selectedVertical.map((vertical) => ({
          label: vertical.name,
          value: vertical.id,
          categoryId: vertical.categoryId,
        }));
        console.log("newSelectedVertical = ", newSelectedVertical);
        if (formikRef.current) {
          formikRef.current.setFieldValue("vertical", newSelectedVertical);
        }
        setSelectedVertical(newSelectedVertical);
      }

      //setSelectedVertical(selectedVertical[0]);
    }
  }, [verticalOptions]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        vertical: selectedVertical,
        name: editMode ? supplier.name : "",
        taxExempt: editMode ? supplier.taxExempt : "",
        taxId: editMode ? supplier.taxId : "",
        createdBy: userObj.username,
        region: "Dar-es-salaam",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          let formData = _.clone(values);
          formData["verticals"] =
            formData.vertical.length > 0
              ? formData.vertical.map((cat) => cat.value)
              : [];
          formData = _.omit(formData, "vertical");

          console.log(formData);
          if (editMode) {
            formData["id"] = supplier.id;
            dispatch(updateSupplierAction(formData));
          } else {
            console.log(formData);
            dispatch(addSupplierAction(formData));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .matches(nameRegExp, " Name cannot contain number")
          .required(" Name is required "),
        taxId: Yup.number().typeError("TIN Number must be a number"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <div>
            {/* <code>{JSON.stringify(values)}</code> */}
            {success && <p className="alert alert-success">{message}</p>}
            {error && <p className="alert alert-danger">{message}</p>}
            <FormWrapper>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row mb-2">
                  <div className="form-group col-md-6 mb-3">
                    <Label htmlFor="name">Supplier Name : </Label>
                    <Input
                      id="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? " is-invalid" : ""
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error ml-2 mt-2"> {errors.name}</p>
                    )}
                  </div>

                  <div className="form-group col-md-6 mb-5">
                    <Label htmlFor="taxId">Supplier TIN Number:</Label>
                    <Input
                      id="taxId"
                      type="text"
                      value={values.taxId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.taxId && touched.taxId ? " is-invalid" : ""
                      }
                    />
                    {errors.taxId && touched.taxId && (
                      <p className="error ml-2 mt-2"> {errors.taxId}</p>
                    )}
                  </div>
                </div>

                <div className="form-row mb-4">
                  <div className="form-group col-md-6 mb-4">
                    <Label htmlFor="name">Vertical: </Label>
                    <Select
                      value={selectedVertical}
                      onChange={(value) => {
                        setFieldValue("vertical", value);
                        setSelectedVertical(value);
                      }}
                      isSearchable={true}
                      options={verticalOptions}
                      name="vertical"
                      isLoading={false}
                      loadingMessage={() => "Fetching verticals"}
                      noOptionsMessage={() => "Vertical appears here"}
                      required
                      isMulti
                    />
                    {errors.vertical && touched.vertical && (
                      <p className="error ml-2 mt-2">{errors.vertical.label}</p>
                    )}
                  </div>

                  <div className="col-sm-6">
                    <div className="form-check">
                      <br />
                      <input
                        type="checkbox"
                        name="taxExempt"
                        id="taxExempt"
                        className="form-check-input"
                        onChange={(event) => {
                          const value = event.target.checked ? "Y" : "N";
                          props.setFieldValue("taxExempt", value);
                        }}
                        checked={values.taxExempt === "Y"}
                      />

                      <label class="form-check-label" for="taxExempt">
                        Is Tax Exempted
                      </label>
                    </div>
                    <div class="row form-group">
                      <label class="col-form-label required col-lg-4"></label>{" "}
                      <div class="col-lg-2 "></div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3 mt-2">
                  <ButtonSmall type="submit" disabled={isSubmitting}>
                    {!editMode && <span>Create Supplier</span>}
                    {editMode && <span>Edit Supplier</span>}
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddSupplierForm;
