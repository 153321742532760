import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import {
  getAllOrdersSummaryAction,
  getOrdersAction,
  getOrderSummaryAction,
} from "../../store/actions/OrderAction";
import Table from "../../components/dom/Table/Table";
import { ButtonWithIcon } from "../../styles/Forms";
import moment from "moment";

const OrdersTablePage = ({ payload, view, viewOder, edit, outletNumber }) => {
  const orders = useSelector((state) => state.OrderManager.orders);
  const isLoading = useSelector((state) => state.OrderManager.loading);
  const success = useSelector((state) => state.stateManager.success);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!outletNumber) {
      console.log("NO outletNumber ", outletNumber);
      let filter = {
        orderDir: "desc",
        orderBy: "id",
      };

      dispatch(getOrdersAction(filter));
    } else {
      console.log("YES outletNumber ", outletNumber);
      let fetchOutletOrders = {
        searches: [
          {
            key: "outletNumber",
            value: outletNumber,
          },
        ],
      };
      dispatch(getOrdersAction(fetchOutletOrders));
    }

    dispatch(getAllOrdersSummaryAction({}));
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch(getAllOrdersSummaryAction({}));
      }, 7000);
    }
  }, [success]);

  const columns = [
    {
      Header: "DATE",
      accessor: (d) =>
        `${
          d.createdAt ? moment(d.createdAt).format("ddd D MMM Y  HH:mm A") : "-"
        }`,
      key: ["createdAt"],
    },

    {
      Header: "Order Number",
      accessor: "orderNumber",
      key: ["orderNumber"],
    },

    {
      Header: "Customer Name",
      accessor: "outletName",
      key: ["outletName"],
    },
    {
      Header: "Customer Location",
      accessor: "areaName",
      key: ["areaName"],
    },
    {
      Header: "Order Value",
      accessor: (d) =>
        d.finalCost ? (
          <CurrencyFormat
            value={d.finalCost}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          0
        ),
      key: ["finalCost"],
    },
    {
      Header: "Collected By",
      accessor: "createdByName",
      key: ["createdByName"],
    },

    {
      Header: "Status",
      accessor: "orderStatus",
      key: ["orderStatus"],
    },

    {
      Header: " ",

      Cell: (row) => (
        <div className="d-flex">
          <ButtonWithIcon
            className="primary text-uppercase font-weight-normal"
            onClick={() => viewOder(row.row.original)}
          >
            View
          </ButtonWithIcon>{" "}
          &nbsp;
          {outletNumber ? (
            " "
          ) : (
            <ButtonWithIcon
              className="primary text-uppercase font-weight-normal"
              onClick={() => edit(row.row.original)}
            >
              Edit
            </ButtonWithIcon>
          )}
        </div>
      ),
    },
  ];

  return <>{!isLoading && <Table data={orders} columns={columns} />}</>;
};

export default OrdersTablePage;
