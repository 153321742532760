import React from "react";
import Loader from "react-loader-spinner";
const LoadingIndicator = () => {
  //other logic

  return (
    <div className="text-centre">
      <Loader
        type="Bars"
        color="#00c400"
        height={100}
        width={100}
        className="m-auto loader"
      />
    </div>
  );
};

export default LoadingIndicator;
