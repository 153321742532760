import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faLock } from "@fortawesome/free-solid-svg-icons";
import { getRolesAction } from "../../store/actions/UserAction";
import Table from "../../components/dom/Table/Table";
import { PageButton } from "../../styles/App";
import AddRoleForm from "./AddRoleForm";
import CreateIcon from "@material-ui/icons/Create";
import LockIcon from "@material-ui/icons/Lock";
import { ButtonWithIcon } from "../../styles/Forms";

const RolesPage = ({ show, handleEdit, handlePermissions }) => {
  const [showForm, setShowForm] = useState(false);
  const [editing, setEditing] = useState(false);
  const [canManagePermissions, setCanManagePermissions] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [role, setRole] = useState({});

  const roles = useSelector(state => state.userManager.roles);
  const success = useSelector(state => state.stateManager.success);
  const message = useSelector(state => state.stateManager.message);
  //const products = useSelector(state => state.productManager.products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRolesAction());
  }, []);

  const resetView = () => {
    setShowForm(false);
    setShowTable(true);
    //dispatch(getRolesAction());
  };

  const columns = [
    {
      Header: "Role Name",
      accessor: "name"
    },
    {
      Header: "Description",
      accessor: "description"
    },
    {
      Header: "Action",

      Cell: row => (
        <div>
          <ButtonWithIcon
            onClick={() => handleEdit(row.row.original)}
            className="pointer mr-2"
          >
            <CreateIcon className="primary pointer" />{" "}
            <span className="primary">Edit </span>
          </ButtonWithIcon>

          <ButtonWithIcon
            onClick={() => handlePermissions(row.row.original)}
            className="pointer"
          >
            <LockIcon className="primary pointer" />{" "}
            <span className="primary">Permissions</span>
          </ButtonWithIcon>
        </div>
      )
    }
  ];

  return (
    <>
      {success && <p className="alert alert-success">{message}</p>}
      <div className="row">
        <div className="col-sm-2">
          <h4 className="mt-3">All Roles</h4>
        </div>
        <div className="col-sm-10">
          <PageButton className="float-right" onClick={show}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Role
          </PageButton>
        </div>
      </div>

      {showTable && <Table data={roles} columns={columns} rowInfo={() => {}} />}

      {showForm && (
        <AddRoleForm editMode={editing} role={role} resetView={resetView} />
      )}
    </>
  );
};

export default RolesPage;
