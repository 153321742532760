import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Table from "../../components/dom/Table/Table";
import { getOutletSubcategoriesAction } from "../../store/actions/OutletAction";
import moment from "moment";
import { PageButton } from "../../styles/App";
import CreateIcon from "@material-ui/icons/Create";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";

const OutletSubcategoriesPage = ({ add, edit, showCutOffTimeForm }) => {
  const categoryColumn = [
    {
      Header: "Subcategory",
      accessor: "name",
    },
    {
      Header: "Category",
      accessor: "mainCategoryName",
    },

    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Created By",
      accessor: "createdBy",
    },

    {
      Header: "Action",

      Cell: (row) => (
        <div className="row">
          <div onClick={() => edit(row.row.original)} className="col-5 pointer">
            <CreateIcon className="primary " />{" "}
            <span className="primary">Edit </span>
          </div>
        </div>
      ),
    },
  ];

  const categories = useSelector(
    (state) => state.outletManager.outletSubcategories
  );
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOutletSubcategoriesAction());
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12"></div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <h4 className="mt-3">All Subctegories</h4>
        </div>
        <div className="col-sm-10">
          <PageButton className="float-right" onClick={add}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Create subcategory
          </PageButton>
        </div>
      </div>
      {categories && categories.length === 0 && (
        <p className="alert alert-info mt-3">No subcategories at the moment</p>
      )}
      {categories && categories.length > 0 && (
        <Table data={categories} columns={categoryColumn} rowInfo={() => {}} />
      )}
    </>
  );
};

export default OutletSubcategoriesPage;
