export const types = [
  { label: "Mamanas", value: "MAMANAS" },
  { label: "Drivers", value: "DRIVERS" },
  { label: "Kiosks", value: "KIOSKS" },
  { label: "Premier Group", value: "PREMIER-GROUP" },
  { label: "Hotel", value: "HOTEL" },
  { label: "Restaurant", value: "RESTAURANT" },
  { label: "Take Away", value: "TAKE-AWAY" },
  { label: "POS", value: "POS" },
  { label: "Mercearia", value: "MERCEARIA" },
  { label: "Supermarket", value: "SUPERMARKET" },
  { label: "Hospital", value: "HOSPITAL" },
  { label: "School", value: "SCHOOL" },
  { label: "Petrol Station", value: "PETROL-STATION" },
  { label: "Company Account", value: "GOVERNMENT-PRIVATE-AGENCY" },
  { label: "Containers", value: "CONTAINERS" },
];
