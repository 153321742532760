import {
  getOutlets,
  getOutletDetails,
  createOutlet,
  updateOutlet,
  getOutletProductPrices,
  changeOutletCreditStatus,
  getOutletOrders,
  setCreditLimit,
  getOutletsCreditLimit,
  getOutletCreditLimit,
  getOutletCategories,
  createOutletCategory,
  updateOutletCategory,
  getOutletSubcategories,
  createOutletSubcategory,
  updateOutletSubategory,
  changeOutletStatus,
  getOutletsByTerritory,
  assignOutlets,
  assignVerticalToOutlet,
  getOutletVerticals,
  getOutletSummary,
} from "../../services/OutletService";
import {
  ACTION_FAIL,
  GET_OUTLETS_SUCCESS,
  ACTION_SUCCESS,
  OUTLET_DETAILS_RETRIEVED_SUCCESS,
  GET_OUTLETS_OPTIONS_SUCCESS,
  GET_OUTLETS_PRODUCT_PRICES_SUCCESS,
  GET_BLACKLISTED_OUTLETS_SUCCESS,
  GET_OUTLET_ORDERS_SUCCESS,
  GET_TERRITORY_OUTLETS_SUCCESS,
  GET_OUTLETS_CREDIT_LIMIT_SUCCESS,
  GET_OUTLET_CREDIT_LIMIT_SUCCESS,
  GET_LOCATED_OUTLETS_SUCCESS,
  GET_CUSTOM_PRODUCT_PRICES_SUCCESS,
  GET_OUTLETS_CATEGORIES_SUCCESS,
  GET_OUTLETS_SUB_CATEGORIES_SUCCESS,
  GET_OUTLETS_CATEGORY_OPTIONS_SUCCESS,
  GET_OUTLETS_SUBCATEGORY_OPTIONS_SUCCESS,
  RESET_SELECTED_USER_OUTLETS,
  OUTLET_VERTICALS_RETRIEVED_SUCCESS,
  UPDATE_OUTLET_TERRITORIES,
  RESET_TERRITORY_OUTLETS,
  GET_OUTLET_SUMMARY_SUCCESS,
  CLEAR_OUTLET_DETAILS,
  CLEAR_OUTLET_VERTICALS
} from "../constants/ActionTypes";
import { handleErrorAction } from "./UtilsAction";
import _ from "lodash";

export const getOutletsAction = (data) => {
  return (dispatch) => {
    return getOutlets(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({
            type: GET_OUTLETS_SUCCESS,
            payload: response.data.responseObject
              ? Array.isArray(response.data.responseObject)
                ? response.data.responseObject
                : [response.data.responseObject]
              : [],
            total: response.data.total,
            totalActive: response.data.totalActive,
            totalInactive: response.data.totalInactive,
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_OUTLETS_SUCCESS,
            payload: [],
          });
        }
      },
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: "Something went wrong" })
    );
  };
};

export const getOutletsByTerritoryAction = (data) => {
  return (dispatch) => {
    return getOutletsByTerritory(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          console.log(
            "getOutletsByTerritoryAction response.data.responseObject data",
            response.data.responseObject,
            data
          );
          dispatch({
            type: GET_TERRITORY_OUTLETS_SUCCESS,
            payload: response.data.responseObject ?? [],
          });
        }
      },
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: "Something went wrong" })
    );
  };
};

export const getOutletCategoriesAction = () => {
  return (dispatch) => {
    return getOutletCategories().then(
      (response) => {
        if (response.data.responseObject) {
          dispatch({
            type: GET_OUTLETS_CATEGORIES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOutletSummaryAction = (data) => {
  return (dispatch) => {
    return getOutletSummary(data).then(
      (response) => {
        if (response.status === 200) {
          let dataObj = {};
          let data = _.flatten(response.data ?? []);
          data.map((d) => (dataObj[d.status] = d.count));
          dispatch({
            type: GET_OUTLET_SUMMARY_SUCCESS,
            payload: dataObj,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addOutletCategoryAction = (data) => {
  return function(dispatch) {
    return createOutletCategory(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({ type: ACTION_SUCCESS, payload: response.data.message });
        }
      },
      (error) =>
        dispatch({
          type: ACTION_FAIL,
          payload:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Oops! Somthing went wrong",
        })
    );
  };
};

export const updateOutletCategoryAction = (data) => {
  return function(dispatch) {
    return updateOutletCategory(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) =>
        dispatch({
          type: ACTION_FAIL,
          payload:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Oops! Somthing went wrong",
        })
    );
  };
};

export const addOutletSubcategoryAction = (data) => {
  return function(dispatch) {
    return createOutletSubcategory(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({ type: ACTION_SUCCESS, payload: response.data.message });
        }
      },
      (error) =>
        dispatch({
          type: ACTION_FAIL,
          payload:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Oops! Somthing went wrong",
        })
    );
  };
};

export const updateOutletSubcategoryAction = (data) => {
  return function(dispatch) {
    return updateOutletSubategory(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) =>
        dispatch({
          type: ACTION_FAIL,
          payload:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Oops! Somthing went wrong",
        })
    );
  };
};

export const getOutletSubcategoriesAction = () => {
  return (dispatch) => {
    return getOutletSubcategories().then(
      (response) => {
        dispatch({
          type: GET_OUTLETS_SUB_CATEGORIES_SUCCESS,
          payload: response.data.responseObject
            ? Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject]
            : [],
        });
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOutletCategoryOptionsAction = () => {
  return (dispatch) => {
    return getOutletCategories().then(
      (response) => {
        if (response.data.responseObject) {
          let categories = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          let categoryOptions = categories.map((category) => ({
            value: category.id,
            label: category.name,
          }));
          dispatch({
            type: GET_OUTLETS_CATEGORY_OPTIONS_SUCCESS,
            payload: categoryOptions,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOutletOptionsAction = (data) => {
  return (dispatch) => {
    return getOutlets(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          let outlets = response.data.responseObject ?? [];

          let outletOptions = outlets.map((outlet) => ({
            value: outlet.id,
            label: outlet.name,
            type: outlet.categoryName,
            outletNumber: outlet.outletNumber,
            name: outlet.name,
            mobile: outlet.phone,
            areaId: outlet.areaId,
            areaName: outlet.address,
          }));

          dispatch({
            type: GET_OUTLETS_OPTIONS_SUCCESS,
            payload: outletOptions,
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_OUTLETS_OPTIONS_SUCCESS,
            payload: [],
          });
        }
      },

      (error) =>
        dispatch({ type: ACTION_FAIL, payload: "Something went wrong" })
    );
  };
};

export const assignOutletsAction = (data) => {
  return (dispatch) => {
    return assignOutlets(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }
        if (response.data && response.data.code === 400) {
          dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOutletSubcategoryOptionsAction = () => {
  return (dispatch) => {
    return getOutletSubcategories().then(
      (response) => {
        if (response.data.responseObject) {
          let categories = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          let categoryOptions = categories.map((category) => ({
            value: category.id,
            label: category.name,
            parent: category.mainCategoryId,
          }));
          dispatch({
            type: GET_OUTLETS_SUBCATEGORY_OPTIONS_SUCCESS,
            payload: categoryOptions,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOutletDetailsAction = (outletNumber) => {
  return (dispatch) => {
    return getOutletDetails(outletNumber).then(
      (response) => {
        if (response.data.code === 200) {
          return dispatch({
            type: OUTLET_DETAILS_RETRIEVED_SUCCESS,
            payload: response.data.responseObject,
          });
        }

        if (response.data.code === 400) {
          return dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const clearOutletDetailsAction = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_OUTLET_DETAILS });
  };
};

export const getOutletVerticalsAction = (outletNumber) => {
  return (dispatch) => {
    return getOutletVerticals(outletNumber).then(
      (response) => {
        if (response.data.code === 200) {
          return dispatch({
            type: OUTLET_VERTICALS_RETRIEVED_SUCCESS,
            payload: response.data.responseObject,
          });
        }

        if (response.data.code === 400) {
          return dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const clearOutletVerticalsAction = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_OUTLET_VERTICALS });
  };
};

export const addOutletAction = (data, verticals) => {
  return function(dispatch) {
    return createOutlet(data).then(
      (response) => {
        if (response.data.code === 200) {
          let outletData = {
            outletNumber: response.data.responseObject.outletNumber,
            verticals: verticals,
          };
          assignVerticalToOutlet(outletData)
            .then((res) => console.log(res))
            .catch((error) => dispatch(handleErrorAction(error)));
          return dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }

        if (response.data.code === 400) {
          return dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const updateOutletAction = (data, verticals, outletNumber) => {
  return function(dispatch) {
    return updateOutlet(data).then(
      (response) => {
        if (response.data.code === 200) {
          let outletData = {
            outletNumber,
            verticals: verticals,
          };
          assignVerticalToOutlet(outletData)
            .then((res) => console.log(res))
            .catch((error) => dispatch(handleErrorAction(error)));
          return dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }

        if (response.data.code === 400) {
          return dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const changeOutletStatusAction = (data) => {
  return function(dispatch) {
    return changeOutletStatus(data).then(
      (response) => {
        if (response.data.code === 200) {
          return dispatch({
            type: ACTION_SUCCESS,
            payload: "Outlet status changed ",
          });
        }

        if (response.data.code === 400) {
          return dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOutletProductPricesAction = (data) => {
  return (dispatch) => {
    return getOutletProductPrices(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          let productPrices = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          dispatch({
            type: GET_OUTLETS_PRODUCT_PRICES_SUCCESS,
            payload: productPrices,
          });
        }

        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_OUTLETS_PRODUCT_PRICES_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const changeOutletCreditStatusAction = (data) => {
  return (dispatch) => {
    return changeOutletCreditStatus(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: ACTION_SUCCESS,
            payload: "Success! Outlet has been removed from the blacklist",
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: ACTION_FAIL,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getBlacklistedOutlets = (data) => {
  return (dispatch) => {
    return getOutlets(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_BLACKLISTED_OUTLETS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
      },
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: "Something went wrong" })
    );
  };
};

export const getOutletOrdersAction = (data) => {
  return (dispatch) => {
    return getOutletOrders(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          if (
            response.data.responseObject[0] &&
            response.data.responseObject[0].orders &&
            response.data.responseObject[0].orders.length > 0
          ) {
            dispatch({
              type: GET_OUTLET_ORDERS_SUCCESS,
              payload: response.data.responseObject[0].orders,
            });
          } else {
            dispatch({
              type: GET_OUTLET_ORDERS_SUCCESS,
              payload: [],
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const setCreditLimitAction = (data) => {
  return (dispatch) => {
    return setCreditLimit(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: ACTION_FAIL,
            payload: response.data.message,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getCreditLimitAction = () => {
  return (dispatch) => {
    return getOutletsCreditLimit().then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_OUTLETS_CREDIT_LIMIT_SUCCESS,
            payload: response.data.responseObject,
          });
        }
        if (response.data && response.data.code === "400") {
          if (response.data.responseObject) {
            dispatch({
              type: ACTION_FAIL,
              payload: response.data.message,
            });
          } else {
            dispatch({
              type: GET_OUTLETS_CREDIT_LIMIT_SUCCESS,
              payload: [],
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOutletCreditLimitAction = (data) => {
  return (dispatch) => {
    return getOutletCreditLimit(data).then(
      (response) => {
        if (response.data.code === "200") {
          return dispatch({
            type: GET_OUTLET_CREDIT_LIMIT_SUCCESS,
            payload: response.data.responseObject.limit,
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getOutletsWithCoordinatesAction = (data) => {
  return (dispatch) => {
    return getOutlets(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          let outlets = response.data.responseObject
            ? response.data.responseObject
            : [];
          let locatedOutlets =
            outlets.length > 0 &&
            outlets.filter((outlet) => outlet.latitude && outlet.longitude);
          // console.log(outlets);
          console.log(locatedOutlets);
          dispatch({
            type: GET_LOCATED_OUTLETS_SUCCESS,
            payload: locatedOutlets,
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_OUTLETS_SUCCESS,
            payload: [],
          });
        }
      },
      (error) =>
        dispatch({ type: ACTION_FAIL, payload: "Something went wrong" })
    );
  };
};

export const toggleOutletCreditAction = (data) => {
  return (dispatch) => {
    return changeOutletCreditStatus(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: ACTION_SUCCESS,
            payload: response.data.message,
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: ACTION_FAIL,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getCutomProductPricesAction = (data, products) => {
  return (dispatch) => {
    return getOutletProductPrices(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          let customPrices = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];

          let customPricesObj = {};

          if (customPrices.length > 0) {
            customPrices.map((customPrice) => {
              let index = _.findIndex(products, [
                "productNumber",
                customPrice.productNumber,
              ]);
              if (index > -1) {
                customPricesObj[index] = {
                  price: customPrice.price,
                  discount: customPrice.discount,
                };
              }
            });

            dispatch({
              type: GET_CUSTOM_PRODUCT_PRICES_SUCCESS,
              payload: customPricesObj,
            });
          } else {
            dispatch({
              type: GET_CUSTOM_PRODUCT_PRICES_SUCCESS,
              payload: {},
            });
          }
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const resetSelectedOutletsAction = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SELECTED_USER_OUTLETS });
  };
};

export const updateTerritoryOutletsAction = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_OUTLET_TERRITORIES, payload: data });
  };
};

export const resetTerritoryOutletsAction = () => {
  return (dispatch) => {
    dispatch({ type: RESET_TERRITORY_OUTLETS });
  };
};
