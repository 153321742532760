import React, { useState, useEffect } from "react";

import {
  addProductCategoryAction,
  updateProductCategoryAction,
} from "../../store/actions/ProductAction";

import { useDispatch, useSelector } from "react-redux";

import { FormWrapper, Input, Label, ButtonSmall } from "../../styles/Forms";
import ImageUploader from "../../components/utils/ImageUploader";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  createTerritoryAction,
  toggleTerritoryFormAction,
  updateTerritoryAction,
} from "../../store/actions/TerritoryAction";

const nameRegExp = /^([^0-9]*)$/;

const AddTerritoryForm = ({ editMode, territory }) => {
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const coordinates = useSelector(
    (state) => state.territoryManager.coordinates
  );

  const data = useSelector((state) => state.stateManager.data);
  const userObj = useSelector((state) => state.authManager.user);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("AddTerritoryForm editMode territory", editMode, territory);
    toggleTerritoryFormAction(false);
  }, []);

  var bounds = [
    [-6.93789, 39.2014],
    [-6.99948, 39.29336],
    [-6.92157, 39.30191],
    [-6.84841, 39.28369],
    [-6.8223, 39.28677],
    [-6.81858, 39.29102],
    [-6.81769, 39.29483],
    [-6.81872, 39.29724],
    [-6.81872, 39.29981],
    [-6.81657, 39.30131],
    [-6.81288, 39.30053],
    [-6.80968, 39.2981],
    [-6.79401, 39.28371],
    [-6.78226, 39.28734],
    [-6.76788, 39.28351],
    [-6.75591, 39.2881],
    [-6.74826, 39.28966],
    [-6.74025, 39.28613],
    [-6.73229, 39.27732],
    [-6.7461, 39.27103],
    [-6.75458, 39.26973],
    [-6.75931, 39.26242],
    [-6.74839, 39.24548],
    [-6.7304, 39.23858],
    [-6.70823, 39.23246],
    [-6.69509, 39.22665],
    [-6.66696, 39.22352],
    [-6.63797, 39.20134],
    [-6.62704, 39.18278],
    [-6.61172, 39.16955],
    [-6.59514, 39.16279],
    [-6.58251, 39.1545],
    [-6.57413, 39.14123],
    [-6.58468, 39.12147],
    [-6.6055, 39.12728],
    [-6.71788, 39.12052],
    [-6.76895, 39.11869],
    [-6.79308, 39.14707],
    [-6.88828, 39.14213],
    [-6.92698, 39.17668],
    [-6.93789, 39.2014],
  ];

  return (
    <Formik
      initialValues={{
        name: editMode ? territory.name : "",
        coordinates: coordinates,
        createdBy: userObj.username,
        description: editMode ? territory.description : "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          let formData = _.clone(values);
          let reversedBounds = bounds.map((bound) => [bound[1], bound[0]]);
          formData["coordinates"] = coordinates;
          //  coordinates[0].push(coordinates[0][0]);
          if (editMode) {
            formData["id"] = territory.id;
            formData["username"] = userObj.username;
            formData["coordinates"] = coordinates;
            formData = _.omit(formData, "createdBy");

            console.log(formData);
            dispatch(updateTerritoryAction(formData));
          } else {
            //dispatch(createTerritoryAction(values));
            dispatch(createTerritoryAction(formData));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(" Name is required "),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div>
            <FormWrapper>
              {!editMode && <h3>Create New Territory</h3>}
              {success && <p className="alert alert-success">{message}</p>}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row mb-4 align-items-center">
                  <div
                    className={`${
                      editMode
                        ? "form-group col-md-4 mb-4"
                        : "form-group col-md-12 mb-4"
                    }`}
                  >
                    <Label htmlFor="name">Name: </Label>
                    <Input
                      id="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? " is-invalid" : ""
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error ml-2 mt-2"> {errors.name}</p>
                    )}
                  </div>
                  <div
                    className={`${
                      editMode
                        ? "form-group col-md-4 mb-4"
                        : "form-group col-md-12 mb-4"
                    }`}
                  >
                    <Label htmlFor="description">Description: </Label>
                    <Input
                      id="description"
                      placeholder="Enter  Description"
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.description && touched.description
                          ? " is-invalid"
                          : ""
                      }
                    />
                    {errors.description && touched.description && (
                      <p className="error ml-2 mt-2"> {errors.description}</p>
                    )}
                  </div>

                  <div
                    className={`${
                      editMode
                        ? "form-group col-md-4 mb-4"
                        : "form-group col-md-12 mb-4"
                    }`}
                  >
                    {editMode && (
                      <button type="submit" disabled={isSubmitting}>
                        <span>Save Changes</span>
                      </button>
                    )}
                    {!editMode && (
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        style={{ width: "100%", padding: "5px" }}
                      >
                        <span>Create Territory</span>
                      </button>
                    )}
                  </div>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddTerritoryForm;
