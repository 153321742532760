import {
  createCrate,
  getCrates,
  editTruck,
  getUserCrates,
  getDamagedCrates,
} from "../../services/CratesService";
import { handleErrorAction } from "./UtilsAction";
import {
  ACTION_FAIL,
  ACTION_SUCCESS,
  GET_CRATES_SUCCESS,
  GET_CRATE_OPTIONS_SUCCESS,
  GET_USER_RETURNED_CRATES_SUCCESS,
  GET_USER_DAMAGED_CRATES_SUCCESS,
} from "../constants/ActionTypes";

export const getCratesAction = () => {
  return (dispatch) => {
    return getCrates().then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_CRATES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_CRATES_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getCratesOptionsAction = () => {
  return (dispatch) => {
    return getCrates().then(
      (response) => {
        if (response.data && response.data.code === "200") {
          let crates = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];
          let options = crates.map((crate) => ({
            value: crate.id,
            label: crate.name,
          }));
          console.log(options);
          dispatch({
            type: GET_CRATE_OPTIONS_SUCCESS,
            payload: options,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addCrateAction = (data) => {
  return function(dispatch) {
    return createCrate(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const editTruckAction = (data) => {
  return function(dispatch) {
    return editTruck(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUserReturnedCratesAction = (data) => {
  return (dispatch) => {
    return getUserCrates(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_USER_RETURNED_CRATES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_USER_RETURNED_CRATES_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getDamagedCratesAction = (data) => {
  return (dispatch) => {
    return getDamagedCrates(data).then(
      (response) => {
        if (response.data.code === "200") {
          dispatch({
            type: GET_USER_DAMAGED_CRATES_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_USER_DAMAGED_CRATES_SUCCESS,
            payload: [],
          });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};


