import {
  createTerritory,
  getTerritories,
  updateTerritory,
} from "../../services/territoryService";
import {
  TOGGLE_TERRITORY_FORM,
  SET_TERRITORY_COORDIBATES,
  ACTION_SUCCESS,
  GET_TERRITORIES_SUCCESS,
  GET_TERRITORY_OPTIONS_SUCCESS,
  UPDATE_TERRITORY_COORDIBATES,
  GET_TERRITORY_POLYGONS_SUCCESS,
} from "../constants/ActionTypes";
import { handleErrorAction } from "./UtilsAction";

export const getTerritoriesAction = (data) => {
  return (dispatch) => {
    return getTerritories(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({
            type: GET_TERRITORIES_SUCCESS,
            payload: response.data.responseObject
              ? response.data.responseObject
              : [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTerritoryPolygonsAction = (data) => {
  return (dispatch) => {
    return getTerritories(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          let territories = response.data.responseObject
            ? response.data.responseObject
            : [];

          let filtredTerritories =
            territories &&
            territories.filter((territory) => territory.coordinates);

          //Store Polygon path in state

          let territoryPaths = filtredTerritories.map((territory) => {
            let latLongArr = territory.coordinates.map((coordinate) => ({
              lat: coordinate[1],
              lng: coordinate[0],
            }));
            return { id: territory.id, name: territory.name, path: latLongArr };
          });

          console.log(
            "getTerritoryPolygonsAction territoryPaths = ",
            territoryPaths
          );
          dispatch({
            type: GET_TERRITORY_POLYGONS_SUCCESS,
            payload: territoryPaths,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTerritoryOptionsAction = (data) => {
  return (dispatch) => {
    return getTerritories(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          let territories = response.data.responseObject
            ? response.data.responseObject
            : [];

          let options = territories.map((product) => ({
            value: product.id,
            label: product.name,
          }));
          dispatch({
            type: GET_TERRITORY_OPTIONS_SUCCESS,
            payload: options,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const createTerritoryAction = (data) => {
  return function(dispatch) {
    return createTerritory(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({ type: ACTION_SUCCESS, payload: response.data.message });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const updateTerritoryAction = (data) => {
  return function(dispatch) {
    return updateTerritory(data).then(
      (response) => {
        if (response.data && response.data.code === 200) {
          dispatch({ type: ACTION_SUCCESS, payload: response.data.message });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const setTerritoryCoordinatesAction = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_TERRITORY_COORDIBATES, payload: data });
  };
};

export const updateTerritoryCoordinatesAction = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_TERRITORY_COORDIBATES, payload: data });
  };
};

export const toggleTerritoryFormAction = (value) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_TERRITORY_FORM, payload: value });
  };
};
