import styled from "styled-components";

export const ScheduleWrapper = styled.div`
  font-size: 12px;
  color: #373d42;
`;

export const SetTargetWrapper = styled.div`
  div.react-datepicker-wrapper,
  div.react-datepicker-wrapper input,
  label {
    width: 100%;
  }

  div.react-datepicker-wrapper input {
    padding: 10px;
  }

  .MuiSvgIcon-root {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

export const DateSearchtWrapper = styled.div`
  div.react-datepicker-wrapper,
  div.react-datepicker-wrapper input,
  label {
    width: 100%;
  }

  div.react-datepicker-wrapper input {
    padding: 5px;
  }

  .MuiSvgIcon-root {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

export const BoxWrapper = styled.div`
  div.react-datepicker-wrapper,
  div.react-datepicker-wrapper input,
  label {
    width: 100%;
    margin-left: 10%
  }

  div.react-datepicker-wrapper input {
    padding: 5px;
    border: 2px solid #e6e6e2;
  }

  .MuiSvgIcon-root {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

export const ButtonBox = styled.button`
  background: none;
  padding: 10px;
  border: 2px solid #e6e6e2;
  border-radius: 2px;Box
  font-weight: bold;
  font-size: 10px;
  color: #00c400;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }
`;
