import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "16%",
    left: "79%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "370px",
    width: "100%",
    background: "none",
    border: "none",
  },
};

const NotificationModal = ({ isOpen, setIsOpen, component }) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      style={customStyles}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName="bgOverlay"
      closeTimeoutMS={2000}
    >
      {component}
    </Modal>
  );
};

export default NotificationModal;
