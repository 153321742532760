export const appVersion = "2.3.5";
export const APP_URL = process.env.REACT_APP_ROOT_URL;
export const NOTIFICATION_SERVICE = "inalipa-b2b";
export const TOKEN_AUTH_URL =
  "https://compliancedev.aimgroup.co.tz:8443/sfa/auth/oauth2/token";
export const GENERICS_URL =
  "https://compliancedev.aimgroup.co.tz:8443/generics/";

export const METABASE_SITE_URL = "https://sfareportsdev.aimgroup.co.tz";
export const SEARCH_LIMIT = 1000;

export const METABASE_SECRET_KEY =
  "f0c0c25585b43514a231685d256ddf31ff9225abf9095d320ae437d932bd1e88";
export const contentTypeApplicationJson = {
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
};

export const contentTypeUrlEncoded = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const contentTypeFormData = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const GOOGLE_MAPS_API_KEY = "AIzaSyBwuLdw8RyUO2mNVZQ3f9ux34F_2duP_lU";
export const SFA_CLIENT_ID = "iQkk65jrK4UvL5Bn4jIWK8BtnRbJJdNi";
export const SFA_CLIENT_SECRET = "LlpxW53AapIZlKG9LPLNl21JgcRu04BZ";
export const SESSION_EXPIRE_TIME = "SET";
export const EXPIRATION_WINDOW_IN_SECONDS = 1200;
export const time = "ddss";

export const imagePlaceholder =
  "https://sfaimagesbucket.s3.amazonaws.com/Profiles/no-photo-opaque.png";
export const AUTOCOMPLETE_LIMIT = 50;
