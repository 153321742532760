

import React from "react";
import { Tile } from "../../styles/App";

const MultiCountsCard = ({ title,value1,desc1,value2,desc2,subValuiption,inlineStyle, }) => {
  return (
    <div class={inlineStyle}>
    <div class="">
    
        <div class="card-body">
           
                <h5 class="text-muted font-weight-normal mt-0" >{title}</h5>
                <div class = "float-left">
                    <h2 class="mt-3 mb-3">{value1}</h2>
                    <p class="mb-0 text-muted">
                       
                        <span class="text-nowrap">{desc1}</span>  
                    </p>
                </div>
                <div class = "float-right">
                    <h2 class="mt-3 mb-3">{value2}</h2>
                    <p class="mb-0 text-muted">
                        <span class="text-nowrap">{desc2}</span>  
                    </p>
                </div>
            
        </div> 
    
    </div> 
    </div> 
  );
};

export default MultiCountsCard;
