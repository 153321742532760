import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReturnedExpiredProductsAction,
  getUserCreditsAction,
} from "../../store/actions/SalesAction";
import { getDamagedCratesAction , getUserReturnedCratesAction} from "../../store/actions/CratesAction";
import Table from "../../components/dom/Table/Table";
import moment from "moment";

const expiredProductsColumns = [
  {
    Header: "Date",
    accessor: (d) => moment(d.createdAt).format("LLL"),
  },
  {
    Header: "Outlet Name",
    accessor: "outletName",
  },
  {
    Header: "Outlet Type",
    accessor: "outletType",
  },
  {
    Header: "Product (Quantity) ",
    accessor: d=>d.items?d.items.map(product=>(`${product.productName} (${product.quantity}) , `)):"-",
  }
  
];

const damagedCratesColumns = [
  {
    Header: "Date",
    accessor: (d) => moment(d.createdAt).format("LLL"),
  },
  {
    Header: "Items",
    accessor: "crateName",
  },
  {
    Header: "QTY",
    accessor: "quantity",
  },
];

const goodCratesColumns = [
  {
    Header: "Date",
    accessor: (d) => moment(d.createdAt).format("LLL"),
  },
  {
    Header: "Items",
    accessor: "crateName",
  },
  {
    Header: "QTY",
    accessor: "returned",
  },
];

const creditsColumns = [
  {
    Header: "Date",
    accessor: "createdAt",
  },
  {
    Header: "Customer Name",
    accessor: "clientName",
  },
  {
    Header: "Payment Type",
    accessor: "paymentType",
  },
  {
    Header: "Route Name",
    accessor: "routeName",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

const UserSummaryPage = ({ user }) => {
  const expiredProducts = useSelector(
    (state) => state.salesManager.expiredProducts
  );
  const crates = useSelector((state) => state.cratesManager.damagedCrates);
  const returnedCrates = useSelector((state) => state.cratesManager.returnedCrates);
  
  const userCredits = useSelector((state) => state.salesManager.userCredits);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(expiredProducts)
    let creditPayload = {
      searches: [
        {
          key: "driver",
          value: user.username,
        },
      ],
    };

    let goodCratesPayload = {
      searches: [
        {
          key: "driver",
          value: user.username,
        },
        { key: "returned", comparisonType: "GREATER_THAN", value: "0" },
      ],
    };
  
    let cratesPayload = {
      searches: [
        {
          key: "driver",
          value: user.username,
        },
      ],
    };
    let filter = {
      searches: [
        {
          key: "status",
          value: "RETURNED",
        },
        {
          key: "collectedBy",
          value: user.username,
        },
      ],
    };
    dispatch(getReturnedExpiredProductsAction(filter));
    dispatch(getDamagedCratesAction(cratesPayload));
    dispatch(getUserReturnedCratesAction(goodCratesPayload));
    dispatch(getUserCreditsAction(creditPayload));
  }, []);
  return (
    <>
      <div className="edo-container">
        <div className="row">
          <div className="col-sm-12">
            <h3>Credit Requests</h3>
            {userCredits && userCredits.length > 0 && (
              <Table
                data={userCredits}
                columns={creditsColumns}
                showTop={false}
              />
            )}
             {userCredits && userCredits.length===0 && 
            <p className="alert alert-info">No credit requests at the moment</p>
            }
          </div>
        </div>
      </div>

      <div className="edo-container">
        <div className="row">
      
          <div className="col-sm-12">
            <h3>Expired Products</h3>
            {expiredProducts && expiredProducts.length>0 && 
            <Table
              data={expiredProducts}
              columns={expiredProductsColumns}
              showTop={false}
            />
            }
            {expiredProducts && expiredProducts.length===0 && 
            <p className="alert alert-info">No expired products at the moment</p>
            }
          </div>

          <div className="col-sm-12">
            <h3>Damaged Crates</h3>
            {crates && (
              <Table
                data={crates}
                columns={damagedCratesColumns}
                showTop={false}
              />
            )}
             {crates && crates.length===0 && 
            <p className="alert alert-info">No damaged crates returned at the moment</p>
            }
          </div>


          <div className="col-sm-12">
            <h3>Good Crates</h3>
            {returnedCrates && (
              <Table
                data={returnedCrates}
                columns={goodCratesColumns}
                showTop={false}
              />
            )}
             {returnedCrates && returnedCrates.length===0 && 
            <p className="alert alert-info">No Good crates returned  at the moment</p>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSummaryPage;
