import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  getOutletCategoriesAction,
  getOutletCategoryOptionsAction,
  getOutletsAction,
  getOutletSubcategoriesAction,
  getOutletSummaryAction,
  toggleOutletCreditAction,
} from "../../store/actions/OutletAction";
import Tile from "../../components/dom/Tile";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import Table from "../../components/dom/Table/Table";
import AddOutletPage from "./AddOutletPage";
import OutletDetails from "./OutletDetails";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import { scrollToTop } from "../../utils/window";
import { ButtonWithIcon } from "../../styles/Forms";
import { usePermissionChecker } from "../../utils/user";
import LoadingIndicator from "../../components/dom/LoadingIndicator";
import { TabsWrapper } from "../../styles/Tabs";
import AddCategoryForm from "./AddCategoryForm";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-tabs/style/react-tabs.css";
import OutletCategoriesPage from "./OutletCategoriesPage";
import PageModal from "../../components/dom/modal";
import { GET_OUTLETS_SUB_CATEGORIES_SUCCESS } from "../../store/constants/ActionTypes";
import OutletSubcategoriesPage from "./OutletSubcategoriesPage";
import AddSubcategoryForm from "./AddSubcategoryForm";

const OutletsPage = () => {
  const location = useLocation();
  const { outletId, nameOfOutlet } = location.state ?? {};
  const [outlet, setOutlet] = useState({});
  const [editMode, setEditMode] = useState(false);
  const outlets = useSelector((state) => state.outletManager.outlets);
  const isLoading = useSelector((state) => state.outletManager.loading);
  const total = useSelector((state) => state.outletManager.total);
  const totalActive = useSelector((state) => state.outletManager.totalActive);
  const totalInactive = useSelector(
    (state) => state.outletManager.totalInactive
  );
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [switchState, setSwitchState] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [outletName, setOutletName] = useState(null);
  const [outletNumber, setOutletNumber] = useState(null);
  const [category, setCategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);
  const [subcategoryModalIsOpen, setSubcategoryModalIsOpen] = useState(false);
  const user = useSelector((state) => state.authManager.user);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const summary = useSelector((state) => state.outletManager.summary);
  const leadsCountByType = useSelector(
    (state) => state.reportManager.leadsCountByType
  );
  const canCreateOutlet = usePermissionChecker("can_create_outlet");

  const handleChange = (row, event, checked) => {
    const list = Object.assign({}, switchState);

    list["switch-" + row.index] = checked;
    let data = {};
    if (checked) {
      data = {
        outletNumber: row.original.outletNumber,
        status: "ALLOWED",
      };
    } else {
      data = {
        outletNumber: row.original.outletNumber,
        status: "DISALLOWED",
      };
    }

    dispatch(toggleOutletCreditAction(data));
    setSwitchState(list);
  };

  const columns = [
    {
      Header: "Created Date",
      accessor: (d) => moment(d.createdAt).format("ddd D MMM YYYY"),
      key: ["createdAt"],
    },
    {
      Header: "Outlet Number",
      accessor: "outletNumber",
      key: ["outletNumber"],
    },
    {
      Header: "Outlet Name",
      accessor: "name",
      key: ["name"],
    },
    {
      Header: "Outlet Type",
      accessor: "outletType",
      key: ["outletType"],
    },

    {
      Header: "Owner Name",
      accessor: "owner",
      key: ["owner"],
    },
    {
      Header: "Mobile",
      accessor: "phone",
      key: ["phone"],
    },
    {
      Header: "Status",
      accessor: "registrationStatus",
      key: ["registrationStatus"],
    },
    {
      Header: "Added By",
      accessor: "createdBy",
      key: ["createdBy"],
    },

    {
      Header: " ",

      Cell: ({ row }) => (
        <ButtonWithIcon
          className="primary text-uppercase font-weight-normal"
          onClick={() => outletDetails(row)}
        >
          View Outlet
        </ButtonWithIcon>
      ),
    },
  ];

  useEffect(() => {
    console.log("useParams outletId = ", outletId);
    if (outletId) {
      setShowDetails(true);
      setOutletName(nameOfOutlet);
      setOutletNumber(outletId);
    }
    dispatch(getOutletsAction({}));
    dispatch(getOutletSummaryAction({}));
    dispatch(getOutletCategoryOptionsAction());
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setCategoryModalIsOpen(false);
        setSubcategoryModalIsOpen(false);
        dispatch(getOutletCategoriesAction());
        dispatch(getOutletSubcategoriesAction());
        dispatch(getOutletsAction({}));
      }, 10000);
    }
  }, [success]);

  const addOutlet = () => {
    setShowForm(true);
    setEditMode(false);
  };

  const addCategory = (row) => {
    setShowForm(false);
    setEditMode(false);
    setCategoryModalIsOpen(true);
  };

  const addSubcategory = (row) => {
    setShowForm(false);
    setEditMode(false);
    setSubcategoryModalIsOpen(true);
  };

  const editCategory = (category) => {
    setShowForm(false);
    setCategory(category);
    setEditMode(true);
    setCategoryModalIsOpen(true);
  };

  const editSubcategory = (subcategory) => {
    setShowForm(false);
    setEditMode(false);
    setSubcategoryModalIsOpen(true);
    setSubcategory(subcategory);
    setEditMode(true);
  };

  const editOutlet = (outlet) => {
    setCategoryModalIsOpen(false);
    setShowForm(true);
    setOutlet(outlet);
    setEditMode(true);
  };

  const resetView = () => {
    setTimeout(() => {
      dispatch(getOutletsAction({}));
    }, 7000);
    setShowDetails(false);
    setShowForm(false);
    setEditMode(false);
    scrollToTop();
  };

  const outletDetails = (rowobject) => {
    //console.log("outletDetails rowobject.values", rowobject.values);
    setShowDetails(true);
    setOutletName(rowobject.values.name);
    setOutletNumber(rowobject.values.outletNumber);
  };

  if (showForm) {
    return (
      <AddOutletPage
        resetView={resetView}
        outlet={outlet}
        editMode={editMode}
      />
    );
  }
  if (showDetails && outletNumber) {
    return (
      <OutletDetails
        name={outletName}
        resetView={resetView}
        outletNumber={outletNumber}
        edit={editOutlet}
      />
    );
  }

  return (
    <>
      {/* {isLoading && <LoadingIndicator />} */}

      <>
        <PageTopHeader
          title="Outlet Management"
          icon={faPlus}
          actionText={canCreateOutlet ? "Create an outlet" : ""}
          action={addOutlet}
          permission="can_create_outlet"
        />
        {error && <p className="alert alrt-danger">{message}</p>}
        {success && <p className="alert alert-success">{message}</p>}

        <div className="row">
          <Tile
            title="total Outlets"
            inlineStyle="col-sm-3"
            value={summary.Active + summary.Inactive}
          />

          <Tile
            title="Activated Outlets"
            inlineStyle="col-sm-3"
            value={summary.Active}
          />
          <Tile
            title="Deactivated Outlets"
            inlineStyle="col-sm-3"
            value={summary.Inactive}
          />
          <Tile
            title="Leads"
            inlineStyle="col-sm-3"
            value={
              leadsCountByType && leadsCountByType.totalLeads
                ? leadsCountByType.totalLeads
                : 0
            }
          />
        </div>
        {/* {success && <p className="alert alert-success">{message}</p>} */}

        <TabsWrapper>
          <Tabs>
            <TabList>
              <Tab>Outlets</Tab>
              <Tab>Outlet Types</Tab>
            </TabList>
            <TabPanel>
              <>
                {outlets && outlets.length === 0 && (
                  <p className="alert alert-info mt-3">
                    No Outlets created at the moment
                  </p>
                )}
                {outlets && outlets.length > 0 && (
                  <Table data={outlets} columns={columns} />
                )}
              </>
            </TabPanel>
            <TabPanel>
              <OutletCategoriesPage add={addCategory} edit={editCategory} />
            </TabPanel>
            <TabPanel>
              <OutletSubcategoriesPage
                add={addSubcategory}
                edit={editSubcategory}
              />
            </TabPanel>
          </Tabs>
        </TabsWrapper>

        <PageModal
          isOpen={categoryModalIsOpen}
          component={
            <AddCategoryForm category={category} editMode={editMode} />
          }
          setIsOpen={setCategoryModalIsOpen}
        />

        <PageModal
          isOpen={subcategoryModalIsOpen}
          component={
            <AddSubcategoryForm subcategory={subcategory} editMode={editMode} />
          }
          setIsOpen={setSubcategoryModalIsOpen}
        />
      </>
    </>
  );
};

export default OutletsPage;
