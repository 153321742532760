import React, { useEffect, useState } from "react";
import { getUserIframeURL } from "../metabase/metabase.embed.js";
import { useDispatch, useSelector } from "react-redux";
import { getAgentRouteAction } from "../../store/actions/RouteAction";

const OrderSummary = (params) => {
  const [reportId, setReportId] = useState(null);
  const user = useSelector((state) => state.authManager.user);
  const userRoute = useSelector((state) => state.routeManager.route);

  const dispatch = useDispatch();

  useEffect(() => {
    getUserRoute(user);
    renderReport();
  }, []);
  const getUserRoute = (user) => {
    setTimeout(
      () =>
        dispatch(
          getAgentRouteAction({ agent: user.username, shift: user.shift })
        ),
      6000
    );
  };
  const renderReport = () => {
    if (user.role === "admin") {
      return setReportId(161);
    } else if (user.role === "call-centre-manager") {
      console.log("user", user);
      return setReportId(161); //was 156
      //160 =>161
    } else if (user.role === "call-centre-agent") {
      return setReportId(159);
    } else if (user.role === "production-manager") {
      return setReportId(160); //was 155
    } else {
      return setReportId(161); //was 130
    }
  };

  return (
    <div>
      Orders summary
    </div>
  );
};

export default OrderSummary;
