import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/dom/Table/Table";
import { unsoldColumns } from "./columns";
import { getUserUnsoldProductsAction } from "../../store/actions/SalesAction";
import "react-tabs/style/react-tabs.css";

const UserUnsoldTable = (payload) => {
  const unsoldProducts = useSelector(
    (state) => state.salesManager.unsoldProducts
  );
  const userObj = useSelector((state) => state.userManager.user);
  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      searches: [payload],
    };

    dispatch(getUserUnsoldProductsAction(data));
  }, []);

  return (
    <>
      {unsoldProducts && unsoldProducts.length > 0 && (
        <Table data={unsoldProducts} columns={unsoldColumns} />
      )}

      {unsoldProducts && unsoldProducts.length === 0 && (
        <p className="alert alert-info">
          No nsold products to show at the moment
        </p>
      )}
    </>
  );
};

export default UserUnsoldTable;
