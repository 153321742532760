import axios from "axios";
import {
  APP_URL,
  GENERICS_URL,
  contentTypeApplicationJson,
  contentTypeUrlEncoded,
  contentTypeFormData,
} from "../constants";
import qs from "qs";

export const getUsers = async () => {
  const result = await axios
    .post(`${APP_URL}usermanagement/userService/listUsers`)
    .then((data) => data);
  return result;
};

export const fetchUsers = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/fetchUsers`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getUserDetails = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/getUserDetails`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const createUser = async (data) => {
  let headers = {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  };
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/addUser`,
      JSON.stringify(data),
      headers
    )
    .then((data) => data);
  return result;
};

export const getUserVerticals = async (username) => {
  const result = await axios
    .get(
      `${APP_URL}productmanagement/productService/get/user/verticals/${username}`
    )
    .then((data) => data);
  return result;
};

export const assignVerticalToUser = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/add/user/vertical`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const uploadUserProfilePicture = async (data) => {
  let headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
    },
  };
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/uploadUserProfilePicture`,
      qs.stringify(data),
      headers
    )
    .then((data) => data);
  return result;
};

export const updateUser = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/updateUser`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const addRole = async (data) => {
  let headers = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
    },
  };
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userPermission/addRole`,
      qs.stringify(data),
      headers
    )
    .then((data) => data);
  return result;
};

export const addPermissionsToRole = async (data) => {
  let headers = {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  };
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userPermission/addOrUpdatePermissionsToRole`,
      JSON.stringify(data),
      headers
    )
    .then((data) => data);
  return result;
};

export const updateRole = async (data) => {
  let headers = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
    },
  };
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userPermission/updateRole`,
      qs.stringify(data),
      headers
    )
    .then((data) => data);
  return result;
};

export const getRoles = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userPermission/getRoles`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const addPermission = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userPermission/addPermission`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const updatePermission = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userPermission/updatePermission`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getPermissions = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userPermission/getPermissions`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const passwordUpdate = async (data) => {
  let headers = {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  };
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/passwordUpdate`,
      JSON.stringify(data),
      headers
    )
    .then((data) => data);
  return result;
};

export const sendPasswordRecoveryToken = async (data) => {
  let headers = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
    },
  };
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/processPasswordRecoveryToken`,
      qs.stringify(data),
      headers
    )
    .then((data) => data);
  return result;
};

export const deactivateUser = (username) => {
  let url = `${APP_URL}usermanagement/userService/deactivateUser`;
  let headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
    },
  };
  var bodyFormData = new FormData();
  bodyFormData.set("username", username);

  return axios.post(url, bodyFormData, headers);
};

export const activateUser = (username) => {
  let url = `${APP_URL}usermanagement/userService/activateUser`;
  let headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
    },
  };
  var bodyFormData = new FormData();
  bodyFormData.set("username", username);

  return axios.post(url, bodyFormData, headers);
};

export const filterUsers = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userService/filterUsers`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
