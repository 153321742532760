import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  getProductCategoryOptionsAction,
  getProductSubcategoryOptionsAction,
  getTaxOptionsAction,
  addProductSKUAction,
  addProductAction,
  getPackagingUnitOptionsAction,
  getMeasurementUnitOptionsAction,
} from "../../store/actions/ProductAction";
import { resetUtils } from "../../store/actions/UtilsAction";
import { useDispatch, useSelector } from "react-redux";
import { FormWrapper, Input, Label, ButtonSmall } from "../../styles/Forms";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";

import ImageUploader from "../../components/utils/ImageUploader";
import "./products.css";

import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { getSupplierOptionsAction } from "../../store/actions/SupplierAction";
import { scrollToTop } from "../../utils/window";

const nameRegExp = /^([^0-9]*)$/;

const options = [
  { value: "box", label: "Box" },
  { value: "lts", label: "Litres" },
  { value: "cm", label: "Centimeters" },
  { value: "dz", label: "Dozens" },
  { value: "ft", label: "Feet" },
  { value: "g", label: "Gram" },
  { value: "pcs", label: "Pieces" },
];

const AddSKUForm = ({ editMode, product, resetView, sku, addMode }) => {
  const [promotions, setPromotions] = useState([]);
  const [packagingUnits, setPackagingUnits] = useState({});
  const [minQuantities, setMinQuantites] = useState({});
  const [maxQuantities, setMaxQuantities] = useState({});
  const [minPrices, setMinPrices] = useState({});
  const [maxPrices, setMaxPrices] = useState({});
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const [selectedUnit, setSelectedUnit] = useState("");
  const addedProduct = useSelector(
    (state) => state.productManager.addedProduct
  );

  const productCategoryOptions = useSelector(
    (state) => state.productManager.categoryOptions
  );
  const productSubcategoryOptions = useSelector(
    (state) => state.productManager.subCategoryOptions
  );
  const taxCodeOptions = useSelector(
    (state) => state.productManager.taxCodeOptions
  );

  const retrievedSupplierOptions = useSelector(
    (state) => state.supplierManager.supplierOptions
  );

  const imageUploadSuccess = useSelector(
    (state) => state.stateManager.imageUploadSuccess
  );
  const imageUploaded = useSelector(
    (state) => state.utilsManager.imageUploadSuccess
  );
  var imgUrl = useSelector((state) => state.utilsManager.imgUrl);
  imgUrl = !imageUploaded && editMode ? product.image : imgUrl;

  const userObj = useSelector((state) => state.authManager.user);
  const categories = useSelector((state) => state.productManager.categories);
  const packagingUnitsOptions = useSelector(
    (state) => state.productManager.packagingUnitsOptions
  );
  const measurementUnitsOptions = useSelector(
    (state) => state.productManager.measurementUnitsOptions
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductCategoryOptionsAction());
    dispatch(getProductSubcategoryOptionsAction());
    dispatch(getTaxOptionsAction());
    dispatch(getSupplierOptionsAction());
    dispatch(getPackagingUnitOptionsAction());
    dispatch(getMeasurementUnitOptionsAction());

    dispatch(resetUtils());
    console.log(product);
  }, []);

  const addPromotion = () => {
    let promotionsLength = promotions.length;
    let promotionObj = {
      id: promotionsLength,
      minQty: 0,
      maxQty: 0,
      minPrice: 0,
      maxPrice: 0,
      packagingUnit: packagingUnitsOptions[0],
    };
    setPromotions([...promotions, promotionObj]);
    setPackagingUnits({ 0: 0 });
  };

  const removePromotion = (e, index) => {
    e.preventDefault();

    let filteredPromotions = promotions.filter(
      (promotion) => promotion.id !== index
    );

    let updatedMinQuantities = _.omit(minQuantities, index);
    let updatedMaxQuantities = _.omit(maxQuantities, index);
    let updatedMinPrices = _.omit(minPrices, index);
    let updatedMaxPrices = _.omit(maxPrices, index);

    setMinQuantites(updatedMinQuantities);
    setMaxQuantities(updatedMaxQuantities);
    setMinPrices(updatedMinPrices);
    setMaxPrices(updatedMaxPrices);

    setPromotions(filteredPromotions);
  };

  const handleMinQuantityChange = (index, e) => {
    let quantity = parseInt(e.target.value);

    setMinQuantites({ ...minQuantities, [index]: quantity });
  };

  const handlePackagingUnitChange = (selectedUnit, index) => {
    setPackagingUnits({ ...packagingUnits, [index]: selectedUnit.value });
  };

  const handleMaxQuantityChange = (index, e) => {
    let quantity = parseInt(e.target.value);
    setMaxQuantities({ ...maxQuantities, [index]: quantity });
  };

  const handleMinPricesChange = (index, e) => {
    let quantity = parseInt(e.target.value);
    setMinPrices({ ...minPrices, [index]: quantity });
  };

  const handleMaxPricesChange = (index, e) => {
    let quantity = parseInt(e.target.value);
    setMaxPrices({ ...maxPrices, [index]: quantity });
  };

  return (
    <Formik
      initialValues={{
        name: editMode ? sku.name : "",
        weight: editMode ? sku.weight : "",
        dimensions: editMode ? product.dimensions : "",
        price: editMode ? sku.price : "",
        cost: editMode ? sku.cost : "",
        inventory: editMode ? sku.inventory : "",
        createdBy: userObj.username,
        productId: addMode ? "" : product.id,
      }}
      onSubmit={(values, { setSubmitting }) => {
        scrollToTop();
        setTimeout(() => {
          let formData = _.clone(values);
          let newPromotions =
            promotions.length > 0 &&
            promotions.map((promotion, index) => {
              console.log(promotion);
              promotion.minQty = minQuantities[index];
              promotion.maxQty = maxQuantities[index];
              promotion.minPrice = minPrices[index];
              promotion.maxPrice = maxPrices[index];
              promotion.packagingUnit = packagingUnits[index];
              promotion = _.omit(promotion, "id");
              promotion = _.omit(promotion, "value");
              return promotion;
            });

          // console.log(newPromotions);

          formData["discountPriceList"] = newPromotions ? newPromotions : [];
          formData["unit"] = selectedUnit.value;
          formData["images"] = [
            {
              path: imgUrl,
              title: values.name,
              metadata: "{}",
              createdBy: userObj.username,
            },
          ];
          console.log(formData);
          console.log(JSON.stringify(formData));
          if (editMode) {
            //console.log(postData);
            //dispatch(editProductAction(formData));
          } else {
            //console.log(postData);
            if (addMode) {
              let product = addedProduct;
              product["skus"] = [formData];
              //console.log(product);
              dispatch(addProductAction(product));
            } else {
              dispatch(addProductSKUAction(formData));
            }
            //console.log(formData);
            //
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(" Name is required "),

        weight: Yup.string().required("Weight is required "),

        price: Yup.number().required("Price is required "),
        cost: Yup.number().required("Cost Price is required "),
        dimensions: Yup.string().required("Dimensions is required "),
        inventory: Yup.string().required("Inventory is required "),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <div className="addSKUForm">
            {success && <p className="alert alert-success">{message}</p>}
            {error && <p className="alert alert-danger">{message}</p>}
            {/* <code>{JSON.stringify(packagingUnits)}</code>
            <code>{JSON.stringify(packagingUnitsOptions)}</code> */}
            {/* <code>{JSON.stringify(errors)}</code> */}
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="row">
                <div class="col-lg-6">
                  <div class="row form-group">
                    <label class="col-form-label required col-lg-4">
                      <span>SKU Name *</span>
                    </label>{" "}
                    <div class="col-lg-8">
                      <input
                        id="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.name && touched.name
                            ? " is-invalid form-control"
                            : "form-control"
                        }
                      />
                      {errors.name && touched.name && (
                        <p className="error ml-2 mt-2"> {errors.name}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="row form-group">
                    <label class="col-form-label required col-lg-4">
                      <span
                        id="ember669"
                        class="tooltip-container text-dashed-underline ember-view"
                      >
                        Unit*
                      </span>
                    </label>{" "}
                    <div class="col-lg-8">
                      <Select
                        options={measurementUnitsOptions}
                        value={selectedUnit}
                        onChange={(selectedOption) =>
                          setSelectedUnit(selectedOption)
                        }
                      />

                      {errors.unit && touched.unit && (
                        <p className="error ml-2 mt-2"> {errors.unit}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="row form-group">
                    <label className="col-form-label required col-lg-4">
                      <span>Selling Price*</span>
                      <br />
                    </label>

                    <div className="col-lg-8">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">TZS</span>
                        </div>
                        <input
                          id="price"
                          type="text"
                          value={values.price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.price && touched.price
                              ? " is-invalid form-control"
                              : "form-control"
                          }
                        />
                      </div>

                      {errors.price && touched.price && (
                        <p className="error ml-2 mt-2"> {errors.price}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="row form-group">
                    <label className="col-form-label required col-lg-4">
                      <span>Cost Price *</span>
                    </label>
                    <div className="col-lg-8">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">TZS</span>
                        </div>
                        <input
                          id="cost"
                          type="text"
                          value={values.cost}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.cost && touched.cost
                              ? " is-invalid form-control"
                              : "form-control"
                          }
                        />
                      </div>

                      {errors.cost && touched.cost && (
                        <p className="error ml-2 mt-2"> {errors.cost}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-6">
                  <div class="row form-group">
                    <label className="col-form-label required col-lg-4">
                      <span>Dimensions (cm) *</span>
                      <br />
                      <span className="text-muted font-xs">
                        (Length X Width X Height)
                      </span>
                    </label>

                    <div class="col-lg-8">
                      <input
                        id="dimensions"
                        type="text"
                        value={values.dimensions}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.dimensions && touched.dimensions
                            ? " is-invalid form-control"
                            : "form-control"
                        }
                      />
                      {errors.dimensions && touched.dimensions && (
                        <p className="error ml-2 mt-2"> {errors.dimensions}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="row form-group">
                    <label className="col-form-label required col-lg-4">
                      <span class="tooltip-container text-dashed-underline ember-view">
                        Weight*
                      </span>
                    </label>{" "}
                    <div class="col-lg-8">
                      <input
                        id="weight"
                        type="text"
                        value={values.weight}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.weight && touched.weight
                            ? " is-invalid form-control"
                            : "form-control"
                        }
                      />
                      {errors.weight && touched.weight && (
                        <p className="error ml-2 mt-2"> {errors.weight}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-6">
                  <div class="row form-group">
                    <label className="col-form-label required col-lg-4">
                      <span>Opening Stock *</span>
                    </label>

                    <div class="col-lg-8">
                      <input
                        id="inventory"
                        type="text"
                        value={values.inventory}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.inventory && touched.inventory
                            ? " is-invalid form-control"
                            : "form-control"
                        }
                      />
                      {errors.inventory && touched.inventory && (
                        <p className="error ml-2 mt-2"> {errors.inventory}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row mb-4 cursor">
                <div onClick={addPromotion}> + Add Price List</div>
              </div>

              {promotions &&
                promotions.length > 0 &&
                promotions.map((promotion, index) => (
                  <div className="form-row mb-4" key={index}>
                    <div className="form-group col-md-3 mb-4">
                      <Label htmlFor="name"> Packaging Unit*</Label>
                      <Select
                        options={packagingUnitsOptions}
                        onChange={(selectedOption) =>
                          handlePackagingUnitChange(selectedOption, index)
                        }
                      />

                      {errors.unit && touched.unit && (
                        <p className="error ml-2 mt-2"> {errors.unit}</p>
                      )}
                    </div>
                    <div className="form-group col-md-2 mb-4">
                      <Label htmlFor="name"> Min Quantity</Label>
                      <input
                        type="number"
                        key={promotion.id}
                        name={`minQty-${promotion.id}`}
                        placeholder="Eg.100"
                        min="1"
                        value={minQuantities[promotion.id]}
                        onChange={(e) => {
                          handleMinQuantityChange(index, e);
                        }}
                        disabled={minQuantities[promotion.id] === 0}
                        className="small-placeholder"
                      />
                    </div>

                    <div className="form-group col-md-2 mb-4">
                      <Label htmlFor="name"> Max Quantity</Label>
                      <input
                        type="number"
                        key={promotion.id}
                        name={`maxQty-${promotion.id}`}
                        placeholder="Eg.200"
                        min="1"
                        value={maxQuantities[promotion.id]}
                        disabled={maxQuantities[promotion.id] === 0}
                        className="small-placeholder"
                        onChange={(e) => {
                          handleMaxQuantityChange(index, e);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-2 mb-4">
                      <Label htmlFor="name"> Min Price</Label>
                      <input
                        type="number"
                        key={promotion.id}
                        name={`value-${promotion.id}`}
                        placeholder="Eg.50"
                        min="1"
                        value={minPrices[promotion.id]}
                        disabled={minPrices[promotion.id] === 0}
                        className="small-placeholder"
                        onChange={(e) => {
                          handleMinPricesChange(index, e);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-2 mb-4">
                      <Label htmlFor="name"> Max Price</Label>
                      <input
                        type="number"
                        key={promotion.id}
                        name={`value-${promotion.id}`}
                        placeholder="Eg.50"
                        min="1"
                        value={maxPrices[promotion.id]}
                        disabled={maxPrices[promotion.id] === 0}
                        className="small-placeholder"
                        onChange={(e) => {
                          handleMaxPricesChange(index, e);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-1 mb-0">
                      <IconButton
                        onClick={(e) => removePromotion(e, index)}
                        className="p-0 m-0"
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}

              <div className="row">
                <div className="col-sm-4">
                  <h3>SKU Image</h3>
                </div>
                <div className="col-sm-12">
                  <ImageUploader
                    success={imageUploadSuccess}
                    imgUrl={imgUrl}
                    editMode={editMode}
                  />
                </div>
              </div>

              <div className="form-group col-md-12 mb-3">
                <ButtonSmall type="submit" disabled={isSubmitting}>
                  {!editMode && (
                    <span>{addMode ? "Create Product" : "Create SKU"}</span>
                  )}
                  {editMode && <span>Edit SKU</span>}
                </ButtonSmall>
              </div>
              {/*JSON.stringify(props, null, 2)*/}
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddSKUForm;
