import {
  GET_ROUTES_SUCCESS,
  CLEAR_LOCATION_DATA,
  GET_ROUTE_SUCCESS,
  GET_ROUTE_ORDERS_SUCCESS,
  GET_ROUTE_OPTIONS_SUCCESS,
  GET_ROUTE_PRODUCT_PRICES_SUCCESS,
  GET_ROUTE_HISTORY_SUCCESS,
  GET_ROUTE_DETAILS_SUCCESS,
  GET_ROUTE_DRIVER,
  GET_SAVED_PROVINCES_SUCCESS,
  GET_SAVED_DISTRICTS_SUCCESS,
  GET_SAVED_WARDS_SUCCESS,
} from "../constants/ActionTypes";
const initialState = {
  routes: [],
  route: {},
  routeOrders: [],
  routeOptions: [],
  products: [],
  routeHistory: [],
  routeDetails: {},
  routeDriver: null,
  savedProvinces: [],
  savedDistricts: [],
  savedWards: [],
};

function RouteReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROUTES_SUCCESS:
      return { ...state, routes: action.payload };
    case GET_ROUTE_SUCCESS:
      return { ...state, route: action.payload };
    case CLEAR_LOCATION_DATA:
      return { ...state, route: {}, routeDetails: {} };
    case GET_ROUTE_ORDERS_SUCCESS:
      return { ...state, routeOrders: action.payload };
    case GET_ROUTE_OPTIONS_SUCCESS:
      return { ...state, routeOptions: action.payload };
    case GET_ROUTE_PRODUCT_PRICES_SUCCESS:
      return { ...state, products: action.payload };
    case GET_ROUTE_HISTORY_SUCCESS:
      return { ...state, routeHistory: action.payload };
    case GET_ROUTE_DETAILS_SUCCESS:
      return { ...state, routeDetails: action.payload };
    case GET_ROUTE_DRIVER:
      return { ...state, routeDriver: action.payload };
    case GET_SAVED_PROVINCES_SUCCESS:
      return { ...state, savedProvinces: action.payload };
    case GET_SAVED_DISTRICTS_SUCCESS:
      return { ...state, savedDistricts: action.payload };
    case GET_SAVED_WARDS_SUCCESS:
      return { ...state, savedWards: action.payload };

    default:
      return state;
  }
}

export default RouteReducer;
