import {
  GET_DAILY_SALES_SUCCESS,
  GET_ALL_SALES_SUCCESS,
  GET_CREDITS_SUCCESS,
  GET_OUTLET_CREDITS_SUCCESS,
  GET_USER_TARGETS_SUCCESS,
  GET_USER_PERFORMANCE_HISTORY_SUCCESS,
  GET_USER_SALES_SUCCESS,
  GET_EXPIRED_PRODUCTS_SUCCESS,
  IS_LOADING,
  GET_USER_UNSOLD_PRODUCTS_SUCCESS,
  GET_USER_CREDITS_SUCCESS,
  GET_CREDITS_COLLECTED_SUCCESS,
} from "../constants/ActionTypes";
const initialState = {
  dailySales: [],
  allSales: [],
  credits: [],
  userTargets: [],
  totalTarget: 0,
  totalScore: 0,
  userPerformanceHistory: [],
  userSales: [],
  expiredProducts: [],
  outletCredits: [],
  isLoading: true,
  unsoldProducts: [],
  userCredits: [],
  creditsCollected: [],
};

function SalesReducer(state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, isLoading: true };
    case GET_DAILY_SALES_SUCCESS:
      return { ...state, dailySales: action.payload };
    case GET_ALL_SALES_SUCCESS:
      return { ...state, allSales: action.payload };
    case GET_USER_SALES_SUCCESS:
      return { ...state, userSales: action.payload, isLoading: false };
    case GET_CREDITS_SUCCESS:
      return { ...state, credits: action.payload };
    case GET_OUTLET_CREDITS_SUCCESS:
      return { ...state, outletCredits: action.payload };
    case GET_USER_UNSOLD_PRODUCTS_SUCCESS:
      return { ...state, unsoldProducts: action.payload, isLoading: false };
    case GET_USER_TARGETS_SUCCESS:
      return {
        ...state,
        userTargets: action.payload,
        totalTarget: action.totalTarget,
        totalScore: action.totalScore,
      };
    case GET_USER_PERFORMANCE_HISTORY_SUCCESS:
      return {
        ...state,
        userPerformanceHistory: action.payload,
      };

    case GET_EXPIRED_PRODUCTS_SUCCESS:
      return { ...state, expiredProducts: action.payload };
    case GET_CREDITS_COLLECTED_SUCCESS:
      return { ...state, creditsCollected: action.payload };
    case GET_USER_CREDITS_SUCCESS:
      return { ...state, userCredits: action.payload };

    default:
      return state;
  }
}

export default SalesReducer;
