import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { TableStyle } from "../../../styles/Table";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { CSVLink, CSVDownload } from "react-csv";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp";
import SortIcon from "@material-ui/icons/Sort";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import _ from "lodash";
import { removeKeys } from "../../../utils/basic";

const filterPages = (visiblePages, totalPages) => {
  let filteredPages = visiblePages.filter((page) => page <= totalPages);

  return filteredPages;
};

const getVisiblePages = (page, total) => {
  if (total < 7) {
    return filterPages([1, 2, 3, 4, 5, 6], total);
  } else {
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    } else {
      return [1, 2, 3, 4, 5, total];
    }
  }
};

function Table({ title, columns, data, rowInfo, showTop = true }) {
  const [filterInput, setFilterInput] = useState("");
  const [canShowExport, showExportModal] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [cols, setCols] = useState([]);
  const [copySuccess, setCopySuccess] = useState("");
  const [filename, setFileName] = useState("edo-doc");
  const tableRef = useRef(null);
  let location = useLocation();

  useEffect(() => {
    let fileName = location.pathname.substr(1);
    let keys = columns.map((column) => column.key);
    keys = keys.filter((key) => key !== undefined);
    keys = _.flatten(keys);
    setCols(keys);

    var exportData = _.clone(data);

    let finalExportData = data.map((row) => {
      let modifiedRow = _.pickBy(row, function (v, k) {
        return _.includes(keys, k);
      });

      return modifiedRow;
    });
    finalExportData = finalExportData.map((row) => {
      if (row.hasOwnProperty("createdDate")) {
        row["createdDate"] = moment(row.createdDate).format(
          "ddd , MMMM DD, YYYY"
        );
      }
      if (row.hasOwnProperty("createDate")) {
        row["createDate"] = moment(row.createDate).format(
          "ddd , MMMM DD, YYYY"
        );
      }
      if (row.hasOwnProperty("lastLogin")) {
        row["lastLogin"] = moment(row.createDate).format("ddd , MMMM DD, YYYY");
      }
      Object.keys(row).map((objKey) => {
        if (Array.isArray(row[objKey])) {
          row[objKey] = row[objKey].map((val) => val.name).join(",");
        }
      });
      return row;
    });

    setFileName(`SFA-INALIPA-${fileName}.csv`);
    setCSVData(finalExportData);
  }, []);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    setGlobalFilter,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,

      hiddenColumns: columns
        .filter((column) => !column.show)
        .map((column) => column.id),
      initialState: {
        pageIndex: 0,
        hiddenColumns: columns
          .filter((column) => !column.show)
          .map((column) => column.id),
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // React.useEffect(() => {
  //   setHiddenColumns(
  //     columns.filter((column) => !column.show).map((column) => column.id)
  //   );
  // }, [columns]);

  const [visiblePages, setVisiblePages] = useState(
    getVisiblePages(null, pageCount)
  );
  const activePage = pageIndex + 1;

  // Update the state when input changes
  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value);
  };

  async function exportToPDF(rows) {
    let pdfCols = [];
    let pdfData = csvData.map(function (obj, index) {
      return Object.keys(obj).map(function (key) {
        if (index === 0) {
          pdfCols.push(key);
        }

        return obj[key];
      });
    });
    await setPageSize(1000000);
    var doc = new jsPDF();

    doc.autoTable(pdfCols, pdfData, {
      startY: 10,
      theme: "grid",
      tableWidth: "auto",
      columnWidth: "auto",
      styles: {
        overflow: "linebreak",
        color: "black",
      },
    });
    doc.save(`${filename}.pdf`);
  }

  const changePage = (page) => {
    const activePage = pageIndex + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = getVisiblePages(pageIndex, pageCount);
    gotoPage(page - 1);
    setVisiblePages(visiblePages);
  };

  useEffect(() => {}, [visiblePages]);
  // Render the UI for your table

  return (
    <>
      <TableStyle>
        <div>
          {showTop && (
            <div className="row mb-3">
              <div className="col-sm-9">
                <strong>{title}</strong>
              </div>
              <div className="col-sm-5">
                <div className="row table-select-wrapper">
                  <div className="col-sm-9">
                    <h4>Show</h4>
                  </div>
                  <div className="col-sm-3 ">
                    <select
                      value={isShowAll ? "all" : pageSize}
                      className="noSelect"
                      onChange={(e) => {
                        let selectedPageSize = e.target.value;
                        let totalLength = data.length;
                        if (selectedPageSize === "all") {
                          // console.log(
                          //   "Show all records totalLength = ",
                          //   totalLength
                          // );
                          setPageSize(Number(totalLength));
                          setIsShowAll(true);
                        } else {
                          setPageSize(Number(selectedPageSize));
                          setIsShowAll(false);
                        }
                      }}
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                      <option value="all">All</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-group mb-3 search-input-group">
                  <div className="input-group-prepend">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                  <input
                    value={filterInput}
                    onChange={handleFilterChange}
                    placeholder="Search..."
                    className="float-right search-input"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-sm-1">
                <div
                  className="export-icon-wrapper"
                  onClick={() => showExportModal(canShowExport ? false : true)}
                >
                  <SaveAltIcon fontSize="large" />
                  {canShowExport && (
                    <div className="export-options">
                      <h5>Download full report</h5>
                      {csvData.length > 0 && (
                        <CSVLink data={csvData} filename={filename}>
                          <ListAltIcon /> Excel
                        </CSVLink>
                      )}

                      <button onClick={() => exportToPDF(rows)}>
                        <PictureAsPdfSharpIcon /> PDF
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="table-container">
          <table {...getTableProps()} id="table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}

                      {/* Add a sort direction indicator */}
                      <span className="sort-icon">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {page && page.length > 0 && (
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps({
                        onClick: () => (rowInfo ? rowInfo(row) : {}),
                      })}
                      className={rowInfo ? "pointer" : ""}
                      valign="middle"
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}

            {page && page.length === 0 && (
              <tbody>
                <tr>
                  <td
                    colspan={headerGroups[0].headers.length}
                    className="text-center no-records"
                  >
                    No matching records found
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>

        {/* 
      Pagination can be built however you'd like. 
      This is just a very basic UI implementation:
    */}
        <div>
          <div className="row table-footer mt-4">
            <div className="col-sm-6">
              <div className="entries-count">
                Showing {pageIndex * pageSize + 1} to{" "}
                {pageSize + pageIndex * pageSize} of {data.length} entries
              </div>
            </div>
            <div className="col-sm-6">
              <div className="prev-next-btn">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="btn-prev"
                >
                  Previous
                </button>

                <div className="Table__visiblePagesWrapper">
                  {visiblePages.map((page, index, array) => {
                    return (
                      <button
                        key={page}
                        className={
                          activePage === page
                            ? "Table__pageButton Table__pageButton--active"
                            : "Table__pageButton"
                        }
                        onClick={() => changePage(page)}
                      >
                        {array[index - 1] + 2 < page ? `...${page}` : page}
                      </button>
                    );
                  })}
                </div>

                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="btn-next"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </TableStyle>
    </>
  );
}

export default Table;
