import { getUserCurrentLocation } from "../../services/LocationService";
import {
  UPDATE_LOCATION,
  RESTORE_DEFAULT_CONFIG,
  CHANGE_LOCATION_PERMISSION_STATUS,
  GET_CURRENT_LOCATION,
  LOCATION_OUT_OF_BOUND,
  GET_USER_LOCATION_SUCCESS,
  LOAD_CURRENT_LOCATION,
  UPDATE_MAP_POSITION,
  GET_USER_LOCATION_FAIL,
} from "../constants/ActionTypes";
import { handleErrorAction } from "./UtilsAction";

export const getCurrentLocationAction = (data) => (dispatch) => {
  return dispatch({ type: GET_CURRENT_LOCATION });
};

export const updateLocationAction = (data) => (dispatch) => {
  return dispatch({ type: UPDATE_LOCATION, payload: data });
};

export const setLocationOutOfBoundAction = (data) => (dispatch) => {
  return dispatch({ type: LOCATION_OUT_OF_BOUND, payload: data });
};

export const restoreDefaultMapConfigAction = () => (dispatch) => {
  return dispatch({ type: RESTORE_DEFAULT_CONFIG });
};

export const changeLocationPermissionStatusAction = (data) => (dispatch) => {
  return dispatch({ type: CHANGE_LOCATION_PERMISSION_STATUS, payload: data });
};

export const getUserLocationAction = (data) => {
  return (dispatch) => {
    return getUserCurrentLocation(data).then(
      (response) => {
        console.log("getUserLocationAction response = ", response);
        if (response.data.code === "200") {
          if (response.data.responseObject) {
            let userLocation = response.data.responseObject;
            userLocation = Array.isArray(userLocation)
              ? userLocation[0]
              : userLocation;
            console.log("userLocation = ", userLocation);
            let parsedData = JSON.parse(userLocation.data.replace(/\'/g, '"'));
            userLocation["device"] = parsedData;
            console.log(parsedData);
            //userLocation["data"] = JSON.parse(userLocation.data);
            dispatch({
              type: GET_USER_LOCATION_SUCCESS,
              payload: userLocation,
            });
          } else {
            dispatch({
              type: GET_USER_LOCATION_FAIL,
              payload:
                "Sorry , Fail to get user current location , make sure the user is actively using the app or try again",
            });
          }
        }
        if (response.data.code === "400") {
          dispatch({
            type: GET_USER_LOCATION_FAIL,
            payload:
              "Sorry , Fail to get user current location , make sure the user is actively using the app or try again",
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const loadCurrentLocationAction = () => {
  return (dispatch) => {
    return dispatch({
      type: LOAD_CURRENT_LOCATION,
    });
  };
};

export const updateMapPositionAction = (data) => {
  return (dispatch) => {
    return dispatch({
      type: UPDATE_MAP_POSITION,
      payload: data,
    });
  };
};
