import axios from "axios";
import {
  APP_URL,
  contentTypeUrlEncoded,
  contentTypeApplicationJson,
} from "../constants";
import qs from "qs";

export const getCrates = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/fetchStockingCrates`,
      {},
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const createCrate = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/addStockingCrate`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const editTruck = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/update/truck`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getUserTruck = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/driver/truck`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getUserCrates = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/fetchAssignedCrates`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getDamagedCrates = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/fetchDamagedCrates`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};
