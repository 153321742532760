import axios from "axios";
import {
  APP_URL,
  contentTypeApplicationJson,
  contentTypeUrlEncoded,
} from "../constants";
import qs from "qs";

export const getAreas = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/all/areas`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getSubLocations = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/area/details`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((res) => res);
  return result;
};

export const searchArea = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/search/area`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((res) => res);
  return result;
};

export const searchRoute = async (data) => {
  const result = await axios
    .post(`${APP_URL}distribution/search/route`, qs.stringify(data))
    .then((data) => data);
  return result;
};

export const getRoutes = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/fetch/all/routes`,
      {},
      contentTypeApplicationJson
    )
    .then((res) => res);
  return result;
};

export const getUserCurrentLocation = async (data) => {
  const result = await axios
    .post(
      `https://compliance.aimgroup.co.tz:8443/general/mobilesnapshot/retrieveLastMobileSnapshot`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};
