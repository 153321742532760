import React, { useState, useEffect } from "react";
import {
  addProductSubcategoryAction,
  createTaxCodeAction,
  getProductCategoryOptionsAction,
  updateProductCategoryAction,
  updateProductSubcategoryAction,
  updateTaxCodeAction,
} from "../../store/actions/ProductAction";
import { useDispatch, useSelector } from "react-redux";
import { FormWrapper, Input, Label, ButtonSmall } from "../../styles/Forms";
import ImageUploader from "../../components/utils/ImageUploader";
import { Formik } from "formik";
import * as Yup from "yup";

import _ from "lodash";
import Select from "react-select";

const nameRegExp = /^([^0-9]*)$/;

const AddTaxCodeForm = ({ editMode, taxCode }) => {
  const [selectedCategory, setSelectedCategory] = useState({});
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const data = useSelector((state) => state.stateManager.data);
  const userObj = useSelector((state) => state.authManager.user);
  const categoryOptions = useSelector(
    (state) => state.productManager.categoryOptions
  );

  useEffect(() => {
    if (success) {
      dispatch({ type: "RESET_VIEW" });
    }
  });

  useEffect(() => {}, []);

  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        name: editMode ? taxCode.name : "",
        code: editMode ? taxCode.code : "",
        value: editMode ? taxCode.value : "",
        createdBy: userObj.username,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setIsButtonDisabled(true);
        setTimeout(() => {
          console.log("TAX Form Submittet");
          //console.log(values);
          let formData = _.clone(values);

          if (editMode) {
            formData["id"] = taxCode.id;

            //console.log(formData);
            dispatch(updateTaxCodeAction(formData));
          } else {
            // console.log(formData);
            dispatch(createTaxCodeAction(formData));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(" Name is required "),
        code: Yup.string()
          .length(1, "Tax code shoud be one letter")
          .required(" Tax Code is required "),
        value: Yup.number().required("Tax Value is required "),
      })}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <div>
            <FormWrapper>
              {success && <p className="alert alert-success">{message}</p>}
              {/* <code>{JSON.stringify(values)}</code>
              <code>{JSON.stringify(selectedCategory)}</code> */}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row mb-1">
                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="name">Tax Name; </Label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Eg.Class A"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? " is-invalid" : ""
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error ml-2 mt-2"> {errors.name}</p>
                    )}
                  </div>

                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="code">Tax Code; </Label>
                    <Input
                      id="code"
                      type="text"
                      placeholder="Eg. A"
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.code && touched.code ? " is-invalid" : ""
                      }
                    />
                    {errors.code && touched.code && (
                      <p className="error ml-2 mt-2"> {errors.code}</p>
                    )}
                  </div>

                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="value">Tax Value: </Label>
                    <Input
                      id="value"
                      type="text"
                      placeholder="Eg. 10.0"
                      value={values.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.value && touched.value ? " is-invalid" : ""
                      }
                    />
                    {errors.value && touched.value && (
                      <p className="error ml-2 mt-2"> {errors.value}</p>
                    )}
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3">
                  <ButtonSmall type="submit" disabled={isButtonDisabled}>
                    {editMode && <span>Update Tax Code </span>}
                    {!editMode && <span>Create Tax Code</span>}
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddTaxCodeForm;
