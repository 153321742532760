import React, { useState, useEffect } from "react";

import {
  addProductCategoryAction,
  addProductVerticalAction,
  updateProductCategoryAction,
} from "../../store/actions/ProductAction";

import { useDispatch, useSelector } from "react-redux";

import { FormWrapper, Input, Label, ButtonSmall } from "../../styles/Forms";
import ImageUploader from "../../components/utils/ImageUploader";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { scrollToTop } from "../../utils/window";

const nameRegExp = /^([^0-9]*)$/;

const AddVerticalForm = ({ editMode, vertical }) => {
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);

  const data = useSelector((state) => state.stateManager.data);
  const userObj = useSelector((state) => state.authManager.user);
  const imageUploadSuccess = useSelector(
    (state) => state.stateManager.imageUploadSuccess
  );
  const imageUploaded = useSelector(
    (state) => state.utilsManager.imageUploadSuccess
  );
  var imgUrl = useSelector((state) => state.utilsManager.imgUrl);
  imgUrl = !imageUploaded && editMode ? vertical.image : imgUrl;

  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        name: editMode ? vertical.name : "",
        description: editMode ? vertical.description : "",
        createdBy: userObj.username,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          scrollToTop();
          let formData = _.clone(values);
          formData["image"] = imgUrl;
          console.log(formData);
          //console.log(values);
          if (editMode) {
            formData["id"] = vertical.id;
            formData["updatedBy"] = userObj.username;
            formData = _.omit(formData, "createdBy");
            //dispatch(updateProductCategoryAction(formData));
          } else {
            dispatch(addProductVerticalAction(formData));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .matches(nameRegExp, " Name cannot contain number")
          .required(" Name is required "),
        description: Yup.string().required("Description is required "),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div>
            <FormWrapper>
              {success && <p className="alert alert-success">{message}</p>}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row mb-4">
                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="name">Vertical Name: </Label>
                    <Input
                      id="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? " is-invalid" : ""
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error ml-2 mt-2"> {errors.name}</p>
                    )}
                  </div>
                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="name">Vertical Description: </Label>
                    <Input
                      id="description"
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.description && touched.description
                          ? " is-invalid"
                          : ""
                      }
                    />
                    {errors.description && touched.description && (
                      <p className="error ml-2 mt-2"> {errors.description}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label>Vertical Image:</label>
                  </div>
                  <div className="col-sm-12 ">
                    <ImageUploader
                      success={imageUploadSuccess}
                      imgUrl={imgUrl}
                      editMode={editMode}
                    />
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3">
                  <ButtonSmall type="submit" disabled={isSubmitting}>
                    {editMode && <span>Update Vertical</span>}
                    {!editMode && <span>Create Vertical</span>}
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddVerticalForm;
