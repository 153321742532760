import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import { getProductionScheduleAction } from "../../store/actions/ProductionAction";
import Table from "../../components/dom/Table/Table";

const options = [
  { value: "AM", label: "AM" },
  { value: "PM", label: "PM" }
];

const ProductionSchedule = () => {
  const [startDate, setStartDate] = useState(new Date());
  // const [selectedShift, setSelectedShift] = useState(
  //   options.filter(option => option.value === moment().format("A"))[0]
  // );
  const [selectedShift, setSelectedShift] = useState({ value: "AM", label: "AM" });
  const schedule = useSelector(state => state.productionManager.schedule);
  const dispatch = useDispatch();
  let date = moment().format("DD-MM-YYYY");
  let shift = moment().format("A");
  let data = {
    date,
    shift:selectedShift.value
  };

  const columns = [
    {
      Header: "Order Date",
      accessor: d => d.date
    },
    {
      Header: "Order Item",
      accessor: "name"
    },
    {
      Header: "Order qty",
      accessor: "quantity"
    }
  ];

  useEffect(() => {
    dispatch(getProductionScheduleAction(data));
  }, []);

  const handleDateCHange = date => {
    setStartDate(date);
    let data = {
      date: moment(date).format("DD-MM-YYYY"),
      shift: selectedShift.value
    };
    dispatch(getProductionScheduleAction(data));
  };

  const handleShiftChange = shift => {
    setSelectedShift(shift);
    let data = {
      date: moment(startDate).format("DD-MM-YYYY"),
      shift: shift.value
    };
    dispatch(getProductionScheduleAction(data));
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-4">
          <h3 className="mt-2">
            Production Schedule for {selectedShift.label} Shift
          </h3>
        </div>
        <div className="col-sm-8">
          <div className="row">
            <div className="col-sm-8">
              <div className="float-right">
                <label>Date : </label>
                <DatePicker
                  selected={startDate}
                  onChange={date => handleDateCHange(date)}
                  dateFormat="d MMMM, yyyy"
                  className="form-control ml-1"
                />
              </div>
            </div>
            <div className="col-sm-4">
              <label>Shift : </label>
              <Select
                className="w-50 d-inline-block ml-1"
                options={options}
                value={selectedShift}
                onChange={selectedOption => handleShiftChange(selectedOption)}
              />
            </div>
          </div>
        </div>
      </div>

      {schedule.items && schedule.items.length > 0 && (
        <Table data={schedule.items} columns={columns} />
      )}

      {schedule.items && schedule.items.length === 0 && (
        <p className="alert alert-info mt-3">
          No production schedule yet . Please check later .
        </p>
      )}
    </>
  );
};

export default ProductionSchedule;
