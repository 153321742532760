import axios from "axios";
import {
  APP_URL,
  contentTypeUrlEncoded,
  contentTypeApplicationJson,
} from "../constants";
import qs from "qs";

export const getOrders = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}salesmanagement/salesService/fetch/orders`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const filterOrders = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}salesmanagement/salesService/search/orders`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const createOrder = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}salesmanagement/salesService/create/order`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const updateOrder = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}salesmanagement/salesService/edit/order`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const changeOrderStatus = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}salesmanagement/salesService/update/order/status`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getOrdersSummary = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/daily/order/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((res) => res);
  return result;
};

export const getDailyOrders = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/product/daily/order/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((res) => res);
  return result;
};

export const getAllOrdersSummary = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/orders/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((res) => res);
  return result;
};

export const failtToCreateOrder = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/reportFailedOuletOrders`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const assignOrderDelivery = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/assign/delivery/order`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

// export const updateOrder = async (data) => {
//   const result = await axios
//     .post(
//       `${APP_URL}ordermanagementservice/updateOrderItems`,
//       JSON.stringify(data),
//       contentTypeApplicationJson
//     )
//     .then((data) => data);
//   return result;
// };

export const editProduct = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/updateProduct`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const createProductCategory = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/createProductCategory`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getProductCategories = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagementservice/fetchProductCategory`,
      {},
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getOrderDetails = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetchOrderDetails`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getOrderDeliveries = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetchOrderDelivery`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getPayments = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/payments`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getFailToOrderCustomers = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/failed/orders`,
      data,
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getCouldNotDeliverOrders = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/notdelivered/products`,
      data,
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getOrderSummary = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}analytics/reports/fetch/daily/order/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getRouteOrders = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetchRouteOrders`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getReturnedProducts = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/returned/products`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getRouteOrdersSummary = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/route/order/summary`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getReconSummary = async (params) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/fetch/recon/summary?${params}`,
      JSON.stringify({}),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const receiveExpiredProducts = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}ordermanagementservice/manager/receive/expired`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getOrderItemDiscountPrice = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/processOrderItemCost`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getOrderItemsDiscountPrice = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}distribution/processOrderItemsCost`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const triggerPhoneCall = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}make/outlet/call`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
