import axios from "axios";
import {
  APP_URL,
  contentTypeApplicationJson,
  contentTypeUrlEncoded,
} from "../constants";
import qs from "qs";

export const getUserOutlets = async (username) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/fetch/user/outlets/${username}`
    )
    .then((data) => data);
  return result;
};

export const createRoutePlan = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/create/route/plan`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};


export const updateRoutePlan = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/update/route/plan`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};


export const getRoutePlan = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}outletmanagement/outletService/fetch/route/plan`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};

export const getTaskList = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}usermanagement/userPermission/getUserRoleActions`,
      qs.stringify(data),
      contentTypeUrlEncoded
    )
    .then((data) => data);
  return result;
};
