import React from "react";
import { Tile } from "../../styles/App";

const TileBlock = ({ title, value, inlineStyle }) => {
  return (
    <div class={inlineStyle}>
      <div div className="tile">
        <span>{title}</span>
        <h5>{value}</h5>
      </div>
    </div>
  );
};

export default TileBlock;
