import axios from "axios";
import {
  APP_URL,
  contentTypeApplicationJson,
  contentTypeUrlEncoded,
} from "../constants";

export const addSupplier = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/add/supplier`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};

export const getSuppliers = async (data) => {
  const result = await axios
    .post(`${APP_URL}productmanagement/productService/fetch/suppliers`)
    .then((data) => data);
  return result;
};

export const updateSupplier = async (data) => {
  const result = await axios
    .post(
      `${APP_URL}productmanagement/productService/edit/supplier`,
      JSON.stringify(data),
      contentTypeApplicationJson
    )
    .then((data) => data);
  return result;
};
