import {
  GET_CRATES_SUCCESS,
  GET_CRATE_SUCCESS,
  GET_CRATE_OPTIONS_SUCCESS,
  GET_USER_RETURNED_CRATES_SUCCESS,
  GET_USER_DAMAGED_CRATES_SUCCESS,
} from "../constants/ActionTypes";
const initialState = {
  crates: [],
  crate: {},
  cratesOptions: [],
  returnedCrates: [],
  damagedCrates: [],
};

function TruckReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CRATES_SUCCESS:
      return { ...state, crates: action.payload };
    case GET_CRATE_OPTIONS_SUCCESS:
      return { ...state, cratesOptions: action.payload };
    case GET_CRATE_SUCCESS:
      return { ...state, crate: action.payload };
    case GET_USER_RETURNED_CRATES_SUCCESS:
      return { ...state, returnedCrates: action.payload };
    case GET_USER_DAMAGED_CRATES_SUCCESS:
      return { ...state, damagedCrates: action.payload };

    default:
      return state;
  }
}

export default TruckReducer;
