import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TerritoryDistributionMap from "../../components/map/TerritoryDistributionMap";
import { getOutletsWithCoordinatesAction } from "../../store/actions/OutletAction";

const TerritoryDistributionPage = () => {
  const dispatch = useDispatch();

  const territories = useSelector(
    (state) => state.territoryManager.territories
  );

  return (
    <div>
      <TerritoryDistributionMap territories={territories} />
    </div>
  );
};

export default TerritoryDistributionPage;
