import styled from "styled-components";

export const BrandTopLine = styled.section`
  border: 2px solid #373d42;
`;

export const BrandLogo = styled.img`
  width: 60px;
  height: auto;
  margin: 15px;
  margin-top: 0px;
`;
