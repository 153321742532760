import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOutletsWithCoordinatesAction } from "../../store/actions/OutletAction";

import moment from "moment";
import SalesMap from "./SalesMapTest";
import { getTerritoryPolygonsAction } from "../../store/actions/TerritoryAction";
import {
  getOutletSalesDistributionAction,
  getSalesDistributionMapAction,
  getSalesReportAction,
} from "../../store/actions/ReportsAction";
import { getVerticalOptionsAction } from "../../store/actions/ProductAction";
import DatePicker from "react-datepicker";
import { BoxWrapper, ButtonBox } from "../../styles/schedule";
import Switch from "react-switch";
import Select from "react-select";

const MapTest = ({ outletNumber, resetView, edit }) => {
  const [polygons, setPolygons] = useState([]);
  const [vertical, setVertical] = useState(0);
  const [canShowAreas, setCanShowAreas] = useState(true);

  const outlet = useSelector((state) => state.outletManager.outlet);
  const userObj = useSelector((state) => state.authManager.user);
  const salesDistribution = useSelector(
    (state) => state.reportManager.salesDistribution
  );
  const outletSalesDistribution = useSelector(
    (state) => state.reportManager.outletSalesDistribution
  );

  const orders = useSelector((state) => state.OrderManager.orders);
  const sales = useSelector((state) => state.reportManager.sales);
  const verticalOptions = useSelector(
    (state) => state.productManager.verticalOptions
  );
  const outlets = useSelector((state) => state.outletManager.locatedOutlets);

  const success = useSelector((state) => state.stateManager.success);
  const outletProductPrices = useSelector(
    (state) => state.outletManager.outletProductPrices
  );
  const territoryPolygons = useSelector(
    (state) => state.territoryManager.territoryPolygons
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let fetchSales = {
      searches: [
        {
          key: "startDate",
          value: moment().subtract(30, "days").format("DD/MM/YYYY"),
        },
        {
          key: "endDate",
          value: moment().format("DD/MM/YYYY"),
        },
      ],
    };
    let outletSalesFilter = {
      startDate: moment().subtract(365, "days").format("DD/MM/YYYY"),
      endDate: moment().format("DD/MM/YYYY"),
    };
    dispatch(getSalesReportAction(fetchSales));
    dispatch(getOutletSalesDistributionAction(outletSalesFilter));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      //   dispatch(getOutletDetailsAction(outletNumber));
    }, 7000);
  }, [success]);

  useEffect(() => {}, []);

  useEffect(() => {
    dispatch(getOutletsWithCoordinatesAction({}));
    // dispatch(getOutletDetailsAction(outletNumber));
    dispatch(getTerritoryPolygonsAction({}));
    dispatch(getSalesDistributionMapAction({}));
    dispatch(getVerticalOptionsAction());
  }, []);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let filter = {
      startDate: moment(startDate).format("DD/MM/YYYY"),
      endDate: moment(endDate).format("DD/MM/YYYY"),
    };
    let fetchSales = {
      searches: [
        {
          key: "startDate",
          value: moment(startDate).format("DD/MM/YYYY"),
        },
        {
          key: "endDate",
          value: moment(endDate).format("DD/MM/YYYY"),
        },
      ],
    };
    dispatch(getSalesReportAction(fetchSales));
    dispatch(getSalesDistributionMapAction(filter));
    dispatch(getOutletSalesDistributionAction(filter));
    setTimeout(() => setLoading(false), 5000);
  };

  const [showPolygon, setShowPolygon] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedArea, setCheckedArea] = useState(true);
  const [checkedOutlet, setCheckedOutlet] = useState(false);
  const [showOutelets, setShowOutlets] = useState(false);

  const handleChange = (checked) => {
    console.log("checked", checked);
    setChecked(checked);
    if (checked === true) {
      setShowPolygon(territoryPolygons);
    } else {
      setShowPolygon();
    }
  };
  const handleAreaChange = (checked) => {
    console.log("checked", checked);
    setCheckedArea(checked);
    if (checked === true) {
      setCanShowAreas(true);
    } else {
      setCanShowAreas(false);
    }
  };
  const handleOutletChange = (checked) => {
    console.log("checked", checked);
    setCheckedOutlet(checked);
    if (checked === true) {
      setShowOutlets(true);
    } else {
      setShowOutlets(false);
    }
  };
  const handleVerticalChange = (selectedOption) => {
    setVertical(selectedOption.value);
    //  setShowProducts(true)
    console.log("selected Vertical:", selectedOption);
  };

  return (
    <div>
      <div className="col-sm-12 shadow-sm p-3 mb-5">
        <BoxWrapper>
          <form onSubmit={onSubmit} role="form">
            <div className="row">
              <div className="col-sm-2">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="from"
                  className="date-picker-input"
                />
              </div>
              <div className="col-sm-2">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  placeholderText="to"
                  className="date-picker-input"
                />
              </div>
              <div className="col-sm-2">
                <Select
                  options={verticalOptions}
                  placeholder="vertical"
                  onChange={(selectedOption) =>
                    handleVerticalChange(selectedOption)
                  }
                />
              </div>
              <div className="col-sm-2">
                <ButtonBox type="submit">filter</ButtonBox>
              </div>
            </div>
          </form>
        </BoxWrapper>
      </div>
      {loading && (
        <div className="alert alert-primary" role="alert">
          Loading...
        </div>
      )}

      <div className="col-sm-12">
        <div className="d-flex justify-content-between">
          {/* <div className="col-sm-4 shadow-sm p-3 mb-5">
            <div className="row d-flex justify-content-around">
              <div className="">
                <label>
                  <h6>Show Areas :</h6>
                </label>
              </div>
              <div className="">
                <Switch onChange={handleAreaChange} checked={checkedArea} />
              </div>
            </div>
          </div> */}
          <div className="col-sm-6 shadow-sm p-3 mb-5">
            <div className="row d-flex justify-content-around">
              <div className="">
                <label>
                  <h6>Show Outlets :</h6>
                </label>
              </div>
              <div className="">
                <Switch onChange={handleOutletChange} checked={checkedOutlet} />
              </div>
            </div>
          </div>
          <div className="col-sm-6 shadow-sm p-3 mb-5">
            <div className="row d-flex justify-content-around">
              <div className="">
                <label>
                  <h6>Show territory :</h6>
                </label>
              </div>
              <div className="">
                <Switch onChange={handleChange} checked={checked} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {JSON.stringify(salesDistribution)}

                      {JSON.stringify(sales)} */}
      {outletSalesDistribution && outletSalesDistribution.length >= 0 && (
        <div className="row mb-3 mt-3">
          <div className="col-sm-12">
            {territoryPolygons && territoryPolygons.length > 0 && (
              <SalesMap
                coordinates={{ lat: 39.28159094481175, lng: 39.28159094481175 }}
                center={{
                  lat: -6.764191203212667,
                  lng: 39.28159094481175,
                }}
                name={outlet.name}
                outlets={outlets}
                outletArr={outlets}
                allSales={sales}
                sales={outletSalesDistribution}
                polygons={showPolygon}
                canShowAreas={checkedArea}
                canShowOutlets={checkedOutlet}
              />
            )}
          </div>
        </div>
      )}

      {salesDistribution && salesDistribution.length === 0 && (
        <div className="alert alert-primary" role="alert">
          No data at the moment
        </div>
      )}
    </div>
  );
};

export default MapTest;
