import React, { useState, useEffect } from "react";

import {
  addProductPackagingUnitAction,
  updateProductCategoryAction,
  updateProductPackagingUnitAction,
} from "../../store/actions/ProductAction";

import { useDispatch, useSelector } from "react-redux";

import { FormWrapper, Input, Label, ButtonSmall } from "../../styles/Forms";
import ImageUploader from "../../components/utils/ImageUploader";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { scrollToTop } from "../../utils/window";
import { updateProductPackagingUnit } from "../../services/ProductService";

const AddPackagingUnitForm = ({ editMode, packagingUnit }) => {
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);

  const data = useSelector((state) => state.stateManager.data);
  const userObj = useSelector((state) => state.authManager.user);
  const imageUploadSuccess = useSelector(
    (state) => state.stateManager.imageUploadSuccess
  );
  const imageUploaded = useSelector(
    (state) => state.utilsManager.imageUploadSuccess
  );
  var imgUrl = useSelector((state) => state.utilsManager.imgUrl);
  imgUrl = !imageUploaded && editMode ? packagingUnit.image : imgUrl;

  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        name: editMode ? packagingUnit.name : "",
        quantity: editMode ? packagingUnit.quantity : "",
        createdBy: userObj.username,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          scrollToTop();
          let formData = _.clone(values);
          formData["image"] = imgUrl;
          console.log(formData);
          //console.log(values);
          if (editMode) {
            formData["id"] = packagingUnit.id;
            formData["quantity"] = parseInt(formData.quantity);
            formData["updatedBy"] = userObj.username;
            formData = _.omit(formData, "createdBy");
            dispatch(updateProductPackagingUnitAction(formData));
          } else {
            dispatch(addProductPackagingUnitAction(formData));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(" Name is required "),
        quantity: Yup.number().required("Quantity is required "),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div>
            <FormWrapper>
              {success && <p className="alert alert-success">{message}</p>}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row mb-4">
                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="name"> Name: </Label>
                    <Input
                      id="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name ? " is-invalid" : ""
                      }
                    />
                    {errors.name && touched.name && (
                      <p className="error ml-2 mt-2"> {errors.name}</p>
                    )}
                  </div>
                  <div className="form-group col-md-12 mb-4">
                    <Label htmlFor="name">Quantity: </Label>
                    <Input
                      id="quantity"
                      type="text"
                      value={values.quantity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.quantity && touched.quantity ? " is-invalid" : ""
                      }
                    />
                    {errors.quantity && touched.quantity && (
                      <p className="error ml-2 mt-2"> {errors.quantity}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label>Product Unit Icon:</label>
                  </div>
                  <div className="col-sm-12 ">
                    <ImageUploader
                      success={imageUploadSuccess}
                      imgUrl={imgUrl}
                      editMode={editMode}
                    />
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3">
                  <ButtonSmall type="submit" disabled={isSubmitting}>
                    {editMode && <span>Update Packaging Unit</span>}
                    {!editMode && <span>Create Packaging Unit</span>}
                  </ButtonSmall>
                </div>
                {/*JSON.stringify(props, null, 2)*/}
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddPackagingUnitForm;
