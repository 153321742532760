import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProductsAction } from "../../store/actions/ProductAction";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import SuppliersTablePage from "./SuppliersTablePage";
import AddSupplierPage from "./AddSupplierPage";
import { getSuppliersAction } from "../../store/actions/SupplierAction";

const SuppliersPage = () => {
  const [canShowCreateProductForm, setCanShowCreateProductForm] = useState(
    false
  );
  const [canShowTabs, setCanShowTabs] = useState(true);

  const [editMode, setEditMode] = useState(false);
  const [supplier, setSupplier] = useState(null);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSuppliersAction({}));
  }, []);

  const editSupplier = (row) => {
    console.log("editSupplier row ", row);
    setEditMode(true);
    setSupplier(row);
    setCanShowTabs(false);
    setCanShowCreateProductForm(true);
  };

  const addSupplier = () => {
    setCanShowTabs(false);
    setCanShowCreateProductForm(true);
    setEditMode(false);
  };

  const resetView = () => {
    setCanShowTabs(true);
    setCanShowCreateProductForm(false);
    setEditMode(false);
  };

  useEffect(() => {
    setTimeout(() => dispatch(getSuppliersAction({})), 5000);
    setCanShowTabs(true);
    setCanShowCreateProductForm(false);
    setEditMode(false);
    //dispatch(getProductsAction())
  }, [success]);

  return (
    <>
      {canShowTabs && (
        <>
          <PageTopHeader title="Supplier Management" />

          <SuppliersTablePage addSupplier={addSupplier} edit={editSupplier} />
        </>
      )}

      {canShowCreateProductForm && (
        <AddSupplierPage
          supplier={supplier}
          editMode={editMode}
          resetView={resetView}
        />
      )}
    </>
  );
};

export default SuppliersPage;
