import {
  createTruck,
  getTrucks,
  getUserTruck,
  editTruck,
  assignTruckToDriver,
  getTruckSurveys,
} from "../../services/TruckService";
import { handleErrorAction } from "./UtilsAction";
import {
  ACTION_FAIL,
  ACTION_SUCCESS,
  GET_TRUCKS_SUCCESS,
  GET_TRUCK_SUCCESS,
  GET_TRUCKS_OPTIONS_SUCCESS,
  GET_TRUCK_SURVEYS_SUCCESS,
} from "../constants/ActionTypes";

export const getTrucksAction = () => {
  return (dispatch) => {
    return getTrucks().then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_TRUCKS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const addTruckAction = (data) => {
  return function(dispatch) {
    return createTruck(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const editTruckAction = (data) => {
  return function(dispatch) {
    return editTruck(data).then(
      (response) =>
        dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const assignTruckToDriverAction = (data) => {
  return function(dispatch) {
    return assignTruckToDriver(data).then(
      (response) => true,
      //dispatch({ type: ACTION_SUCCESS, payload: response.data.message }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getUserTruckAction = (data) => {
  return (dispatch) => {
    return getUserTruck(data).then(
      (response) => {
        if (response.data.code === "200") {
          if (response.data.responseObject) {
            let trucks = Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject];

            dispatch({
              type: GET_TRUCK_SUCCESS,
              payload: trucks[0],
            });
          } else {
            dispatch({
              type: GET_TRUCK_SUCCESS,
              payload: [],
            });
          }
        }
        if (response.data.code === "400") {
            dispatch({
              type: GET_TRUCK_SUCCESS,
              payload: [],
            });
        }
      },

      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTrucksOptionsAction = () => {
  return (dispatch) => {
    return getTrucks().then(
      (response) => {
        if (response.data && response.data.code === "200") {
          let trucks = Array.isArray(response.data.responseObject)
            ? response.data.responseObject
            : [response.data.responseObject];
          let options = trucks.map((truck) => ({
            value: truck.truckNumber,
            label: truck.name,
            driver: truck.driver,
          }));
          dispatch({
            type: GET_TRUCKS_OPTIONS_SUCCESS,
            payload: options,
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};

export const getTruckSurveysAction = (data) => {
  return (dispatch) => {
    return getTruckSurveys(data).then(
      (response) => {
        if (response.data && response.data.code === "200") {
          dispatch({
            type: GET_TRUCK_SURVEYS_SUCCESS,
            payload: Array.isArray(response.data.responseObject)
              ? response.data.responseObject
              : [response.data.responseObject],
          });
        }
        if (response.data && response.data.code === "400") {
          dispatch({
            type: GET_TRUCK_SURVEYS_SUCCESS,
            payload: [],
          });
        }
      },
      (error) => dispatch(handleErrorAction(error))
    );
  };
};
