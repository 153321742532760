import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux"
import axios from "axios";
import ReactApexChart from 'react-apexcharts';
import { actions, defaultOrderByFn } from "react-table";
import moment from "moment";

import { LOADING, ON_CLICK_SUCCESS } from "../../../store/constants/ActionTypes";
import {getTreeMapEventAction,
  clearSateAction
} from "../../../store/actions/ReportsAction";


const TreeMapChart2 = ({title,yaxisData,}) => {
  const dispatch = useDispatch();
  
   const series = [
    {
      data: yaxisData
    }
  ]     
        
   const options =  {
        legend: {
            show: true
            },

        chart: {
          height: 350,
          type: 'treemap',
        
        },
        dataLabels: {
          enabled: true
        },
        title: {
            text: title,
            align: 'center'
        },
        colors: [
            '#3B93A5',
            '#F7B844',
            '#ADD8C7',
            '#EC3C65',
            '#CDD7B6',
            '#C1F666',
            '#D43F97',
            '#1E5D8C',
            '#421243',
            '#7F94B0',
            '#EF6537',
            '#C0ADDB'
            ],

            plotOptions: {
                treemap: {
                    distributed: true,
                    enableShades: false
                }
                },


      
      };
    
   
     

  return (
    <>
        <ReactApexChart 
            options={options} 
            series={series} 
            type="treemap"
            height={300} />

    
    </>
  );
};

export default TreeMapChart2;
