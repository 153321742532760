import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { toggleOrderModalAction } from "../../store/actions/uiAction";

const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "740px",
    width: "100%",
    overflow: "hidden",
  },
};

const PageModal = ({ isOpen, setIsOpen, component }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      style={customStyles}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName="bgOverlay"
    >
      {component}
    </Modal>
  );
};

export default PageModal;
