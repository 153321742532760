import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactFileReader from "react-file-reader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  getProductsAction,
  getCategoriesAction,
  getSubcategoriesAction,
  getTaxCodeAction,
  uploadProductsAction,
  deleteProductAction,
  changeProductVisibilityAction,
  bulkEditAction,
  uploadEditProductsAction,
  getAllProductsAction,
} from "../../store/actions/ProductAction";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import ProductsTablePage from "./ProductsTablePage";
import CategoriesPage from "./CategoriesPage";
import AddProductPage from "./AddProductPage";
import AddCategoryForm from "./AddCategoryForm";
import AddSubcategoryForm from "./AddSubcategoryForm";
import SetCutOffTimeForm from "./SetCutOffTimeForm";
import { TabsWrapper } from "../../styles/Tabs";
import PageModal from "../../components/dom/modal";
import excel from "xlsx";

import "react-tabs/style/react-tabs.css";
import SubcategoriesPage from "./SubcategoriesPage";
import { getProductSubcategories } from "../../services/ProductService";
import TaxCodePage from "./TaxCodePage";
import AddTaxCodeForm from "./AddTaxCodeForm";
import ProductPage from "./ProductPage";
import VerticalsTablePage from "./VerticalsTablePage";
import AddVerticalForm from "./AddVerticalForm";
import AddVerticalPage from "./AddVerticalPage";
import VerticalsPage from "./VerticalsPage";
import PackagingUnitPage from "./PackagingUnitPage";
import AddPackagingUnitPage from "./AddPackagingUnitPage";
import AddMeasurementUnitForm from "./AddMeasurementUnitForm";
import MeasurementUnitPage from "./MeasurementUnitPage";

const ProductsPage = () => {
  const userObj = useSelector((state) => state.authManager.user);
  const [canShowCreateProductForm, setCanShowCreateProductForm] = useState(
    false
  );
  const [canShowCreateVerticalForm, setCanShowCreateVerticalForm] = useState(
    false
  );
  const [
    canShowCreatePackagingUnitForm,
    setCanShowCreatePackagingUnitForm,
  ] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [canShowTabs, setCanShowTabs] = useState(true);
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);
  const [subcategoryModalIsOpen, setSubcategoryModalIsOpen] = useState(false);
  const [taxCodeModalIsOpen, setTaxCodeModalIsOpen] = useState(false);
  const [cutOffTimeModelIsOpen, setCutOffTimeModelIsOpen] = useState(false);
  const [measurementUnitModalIsOpen, setMeasurementUnitModalIsOpen] = useState(
    false
  );
  const [category, setCategory] = useState(null);
  const [vertical, setVertical] = useState(null);
  const [measurementUnit, setMeasurementUnit] = useState(null);
  const [packagingUnit, setPackagingUnit] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const [taxCode, setTaxCode] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [product, setProduct] = useState({});
  const canResetView = useSelector((state) => state.stateManager.canResetView);
  const success = useSelector((state) => state.stateManager.success);
  const isBulkEditTriggered = useSelector(
    (state) => state.stateManager.isBulkEditTriggered
  );

  const link = useSelector((state) => state.stateManager.link);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const dispatch = useDispatch();

  const addCategory = () => {
    setCanShowTabs(true);
    setCanShowCreateProductForm(false);
    setEditMode(false);
    setCategoryModalIsOpen(true);
    setCategory(null);
    setEditMode(false);
  };

  useEffect(() => {
    dispatch(getAllProductsAction());
  }, []);

  useEffect(() => {
    if (isBulkEditTriggered) {
      console.log("isBulkEditTriggered true link = ", link);
      window.open(
        link,
        "_blank" // <- This is what makes it open in a new window.
      );
    }
  }, [isBulkEditTriggered]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setViewMode(false);
        setSubcategoryModalIsOpen(false);
        setMeasurementUnitModalIsOpen(false);
        setCategoryModalIsOpen(false);
        setTaxCodeModalIsOpen(false);
        dispatch(getSubcategoriesAction());
        dispatch(getCategoriesAction());
        dispatch(getTaxCodeAction());
        dispatch(getAllProductsAction());
        setCanShowTabs(true);
        setCanShowCreateProductForm(false);
        setCanShowCreateVerticalForm(false);
        setCanShowCreatePackagingUnitForm(false);
      }, 6000);
    }
  }, [success]);

  const resetView = () => {
    setCanShowTabs(true);
    setCanShowCreateProductForm(false);
    setCutOffTimeModelIsOpen(false);
    setCanShowCreateVerticalForm(false);
    setCanShowCreatePackagingUnitForm(false);
  };

  const addSubcategory = (row) => {
    setEditMode(false);
    setSubcategoryModalIsOpen(true);
  };

  const addTaxCode = (row) => {
    setEditMode(false);
    setTaxCodeModalIsOpen(true);
  };

  const addMeasurementUnit = () => {
    setEditMode(false);
    setMeasurementUnitModalIsOpen(true);
  };

  const editCategory = (row) => {
    setCategoryModalIsOpen(true);
    setCategory(row);
    setEditMode(true);
  };

  const editSubcategory = (subcategory) => {
    setEditMode(false);
    setSubcategoryModalIsOpen(true);
    setSubcategory(subcategory);
    setEditMode(true);
  };

  const editTaxCode = (taxCOde) => {
    setEditMode(false);
    setTaxCodeModalIsOpen(true);
    setTaxCode(taxCOde);
    setEditMode(true);
  };

  const editMeasurementUnit = (unit) => {
    setEditMode(false);
    setMeasurementUnitModalIsOpen(true);
    setMeasurementUnit(unit);
    setEditMode(true);
  };
  const editProduct = (row) => {
    setEditMode(true);
    setProduct(row);
    setCanShowTabs(false);
    setCanShowCreateProductForm(true);
  };

  const editVertical = (vertical) => {
    setEditMode(true);
    setVertical(vertical);
    setCanShowTabs(false);
    setCanShowCreateVerticalForm(true);
  };

  const editPackagingUnit = (unit) => {
    setEditMode(true);
    setPackagingUnit(unit);
    setCanShowTabs(false);
    setCanShowCreateVerticalForm(false);
    setCanShowCreatePackagingUnitForm(true);
  };

  const viewProduct = (row) => {
    setEditMode(false);
    setProduct(row);
    setCanShowTabs(false);
    setViewMode(true);
  };

  const deleteProduct = (product) => {
    dispatch(deleteProductAction(product.id));
  };
  const hideProduct = (product) => {
    let data = {
      ids: [product.id],
      status: product.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
      username: userObj.username,
    };

    dispatch(changeProductVisibilityAction(data));
  };

  const showAddProductForm = () => {
    setCanShowTabs(false);
    setCanShowCreateProductForm(true);
    setEditMode(false);
  };

  const showAddVerticalForm = () => {
    setCanShowTabs(false);
    setCanShowCreateVerticalForm(true);
    setEditMode(false);
  };

  const showAddPackagingUnitForm = () => {
    setCanShowTabs(false);
    setCanShowCreatePackagingUnitForm(true);
    setEditMode(false);
  };

  const goBack = () => {
    setCanShowTabs(true);
    setCanShowCreateProductForm(false);
    setCanShowCreateVerticalForm(false);
    setEditMode(false);
    setViewMode(false);
  };

  const bulkUploadProducts = (files) => {
    let file = files.fileList[0];
    let fileName = files.fileList[0].name;

    console.log("file + fileName = ", file, fileName);
    dispatch(uploadProductsAction(file, fileName));
  };

  const handleBulkEditUpload = (files) => {
    let file = files.fileList[0];
    let fileName = files.fileList[0].name;

    console.log("file + fileName = ", file, fileName);
    dispatch(uploadEditProductsAction(file, fileName));
  };

  const handleFile = (e) => {
    const content = e.target.result;
    console.log("file content", content);
    // You can set content in state and show it in render.
  };

  const bulkEditProduct = (productIds) => {
    let data = {
      products: productIds,
      username: userObj.username,
    };

    dispatch(bulkEditAction(data));
    //console.log("bulkEditProduct() data = ", data);
  };

  useEffect(() => {
    if (canResetView) {
      setCategoryModalIsOpen(false);
      setTimeout(() => dispatch(getProductsAction()), 10000);
      //setTimeout(() => dispatch(getCategoriesAction()), 10000);

      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    }
  });

  return (
    <>
      {canShowTabs && (
        <>
          <PageTopHeader title="Product Management" />
          <TabsWrapper>
            <Tabs>
              <TabList>
                <Tab>Products</Tab>
                <Tab>Verticals</Tab>
                <Tab>Categories</Tab>
                <Tab>Subcategories</Tab>

                <Tab>Packaging Unit</Tab>
                <Tab>Tax Codes</Tab>
              </TabList>
              <TabPanel>
                <ProductsTablePage
                  addProduct={showAddProductForm}
                  edit={editProduct}
                  view={viewProduct}
                  upload={bulkUploadProducts}
                  deleteProduct={deleteProduct}
                  hideProduct={hideProduct}
                  buldEdit={bulkEditProduct}
                  bulkEditHandleUpload={handleBulkEditUpload}
                />
              </TabPanel>
              <TabPanel>
                <VerticalsPage
                  add={showAddVerticalForm}
                  edit={editVertical}
                  view={viewProduct}
                />
              </TabPanel>
              <TabPanel>
                <CategoriesPage edit={editCategory} add={addCategory} />
              </TabPanel>
              <TabPanel>
                <SubcategoriesPage
                  add={addSubcategory}
                  edit={editSubcategory}
                />
              </TabPanel>

              <TabPanel>
                <PackagingUnitPage
                  add={showAddPackagingUnitForm}
                  edit={editPackagingUnit}
                  packagingUnit={packagingUnit}
                />
              </TabPanel>
              <TabPanel>
                <TaxCodePage add={addTaxCode} edit={editTaxCode} />
              </TabPanel>
            </Tabs>
          </TabsWrapper>
        </>
      )}

      {canShowCreateProductForm && (
        <AddProductPage
          resetView={resetView}
          product={product}
          editMode={editMode}
        />
      )}

      {canShowCreateVerticalForm && (
        <AddVerticalPage
          resetView={resetView}
          vertical={vertical}
          editMode={editMode}
        />
      )}

      {canShowCreatePackagingUnitForm && (
        <AddPackagingUnitPage
          resetView={resetView}
          packagingUnit={packagingUnit}
          editMode={editMode}
        />
      )}

      {}

      {viewMode && (
        <ProductPage
          resetView={resetView}
          product={product}
          editMode={editMode}
          goBack={goBack}
        />
      )}

      <PageModal
        isOpen={categoryModalIsOpen}
        component={<AddCategoryForm category={category} editMode={editMode} />}
        setIsOpen={setCategoryModalIsOpen}
      />

      <PageModal
        isOpen={cutOffTimeModelIsOpen}
        component={
          <SetCutOffTimeForm category={category} resetView={resetView} />
        }
        setIsOpen={setCutOffTimeModelIsOpen}
      />

      <PageModal
        isOpen={subcategoryModalIsOpen}
        component={
          <AddSubcategoryForm subcategory={subcategory} editMode={editMode} />
        }
        setIsOpen={setSubcategoryModalIsOpen}
      />

      <PageModal
        isOpen={taxCodeModalIsOpen}
        component={<AddTaxCodeForm taxCode={taxCode} editMode={editMode} />}
        setIsOpen={setTaxCodeModalIsOpen}
      />

      <PageModal
        isOpen={measurementUnitModalIsOpen}
        component={
          <AddMeasurementUnitForm
            measurementUnit={measurementUnit}
            editMode={editMode}
          />
        }
        setIsOpen={setMeasurementUnitModalIsOpen}
      />
    </>
  );
};

export default ProductsPage;
