import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as d3 from "d3";
import PieChart from "./PieChart";
import { getIframeURL } from "../metabase/metabase.embed.js";
import Overview from "../metabase/Overview";
import Tile from "../../components/dom/ReportTile";
import CountsTile from "../../components/dom//CountTile"; 
import CountsCard from "../../components/dom/CountsCard";
import SingleCountsCard from "../../components/dom/SingleCountCard";
import MultiCountsCard from "../../components/dom//MultiCountsCard";
import AreaChart from "../reports/charts/areaChart";
import DonutChart from "../reports/charts/DonutChart";
import ProductDonutChart from "../reports/charts/salesByProductPie";
import MultipeYGraph from "../reports/charts/DoubleYaxisGraph";
import {
  getDailySalesReportAction,
  geOutletsCountByTypeAction,
  geLeadsCountByTypeAction,
  getOrdersBreakDownAction,
  getAverageOutletOrderValueAction,
  getTotalRevenueAction,
  getOrderStatusSummaryAction,
  getUsersReportAction,
  getDailyOrderSummaryAction,
  geOutletsStatusAction,
  geUsersStatusAction
} from "../../store/actions/ReportsAction";
import {
  getOutletsAction,
} from "../../store/actions/OutletAction";
import {
  getUsersAction,
} from "../../store/actions/UserAction";
import { getOrdersAction } from "../../store/actions/OrderAction";
import moment from "moment";
import DatePicker from "react-datepicker";
import { ButtonWithIcon } from "../../styles/Forms";
import { BoxWrapper,ButtonBox } from "../../styles/schedule";
import { CollectionsBookmarkTwoTone } from "@material-ui/icons";


const Dashboard = () => {
  const dailySalesReport = useSelector((state) => state.reportManager.dailySalesReport);
  const outletsCountByType = useSelector((state) => state.reportManager.outletsCountByType);
  const leadsCountByType = useSelector((state) => state.reportManager.leadsCountByType);
  const ordersBreakDown = useSelector((state) => state.reportManager.ordersBreakDown);
  const avgOutletOrderValue = useSelector((state) => state.reportManager.avgOutletOrderValue);
  const totalRevenue = useSelector((state) => state.reportManager.totalRevenue);
  const orders = useSelector((state) => state.OrderManager.orders);
  const outlets = useSelector((state) => state.outletManager.outlets);
  const users = useSelector((state) => state.userManager.users);
  const isLoading = useSelector((state) => state.reportManager.loading);
  const userReport =  useSelector((state) => state.reportManager.userReport);
  const dailyOrderSummary =  useSelector((state) => state.reportManager.dailyOrderSummary);
  const userStatus =  useSelector((state) => state.reportManager.userStatus);
  const outletStatus =  useSelector((state) => state.reportManager.outletStatus);

console.log("userStatus--=-=",userStatus)
console.log("outletStatus0-0=0=",outletStatus)

 // console.log("dailyOrderSummary===",dailyOrderSummary)

  const dispatch = useDispatch();
  const user = useSelector((state) => state.authManager.user);
  const generateData = (value, length = 3) =>
    d3.range(length).map((item, index) => ({
      date: index,
      value:
        value === null || value === undefined ? Math.random() * 100 : value,
    }));

  const [data, setData] = useState(generateData());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);

  const [loading, setLoading] = useState(false);


  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const changeData = () => {
    setData(generateData());
  };


  const onSubmit = (e) => {
    e.preventDefault(); 
    setLoading(true) ;
   
    let filter = {
       startDate : moment(startDate).format("DD/MM/YYYY"),
       endDate : moment(endDate).format("DD/MM/YYYY"),
       vertical: "",
       username:"",
       area:"",

    }
    dispatch(getDailySalesReportAction(filter));
    dispatch(geOutletsCountByTypeAction(filter));
    dispatch(geLeadsCountByTypeAction(filter));

    dispatch(getOrdersBreakDownAction(filter));
    dispatch(getAverageOutletOrderValueAction(filter));

    dispatch(getTotalRevenueAction(filter));
    dispatch(getOutletsAction(filter));
    
    dispatch(getOrdersAction(filter)); //no 

    dispatch(getDailyOrderSummaryAction({filter}));
    dispatch(geOutletsStatusAction({filter}));
    dispatch(geUsersStatusAction({filter}));

    
    setTimeout(
      () =>
      setLoading(false) ,
      5000
    );
  
  };
  
  useEffect(() => {
    dispatch(getUsersAction({}));
  }, [dispatch]);


  useEffect(() => {
    let allSalesFilter = {
      orderDir: "desc",
      orderBy: "id",
      searches: [
        {
          key: "deliveryDateInt",
          value: moment().format("YYYYMMDD"),
        },
      ],
    };

    let startDate = moment().add(-3,"days").format("DD-MM-YYYY"); 
    let endDate = moment().format("DD-MM-YYYY");

    dispatch(getDailySalesReportAction({}));

    dispatch(geOutletsCountByTypeAction({}));
    dispatch(geLeadsCountByTypeAction({}));

    dispatch(getOrdersBreakDownAction({}));
    dispatch(getAverageOutletOrderValueAction({}));

    dispatch(getTotalRevenueAction({}));
    dispatch(getOutletsAction({}));

    dispatch(getOrdersAction({}));
    dispatch(getDailyOrderSummaryAction({}));
    dispatch(geOutletsStatusAction({}));
    dispatch(geUsersStatusAction({}));


  }, []);

   useEffect(() => {
   getReports()
  },[])

  const reps_count = users.filter(item => {
    return item.role === "sales-representative"
    })
  const active_reps = reps_count.filter(item => {
    return item.status === "ACTIVE"
    })
  const activeOutletsCount = outlets.filter(item => {
    return item.status === "ACTIVE"
    })
  const getReports = () => {
     

  }


  return (
    <>
      <div className = "shadow p-3 mb-5 bg-white">
        <h2>Overview</h2>

         { loading &&
          <div className="alert alert-primary" role="alert">
              Loading...
            </div>
          }
          
        <div>
          <div className = "col-sm-12 shadow-sm p-3 mb-5">
                      <BoxWrapper>
                            <form onSubmit={onSubmit} role="form">
                              <div className="row">
                                    <div className="col-sm-2">
                                      <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        placeholderText="from"
                                        className="date-picker-input"
                                      />
                                    </div>
                                
                                    <div className="col-sm-2">
                                      <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        placeholderText="to"
                                        className="date-picker-input"
                                      />
                                    </div>

                                
                                  
                                  <div className="col-sm-2">
                                    <ButtonBox type="submit">
                                      filter
                                    </ButtonBox>
                                  </div>
                              
                              </div>
                            </form>
                          </BoxWrapper>
                      </div>
          
          

          <div className="row">  
            
              <div className = "col-sm-3 shadow p-3 mb-5 bg-white  rounded">
              <h6 className="text-muted font-weight-normal mt-0" >Orders</h6> 
              {orders && orders.length &&
               <CountsCard 
                  // inlineStyle = "col-sm-3"
                  //  title = "Orders"
                    totalValue = {orders.length}
                    // subValue = "44%"
                    // description = "Since Yesterday"
                />   }
              </div>
            <div className = "col-sm-2 shadow p-3 mb-5 bg-white rounded">
            <h6 className="text-muted font-weight-normal mt-0" >Leads</h6> 
              {leadsCountByType && leadsCountByType.totalLeads && 
               <MultiCountsCard 
                    //title = "Leads"
                    value1 =  {leadsCountByType.totalLeads}
                    desc1 = "Total"
                    // value2 = "50"
                    // desc2 ="Active" 
                />  }
              </div>   

              <div className = "col-sm-2 shadow p-3 mb-5 bg-white rounded">
              <h6 className="text-muted font-weight-normal mt-0" >Outlets</h6> 
               {  outletStatus && outletStatus.active &&             
                 <MultiCountsCard 
                    //  title = "Sales Reps"
                      value1 =  {outletStatus.active}
                      desc1 = "Active"
                      value2 = {outletStatus.inactive}
                      desc2 ="Inactive" 
                  />  }
                {/* {outletsCountByType && outletsCountByType.totalOutlets &&
                  <MultiCountsCard 
                   // title = "Outlets"
                    value1 =  {outletsCountByType.totalOutlets}
                    desc1 = "Total"
                    value2 = {activeOutletsCount.length}
                    desc2 ="Active" 
                />  } */}
              </div>   
              
              <div className = "col-sm-2 shadow p-3 mb-5 bg-white rounded">
              <h6 className="text-muted font-weight-normal mt-0" >Sales represenatives</h6> 
              {  userStatus && userStatus.active &&             
                 <MultiCountsCard 
                    //  title = "Sales Reps"
                      value1 =  {userStatus.active}
                      desc1 = "Active"
                      value2 = {userStatus.inactive}
                      desc2 ="Inactive" 
                  />  }
                {/* <MultiCountsCard 
                  //  title = "Sales Reps"
                    value1 =  {reps_count.length}
                    desc1 = "Total"
                    value2 = {active_reps.length}
                    desc2 ="Active" 
                />   */}
              </div>                       
            
              <div className = "col-sm-3 shadow p-3 mb-5 bg-white rounded"> 
              <h6 className="text-muted font-weight-normal mt-0" >Revenue (Tsh)</h6> 
                  
                 {totalRevenue && totalRevenue.revenue &&
                  <CountsCard 
                  //  inlineStyle = "col-sm-3"
                  //  title = "Revenue (Tsh)"
                    totalValue = {totalRevenue.revenue === undefined ? " " : totalRevenue.revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    subValue
                  // description = "Today Total Amount"
                  />}

              </div>
          </div>

          <div className = "row">
            <div className = "col-sm-4 shadow p-3 mb-5 bg-white rounded">
               <h6 className="text-muted font-weight-normal mt-0" >Outlets by type</h6> 
             
              {outletsCountByType && outletsCountByType.outletType && 
               <DonutChart
                 // title = "Outlets by Types"
                  dataLabels = {outletsCountByType.outletType}
                  dataValues = {outletsCountByType.outletTypeCount}
                /> }
              
              </div>
              
            <div className = 'col-sm-8 shadow p-3 mb-5 bg-white rounded'>
            <h6 className="text-muted font-weight-normal mt-0" >Orders Placed Vs Processed</h6> 
            {ordersBreakDown&&ordersBreakDown.orderPlaced &&
                  <AreaChart 
                 // title = "Orders placed Vs processed"
                  yaxisData = {
                                [{
                                  name: 'Placed',
                                  data: ordersBreakDown.orderPlaced
                                }, {
                                  name: 'Pocessed',
                                  data: ordersBreakDown.orderProcessed
                                },]
                              }
                  xaxisData = {ordersBreakDown.orderDate}
                  xDataType = "datetime"
                  dateFormant = "hh:mm"
                  
                  />
            }
           
            </div>
          </div>

          <div className = "row">
            <div className = "col-sm-4 shadow p-3 mb-5 bg-white rounded">
            <h6 className="text-muted font-weight-normal mt-0" >Leads by Types</h6> 
                {leadsCountByType && leadsCountByType.leadType &&
                  <DonutChart
                //  title = "Leads by Types"
                  dataLabels = {leadsCountByType.leadType}
                  dataValues = {leadsCountByType.leadCount}
                />}
              </div>
              
            <div className = 'col-sm-8 shadow p-3 mb-5 bg-white rounded'>
            <h6 className="text-muted font-weight-normal mt-0" >Average Order Value per outlet type</h6> 
              { avgOutletOrderValue &&  avgOutletOrderValue.items &&
               <AreaChart 
               // title = "Average Order value per outlet type"
                yaxisData = {avgOutletOrderValue.data}
                xaxisData = {avgOutletOrderValue.items}
                xDataType = "datetime"
                dateFormant = "DD MMM YYYY"
                
              /> }
            </div>
          </div>

          <div className = "row">

            <div className = 'col-sm-12 shadow p-3 mb-5 bg-white rounded'>
            <h6 className="text-muted font-weight-normal mt-0" >Daily Sales</h6> 
              { dailySalesReport && dailySalesReport.totalAmount &&
               <AreaChart
               // title = "Daily Sales"
                yaxisData = {[
                                {  name: "Total Amount",
                                   data: dailySalesReport.totalAmount
                                }
                            ]}
                xaxisData = {dailySalesReport.dailySaleDate}
                xDataType = "datetime"
                dateFormant = "DD MMM YYYY"
              /> }
            </div>
            
          </div>


          <div className = "row">
              <div className = "col-sm-12 p-3 mb-5 bg-white rounded">
              <h6 className="text-muted font-weight-normal mt-0" >Total Daily Orders Placed Vs Processed </h6> 
                  {dailyOrderSummary && dailyOrderSummary.orderPlaced &&
                    <AreaChart
                   // title = 'Total Daily Placed VS Processed Orders'
                    xaxisData = {dailyOrderSummary.orderDate}
                    yaxisData = {
                                      [{
                                        name: 'Placed',
                                       // type: 'line',
                                        data: dailyOrderSummary.orderPlaced
                                      },
                                      
                                      {
                                        name: 'Processed',
                                       // type: 'line',
                                        data: dailyOrderSummary.orderProcessed
                                      },
                                      
                                    ]
                                }
                    xDataType = "datetime"
                    dateFormant = "DD MMM YYYY "
                  />}
              </div>
          </div>

        </div> 
        
      
      
      </div>
    </>
  );
};

export default Dashboard;
