import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getProductsAction } from "../../store/actions/ProductAction";
import Table from "../../components/dom/Table/Table";
import { PageButton } from "../../styles/App";
import CreateIcon from "@material-ui/icons/Create";
import LoadingIndicator from "../../components/dom/LoadingIndicator";

const TerritoryTablePage = ({ add, edit }) => {
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const territories = useSelector(
    (state) => state.territoryManager.territories
  );
  const isLoading = useSelector((state) => state.productManager.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(getProductsAction())
  }, []);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      key: ["name"],
    },
    {
      Header: "Region",
      accessor: "region",
      key: ["region"],
    },
    {
      Header: "District",
      accessor: "district",
      key: ["district"],
    },
    {
      Header: "Ward",
      accessor: "ward",
      key: ["ward"],
    },

    {
      Header: " ",

      Cell: (row) => (
        <div onClick={() => edit(row.row.original)}>
          <CreateIcon className="primary pointer" />
        </div>
      ),
    },
  ];

  return (
    <>
      {success && <p className="alert alert-success">{message}</p>}
      {/* {isLoading && <LoadingIndicator />} */}

      <div className="row">
        <div className="col-sm-2">
          <h4 className="mt-3">All Territories</h4>
        </div>
        <div className="col-sm-10">
          <PageButton className="float-right" onClick={add}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Territory
          </PageButton>
        </div>
      </div>

      {territories && territories.length === 0 && (
        <p className="alert alert-info mt-3">No Territories at the moment</p>
      )}
      {territories && territories.length > 0 && (
        <Table data={territories} columns={columns} />
      )}
    </>
  );
};

export default TerritoryTablePage;
