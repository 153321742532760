import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import stateReducer from "./stateReducer";
import userReducer from "./UserReducer";
import outletsReducer from "./outletsReducer";
import productReducer from "./ProductReducer";
import OrderReducer from "./OrderReducer";
import UtilsReducer from "./UtilsReducer";
import TruckReducer from "./TruckReducer";
import CratesReducer from "./CratesReducer";
import LocationReducer from "./LocationReducer";
import RouteReducer from "./RouteReducer";
import ProductionReducer from "./ProductionReducer";
import SalesReducer from "./SalesReducer";
import supplierReducer from "./supplierReducer";
import TerritoryReducer from "./TerritoryReducer";
import uiReducer from "./uiReducer";
import RoutePlanReducer from "./routePlanReducer";
import ReportManager from "./ReportsReducer"

const appReducer = combineReducers({
  authManager: AuthReducer,
  stateManager: stateReducer,
  userManager: userReducer,
  outletManager: outletsReducer,
  productManager: productReducer,
  OrderManager: OrderReducer,
  utilsManager: UtilsReducer,
  truckManager: TruckReducer,
  cratesManager: CratesReducer,
  locationManager: LocationReducer,
  routeManager: RouteReducer,
  productionManager: ProductionReducer,
  salesManager: SalesReducer,
  supplierManager: supplierReducer,
  uiManager: uiReducer,
  territoryManager: TerritoryReducer,
  routePlanManager: RoutePlanReducer,
  reportManager: ReportManager,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
    localStorage.removeItem("persist:root");
  }
  return appReducer(state, action);
};

export default rootReducer;
