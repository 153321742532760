import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddUserForm from "../users/AddUserForm";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";

const AddUserPage = ({ resetView, user, editMode }) => {
  return (
    <div className="row">
      <div className="col-md-1 back-arrow">
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="2x"
          className="mr-2"
          onClick={() => resetView()}
        />
      </div>
      <div className="col-md-11">
        {!editMode && <PageTopHeader title="Create New User" />}
        {editMode && <PageTopHeader title="Edit  User" />}
        <AddUserForm resetView={resetView} user={user} editMode={editMode} />
      </div>
    </div>
  );
};

export default AddUserPage;
