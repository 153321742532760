import React from "react";
import moment from "moment";

export const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "Number",
    accessor: "orderNumber",
  },
  {
    Header: "Collected By",
    accessor: "collectedBy",
  },
  {
    Header: "Status",
    accessor: "status",
  },

  {
    Header: " ",

    Cell: (row) => <div>View Items</div>,
  },
];

export const allCreditsColumns = [
  {
    Header: "Created Date",
    accessor: (d) => moment(d.createdAt).format("ddd D MMM YYYY"),
    key:["createdAt"]
  },
  {
    Header: "Outlet",
    accessor: "outletName",
    key:["outletName"]
  },
  {
    Header: "Agent Name",
    accessor: "orderDetails.orderBy",
    key:["orderDetails.orderBy"]
  },
  {
    Header: "COLLECTED By",
    accessor: (d) => (d.paymentReceivedBy ? d.paymentReceivedBy : "-"),
    key:["paymentReceivedBy"]
  },
  {
    Header: "DRIVER",
    accessor: "deliveredBy",
    key:["deliveredBy"]
  },
  {
    Header: "Due date",
    accessor: "dueDate",
    key:["dueDate"]
    
  },
  {
    Header: "overdue amount",
    accessor: "amount",
    key:["amount"]
  },
  {
    Header: "status",
    accessor: "paymentStatus",
    key:["paymentStatus"]
  },
  {
    Header: "Days Delayed",
    accessor: "overDueDays",
    key:["overDueDays"]
  },
];

export const blackListedColumns = [
  {
    Header: "ID",
    accessor: "outletNumber",
  },
  {
    Header: "Customer Name",
    accessor: "name",
  },
  {
    Header: "Customer Mobile",
    accessor: "telephone",
  },
  {
    Header: "Province",
    accessor: "province",
  },
  {
    Header: "Route",
    accessor: "routeIdentity",
  },
];
