import {
  ACTION_FAIL,
  LOADING,
  DONE,
  IMAGE_UPLOAD_SUCCESS,
  RESET_UTILS,
  START_FETCHING,
  UPDATE_IMAGE_URL,
} from "../constants/ActionTypes";
import { uploadUserProfilePicture } from "../../services/UserService";
import { uploadImages } from "../../services/UtilsService";
export const UploadPictureAction = (data) => {
  return (dispatch) => {
    console.log(data);
  };
};

export const handleErrorAction = (error) => {
  return {
    type: ACTION_FAIL,
    payload:
      error && error.response && error.response.data.message
        ? error.response.data.message
        : "Something wen't wrong , please try again",
  };
};

export const startProgress = () => {
  return (dispatch) => {
    dispatch({ type: LOADING });
  };
};

export const doneProgress = () => {
  return (dispatch) => {
    dispatch({ type: DONE });
  };
};

export function uploadProfPic(data) {
  return (dispatch) => {
    return uploadUserProfilePicture(data).then(
      (response) =>
        dispatch({
          type: IMAGE_UPLOAD_SUCCESS,
          payload: response.data.responseObject.$,
        }),
      (error) => dispatch({ type: ACTION_FAIL })
    );
  };
}

export function uploadImageAction(data) {
  return (dispatch) => {
    return uploadImages(data).then(
      (response) =>
        dispatch({
          type: IMAGE_UPLOAD_SUCCESS,
          payload: response.data.responseObject.$,
        }),
      (error) => dispatch(handleErrorAction(error))
    );
  };
}

export const resetUtils = () => {
  return (dispatch) => {
    dispatch({ type: RESET_UTILS });
  };
};

export const startFetching = () => {
  return (dispatch) => {
    dispatch({ type: START_FETCHING });
  };
};

export const updateImageUrlAction = (url) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_IMAGE_URL, payload: url });
  };
};
