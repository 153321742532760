import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersAction } from "../../store/actions/UserAction";
import Select from "react-select";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Table from "../../components/dom/Table/Table";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import { usePermissionChecker } from "../../utils/user";
import Tile from "../../components/dom/Tile";
import { ButtonWithIcon } from "../../styles/Forms";
import moment from "moment";

const UsersTablePage = ({ users, showForm, viewUser }) => {
  const [selectedShift, setSelectedShift] = useState(undefined);
  const [selectedRole, setSelectedRole] = useState(undefined);
  const success = useSelector((state) => state.stateManager.success);
  const message = useSelector((state) => state.stateManager.message);
  const roleOptions = useSelector((state) => state.userManager.roleOptions);
  const total = useSelector((state) => state.userManager.total);
  const totalActive = useSelector((state) => state.userManager.totalActive);
  const totalInactive = useSelector((state) => state.userManager.totalInactive);
  const userObj = useSelector((state) => state.authManager.user);
  const dispatch = useDispatch();

  const handleShiftChange = (selectedOption) => {
    setSelectedShift(selectedOption.value);
    let filter = {};
    if (selectedRole) {
      filter = {
        orderBy: "id",
        orderDir: "desc",

        searches: [
          {
            key: "shift",
            value: selectedOption.value,
          },
          {
            key: "role",
            value: selectedRole,
          },
        ],
      };
    } else {
      filter = {
        orderBy: "id",
        orderDir: "desc",

        searches: [
          {
            key: "shift",
            value: selectedOption.value,
          },
        ],
      };
    }

    // console.log(filter);
    dispatch(getUsersAction(filter));
    //dispatch(getCreditsAction(filter));
  };

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption.value);
    let filter = {};
    if (selectedShift) {
      filter = {
        orderBy: "id",
        orderDir: "desc",
        searches: [
          {
            key: "role",
            value: selectedOption.value,
          },
          {
            key: "shift",
            value: selectedShift,
          },
        ],
      };
    } else {
      filter = {
        orderBy: "id",
        orderDir: "desc",
        searches: [
          {
            key: "role",
            value: selectedOption.value,
          },
        ],
      };
    }

    //console.log(filter);
    dispatch(getUsersAction(filter));
  };

  const columns = [
    {
      Header: "Created Date",
      accessor: (d) => moment(d.createDate).format("ddd MMM D , YYYY"),
      key: ["createDate"],
    },
    {
      Header: "Name",
      accessor: (d) => `${d.firstName} ${d.lastName}`,
      key: ["firstName", "lastName"],
    },

    {
      Header: "Role",
      accessor: (d) => d.role.replace(/-/g, " "),
      filterMethod: (filter, row) => {
        if (row[filter.id]) {
          return row[filter.id].includes(filter.value);
        }
      },
      key: ["role"],
    },
    {
      Header: "username",
      accessor: "username",
      key: ["username"],
    },

    {
      Header: "Last Login",
      accessor: (d) =>
        d.lastLogin ? moment(d.lastLogin).format("ddd MMM D ,  YYYY") : "-",
      key: ["lastLogin"],
    },

    {
      Header: "status",
      accessor: "status",
      filterMethod: (filter, row) => {
        if (row[filter.id]) {
          return row[filter.id].includes(filter.value);
        }
      },
      key: ["status"],
    },
    {
      Header: " ",

      Cell: ({ row }) => (
        <ButtonWithIcon
          className="primary text-uppercase font-weight-normal"
          onClick={() => viewUser(row.original)}
        >
          View User
        </ButtonWithIcon>
      ),
    },
  ];

  return (
    <>
      {/* <LoadingIndicator /> */}
      <PageTopHeader
        title="User Management"
        icon={faPlus}
        actionText={
          usePermissionChecker("can_create_user") ? "Create New User" : ""
        }
        action={showForm}
      />
      {success && <p className="alert alert-success">{message}</p>}
      <div className="row">
        <Tile title="total users" inlineStyle="col-sm-4" value={total} />
        <Tile title="active users" inlineStyle="col-sm-4" value={totalActive} />
        <Tile
          title="inactive users"
          inlineStyle="col-sm-4"
          value={totalInactive}
        />
      </div>
      <div className="row">
        <div className="col-sm-5">
          <Select
            options={roleOptions}
            placeholder="Filter by role"
            onChange={(selectedOption) => handleRoleChange(selectedOption)}
            isDisabled={userObj.role === "distribution-manager" ? true : false}
          />
        </div>
      </div>
      <Table data={users} columns={columns} />
    </>
  );
};

export default UsersTablePage;
