import styled from "styled-components";

export const TabsWrapper = styled.div`
  .react-tabs__tab {
    border: none;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 20px;
  }
  .react-tabs__tab--selected {
    color: #00c400 !important;
    border-bottom: 4px solid #00c400 !important;
    padding-bottom: 10px;
    font-weight: 500;
  }

  .react-tabs__tab-list {
    border-bottom: 0.5px solid #979797 !important;
  }
`;
