import {
  UPDATE_LOCATION,
  RESTORE_DEFAULT_CONFIG,
  CHANGE_LOCATION_PERMISSION_STATUS,
  GET_CURRENT_LOCATION,
  LOCATION_OUT_OF_BOUND,
  GET_USER_LOCATION_SUCCESS,
  GET_USER_LOCATION_FAIL,
  LOAD_CURRENT_LOCATION,
  UPDATE_MAP_POSITION,
} from "../constants/ActionTypes";
import _ from "lodash";

const initialState = {
  markerPosition: null,
  mapPosition: {
    lat: -6.7537074,
    lng: 39.2756294,
  },
  establishment: "",
  address: "",
  zoom: 8,
  district: "",
  region: "",
  area: "",
  region: "",
  isLocationEnabled: false,
  isLocationSaved: false,
  isLocationOutOfBound: false,
  userLocation: null,
  isLoading: false,
};

function LocationReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOCATION:
      return {
        ...state,
        establishment: action.payload.establishment,
        address: action.payload.address,
        mapPosition: action.payload.mapPosition,
        markerPosition: action.payload.markerPosition,
        region: action.payload.region,
        area: action.payload.area,
        district: action.payload.district,
        zoom: action.payload.zoom,
        isLoading: false,
        isLocationSaved: true,
        isLocationOutOfBound: false,
        message: "",
        isError: false,
      };
    case RESTORE_DEFAULT_CONFIG:
      return { ...state, zoom: 8 };
    case LOCATION_OUT_OF_BOUND:
      return { ...state, isLocationOutOfBound: action.payload };
    case UPDATE_MAP_POSITION:
      return {
        ...state,
        mapPosition: action.payload,
      };
    case CHANGE_LOCATION_PERMISSION_STATUS:
      return {
        ...state,
        isLocationEnabled: action.payload,
        isLoading: true,
      };
    case GET_CURRENT_LOCATION:
      return {
        ...state,
        isLoading: true,
      };

    case GET_USER_LOCATION_SUCCESS:
      return {
        ...state,
        userLocation: action.payload,
        isLoading: false,
        isError: false,
      };

    case GET_USER_LOCATION_FAIL:
      return {
        ...state,
        message: action.payload,
        isError: true,
        isLoading: false,
      };

    case LOAD_CURRENT_LOCATION: {
      return { ...state, isLoading: true };
    }

    default:
      return state;
  }
}

export default LocationReducer;
