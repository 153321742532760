import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import {
  getProductCategoryOptionsAction,
  getProductSubcategoryOptionsAction,
  getTaxOptionsAction,
  addProductInfoAction,
  getVerticalOptionsAction,
  getPackagingUnitOptionsAction,
  getMeasurementUnitOptionsAction,
  addProductAction,
  editProductAction,
} from "../../store/actions/ProductAction";
import {
  resetUtils,
  updateImageUrlAction,
} from "../../store/actions/UtilsAction";
import { useDispatch, useSelector } from "react-redux";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ImageUploader from "../../components/utils/ImageUploader";

import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { getSupplierOptionsAction } from "../../store/actions/SupplierAction";
import { scrollToTop } from "../../utils/window";
import AddSKUForm from "./AddSKUForm";
import { Label } from "../../styles/Forms";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import AutoNumeric from "autonumeric";

import "./products.css";

const nameRegExp = /^([^0-9]*)$/;

const AddProductForm = ({ editMode, product, resetView }) => {
  const errorRef = useRef(null);

  const [promotions, setPromotions] = useState([]);
  const [packagingUnits, setPackagingUnits] = useState({});
  const [packaging, setPackaging] = useState([]);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [minQuantities, setMinQuantites] = useState({});
  const [maxQuantities, setMaxQuantities] = useState({});
  const [minPrices, setMinPrices] = useState({});
  const [maxPrices, setMaxPrices] = useState({});

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryOptions, setCategoryOptions] = useState("");
  const [canShowSKUForm, setCanShowSKUForm] = useState(false);
  const [verticalOptions, setVerticalOptions] = useState("");
  const [subcategoryOptions, setSubcategoryOptions] = useState("");
  const [selectedVertical, setSelectedVertical] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedTaxCode, setSelectedTaxCode] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [supplierOptions, setSupplierOptions] = useState("");

  const formikRef = useRef();
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const isProductAdded = useSelector(
    (state) => state.stateManager.isProductAdded
  );
  const productCategoryOptions = useSelector(
    (state) => state.productManager.categoryOptions
  );
  const productSubcategoryOptions = useSelector(
    (state) => state.productManager.subCategoryOptions
  );
  const taxCodeOptions = useSelector(
    (state) => state.productManager.taxCodeOptions
  );

  const retrievedSupplierOptions = useSelector(
    (state) => state.supplierManager.supplierOptions
  );

  const packagingUnitsOptions = useSelector(
    (state) => state.productManager.packagingUnitsOptions
  );

  const imageUploadSuccess = useSelector(
    (state) => state.stateManager.imageUploadSuccess
  );
  const imageUploaded = useSelector(
    (state) => state.utilsManager.imageUploadSuccess
  );
  var imgUrl = useSelector((state) => state.utilsManager.imgUrl);
  var s3Url = useSelector((state) => state.utilsManager.imgUrl);
  imgUrl =
    !imageUploaded && editMode
      ? product.images && product.images[0].path
      : imgUrl;

  const userObj = useSelector((state) => state.authManager.user);
  const categories = useSelector((state) => state.productManager.categories);
  const productVerticalOptions = useSelector(
    (state) => state.productManager.verticalOptions
  );

  const imageUrl = editMode ? product.image : imgUrl;
  const dispatch = useDispatch();

  const addPromotion = () => {
    let promotionsLength = promotions.length;
    let promotionObj = {
      id: promotionsLength,
      minQty: 0,
      maxQty: 0,
      minPrice: 0,
      maxPrice: 0,
      packagingUnit: packagingUnitsOptions[0],
    };
    setPromotions([...promotions, promotionObj]);
    setPackagingUnits({ 0: 0 });
  };

  useEffect(() => {
    console.log("AddProductForm Product = ", product);
    if (editMode) {
      let savedPackagingUnits = product.packages.map((packagingUnit) => ({
        id: packagingUnit.id,
        name: packagingUnit.name,
        quantity: packagingUnit.quantity,
        bands: packagingUnit.sellingBands,
      }));
      setPackaging(savedPackagingUnits);
      if (product.image) {
        dispatch(
          updateImageUrlAction(product.images && product.images[0].path)
        );
      }
    }
    dispatch(getProductCategoryOptionsAction());
    dispatch(getProductSubcategoryOptionsAction());
    dispatch(getTaxOptionsAction());
    dispatch(getSupplierOptionsAction());
    dispatch(getVerticalOptionsAction());
    dispatch(getPackagingUnitOptionsAction());
    dispatch(getMeasurementUnitOptionsAction());

    if (!editMode) {
      dispatch(resetUtils());
    }

    new AutoNumeric(".amount");
  }, []);

  useEffect(() => {
    if (isProductAdded) {
      setCanShowSKUForm(true);
    }
  }, [isProductAdded]);

  useEffect(() => {
    setVerticalOptions(productVerticalOptions);
    if (editMode) {
      let selectedVertical = productVerticalOptions.filter(
        (cat) => cat.value === product.verticalId
      );

      if (selectedVertical && selectedVertical.length > 0) {
        if (formikRef.current) {
          formikRef.current.setFieldValue("verticalId", selectedVertical[0]);
        }
        setSelectedVertical(selectedVertical[0]);
      }
    }
  }, [productVerticalOptions]);

  useEffect(() => {
    setCategoryOptions(productCategoryOptions);
    if (editMode) {
      let selectedCategory = productCategoryOptions.filter(
        (cat) => cat.value === product.categoryId
      );

      if (selectedCategory && selectedCategory.length > 0) {
        if (formikRef.current) {
          formikRef.current.setFieldValue("categoryId", selectedCategory[0]);
        }
        setSelectedCategory(selectedCategory[0]);
      }
    }
  }, [productCategoryOptions]);

  useEffect(() => {
    setSubcategoryOptions(productSubcategoryOptions);
    if (editMode) {
      let selectedSubcategory = productSubcategoryOptions.filter(
        (cat) => cat.value === product.subcategoryId
      );

      if (selectedSubcategory && selectedSubcategory.length > 0) {
        if (formikRef.current) {
          formikRef.current.setFieldValue(
            "subCategoryId",
            selectedSubcategory[0]
          );
        }
        setSelectedSubcategory(selectedSubcategory[0]);
      }
    }
  }, [productSubcategoryOptions]);

  useEffect(() => {
    setSupplierOptions(retrievedSupplierOptions);
    if (editMode) {
      let selectedSupplier = retrievedSupplierOptions.filter(
        (supplier) => supplier.value === product.supplierId
      );

      if (selectedSupplier && selectedSupplier.length > 0) {
        if (formikRef.current) {
          formikRef.current.setFieldValue("supplierId", selectedSupplier[0]);
        }
        setSelectedSupplier(selectedSupplier[0]);
      }
    }
  }, [retrievedSupplierOptions]);

  useEffect(() => {
    if (editMode) {
      let selectedTaxCode = taxCodeOptions.filter(
        (taxCode) => taxCode.taxCode === product.taxCode
      );

      if (selectedTaxCode && selectedTaxCode.length > 0) {
        if (formikRef.current) {
          formikRef.current.setFieldValue("taxCode", selectedTaxCode[0]);
        }
        setSelectedTaxCode(selectedTaxCode[0]);
      }
    }
  }, [taxCodeOptions]);

  const handlePackageValuesChange = (index, e, packagingUnit, key) => {
    let clonePackagingUnit = _.clone(packagingUnit);
    let quantity = e.target.value ? parseFloat(e.target.value) : null;
    let totalQty = Math.round(clonePackagingUnit.quantity * quantity);

    clonePackagingUnit["bands"][index][key] = quantity;
    let updatedPackaging = packaging.map((p) =>
      p.name === clonePackagingUnit.name ? clonePackagingUnit : p
    );
    setPackaging(updatedPackaging);

    if (
      (!isError && key !== "minQty") ||
      (maxQuantity < totalQty && key === "minQty") ||
      maxQuantity === 0
    ) {
      let bandsArr = updatedPackaging.map((packaging) => {
        packaging.bands.map(
          (band) => (band["max"] = packaging.quantity * band.maxQty)
        );
        return packaging.bands;
      });
      bandsArr = _.flatten(bandsArr);
      let maxQty = _.maxBy(bandsArr, (o) => {
        return o.max;
      });
      if (maxQty) {
        setMaxQuantity(maxQty.max);
      }
      setIsError(false);
      setErrorMessage("");
    } else {
      //scrollToTop();
      setIsError(true);
      setErrorMessage(`Minimum quantity should be more than ${maxQuantity}  `);
    }
  };

  const handlePackagingUnitChange = (selectedUnit) => {
    let packagingLength = packaging.length + 1;
    if (packagingLength <= 2) {
      let isUnitAlreadyThere = _.find(packaging, { name: selectedUnit.label });

      if (!isUnitAlreadyThere) {
        let packagingSKU = {
          quantity: selectedUnit.quantity,
          name: selectedUnit.label,
          bands: [{ minQty: 0, maxQty: 0, bandPrice: 0 }],
        };
        setPackaging([...packaging, packagingSKU]);
      }
    } else {
      setIsError(true);
      setErrorMessage("Sorry , you cannot add more than two packaging units");
      setTimeout(() => setIsError(false), 6000);
    }

    //setPackagingUnits({ ...packagingUnits, [index]: selectedUnit.value });
  };

  const removepackagingUnit = (e, id) => {
    e.preventDefault();
    console.log("Remove pacjkaging Unit id =  ", id);
    let updatedPackagingList = packaging.filter(
      (packagingUnit) => packagingUnit.id !== id
    );
    console.log(JSON.stringify(packaging));
    console.log("updatedPackagingList = ", updatedPackagingList);
    setPackaging(updatedPackagingList);
  };

  const removeBand = (e, packagingUnit, removedBandIndex) => {
    e.preventDefault();
    let clonePackagingUnit = _.clone(packagingUnit);

    clonePackagingUnit["bands"] = clonePackagingUnit["bands"].filter(
      (band, index) => index !== removedBandIndex
    );

    setPackaging(
      packaging.map((p) =>
        p.name === clonePackagingUnit.name ? clonePackagingUnit : p
      )
    );
  };

  const addBands = (e, packagingUnit) => {
    e.preventDefault();
    let clonePackagingUnit = _.clone(packagingUnit);
    let bandLength = clonePackagingUnit["bands"].length + 1;
    if (bandLength <= 4) {
      setIsError(false);
      clonePackagingUnit["bands"] = [
        ...clonePackagingUnit["bands"],
        { minQty: 0, maxQty: 0, bandPrice: 0 },
      ];

      setPackaging(
        packaging.map((p) =>
          p.name === clonePackagingUnit.name ? clonePackagingUnit : p
        )
      );
    } else {
      // errorRef.current.scrollIntoView();
      setIsError(true);
      setErrorMessage("Sorry , you cannot add more than four bands ");
    }
  };

  useEffect(() => {
    if (success || error) {
      setIsSubmitDisabled(false);
    }
  }, [success, error]);

  return (
    <>
      {!canShowSKUForm && (
        <Formik
          innerRef={formikRef}
          initialValues={{
            nameEnglish: editMode ? product.name : "",
            nameSwahili: editMode ? product.nameSwahili : "",
            code: editMode ? product.productCode : "",
            verticalId: selectedVertical,
            categoryId: selectedCategory,
            subCategoryId: selectedSubcategory,
            cartonSize: editMode ? product.cartonSize : "",
            cartonCost: editMode
              ? product.cartonCost
                ? product.cartonCost.toString()
                : 0
              : "",
            description: editMode ? product.description : "",
            taxCode: selectedTaxCode,
            inventory: editMode ? product.inventory : "",
            minStockB2B: editMode ? product.minStockB2B : "",
            supplierId: selectedSupplier,
            username: userObj.username,
            brand: editMode ? product.brand : "",

            unitCostIncVat: editMode ? product.costVatInc ?? "" : "",
            unitCostExlVat: editMode ? product.costVatExl ?? "" : "",
            visibleB2B: editMode ? product.visibleB2B : "Y",
            trackB2B: editMode ? product.trackB2B : "Y",
            outOfStockB2B: editMode ? product.outOfStockB2B : "N",
            featuredB2B: editMode ? product.featuredB2B : "N",
            preOrderB2B: "Y",
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log("onSubmit");
            scrollToTop();
            setSubmitting(true);
            setIsSubmitDisabled(true);
            setTimeout(() => {
              let formData = _.clone(values);

              formData["verticalId"] = formData.verticalId.value;
              formData["categoryId"] = formData.categoryId.value;
              formData["subCategoryId"] = formData.subCategoryId.value;
              formData["taxCode"] = formData.taxCode.taxCode;
              formData["supplierId"] = formData.supplierId.value;
              formData["images"] = [imageUrl];
              formData["packaging"] = packaging;
              formData["unitCostIncVat"] = formData.unitCostIncVat
                ? typeof formData.unitCostIncVat === "string" ||
                  formData.unitCostIncVat instanceof String
                  ? parseInt(formData.unitCostIncVat.replace(/,/g, ""))
                  : parseInt(formData.unitCostIncVat)
                : 0;
              formData["unitCostExlVat"] =
                typeof formData.unitCostExlVat === "string" ||
                formData.unitCostExlVat instanceof String
                  ? formData.unitCostExlVat
                    ? parseInt(formData.unitCostExlVat.replace(/,/g, ""))
                    : parseInt(formData.unitCostExlVat)
                  : 0;
              formData["cartonCost"] = formData.cartonCost
                ? typeof formData.cartonCost === "string" ||
                  formData.cartonCost instanceof String
                  ? parseInt(formData.cartonCost.replace(/,/g, ""))
                  : parseInt(formData.cartonCost)
                : 0;

              console.log(JSON.stringify(formData));
              if (editMode) {
                formData["id"] = product.id;
                formData["images"] = [s3Url];
                formData["image"] = s3Url;
                //formData["packages"] = packaging;
                formData["updatedBy"] = userObj.username;
                console.log(JSON.stringify(formData));
                dispatch(editProductAction(formData));
              } else {
                dispatch(addProductAction(formData));
              }
            }, 800);
          }}
          validationSchema={Yup.object().shape({
            nameEnglish: Yup.string().required(
              " Product Name English is required "
            ),
            brand: Yup.string().required(" Brand  is required "),
            cartonSize: Yup.number().required(" Carton Size  is required "),
            cartonCost: Yup.string().required(" Carton Cost  is required "),
            supplierId: Yup.object().shape({
              label: Yup.string().required("Supplier is required"),
            }),
            verticalId: Yup.object().shape({
              label: Yup.string().required("Vertical is required"),
            }),
            categoryId: Yup.object().shape({
              label: Yup.string().required("Category is required"),
            }),

            taxCode: Yup.object().shape({
              label: Yup.string().required("Tax code is required"),
            }),

            inventory: Yup.number().required(" Opening Stock  is required "),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;

            return (
              <div className="addProductForm">
                {/* <code>{JSON.stringify(errors)}</code> */}
                {/* <h3>Values</h3> */}
                {/* <code>{JSON.stringify(packaging)}</code> */}
                {/* <h3>Category Options</h3> */}
                {/* <code>{JSON.stringify(values)}</code> */}

                {success && <p className="alert alert-success">{message}</p>}
                {error && <p className="alert alert-danger">{message}</p>}

                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span
                            id="ember669"
                            class="tooltip-container text-dashed-underline ember-view"
                          >
                            Product Name (en) *
                          </span>
                        </label>{" "}
                        <div class="col-lg-8">
                          <input
                            id="nameEnglish"
                            type="text"
                            value={values.nameEnglish}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.nameEnglish && touched.nameEnglish
                                ? " is-invalid form-control"
                                : "form-control"
                            }
                          />
                          {errors.nameEnglish && touched.nameEnglish && (
                            <p className="error ml-2 mt-2">
                              {" "}
                              {errors.nameEnglish}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span
                            id="ember669"
                            class="tooltip-container text-dashed-underline ember-view"
                          >
                            Product Name (sw)
                          </span>
                        </label>{" "}
                        <div class="col-lg-8">
                          <input
                            id="nameSwahili"
                            type="text"
                            value={values.nameSwahili}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.nameSwahili && touched.nameSwahili
                                ? " is-invalid form-control"
                                : "form-control"
                            }
                          />
                          {errors.nameSwahili && touched.nameSwahili && (
                            <p className="error ml-2 mt-2">
                              {" "}
                              {errors.nameSwahili}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span>Barcode </span>
                        </label>
                        <div class="col-lg-8">
                          <input
                            id="code"
                            type="text"
                            value={values.code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.code && touched.code
                                ? " is-invalid form-control"
                                : "form-control"
                            }
                          />
                          {errors.code && touched.code && (
                            <p className="error ml-2 mt-2"> {errors.code}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span
                            id="ember669"
                            class="tooltip-container text-dashed-underline ember-view"
                          >
                            Brand *
                          </span>
                        </label>{" "}
                        <div class="col-lg-8">
                          <input
                            id="brand"
                            type="text"
                            value={values.brand}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.brand && touched.brand
                                ? " is-invalid form-control"
                                : "form-control"
                            }
                          />
                          {errors.brand && touched.brand && (
                            <p className="error ml-2 mt-2"> {errors.brand}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span
                            id="ember669"
                            class="tooltip-container text-dashed-underline ember-view"
                          >
                            Supplier
                          </span>
                        </label>{" "}
                        <div class="col-lg-8">
                          <Select
                            value={selectedSupplier}
                            onChange={(value) => {
                              setFieldValue("supplierId", value);
                              setSelectedSupplier(value);
                            }}
                            isSearchable={true}
                            options={supplierOptions}
                            name="supplierId"
                            isLoading={false}
                            loadingMessage={() => "Fetching supplier"}
                            noOptionsMessage={() =>
                              "No suppliers at the moment"
                            }
                          />
                          {errors.supplierId && touched.supplierId && (
                            <p className="error ml-2 mt-2">
                              {errors.supplierId.label}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span
                            id="ember669"
                            class="tooltip-container text-dashed-underline ember-view"
                          >
                            Vertical
                          </span>
                        </label>{" "}
                        <div class="col-lg-8">
                          <Select
                            value={selectedVertical}
                            onChange={(value) => {
                              setFieldValue("verticalId", value);

                              let filteredCategories =
                                productCategoryOptions.filter(
                                  (cat) => cat.verticalId === value.value
                                );
                              setSelectedVertical(value);
                              setCategoryOptions(filteredCategories);
                            }}
                            isSearchable={true}
                            options={verticalOptions}
                            name="vertical"
                            isLoading={false}
                            loadingMessage={() => "Fetching vertical"}
                            noOptionsMessage={() => "No vertical option"}
                          />
                          {errors.vertical && touched.vertical && (
                            <p className="error ml-2 mt-2">
                              {errors.vertical.label}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span
                            id="ember669"
                            class="tooltip-container text-dashed-underline ember-view"
                          >
                            Category *
                          </span>
                        </label>{" "}
                        <div class="col-lg-8">
                          <Select
                            value={selectedCategory}
                            onChange={(value) => {
                              setFieldValue("categoryId", value);
                              let filteredSubCategories =
                                productSubcategoryOptions.filter(
                                  (cat) => cat.categoryId === value.value
                                );
                              setSelectedCategory(value);
                              setSubcategoryOptions(filteredSubCategories);
                            }}
                            isSearchable={true}
                            options={categoryOptions}
                            name="category"
                            isLoading={false}
                            loadingMessage={() => "Fetching category"}
                            noOptionsMessage={() => "category appears here"}
                            required
                          />
                          {errors.category && touched.category && (
                            <p className="error ml-2 mt-2">
                              {errors.category.label}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span class="">subcategory</span>
                        </label>{" "}
                        <div class="col-lg-8">
                          <Select
                            value={selectedSubcategory}
                            onChange={(value) => {
                              setFieldValue("subCategoryId", value);
                              setSelectedSubcategory(value);
                            }}
                            isSearchable={true}
                            options={subcategoryOptions}
                            name="subcategory"
                            isLoading={false}
                            loadingMessage={() => "Fetching subcategory"}
                            noOptionsMessage={() => "subcategory appears here"}
                          />
                          {errors.subcategory && touched.subcategory && (
                            <p className="error ml-2 mt-2">
                              {errors.subcategory.label}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row form-group">
                        <label className="col-form-label required col-lg-4">
                          <span>Carton Size: </span>
                          <br />
                        </label>

                        <div className="col-lg-8">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">Units</span>
                            </div>
                            <input
                              id="cartonSize"
                              type="text"
                              value={values.cartonSize}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.cartonSize && touched.cartonSize
                                  ? " is-invalid form-control"
                                  : "form-control"
                              }
                            />
                          </div>

                          {errors.cartonSize && touched.cartonSize && (
                            <p className="error ml-2 mt-2">
                              {" "}
                              {errors.cartonSize}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row form-group">
                        <label className="col-form-label required col-lg-4">
                          <span>Carton Cost *</span>
                        </label>
                        <div className="col-lg-8">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">TZS</span>
                            </div>
                            <input
                              id="cartonCost"
                              type="text"
                              value={values.cartonCost}
                              onChange={(event) => {
                                const value = event.target.value;
                                let intValue = parseInt(
                                  value.replace(/,/g, "")
                                );
                                let unitCost = values.cartonSize
                                  ? intValue / values.cartonSize
                                  : 0;
                                let unitCostExclVAT =
                                  selectedSupplier &&
                                  selectedSupplier.isTaxExempted
                                    ? unitCost
                                    : unitCost -
                                      parseFloat(
                                        unitCost - unitCost / 1.18
                                      ).toFixed(2);

                                setFieldValue("cartonCost", value);
                                setFieldValue(
                                  "unitCostIncVat",
                                  unitCost.toLocaleString()
                                );
                                setFieldValue(
                                  "unitCostExlVat",
                                  unitCostExclVAT.toLocaleString()
                                );
                              }}
                              onBlur={handleBlur}
                              className={
                                errors.cartonCost && touched.cartonCost
                                  ? " is-invalid form-control amount"
                                  : "form-control amount"
                              }
                            />
                          </div>

                          {errors.cartonCost && touched.cartonCost && (
                            <p className="error ml-2 mt-2">
                              {" "}
                              {errors.cartonCost}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row form-group">
                        <label className="col-form-label required col-lg-4">
                          <span>Unit Cost Price (VAT Inc)</span>
                        </label>
                        <div className="col-lg-8">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">TZS</span>
                            </div>
                            <input
                              id="unitCostIncVat"
                              type="text"
                              value={values.unitCostIncVat}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.unitCostIncVat && touched.unitCostIncVat
                                  ? " is-invalid form-control amount"
                                  : "form-control amount"
                              }
                              disabled
                            />
                          </div>

                          {errors.cost && touched.cost && (
                            <p className="error ml-2 mt-2"> {errors.cost}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row form-group">
                        <label className="col-form-label required col-lg-4">
                          <span>Unit Cost Price (VAT Excl) </span>
                        </label>
                        <div className="col-lg-8">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">TZS</span>
                            </div>
                            <input
                              id="unitCostExlVat"
                              type="text"
                              value={values.unitCostExlVat}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.unitCostExlVat && touched.unitCostExlVat
                                  ? " is-invalid form-control amount"
                                  : "form-control amount"
                              }
                              disabled
                            />
                          </div>

                          {errors.cost && touched.cost && (
                            <p className="error ml-2 mt-2"> {errors.cost}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span
                            id="ember669"
                            class="tooltip-container text-dashed-underline ember-view"
                          >
                            Tax code *
                          </span>
                        </label>{" "}
                        <div class="col-lg-8">
                          <Select
                            value={selectedTaxCode}
                            onChange={(value) => {
                              setFieldValue("taxCode", value);
                              setSelectedTaxCode(value);
                            }}
                            isSearchable={true}
                            options={taxCodeOptions}
                            name="taxCode"
                            isLoading={false}
                            loadingMessage={() => "Fetching tax code"}
                            noOptionsMessage={() => "No tax code at the moment"}
                          />
                          {errors.taxCode && touched.taxCode && (
                            <p className="error ml-2 mt-2">
                              {errors.taxCode.label}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          <span
                            id="ember669"
                            class="tooltip-container text-dashed-underline ember-view"
                          >
                            Description:
                          </span>
                        </label>{" "}
                        <div class="col-lg-8">
                          <textarea
                            id="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.description && touched.description
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          ></textarea>
                          {errors.description && touched.description && (
                            <p className="error ml-2 mt-2">
                              {errors.description.label}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          Opening Stock *
                        </label>{" "}
                        <div class="col-lg-8">
                          <input
                            id="inventory"
                            type="text"
                            value={values.inventory}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.inventory && touched.inventory
                                ? " is-invalid form-control"
                                : "form-control"
                            }
                          />
                          {errors.inventory && touched.inventory && (
                            <p className="error ml-2 mt-2">
                              {errors.inventory}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4">
                          Minimum Stock *
                        </label>{" "}
                        <div class="col-lg-8">
                          <input
                            id="minStockB2B"
                            type="text"
                            value={values.minStockB2B}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.minStockB2B && touched.minStockB2B
                                ? " is-invalid form-control"
                                : "form-control"
                            }
                          />
                          {errors.minStockB2B && touched.minStockB2B && (
                            <p className="error ml-2 mt-2">
                              {errors.minStockB2B}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="visibleB2B"
                          id="visibleB2B"
                          className="form-check-input"
                          onChange={(event) => {
                            const value = event.target.checked ? "Y" : "N";
                            props.setFieldValue("visibleB2B", value);
                          }}
                          checked={values.visibleB2B === "Y"}
                        />
                        <label class="form-check-label" for="visibleB2B">
                          Is Visible
                        </label>
                      </div>
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4"></label>{" "}
                        <div class="col-lg-2 "></div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="trackB2B"
                          id="trackB2B"
                          className="form-check-input"
                          onChange={(event) => {
                            const value = event.target.checked ? "Y" : "N";
                            props.setFieldValue("trackB2B", value);
                          }}
                          checked={values.trackB2B === "Y"}
                        />
                        <label class="form-check-label" for="trackB2B">
                          Can Track
                        </label>
                      </div>
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4"></label>{" "}
                        <div class="col-lg-2 "></div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="outOfStockB2B"
                          id="outOfStockB2B"
                          className="form-check-input"
                          onChange={(event) => {
                            const value = event.target.checked ? "Y" : "N";
                            props.setFieldValue("outOfStockB2B", value);
                          }}
                          checked={values.outOfStockB2B === "Y"}
                        />
                        <label class="form-check-label" for="outOfStockB2B">
                          Is Out of Stock
                        </label>
                      </div>
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4"></label>{" "}
                        <div class="col-lg-2 "></div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="featuredB2B"
                          id="featuredB2B"
                          className="form-check-input"
                          onChange={(event) => {
                            const value = event.target.checked ? "Y" : "N";
                            props.setFieldValue("featuredB2B", value);
                          }}
                          checked={values.featuredB2B === "Y"}
                        />
                        <label class="form-check-label" for="featuredB2B">
                          Is featured
                        </label>
                      </div>
                      <div class="row form-group">
                        <label class="col-form-label required col-lg-4"></label>{" "}
                        <div class="col-lg-2 "></div>
                      </div>
                    </div>
                  </div>
                  {isError && (
                    <p className="alert alert-danger" ref={errorRef}>
                      {errorMessage}
                    </p>
                  )}
                  <div className="row">
                    <div className="form-group col-md-6 mb-4">
                      <Label htmlFor="name"> Price Packaging Unit*</Label>
                      <Select
                        options={packagingUnitsOptions}
                        onChange={(selectedOption) =>
                          handlePackagingUnitChange(selectedOption)
                        }
                      />

                      {errors.unit && touched.unit && (
                        <p className="error ml-2 mt-2"> {errors.unit}</p>
                      )}
                    </div>

                    {/* <code>{JSON.stringify(packaging)}</code> */}
                    {packaging &&
                      packaging.length > 0 &&
                      packaging.map((packagingUnit, index) => (
                        <div className="row">
                          <div className="col-md-4 packagingUnit__Heading">
                            <h2>{packagingUnit.name}</h2>
                            <br />
                            <IconButton
                              onClick={(e) =>
                                removepackagingUnit(e, packagingUnit.id)
                              }
                              className="p-0 ml-5"
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </div>
                          <div className="col-md-8 ">
                            {packagingUnit.bands &&
                              packagingUnit.bands.length > 0 &&
                              packagingUnit.bands.map((band, bandIndex) => (
                                <div
                                  className="form-row mb-4 align-items-center"
                                  key={bandIndex}
                                >
                                  <div className="form-group col-md-3 mb-4">
                                    <Label htmlFor="name"> Min Quantity</Label>

                                    <input
                                      type="number"
                                      key={bandIndex}
                                      name={`minQty-${bandIndex}`}
                                      placeholder="Eg.100"
                                      min="1"
                                      value={band.minQty}
                                      onChange={(e) => {
                                        handlePackageValuesChange(
                                          bandIndex,
                                          e,
                                          packagingUnit,
                                          "minQty"
                                        );
                                      }}
                                      className="small-placeholder"
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-md-3 mb-4">
                                    <Label htmlFor="name"> Max Quantity</Label>
                                    <input
                                      type="number"
                                      key={bandIndex}
                                      name={`maxQty-${bandIndex}`}
                                      placeholder="Eg.200"
                                      min="1"
                                      value={band.maxQty}
                                      className="small-placeholder"
                                      onChange={(e) => {
                                        handlePackageValuesChange(
                                          bandIndex,
                                          e,
                                          packagingUnit,
                                          "maxQty"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="form-group col-md-3 mb-4">
                                    <Label htmlFor="name"> Price</Label>
                                    <input
                                      type="number"
                                      key={bandIndex}
                                      name={`value-${bandIndex}`}
                                      placeholder="Eg.50"
                                      step="0.01"
                                      min="1"
                                      value={band.bandPrice}
                                      className="small-placeholder amount"
                                      onChange={(e) => {
                                        handlePackageValuesChange(
                                          bandIndex,
                                          e,
                                          packagingUnit,
                                          "bandPrice"
                                        );
                                      }}
                                      required
                                    />
                                  </div>

                                  <div className="form-group col-md-3 mb-0">
                                    <IconButton
                                      onClick={(e) =>
                                        removeBand(e, packagingUnit, bandIndex)
                                      }
                                      className="p-0 ml-5"
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              ))}
                            <div className="float-right">
                              <button
                                onClick={(e) => addBands(e, packagingUnit)}
                              >
                                + Add more bands
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label>Product Image:</label>
                    </div>
                    <div className="col-sm-12 ">
                      <ImageUploader
                        success={imageUploadSuccess}
                        imgUrl={imgUrl}
                        editMode={editMode}
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-12 mb-3">
                    <button
                      type="submit"
                      className="actionBtn"
                      disabled={isSubmitDisabled || isError}
                    >
                      {!editMode && (
                        <span>
                          Submit <NavigateNextIcon />
                        </span>
                      )}
                      {editMode && <span>Update</span>}
                    </button>
                  </div>
                  {/*JSON.stringify(props, null, 2)*/}
                </form>
              </div>
            );
          }}
        </Formik>
      )}

      {canShowSKUForm && (
        <AddSKUForm editMode={false} sku={null} product={null} addMode={true} />
      )}
    </>
  );
};

export default AddProductForm;
