import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsAction } from "../../../store/actions/UserAction";
import moment from "moment";
import "./Order.css";
import {
  changeOrderStatusAction,
  getOrdersAction,
} from "../../../store/actions/OrderAction";
import CurrencyFormat from "react-currency-format";

const OrderDetails = ({ order }) => {
  const [orderData, setOrderData] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const user = useSelector((state) => state.userManager.user);
  const userObj = useSelector((state) => state.authManager.user);
  const success = useSelector((state) => state.stateManager.success);
  const error = useSelector((state) => state.stateManager.error);
  const message = useSelector((state) => state.stateManager.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDetailsAction({ username: order.createdBy }));
  }, []);

  useEffect(() => {
    if (success) {
      order.orderStatus = orderData.status;
      setTimeout(() => dispatch(getOrdersAction()), 4000);
    }
  }, [success]);

  const approveOrder = () => {
    setSubmitDisabled(true);
    let data = {
      id: order.id,
      status: "PROCESSED",
      username: userObj.username,
      comments: "",
    };
    setOrderData(data);
    dispatch(changeOrderStatusAction(data));
    console.log("approveOrder data=", data);
  };

  const rejectOrder = () => {
    setSubmitDisabled(true);
    let data = {
      id: order.id,
      status: "REJECTED",
      username: userObj.username,
      comments: "",
    };
    setOrderData(data);
   dispatch(changeOrderStatusAction(data));
    console.log("approveOrder data=", data);
  };

  const cancelOrder = () => {
    setSubmitDisabled(true);
    let data = {
      id: order.id,
      status: "CANCELLED",
      username: userObj.username,
      comments: "",
    };
    setOrderData(data);
    dispatch(changeOrderStatusAction(data));
    console.log("approveOrder data=", data);
  };

  return (
    <div className="Order">
      <div className="row ">
        <div className="col-sm-6">
          <h5>
            From: <strong>{`${user.firstName} ${user.lastName}`}</strong>
          </h5>
          <h5>
            Email: <strong>{user.email}</strong>
          </h5>
          <h5>
            Mobile: <strong>{user.mobile}</strong>
          </h5>
          <h5>
            Role:<strong>{user.role}</strong>
          </h5>
          <h5>
            Adress: <strong>{user.address}</strong>
          </h5>
        </div>

        <div className="col-sm-6 text-right">
          <h4>Sales Order No.</h4>
          <h4 className="text-navy">{order.orderNumber}</h4>

          <h5>
            To:
            <strong>Sales Manager</strong>
          </h5>

          <h5>
            Role : <strong>Sales Manager</strong>
          </h5>

          <h5>
            <strong>Sales Order Date:</strong>
            {moment(order.createdAt).format("LLLL")}
          </h5>

          <h5>
            <strong>Sales Order State:</strong> {order.orderStatus}
          </h5>
        </div>
      </div>

      <table id="order-table" className="table table-striped">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Package</th>
            <th>Quantity Ordered</th>
            <th>X</th>
            <th> Cost (TZS)</th>
            <th>=</th>
            <th>Total Amount(TZS)</th>
          </tr>
        </thead>
        <tbody>
          {order &&
            order.orderItems !== null &&
            order.orderItems.map((product, index) => (
              <tr key={index}>
                <td>
                  <strong> {product.productName} </strong>{" "}
                </td>
                <td>
                  <strong> {product.packageName} </strong>{" "}
                </td>

                <td className="text-center">
                  {Number(product.quantity).toFixed()}
                </td>
                <td>X</td>
                <td>
                  <CurrencyFormat
                    value={product.price ?? 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </td>
                <td>=</td>
                <td>
                  <CurrencyFormat
                    value={product.finalCost ?? 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </td>
              </tr>
            ))}

          <tr className="order-total">
            <td colSpan={5}></td>
            <td className="total-title">
              <strong> SUBTOTAL</strong>
            </td>
            <td className="total-amount">
              <CurrencyFormat
                value={order.finalCost ?? 0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </td>
          </tr>
        </tbody>
      </table>

      {order.orderStatus === "PLACED" && (
        <div className="float-right mt-2 mb-2">
          <button
            className="btn btn-danger btn-sm mr-2"
            type="button"
            onClick={rejectOrder}
            disabled={submitDisabled  ? true : false}
          >
            <i className="fa fa-times"></i>Reject
          </button>

          <button
            className="btn btn-primary btn-space btn-sm"
            onClick={approveOrder}
            disabled={submitDisabled ? true : false}
          >
            <i className="fa fa-check"></i>Approve
          </button>
        </div>
      )}

      {order.orderStatus === "PROCESSED" && (
        <div className="float-right mt-2 mb-2">
          <button
            className="btn btn-danger btn-sm mr-2"
            type="button"
            onClick={cancelOrder}
            disabled={submitDisabled ? true : false}
          >
            <i className="fa fa-times"></i>Cancel
          </button>

          <a
            className="btn btn-primary btn-space btn-sm"
            href={order.taxReceipt}
            target="_blank"
          >
            <i className="fa fa-receipt mr-2"></i>Download Receipt
          </a>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
