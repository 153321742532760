import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  Polygon,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";

import {
  updateLocationAction,
  restoreDefaultMapConfigAction,
  getCurrentLocationAction,
} from "../../../store/actions/LocationAction";
import { Label } from "../../../styles/Forms";

Geocode.setApiKey("AIzaSyBwuLdw8RyUO2mNVZQ3f9ux34F_2duP_lU");
Geocode.enableDebug();

class Map extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      address: null,
      city: "",
      area: "",
      state: "",
      canPickLocation: false,
      canShowMarker: false,
      mapPosition: {
        lat: this.props.mapPosition.lat,
        lng: this.props.mapPosition.lng,
      },
      zoom: null,
      showPolygon: true,
      markerPosition: this.props.markerPosition
        ? {
            lat: this.props.markerPosition.lat,
            lng: this.props.markerPosition.lng,
          }
        : { lat: this.props.mapPosition.lat, lng: this.props.mapPosition.lng },
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log("name + value ", name, value);
    if (value) {
      this.props.getCurrentLocationAction();
    }
    this.setState({
      [name]: value,
    });
  };

  componentWillReceiveProps(nextProps) {
    const { markerPosition, address } = nextProps;
    this.setState({
      address: address,
      zoom: 16,
      showPolygon: false,
      markerPosition: markerPosition,
      mapPosition: markerPosition,
    });
    console.log("componentWillReceiveProps", markerPosition, address);
  }

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    console.log("Location Picker componentDidMount props", this.props);
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng
    ).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);

        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.markerPosition) {
  //     if (
  //       this.state.markerPosition.lat !== nextState.markerPosition.lat ||
  //       this.state.address !== nextState.address
  //     ) {
  //       return true;
  //     } else if (this.state.markerPosition.lat === nextProps.center.lat) {
  //       return false;
  //     }
  //   }
  //   return false;
  // }

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {
    // console.log("Window Closed");
  };

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event, google) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    // if (
    //   this._map.geometry.poly.containsLocation(
    //     { lat: newLat, lng: newLng },
    //     boundsObjArr,
    //   )
    // ) {
    //   //console.log("Location present")
    // } else {
    //   //console.log("Location outside Dar")
    // }
    // let convertedLatLng =  this.props.google.maps.LatLng(newLat, newLng);
    //  if (this.props.google.maps.geometry.poly.containsLocation(convertedLatLng, boundsObjArr)) {
    //    console.log("Location present")
    //  } else {
    //     console.log("Location outside Dar")
    // }

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);

        let coordinates = {
          lat: newLat,
          lng: newLng,
        };
        let data = {
          address,
          city,
          area,
          state,
          mapPosition: coordinates,
          markerPosition: coordinates,

          zoom: 15,
        };
        this.props.updateLocationAction(data);
        document.getElementById("locationAutocomplete").value = address;
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          zoom: 16,
          showPolygon: false,
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    console.log("onPlaceSelected place =  ", place);
    //console.log("plc", place);
    const address = `${place.name} , ${place.formatted_address}`,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    let coordinates = {
      lat: latValue,
      lng: lngValue,
    };
    let data = {
      address,
      city,
      area,
      state,
      mapPosition: coordinates,
      markerPosition: coordinates,
      zoom: 15,
      showPolygon: false,
    };
    console.log(data);
    this.props.updateLocationAction(data);
    // Set these values in the state.
    this.setState({
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      zoom: 16,
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
      showPolygon: false,
    });
  };

  render() {
    const { t, showMap } = this.props;
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <>
          {this.props.showMap && (
            <GoogleMap
              google={this.props.google}
              defaultZoom={this.state.zoom ? this.state.zoom : this.props.zoom}
              defaultCenter={{
                lat:
                  this.state.mapPosition && this.state.mapPosition.lat
                    ? this.state.mapPosition.lat
                    : null,
                lng:
                  this.state.mapPosition && this.state.mapPosition.lng
                    ? this.state.mapPosition.lng
                    : null,
              }}
              style={{
                marginBottom: "120px",
                display: this.props.showMap ? "block" : "none",
              }}
              ref={(map) => (this._map = map)}
            >
              {this.state.markerPosition && this.props.isLocationSaved && (
                <InfoWindow
                  onCloseClick={this.onInfoWindowClose}
                  position={{
                    lat: this.state.markerPosition.lat + 0.0018,
                    lng: this.state.markerPosition.lng,
                  }}
                  className="Name"
                >
                  <div className="addressTitle">{this.state.address}</div>
                </InfoWindow>
              )}
              {this.state.markerPosition && this.props.isLocationSaved && (
                <>
                  <Marker
                    google={this.props.google}
                    draggable={true}
                    onDragEnd={(e) =>
                      this.onMarkerDragEnd(e, this.props.google)
                    }
                    position={{
                      lat: this.state.markerPosition.lat,
                      lng: this.state.markerPosition.lng,
                    }}
                  />
                  <Marker />
                </>
              )}

              {/* For Auto complete Search Box */}
            </GoogleMap>
          )}
        </>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <Autocomplete
            id="locationAutocomplete"
            style={{
              marginBottom: "30px",
            }}
            onPlaceSelected={this.onPlaceSelected}
            types={["establishment"]}
            className="w-input form-control"
            componentRestrictions={{ country: "tz" }}
            placeholder={
              this.props.editMode ? this.props.address : "Type address here"
            }
            fields={[
              "formatted_address",
              "place_id",
              "name",
              "address_components",
              "geometry",
            ]}
          />

          <AsyncMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBwuLdw8RyUO2mNVZQ3f9ux34F_2duP_lU&v=3.exp&libraries=geometry,drawing,places "
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{ height: this.props.height, marginBottom: "20px" }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}

const mapStateToProps = (state) => {
  return {
    address: state.locationManager.address,
    mapPosition: state.locationManager.mapPosition,
    markerPosition: state.locationManager.markerPosition,
    isLoading: state.locationManager.isLoading,
    isLocationSaved: state.locationManager.isLocationSaved,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateLocationAction: (data) => dispatch(updateLocationAction(data)),
  getCurrentLocationAction: () => dispatch(getCurrentLocationAction()),
  restoreDefaultMapConfigAction: () =>
    dispatch(restoreDefaultMapConfigAction()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Map);
