import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import AddOutletForm from "./AddOutletForm";
import OutletsPage from "./OutletsPage";

const AddOutletPage = ({ resetView, editMode, outlet }) => {
  const [showOutletsPage, setShowOutletsPage] = useState(false);
  const toOutletsPage = () => {
    setShowOutletsPage(true);
  };

  if (showOutletsPage) {
    return <OutletsPage />;
  }
  return (
    <div className="row">
      <div className="col-md-1 back-arrow">
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="2x"
          className="mr-2"
          onClick={() => resetView()}
        />
      </div>
      <div className="col-md-11">
        {!editMode && <PageTopHeader title="Create an Outlet" />}
        {editMode && <PageTopHeader title="Edit an Outlet" />}

        <AddOutletForm
          editMode={editMode}
          outlet={outlet}
          resetView={resetView}
        />
      </div>
    </div>
  );
};
export default AddOutletPage;
