import React from "react";
import { PageTitle, PageButton } from "../../../styles/App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PageTopHeader = ({ title, icon, actionText, action, permission }) => {
  return (
    <div className="row mb-2">
      <div className="col-sm-6">
        <PageTitle>{title}</PageTitle>
      </div>
      <div className="col-sm-6">
        {actionText && (
          <PageButton className="float-right" onClick={action}>
            <FontAwesomeIcon icon={icon} className="mr-2" />
            {actionText}
          </PageButton>
        )}
      </div>
    </div>
  );
};

export default PageTopHeader;
