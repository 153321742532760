import {
  GET_OUTLETS_SUCCESS,
  OUTLET_DETAILS_RETRIEVED_SUCCESS,
  GET_OUTLETS_OPTIONS_SUCCESS,
  GET_OUTLETS_PRODUCT_PRICES_SUCCESS,
  GET_OUTLET_ORDERS_SUCCESS,
  GET_OUTLETS_CREDIT_LIMIT_SUCCESS,
  GET_OUTLET_CREDIT_LIMIT_SUCCESS,
  GET_LOCATED_OUTLETS_SUCCESS,
  GET_CUSTOM_PRODUCT_PRICES_SUCCESS,
  GET_OUTLETS_CATEGORIES_SUCCESS,
  GET_OUTLETS_SUB_CATEGORIES_SUCCESS,
  GET_OUTLETS_CATEGORY_OPTIONS_SUCCESS,
  GET_OUTLETS_SUBCATEGORY_OPTIONS_SUCCESS,
  GET_TERRITORY_OUTLETS_SUCCESS,
  RESET_SELECTED_USER_OUTLETS,
  OUTLET_VERTICALS_RETRIEVED_SUCCESS,
  UPDATE_OUTLET_TERRITORIES,
  CLEAR_USER_OUTLETS,
  RESET_TERRITORY_OUTLETS,
  GET_OUTLET_SUMMARY_SUCCESS,
  CLEAR_OUTLET_DETAILS,
  CLEAR_OUTLET_VERTICALS
} from "../constants/ActionTypes";

const initialState = {
  outlets: [],
  outlet: {},
  outletOptions: [],
  outletProductPrices: [],
  loading: true,
  outletOrders: [],
  total: 0,
  totalActive: 0,
  totalInactive: 0,
  creditLimits: [],
  outletCreditLimit: 0,
  locatedOutlets: [],
  customers: [],
  customProductPrices: {},
  outletCategoryOptions: [],
  outletSubcategoryOptions: [],
  territoryOutlets: [],
  outletVerticals: [],
  outletSearchResults: [],
  summary: {},
};

const outletReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OUTLETS_SUCCESS:
      return {
        ...state,
        outlets: action.payload,
        outletCategories: [],
        customers: action.payload,
        loading: false,
        total: action.total,
        totalActive: action.totalActive,
        totalInactive: action.totalInactive,
      };
    case OUTLET_DETAILS_RETRIEVED_SUCCESS:
      return { ...state, outlet: action.payload };
    case CLEAR_OUTLET_DETAILS:
      return {...state, outlet: [] };
    case CLEAR_OUTLET_VERTICALS:
      return {...state, outletVerticals: [] };
    case GET_OUTLET_SUMMARY_SUCCESS:
      return { ...state, summary: action.payload };
    case OUTLET_VERTICALS_RETRIEVED_SUCCESS:
      return { ...state, outletVerticals: action.payload };
    case GET_TERRITORY_OUTLETS_SUCCESS:
      return { ...state, territoryOutlets: action.payload };
    case UPDATE_OUTLET_TERRITORIES:
      return { ...state, outletSearchResults: action.payload };
    case RESET_TERRITORY_OUTLETS:
      return { ...state, territoryOutlets: [] };
    case CLEAR_USER_OUTLETS:
      return {
        ...state,
        territoryOutlets: [],
      };
    case RESET_SELECTED_USER_OUTLETS:
      return { ...state, territoryOutlets: [] };

    case GET_OUTLETS_OPTIONS_SUCCESS:
      return { ...state, outletOptions: action.payload };
    case GET_OUTLETS_CATEGORIES_SUCCESS:
      return { ...state, outletCategories: action.payload };
    case GET_OUTLETS_SUB_CATEGORIES_SUCCESS:
      return { ...state, outletSubcategories: action.payload };
    case GET_OUTLETS_CATEGORY_OPTIONS_SUCCESS:
      return { ...state, outletCategoryOptions: action.payload };
    case GET_OUTLETS_SUBCATEGORY_OPTIONS_SUCCESS:
      return { ...state, outletSubcategoryOptions: action.payload };

    case GET_OUTLETS_PRODUCT_PRICES_SUCCESS:
      return { ...state, outletProductPrices: action.payload };
    case GET_CUSTOM_PRODUCT_PRICES_SUCCESS:
      return { ...state, customProductPrices: action.payload };
    case GET_OUTLET_ORDERS_SUCCESS:
      return { ...state, outletOrders: action.payload, loading: false };
    case GET_OUTLETS_CREDIT_LIMIT_SUCCESS:
      return { ...state, creditLimits: action.payload, loading: false };
    case GET_OUTLET_CREDIT_LIMIT_SUCCESS:
      return { ...state, outletCreditLimit: action.payload, loading: false };
    case GET_LOCATED_OUTLETS_SUCCESS:
      return { ...state, locatedOutlets: action.payload };
    default:
      return state;
  }
};

export default outletReducer;
