import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import {
  createOrderAction,
  getOrderItemsDiscountPriceAction,
  clearDiscountsAction,
  updateOrderAction,
} from "../../store/actions/OrderAction";
import { OrderFormWrapper, Input } from "../../styles/Order";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";
import { scrollToTop } from "../../utils/window";
import { IconButton } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { removeOrderItemAction } from "../../store/actions/ProductAction";

const OrderForm = ({ customer, editMode, order }) => {
  const myInput = useRef(null);
  const [price, setPrice] = useState({});
  const [vatObj, setVatObj] = useState({});
  const [totalVAT, setTotalVAT] = useState(0);
  const [selectedPackagingUnit, setSelectedPackagingUnit] = useState({});
  const [productPackagingOptions, setProductPackagingOptions] = useState([]);
  const [defaultPackagingUnits, setDefaultPackagingUnits] = useState([]);
  const [packageIds, setPackageIds] = useState({});
  const [bandIds, setBandIds] = useState({});

  const [quantities, setQuantites] = useState({});
  const userObj = useSelector((state) => state.authManager.user);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [canCheckout, setCheckout] = useState(false);
  const [total, setTotal] = useState(0);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalObj, setTotalObj] = useState({});

  const products = useSelector((state) => state.productManager.orderItems);

  const error = useSelector((state) => state.stateManager.error);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("NewOrderForm customer + order ", customer, order);
    dispatch(clearDiscountsAction());
    setTotal(0);
    let resetQuantites = {};
    Object.keys(quantities).map((key) => {
      resetQuantites[key] = "";
    });

    setQuantites(resetQuantites);
  }, [customer]);

  useEffect(() => {
    if (products.length > 0) {
      console.log("products = ", products);
      let packagingOptions = products.map((product) =>
        product.packages.map((packageUnit) => {
          let selectedOrderItem = _.find(order.orderItems, [
            "packageId",
            packageUnit.id,
          ]);

          console.log("selectedOrderItem = ", selectedOrderItem);
          return {
            productId: product.id,
            productNumber: product.productNumber,
            productName: product.name,
            bandId: selectedOrderItem ? selectedOrderItem.bandId : null,
            packageId: packageUnit.id,
            packageQuantity: packageUnit.quantity,
            discount: 0.0,
            taxCode: product.taxCode,
            value: packageUnit.quantity,
            label: packageUnit.name,
            bands: packageUnit.sellingBands,
            taxValue: product.taxValue ?? 0,
            id:
              selectedOrderItem && selectedOrderItem.id
                ? selectedOrderItem.id
                : null,
          };
        })
      );
      setProductPackagingOptions(packagingOptions);
      console.log("packaging Options = ", packagingOptions);
      if (editMode) {
        let selectedPOptions = packagingOptions.map(
          (product, index) =>
            product.filter(
              (packagingUnit) => packagingUnit.packageId === packageIds[index]
            )[0]
        );
        console.log(
          "selectedPOptions + selectedPackagingUnit + quantities = ",
          selectedPOptions,
          selectedPackagingUnit,
          quantities
        );
        let savedSelectedPackagingOptions = {};
        selectedPOptions.map((productPackagingUnit) => {
          if (productPackagingUnit) {
            savedSelectedPackagingOptions = {
              ...savedSelectedPackagingOptions,
              [productPackagingUnit.packageId]: productPackagingUnit,
            };
          }
        });

        // console.log("savedSelectedPackagingOptions = ", savedSelectedPackagingOptions);

        setSelectedPackagingUnit(savedSelectedPackagingOptions);
      }
    }
  }, [products.length]);

  useEffect(() => {
    if (editMode) {
      let pricesObj = {};
      let quantitiesObj = {};
      let totalObj = {};
      let packageIds = {};
      let vatObj = {};
      let bandIds = {};
      order.orderItems.map((item, index) => {
        pricesObj[item.packageId] = item.price;
        quantitiesObj[item.packageId] = item.quantity;
        bandIds[item.packageId] = item.bandId;
        vatObj[item.packageId] = item.tax ? parseFloat(item.tax) : 0;
        totalObj[item.packageId] = item.cost;
        packageIds[index] = item.packageId;
        defaultPackagingUnits[index] = item.packageId;
      });

      let total = _.sum(_.values({ ...totalObj }));
      let totalVAT = _.sum(_.values({ ...vatObj }));

      console.log(
        "total + totalObj + totalVATarr= ",
        total,
        totalObj,
        totalVAT
      );

      setPrice(pricesObj);
      setQuantites(quantitiesObj);
      setTotalObj(totalObj);
      setTotal(total);
      setPackageIds(packageIds);
      setVatObj(vatObj);
      setTotalVAT(totalVAT);
    }
  }, []);

  const handleQuantityChange = (qty, packageId) => {
    console.log(
      "handleQuantityChange qty + packageId + selectedPackagingUnit",
      qty,
      packageId,
      selectedPackagingUnit
    );
    console.log("price  = ", price);

    let currentPackagingUnitPrice = price[packageId] ?? 0;

    console.log("Current packagingUnit price  = ", currentPackagingUnitPrice);

    if (packageId) {
      let currentPackagingUnit = selectedPackagingUnit[packageId];
      let currentBandPrices = currentPackagingUnit.bands.map(
        (band) => band.bandPrice
      );
      let minPrice = _.min(currentBandPrices);
      let maxPrice = _.max(currentBandPrices);
      console.log("selectedPrice >= minPrice && selectedPrice <= maxPrice");
      console.log(currentPackagingUnitPrice, minPrice, maxPrice);

      if (
        (currentPackagingUnitPrice >= minPrice &&
          currentPackagingUnitPrice <= maxPrice) ||
        currentPackagingUnitPrice === 0
      ) {
        setIsError(false);

        if (qty === "") {
          setQuantites({ ...quantities, [packageId]: "" });
          delete totalObj[packageId];
          let total = _.sum(_.values({ ...totalObj, [packageId]: 0 }));
          setTotal(total);

          //setIsSubmitDisabled(true);
          //setIsSubmitDisabled(true);
        } else {
          let quantity = parseInt(qty);
          let isCustomPrice =
            selectedPackagingUnit[packageId] &&
            selectedPackagingUnit[packageId].bands &&
            _.find(selectedPackagingUnit[packageId].bands, [
              "bandPrice",
              currentPackagingUnitPrice,
            ]);

          isCustomPrice = isCustomPrice ? false : true;
          console.log("isCustomPrice = ", isCustomPrice);

          let isBandALlowed =
            selectedPackagingUnit[packageId] &&
            selectedPackagingUnit[packageId].bands &&
            selectedPackagingUnit[packageId].bands.filter((band) => {
              let selectedPackage = selectedPackagingUnit[packageId];
              if (editMode && isCustomPrice) {
                band["bandPrice"] = currentPackagingUnitPrice;
              }
              if (quantity >= band.minQty && quantity <= band.maxQty) {
                selectedPackage["bandId"] = band.id;

                console.log("Selected Band  = ", band);
                setSelectedPackagingUnit({
                  ...selectedPackagingUnit,
                  [packageId]: selectedPackage,
                });

                return band;
              } else if (quantity >= band.minQty && band.maxQty === null) {
                selectedPackage["bandId"] = band.id;
                setSelectedPackagingUnit({
                  ...selectedPackagingUnit,
                  [packageId]: selectedPackage,
                });
                return band;
              } else {
              }
            });

          if (isBandALlowed && isBandALlowed.length > 0) {
            let band = isBandALlowed[0];

            setIsError(false);
            let totalItem = Math.round(quantity * band.bandPrice);
            let total = _.sum(
              _.values({ ...totalObj, [packageId]: totalItem })
            );
            let vat = parseFloat(
              totalItem -
                totalItem /
                  (1 + selectedPackagingUnit[packageId].taxValue / 100)
            );
            let updatedVATObj = {
              ...vatObj,
              [packageId]: vat,
            };
            let totalVATarr = Object.keys(updatedVATObj).map(
              (key) => updatedVATObj[key]
            );

            let updatedVAT = totalVATarr.reduce(
              (a, b) => parseFloat(a) + parseFloat(b),
              0
            );

            setTotal(total.toFixed(2));
            setTotalVAT(updatedVAT);
            setTotalObj({
              ...totalObj,
              [packageId]: parseFloat(totalItem.toFixed(2)),
            });

            setVatObj(updatedVATObj);
            setQuantites({ ...quantities, [packageId]: quantity });
            setBandIds({ ...bandIds, [packageId]: band.id });
            setPrice({
              ...price,
              [packageId]:
                price[packageId] ?? parseFloat(band.bandPrice.toFixed(2)),
            });
            return false;
          } else {
            setIsError(true);
            setErrorMessage(`Sorry, you cannot place with ${quantity} item(s)`);
          }
        }
      } else {
        setIsError(true);
        setErrorMessage(
          `Sorry , the adjusted price should be between ${minPrice} and ${maxPrice}`
        );
      }
    } else {
      setIsError(true);
      setErrorMessage(
        "You must select a packaging unit before entering quantity"
      );
    }
  };

  const handlePriceChange = (selectedPackagePrice, packageId) => {
    // let currentPackagingUnit = selectedPackagingUnit.filter(
    //   (unit) => unit.packageId === packageId
    // )[0];
    console.log(
      "handlePriceChange price + packageId = ",
      selectedPackagePrice,
      packageId
    );
    let selectedPrice = parseFloat(selectedPackagePrice);

    let currentPackagingUnit = selectedPackagingUnit[packageId];
    let currentBandPrices = currentPackagingUnit.bands.map(
      (band) => band.bandPrice
    );
    let minPrice = _.min(currentBandPrices);
    let maxPrice = _.max(currentBandPrices);

    if (currentPackagingUnit) {
      if (selectedPackagePrice === "") {
        setQuantites({ ...quantities, [packageId]: "" });
        setPrice({
          ...price,
          [packageId]: "",
        });
        setVatObj({ ...vatObj, [packageId]: 0 });
        delete totalObj[packageId];
        let total = _.sum(_.values({ ...totalObj, [packageId]: 0 }));
        setTotal(total);
        //setIsSubmitDisabled(true);
        //setIsSubmitDisabled(true);
      } else {
        if (selectedPrice >= minPrice && selectedPrice <= maxPrice) {
          let totalItem = quantities[packageId] * selectedPrice;
          let total = _.sum(_.values({ ...totalObj, [packageId]: totalItem }));
          setTotal(total.toFixed(2));
          setTotalObj({
            ...totalObj,
            [packageId]: parseFloat(totalItem.toFixed(2)),
          });
          setPrice({
            ...price,
            [packageId]: selectedPackagePrice,
          });
          setIsError(false);
        } else {
          setPrice({
            ...price,
            [packageId]: selectedPackagePrice,
          });
          setIsError(true);
          setErrorMessage(
            `Sorry , the adjusted price should be between ${minPrice} and ${maxPrice}`
          );
        }
      }
    }
  };

  const onSubmit = (e) => {
    setSubmitDisabled(true);
    e.preventDefault();
    scrollToTop();
    setTimeout(() => {
      if (total === 0) {
        setIsError(true);
        setErrorMessage("You cannot submit an empty order");
        scrollToTop();
      } else {
        setIsError(false);
        setSubmitDisabled(true);

        let orderItems = Object.keys(selectedPackagingUnit).map((key) => {
          if (selectedPackagingUnit[key]) {
            let savedProduct = null;

            if (editMode) {
              savedProduct = _.find(order.orderItems, [
                "productId",
                selectedPackagingUnit[key].productId,
              ]);
            }

            // console.log("savedProduct = ", savedProduct);
            return {
              productId: selectedPackagingUnit[key].productId,
              productNumber: selectedPackagingUnit[key].productNumber,
              productName: selectedPackagingUnit[key].productName,
              quantity: quantities[selectedPackagingUnit[key].packageId]
                ? parseInt(quantities[selectedPackagingUnit[key].packageId])
                : 0,
              price: price[selectedPackagingUnit[key].packageId],
              discount: 0.0,
              cost: totalObj[selectedPackagingUnit[key].packageId],
              finalCost: totalObj[selectedPackagingUnit[key].packageId],
              bandId: selectedPackagingUnit[key].bandId,
              packageId: selectedPackagingUnit[key].packageId,
              taxCode: selectedPackagingUnit[key].taxCode,
              id: selectedPackagingUnit[key].id,
            };
          }
        });

        orderItems = _.filter(orderItems, function (o) {
          return o.quantity && o.quantity > 0;
        });

        if (orderItems && Array.isArray(orderItems) && orderItems.length > 0) {
          let data = {
            outletId: customer.value,
            outletName: customer.label,
            outletNumber: customer.outletNumber,
            username: userObj.username,
            latitude: -6.776853815580169,
            longitude: 39.20898199081421,
            areaId: customer.areaId,
            areaName: customer.areaName,
            orderItems: orderItems,
          };

          if (editMode) {
            data["id"] = order.id;
            console.log("Update Order  = ", order);
            console.log(JSON.stringify(data));
            dispatch(updateOrderAction(data));
          } else {
            dispatch(createOrderAction(data));
          }

          // console.log("createOrderAction");
          // console.log(JSON.stringify(data));
        } else {
          setIsError(true);
          setErrorMessage("You cannot submit an empty order");
          scrollToTop();
        }
      }
    }, 300);
  };

  const handlePackagingUnitChange = (productPackagingUnit, index) => {
    setIsError(false);

    setPackageIds({
      ...packageIds,
      [index]: productPackagingUnit.packageId,
    });

    setSelectedPackagingUnit({
      ...selectedPackagingUnit,
      [productPackagingUnit.packageId]: productPackagingUnit,
    });
  };

  const removeOrderItem = (index) => {
    dispatch(removeOrderItemAction(index));
    if (packageIds[index]) {
      let packageId = packageIds[index];
      let newPackageIds = {};

      delete totalObj[packageId];
      delete vatObj[packageId];
      delete quantities[packageId];
      delete price[packageId];
      delete packageIds[index];
      Object.keys(packageIds).map((id, index) => {
        newPackageIds[index] = packageIds[id];
      });

      setPackageIds(newPackageIds);

      let totalVATarr = Object.keys(vatObj).map((key) => vatObj[key]);

      let updatedVAT = totalVATarr.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
      let total = _.sum(_.values({ ...totalObj, [packageId]: 0 }));

      //let totalVat = _.sum(_.values({ ...vatObj, [packageId]: 0 }));
      setTotal(total);
      setTotalVAT(updatedVAT);
    }
  };

  return (
    <OrderFormWrapper>
      <div className="row">
        <div className="col-md-12"></div>
      </div>
      {isError && <p className="alert alert-danger mt-2"> {errorMessage}</p>}
      {/* <h3>selectedPackagingUnit</h3>
      <code>{JSON.stringify(selectedPackagingUnit)}</code>*/}
      {/* <code>{JSON.stringify(price)}</code> */}

      {/* <h3>Price</h3>
      <code>{JSON.stringify(price)}</code> */}
      <div className="row ">
        <div className="col-sm-6"></div>
        <div className="col-sm-6 "></div>
      </div>
      <div className="row row-bg">
        <div className="col-sm-2">Item</div>
        <div className="col-sm-2">Packaging Unit</div>
        <div className="col-sm-1">Carton Cost</div>
        <div className="col-sm-1">Unit Price </div>
        <div className="col-sm-1">Quantity</div>
        <div className="col-sm-1">Sub-total</div>
        <div className="col-sm-1">VAT</div>
        <div className="col-sm-2">Total</div>
      </div>
      <form onSubmit={onSubmit} className="product-form">
        <div className="gray-bg mb-3">
          {products &&
            products.length > 0 &&
            products.map((product, index) => {
              return (
                <div className="row row-items pb-5">
                  <input ref={myInput} type="hidden" />
                  <input
                    type="hidden"
                    key={product.identity}
                    name={product.identity}
                    value={JSON.stringify({
                      productId: product.identity,
                      quantity: 0,
                      price: 0,
                    })}
                  />
                  <div className="col-sm-2">{product.name}</div>
                  <div className="col-sm-2">
                    <Select
                      options={productPackagingOptions[index]}
                      onChange={(option) =>
                        handlePackagingUnitChange(option, index)
                      }
                      value={
                        productPackagingOptions[index] &&
                        productPackagingOptions[index].length > 0 &&
                        productPackagingOptions[index].filter(
                          (option) => option.packageId === packageIds[index]
                        )[0]
                      }
                    />
                  </div>
                  <div className="col-sm-1">
                    {editMode ? (
                      <Input
                        type="price"
                        key={product.id}
                        name={`quantity-${product.id}`}
                        min="1"
                        value={price[packageIds[index]]}
                        onChange={(e) => {
                          handlePriceChange(e.target.value, packageIds[index]);
                        }}
                        className="small-placeholder"
                      />
                    ) : price[packageIds[index]] ? (
                      <CurrencyFormat
                        value={price[packageIds[index]]}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    ) : (
                      0
                    )}
                  </div>
                  <div className="col-sm-1">
                    <CurrencyFormat
                      value={
                        price[packageIds[index]] &&
                        selectedPackagingUnit[packageIds[index]]
                          ? price[packageIds[index]] /
                            selectedPackagingUnit[packageIds[index]].value
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                    />
                  </div>
                  <div className="col-sm-1">
                    <Input
                      type="number"
                      value={quantities[packageIds[index]]}
                      key={product.identity}
                      name={`quantity-${product.identity}`}
                      placeholder="Eg.100"
                      min="1"
                      onChange={(e) => {
                        handleQuantityChange(e.target.value, packageIds[index]);
                      }}
                      className="small-placeholder"
                    />
                  </div>
                  <div className="col-sm-1">
                    {totalObj[packageIds[index]] ? (
                      <CurrencyFormat
                        value={parseFloat(
                          totalObj[packageIds[index]] -
                            vatObj[packageIds[index]] ?? 0
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    ) : (
                      0
                    )}
                  </div>

                  <div className="col-sm-1">
                    {vatObj[packageIds[index]] ? (
                      <CurrencyFormat
                        value={parseFloat(vatObj[packageIds[index]]).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    ) : (
                      0
                    )}
                  </div>

                  <div className="col-sm-2">
                    {totalObj[packageIds[index]] ? (
                      <CurrencyFormat
                        value={totalObj[packageIds[index]]}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    ) : (
                      0
                    )}
                    <span className="float-right">
                      {" "}
                      <IconButton
                        onClick={() => removeOrderItem(index)}
                        className="p-0"
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </span>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="gray-bg p-2">
          <div className="row">
            <div className="col-sm-3 faded text-uppercase font-weight-bold">
              Subtotal
            </div>
            <div className="col-sm-9 text-right total">
              {
                <CurrencyFormat
                  value={parseFloat(total - totalVAT).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              }
              TZS
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 faded text-uppercase font-weight-bold">
              VAT
            </div>
            <div className="col-sm-9 text-right total">
              {
                <CurrencyFormat
                  value={parseFloat(totalVAT).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              }
              TZS
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 faded text-uppercase font-weight-bold">
              Total
            </div>
            <div className="col-sm-9 text-right total">
              {
                <CurrencyFormat
                  value={total}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              }
              TZS
            </div>
          </div>
          <hr />
          <button
            type="submit"
            className={
              isError
                ? "btn-order btn-light btn-disabled"
                : "pointer btn-order "
            }
            disabled={submitDisabled || isError ? true : false}
          >
            {editMode ? "Update Order" : "Place Order"}
          </button>
        </div>
        <hr />
      </form>
    </OrderFormWrapper>
  );
};

export default OrderForm;
