import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import { createHashHistory as createHistory } from "history";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { whichBrowser } from "../utils/browser";

export const history = createHistory();
const { NODE_ENV } = process.env;

const persistConfig = {
  key: "root",
  storage: storageSession,
  blacklist: ["stateManager"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = [thunk];
let enhancers = [applyMiddleware(...middleware)];

if (NODE_ENV === "development" && whichBrowser() !== "Safari") {
  enhancers.push(
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
}

export const store = createStore(persistedReducer, {}, compose(...enhancers));

export const persistor = persistStore(store);
