import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import "./Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { CSVLink, CSVDownload } from "react-csv";

import { dispatch } from "d3-dispatch";
import {
  changeEventDateAction,
  AddEventAction,
  createRoutePlanAction,
  resetCalendarEventsAction,
  getUserRoutePlanAction,
  selectEventAction,
  updateRoutePlanAction,
  toggleEventModalAction,
} from "../../../store/actions/routePlanActions";
import {
  createPdf,
  generateEventData,
  getOutletsAndAreasFromEvents,
} from "../../../utils/routePlanUtils";
import moment from "moment";
import EventInfoDialog from "../../dialogs/EventInfoDialog/EventInfoDialog";
// import the third-party stylesheets directly from your JS
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css"; // needs additional webpack config!
import { updateRoutePlan } from "../../../services/routePlanService";
import { scrollToTop } from "../../../utils/window";

const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"],
];

const Calendar = () => {
  const calendarRef = useRef(null);
  const isopen = useSelector((state) => state.routePlanManager.isModalOpen);
  const [selectedValue, setSelectedValue] = React.useState("");
  const excelDownloadRef = useRef(null);
  const [csvEvents, setCSVEvents] = useState([]);

  const [event, setEvent] = useState(null);
  const calendarEvents = useSelector(
    (state) => state.routePlanManager.calendarEvents
  );

  const isPlanExist = useSelector(
    (state) => state.routePlanManager.isPlanExist
  );

  const routePlanId = useSelector(
    (state) => state.routePlanManager.routePlanId
  );

  const selectedUser = useSelector((state) => state.userManager.user);
  const userObj = useSelector((state) => state.authManager.user);
  //const csvLink = useRef();
  const csvLinkRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCalendarEventsAction());
    let data = {
      username: selectedUser.username,
      fromDate: moment().subtract(6, "months").format("DD/MM/Y"),
      toDate: moment().add(6, "months").format("DD/MM/Y"),
    };
    dispatch(getUserRoutePlanAction(data));
  }, []);

  const handleEventReceive = (e) => {
    let eventInfo = e.event.toPlainObject();

    const calendar = calendarRef.current.getApi();
    calendar.getEventById(eventInfo.id).remove();

    console.log("handleEventReceive eventInfo = ", eventInfo);

    if (moment.now() > moment(eventInfo.start).add(1, "days")) {
      Swal.fire(
        "Oops...",
        "Sorry , you cannot plan for past days . Choose a day from today! ",
        "error"
      );

      return false;
    }

    dispatch(AddEventAction(eventInfo));
  };

  useEffect(() => {
    console.log("useEffect calendarEvents= ", calendarEvents);
    const calendar = calendarRef.current.getApi();
    calendar.removeAllEvents();
    calendar.addEventSource(calendarEvents);
    //calendar.refetchEvents();
  }, [calendarEvents.length]);

  const handleEventChange = (changeInfo) => {
    let eventInfo = changeInfo.event.toPlainObject();
    dispatch(changeEventDateAction(eventInfo));
  };

  const SaveCalendar = () => {
    scrollToTop();
    let outletsAndAreas = getOutletsAndAreasFromEvents(calendarEvents);
    console.log(
      "SaveCalendar getOutletsAndAreasFromEvents = ",
      outletsAndAreas
    );
    let data = {
      username: selectedUser.username,
      fullName: `${selectedUser.firstName} ${selectedUser.lastName}`,
      createdBy: userObj.username,
      createdByName: `${userObj.firstName} ${userObj.lastName}`,
      ...outletsAndAreas,
    };

    console.log(JSON.stringify(data));

    if (isPlanExist) {
      data["id"] = routePlanId;
      //console.log(JSON.stringify(data));
      dispatch(updateRoutePlanAction(data));
    } else {
      dispatch(createRoutePlanAction(data));
    }
  };

  const handleEventClick = (clickInfo) => {
    let eventInfo = clickInfo.event.toPlainObject();

    let eventData = generateEventData(eventInfo);
    dispatch(selectEventAction(eventData));
    dispatch(toggleEventModalAction(true));
    setEvent(eventData);
  };

  const handleClose = (value) => {
    setSelectedValue(value);
    dispatch(toggleEventModalAction(false));
  };

  const exportExcel = () => {
    console.log("Export Excel ");
    let titleColumns = [
      "Title",
      "Type",
      "Start Date",
      "End Date",
      "Repat Tyoe",
    ];
    if (csvLinkRef?.current) {
      if (calendarEvents.length > 0) {
        let allEvents = calendarEvents.map((event) => {
          let singleEvent = [
            event.title,
            event.areaId ? "Area" : "Outlet",
            moment(event.start).format("dddd, MMMM D, YYYY"),
            moment(event.end).format("dddd, MMMM D, YYYY"),
            event.repeatType,
          ];

          return singleEvent;
        });
        let eventsWithTitles = [titleColumns, ...allEvents];
        console.log("eventsWithTitles = ", eventsWithTitles);
        setCSVEvents(eventsWithTitles);
        console.log("Ecport excel all Events = ", allEvents);
        setTimeout(() => csvLinkRef.current.link.click(), 2000);
      }
    }

    //CSVLink.current?.focus();
    //excelDownloadRef.current.;
  };

  // const renderEventContent = (eventInfo) => {
  //   return (
  //     <>
  //       <i>{eventInfo.event.title}</i>
  //     </>
  //   );
  // };

  const exportPdf = () => {
    if (calendarEvents.length > 0) {
      let allEvents = calendarEvents.map((event) => ({
        title: event.title,
        entityType: event.areaId ? "Area" : "Outlet",
        startDate: moment(event.start).format("dddd, MMMM D, YYYY"),
        endDate: moment(event.end).format("dddd, MMMM D, YYYY"),
        repeatType: event.repeatType,
      }));

      allEvents = _.orderBy(allEvents, ["startDate"], ["desc"]);

      var columns = [
        { title: "Start Date", dataKey: "startDate" },
        { title: "End Date", dataKey: "endDate" },
        { title: "Repeat", dataKey: "repeatType" },
        { title: "Name", dataKey: "title" },
        { title: "Type", dataKey: "entityType" },
      ];

      var rows = allEvents;
      let title = `${selectedUser.firstName} ${selectedUser.lastName} Call cycle`;
      createPdf(columns, rows, title);
    }
  };

  return (
    <div>
      <CSVLink data={csvEvents} ref={csvLinkRef}>
        _
      </CSVLink>
      ;
      <FullCalendar
        plugins={[interactionPlugin, dayGridPlugin, bootstrapPlugin]}
        themeSystem="bootstrap"
        initialView="dayGridMonth"
        eventDurationEditable={false}
        editable={true}
        droppable={true}
        events={calendarEvents}
        eventChange={handleEventChange}
        eventReceive={handleEventReceive}
        ref={calendarRef}
        eventClick={handleEventClick}
        // eventContent={renderEventContent}
        customButtons={{
          exportPDFBtn: {
            text: "custom!",
            click: exportPdf,
            bootstrapFontAwesome: "fa-file-pdf",
          },
          exportExcelBtn: {
            text: "Excel",
            click: exportExcel,
            bootstrapFontAwesome: "fa-file-excel",
          },
        }}
        headerToolbar={{
          left: "prev,next,today",
          center: "title",
          right: "exportPDFBtn,exportExcelBtn",
        }}
      />
      <div className="float-right mt-3">
        <button className="btn btn-primary" onClick={SaveCalendar}>
          {isPlanExist ? "Update Calendar" : "Save Calendar"}
        </button>
      </div>
      <EventInfoDialog event={event} open={isopen} onClose={handleClose} />
    </div>
  );
};

export default Calendar;
