
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UsersPieChart from '../reports/charts/UsersPieChart';
//import BarGraph from '../reports/charts/barGraph';
import ColumnChart from '../reports/charts/ColumnChart';
import BarChart from '../reports/charts/BarChart'; 
import DonutChart from './charts/DonutChart';
import Table from "../../components/dom/Table/Table";
import DatePicker from "react-datepicker";
import { BoxWrapper,ButtonBox } from "../../styles/schedule";
import {
  getUsersAction,
  getRolesOptionsAction,
  getUserDetailsAction,
} from "../../store/actions/UserAction";
import {
  geUsersByRoleAction,
  geUsersByStatusAction,
  fetchAllTargetsAction


} from "../../store/actions/ReportsAction";
import moment from "moment";
import { getUsersByRole, getUsersByStatus } from "../../services/ReportsService";
import { setIn } from "formik";



const UserSummary = (params) => {
    const [reportId, setReportId] = useState(null);
    const user = useSelector(state => state.authManager.user);
    const users = useSelector((state) => state.userManager.users);
    const usersByStatus = useSelector((state) => state.reportManager.usersByStatus);
    const usersByRole = useSelector((state) => state.reportManager.usersByRole);
    const allTargets = useSelector((state) => state.reportManager.allTargets)

    const [data, setData] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);


    console.log("allTargets=>",allTargets)
  

    const dispatch = useDispatch();


    useEffect(() => {
      let filter = {
        orderBy: "lastLogin",
        orderDir: "desc",
      };
  
      dispatch(getUsersAction({}));
      dispatch(getRolesOptionsAction());
      dispatch(geUsersByRoleAction({}));
      dispatch(geUsersByStatusAction({}));
      dispatch(fetchAllTargetsAction({}))
    }, [dispatch]);

  
  
    const onChange = dates => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };
  
    const changeData = () => {
     // setData(generateData());
    };
  
  
    const onSubmit = (e) => {
      e.preventDefault();
      let filter = {
        startDate : moment(startDate).format("DD/MM/YYYY"),
        endDate : moment(endDate).format("DD/MM/YYYY"),
        }
      let userFilter  = {
                        searches: [
                              {
                                 key: "lastLoginStartDate",
                                 value: moment(startDate).format("YYYYMMDD"),
                              },
                              {
                                 key: "lastLoginEndDate",
                                 value: moment(endDate).format("YYYYMMDD"),
                              }
                              ]
                        }
      setLoading(true)

      dispatch(getUsersAction(userFilter));
      dispatch(getRolesOptionsAction(filter));
      dispatch(geUsersByRoleAction(userFilter));
      dispatch(geUsersByStatusAction(userFilter));
      dispatch(fetchAllTargetsAction(filter))
      setTimeout(
        () =>
        setLoading(false) ,
        5000
      );
    
     
    };



    const columns = [
        {
          Header: "Created Date",
          accessor: (d) => moment(d.createDate).format("ddd MMM D , YYYY"),
          key: ["createDate"],
        },
        {
          Header: "Name",
          accessor: (d) => `${d.firstName} ${d.lastName}`,
          key: ["firstName", "lastName"],
        },
    
        {
          Header: "Role",
          accessor: (d) => d.role.replace(/-/g, " "),
          filterMethod: (filter, row) => {
            if (row[filter.id]) {
              return row[filter.id].includes(filter.value);
            }
          },
          key: ["role"],
        },
        {
          Header: "username",
          accessor: "username",
          key: ["username"],
        },
        {
          Header: "address",
          accessor: "address",
          key: ["address"],
        },
    
        {
          Header: "Last Login",
          accessor: (d) =>
            d.lastLogin ? moment(d.lastLogin).format("ddd MMM D ,  YYYY") : "-",
          key: ["last_login"],
        },
    
        {
          Header: "status",
          accessor: "status",
          filterMethod: (filter, row) => {
            if (row[filter.id]) {
              return row[filter.id].includes(filter.value);
            }
          },
          key: ["status"],
        },
        {
          Header: "Updated Date",
          accessor: (d) => moment(d.updatedDate).format("ddd MMM D , YYYY"),
          key: ["updatedDate"],
        },
      
      ];

      const targetsColumn = [
        {
          Header: "Created Date",
          accessor: (d) => moment(d.createdAt).format("ddd MMM D , YYYY"),
          key: ["createDate"],
        },
        {
          Header: "Name",
          accessor: (d) => `${d.fullName}`,
          key: ["fullName"],
        },
        {
          Header: "Type",
          accessor: "type",
          key: ["type"],
        },
        {
          Header: "Target",
          accessor: "target",
          key: ["target"],
        },
        {
          Header: "Achieved",
          accessor: "achieved",
          key: ["achieved"],
        },

        {
          Header: "Start Date",
          accessor: (d) => moment(d.startDate).format("ddd MMM D , YYYY"),
          key: ["startDate"],
        },

        {
          Header: "End Date",
          accessor: (d) => moment(d.endDate).format("ddd MMM D , YYYY"),
          key: ["endDate"],
        },
      
      ];
 
    
    return (
       <> 
         
                    <h3>User summary</h3>

                    { loading &&
                      <div className="alert alert-primary" role="alert">
                          Loading...
                        </div>
                      }

                    <div className = "col-sm-12 shadow-sm p-3 mb-5">
                    <BoxWrapper>
                          <form onSubmit={onSubmit} role="form">
                            <div className="row">
                                  <div className="col-sm-2">
                                    <DatePicker
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      placeholderText="from"
                                      className="date-picker-input"
                                    />
                                  </div>
                               
                                  <div className="col-sm-2">
                                    <DatePicker
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      placeholderText="to"
                                      className="date-picker-input"
                                    />
                                  </div>
                                
                                <div className="col-sm-2">
                                  <ButtonBox type="submit">
                                    filter
                                  </ButtonBox>
                                </div>
                            
                            </div>
                          </form>
                        </BoxWrapper>
                    </div>
                    


                    <div className = "row">
                        <div className = "col-sm-6 shadow p-3 mb-5 bg-white rounded">
                        <h6 className="text-muted font-weight-normal mt-0" >Users</h6>
                            {usersByRole && usersByRole.userRole &&
                              <DonutChart
                               // title = "Users "
                                dataLabels = {usersByRole.userRole}
                                dataValues = {usersByRole.userRoleCount}
                            />}
                        </div>
                        <div className = "col-sm-6 shadow p-3 mb-5 bg-white rounded">
                        <h6 className="text-muted font-weight-normal mt-0" >User Status by role</h6>
                            {usersByStatus && usersByStatus.statusRole && 
                              <BarChart
                               // title = "User Status by role"
                                xaxisData = {usersByStatus.statusRole}
                                yaxisData = { [{
                                                name: 'Active',
                                                data:usersByStatus.activeStatus
                                            }, {
                                                name: 'Inactive',
                                                data: usersByStatus.inactiveStatus
                                            },]
                                      }
                                // xDataType = "datetime"
                                // dateFormant = "DD MMM YYYY"
                            />}
                        </div>
                    </div>
                    <div>
                      {/* <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                              <BarChart
                                  title = "Performance per sales rep"
                                  xaxisData = {["j.allan","b.marandus","j.bushushu","w.elias","b.duncan","j.masinde","k.mwansasu","n.gozy"]}
                                  yaxisData = { [{
                                                  name: 'Target',
                                                  data: [50, 100, 50, 50,50, 20, 25, 5]
                                              }, 
                                              {
                                                  name: 'Achievement',
                                                  data: [40, 10, 0,30,50, 20, 25, 5]
                                              },
                                              //   {
                                              //     name: 'Shop',
                                              //     data: [30, 20, 10, 0,50, 20, 25, 5]
                                              // },
                                            ]
                                        }
                                  stacked = {false}
                                  horizontal = {false}
                              />
                          </div> */}
                    </div>
                    
                  { allTargets && allTargets.outletName &&

                     <div className = "row">
                        <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                         <h6 className="text-muted font-weight-normal mt-0" >Targets</h6>
                            <Table columns={targetsColumn} data={allTargets}/>
                        </div>
                    </div>}

                    <div className = "row">
                        <div className = "col-sm-12 shadow p-3 mb-5 bg-white rounded">
                         <h6 className="text-muted font-weight-normal mt-0" >Users Table</h6>
                            <Table columns={columns} data={users}/>
                        </div>
                    </div>

                    
                   
       
       </>
    
 )
}
    

export default UserSummary