import React, { useState } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import RolesPage from "../../pages/roles/RolesPage";
import AddRolePage from "../../pages/roles/AddRolePage";
import AddPermissionPage from "../../pages/permissions/AddPermissionPage";
import PermissionsPage from "../../pages/permissions/PermissionsPage";
import AddRolePermissionPage from "../../pages/roles/AddRolePermissionPage";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import { TabsWrapper } from "../../styles/Tabs";
import "react-tabs/style/react-tabs.css";

const SettingsPage = () => {
  const [canShowRoleForm, setCanShowRoleForm] = useState(false);
  const [canShowPermissionForm, setCanShowPermissionForm] = useState(false);
  const [canShowTabs, setCanShowTabs] = useState(true);
  const [canShowRolePermissionForm, setCanShowRolePermissionForm] = useState(
    false
  );
  const [editMode, setEditMode] = useState(false);
  const [permission, setPermission] = useState({});
  const [role, setRole] = useState({});

  const showAddRoleForm = () => {
    setCanShowTabs(false);
    setCanShowPermissionForm(false);
    setCanShowRoleForm(true);
    setEditMode(false);
  };

  const showAddPermissionForm = role => {
    setCanShowPermissionForm(true);
    setCanShowTabs(false);
    setCanShowRoleForm(false);
    setEditMode(false);
  };

  const resetView = () => {
    setCanShowTabs(true);
    setCanShowRoleForm(false);
    setCanShowPermissionForm(false);
    setCanShowRolePermissionForm(false);
  };

  const editPermission = row => {
    setPermission(row);
    setEditMode(true);
    setCanShowTabs(false);
    setCanShowRoleForm(false);
    setCanShowPermissionForm(true);
  };

  const editRole = row => {
    setRole(row);
    setEditMode(true);
    setCanShowTabs(false);
    setCanShowRoleForm(true);
    setCanShowPermissionForm(false);
  };

  const editRolePermissions = role => {
    setRole(role);
    setEditMode(true);
    setCanShowTabs(false);
    setCanShowRoleForm(false);
    setCanShowPermissionForm(false);
    setCanShowRolePermissionForm(true);
  };

  return (
    <>
      {canShowTabs && (
        <>
          <PageTopHeader title="Settings" />

          <TabsWrapper>
            <Tabs>
              <TabList>
                <Tab>Roles</Tab>
                <Tab>Permissions</Tab>
              </TabList>

              <TabPanel>
                <RolesPage
                  show={showAddRoleForm}
                  handleEdit={editRole}
                  handlePermissions={editRolePermissions}
                />
              </TabPanel>
              <TabPanel>
                <PermissionsPage
                  addPermission={showAddPermissionForm}
                  edit={editPermission}
                />
              </TabPanel>
            </Tabs>
          </TabsWrapper>
        </>
      )}

      {canShowRoleForm && (
        <AddRolePage resetView={resetView} editMode={editMode} role={role} />
      )}
      {canShowPermissionForm && (
        <AddPermissionPage
          resetView={resetView}
          editMode={editMode}
          permission={permission}
        />
      )}

      {canShowRolePermissionForm && (
        <AddRolePermissionPage
          resetView={resetView}
          editMode={editMode}
          role={role}
        />
      )}
    </>
  );
};

export default SettingsPage;
