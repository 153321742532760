import {
  ACTION_FAIL,
  ACTION_SUCCESS,
  RESET_PASSWORD_ACTION_SUCCESS,
  RESET_VIEW,
  LOADING,
  DONE,
  ADD_PRODUCT_SUCCESS,
  BULK_EDIT_SUCCESS,
} from "../constants/ActionTypes";

const initialState = {
  error: false,
  success: false,
  message: "",
  resetSuccess: false,
  inProgress: false,
  canResetView: false,
  isComplete: false,
  isLoading: false,
  isProductAdded: false,
  isBulkEditTriggered: false,
  link: "",
  data: {
    imageUrl: "https://s3.amazonaws.com/aimrough/potraits/default-avatar.png",
  },
};

const stateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION_SUCCESS:
      return { ...state, error: false, success: true, message: payload };
    case ACTION_FAIL:
      return {
        ...state,
        error: true,
        success: false,
        message: payload,
      };
    case BULK_EDIT_SUCCESS:
      return {
        ...state,
        isBulkEditTriggered: true,
        success: true,
        message: payload.message,
        link: payload.link,
      };
    case RESET_VIEW:
      return { ...state, canResetView: true };
    case LOADING:
      return { ...state, isLoading: true };
    case DONE:
      return { ...state, isLoading: false };

    case RESET_PASSWORD_ACTION_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        message: payload,
        resetSuccess: true,
      };
    case ADD_PRODUCT_SUCCESS:
      return { ...state, isProductAdded: true };
    default:
      return initialState;
  }
};

export default stateReducer;
