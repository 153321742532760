import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddProductForm from "./AddProductForm";
import PageTopHeader from "../../components/general/PageTopHeader/PageTopHeader";
import AddVerticalForm from "./AddVerticalForm";
import AddPackagingUnitForm from "./AddPackagingUnitForm";

const AddPackagingUnitPage = ({ editMode, packagingUnit, resetView }) => {
  return (
    <div className="row">
      <div className="col-md-1 back-arrow">
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="2x"
          className="mr-2"
          onClick={() => resetView()}
        />
      </div>
      <div className="col-md-11">
        {!editMode && <PageTopHeader title="Create Packaging Unit" />}
        {editMode && <PageTopHeader title="Edit  Packaging Unit" />}
        <AddPackagingUnitForm
          resetView={resetView}
          editMode={editMode}
          packagingUnit={packagingUnit}
        />
      </div>
    </div>
  );
};

export default AddPackagingUnitPage;
