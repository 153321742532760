import React, { useEffect } from "react";
import {
  addRoleAction,
  editRoleAction,
  getRolesAction
} from "../../store/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  FormWrapper,
  Input,
  Label,
  Select,
  InputWrapper,
  ButtonSmall
} from "../../styles/Forms";

const AddRoleForm = ({ editMode, role, resetView }) => {
  const success = useSelector(state => state.stateManager.success);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      resetView();
    }
  });

  useEffect(() => {
    return () => setTimeout(() => dispatch(getRolesAction()), 30000);
  });

  return (
    <Formik
      initialValues={{
        name: editMode ? role.name : "",
        description: editMode ? role.description : ""
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          if (editMode) {
            values["name"] = role.name;

            dispatch(editRoleAction(values));
          } else {
            //console.log(values);
            dispatch(addRoleAction(values));
          }

          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Role Name is required "),
        description: Yup.string().required("Role description is required ")
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;

        return (
          <div>
            <FormWrapper>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-row">
                  <div className="form-group col-md-12 mb-3">
                    <Label htmlFor="firstName">Role Name</Label>
                    <Input
                      id="name"
                      placeholder="Enter Role Name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.name && touched.name
                          ? "text-input form-control is-invalid"
                          : "text-input form-control"
                      }
                      disabled={editMode}
                    />

                    {errors.name && touched.name && (
                      <p className="error mt-2 ml-2"> {errors.name}</p>
                    )}
                  </div>
                  <div className="form-group col-md-12 mb-3">
                    <Label htmlFor="lastName">Role Description </Label>
                    <Input
                      id="description"
                      placeholder="Enter description"
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.description && touched.description
                          ? "text-input form-control is-invalid"
                          : "text-input form-control"
                      }
                    />
                    {errors.description && touched.description && (
                      <p className="error mt-2 ml-2"> {errors.description}</p>
                    )}
                  </div>
                </div>

                <div className="form-group col-md-12 mb-3">
                  <ButtonSmall type="submit" disabled={isSubmitting}>
                    {!editMode && <span>Create Role</span>}
                    {editMode && <span>Edit Role</span>}
                  </ButtonSmall>
                </div>
              </form>
            </FormWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddRoleForm;
