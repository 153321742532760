import {
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_OPTIONS_SUCCESS,
  GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS,
  GET_PRODUCT_SUBCATEGORIES_SUCCESS,
  GET_PRODUCT_SUBCATEGORY_OPTIONS_SUCCESS,
  GET_TAX_CODES_SUCCESS,
  GET_TAX_CODE_OPTIONS_SUCCESS,
  ADD_ORDER_ITEMS,
  GET_OUTLETS_OPTIONS_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  GET_PRODUCT_VERTICALS_SUCCESS,
  GET_PACKAGING_UNIT_SUCCESS,
  GET_PACKAGING_UNIT_OPTIONS_SUCCESS,
  GET_MEASURING_UNITS_SUCCESS,
  GET_MEASUREMENT_UNIT_OPTIONS_SUCCESS,
  GET_PRODUCT_VERTICAL_OPTIONS_SUCCESS,
  S3_UPLOAD_SUCCESS,
  ACTION_SUCCESS,
  S3_UPLOAD_STARTED,
  GET_ORDERED_PRODUCT_DETAILS_SUCCESS,
  RESET_ORDER_ITEMS,
  START_LOADING,
  REMOVE_ORDER_ITEM,
} from "../constants/ActionTypes";
const initialState = {
  categories: [],
  products: [],
  productOptions: [],
  isLoading: false,
  categoryOptions: [],
  subcategories: [],
  subCategoryOptions: [],
  taxCodes: [],
  taxCodeOptions: [],
  verticals: [],
  orderItems: [],
  addedProduct: {},
  packagingUnits: [],
  isProductCreationInProgress: false,
  packagingUnitsOptions: [],
  measuringUnits: [],
  measurementUnitsOptions: [],
  verticalOptions: [],
  isProductsUploadedToS3: false,
  message: "",
};

function ProductReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload };
    case ADD_ORDER_ITEMS:
      return { ...state, orderItems: [...state.orderItems, action.payload] };
    case REMOVE_ORDER_ITEM:
      return {
        ...state,
        orderItems: state.orderItems.filter(
          (item, index) => index !== action.payload
        ),
      };
    case RESET_ORDER_ITEMS:
      return { ...state, orderItems: [] };
    case GET_ORDERED_PRODUCT_DETAILS_SUCCESS:
      return { ...state, orderItems: action.payload };

    case GET_PRODUCT_SUBCATEGORIES_SUCCESS:
      return { ...state, subcategories: action.payload };
    case START_LOADING:
      return { ...state, isLoading: true };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        isLoading: false,
        isProductsUploadedToS3: false,
        message: "",
      };
    case GET_PRODUCT_OPTIONS_SUCCESS:
      return { ...state, productOptions: action.payload };
    case GET_PRODUCT_CATEGORY_OPTIONS_SUCCESS:
      return { ...state, categoryOptions: action.payload };
    case GET_PRODUCT_SUBCATEGORY_OPTIONS_SUCCESS:
      return { ...state, subCategoryOptions: action.payload };
    case GET_TAX_CODES_SUCCESS:
      return { ...state, taxCodes: action.payload };
    case GET_TAX_CODE_OPTIONS_SUCCESS:
      return { ...state, taxCodeOptions: action.payload };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        addedProduct: action.payload,
        isProductCreationInProgress: true,
      };
    case GET_PRODUCT_VERTICALS_SUCCESS:
      return { ...state, verticals: action.payload };
    case GET_PACKAGING_UNIT_SUCCESS:
      return { ...state, packagingUnits: action.payload };
    case GET_PACKAGING_UNIT_OPTIONS_SUCCESS:
      return { ...state, packagingUnitsOptions: action.payload };
    case GET_MEASUREMENT_UNIT_OPTIONS_SUCCESS:
      return { ...state, measurementUnitsOptions: action.payload };
    case GET_MEASURING_UNITS_SUCCESS:
      return { ...state, measuringUnits: action.payload };
    case GET_PRODUCT_VERTICAL_OPTIONS_SUCCESS:
      return { ...state, verticalOptions: action.payload };
    case S3_UPLOAD_SUCCESS:
      return {
        ...state,
        isProductsUploadedToS3: true,
        message: action.payload,
      };
    case S3_UPLOAD_STARTED:
      return {
        ...state,
        isProductsUploadedToS3: true,
        message: action.payload,
      };

    case ACTION_SUCCESS:
      return {
        ...state,
        isProductsUploadedToS3: false,
        message: "",
      };

    default:
      return state;
  }
}

export default ProductReducer;
